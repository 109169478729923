export let childs = [
    {
        "id": "01000000-ac12-0242-560b-08dcd2f11bbe",
        "name": "Абакаров Газимагомед Абакарович",
        "birthDay": "27.07.2017",
        "city": {
            "name": "Махачкала",
            "id": "01000000-ac12-0242-ffd2-08dcd1ad717b"
        },
        "school": {
            "name": "МБОУ \"Лицей №5\" г. Махачкала",
            "id": "01000000-ac12-0242-5149-08dcd1ad8b43"
        },
        "classObj": {
            "number": 1,
            "letter": "А",
            "id": "01000000-ac12-0242-22b9-08dcd1adce16"
        },
        isUpdate: false,
        "update": {
            "id": "01000000-ac12-0242-560b-08dcd2f11bbe",
            "name": "Абакаров Газимагамед Абакарович1",
            "birthDay": "27.09.2017",
            status: -1,
            "city": {
                "name": "Махачкала",
                "id": "01000000-ac12-0242-ffd2-08dcd1ad717b"
            },
            "school": {
                "name": "МБОУ \"Лицей №9\" г. Махачкала",
                "id": "01000000-ac12-0242-5149-08dcd1ad8b49"
            },
            "classObj": {
                "number": 1,
                "letter": "А",
                "id": "01000000-ac12-0242-22b9-08dcd1adce16"
            },
        }
    },
    {
        "id": "02000000-ac12-0242-560b-65dcd2f11bbe",
        "name": "Абакарова Зульфия Абакаровна",
        "birthDay": "13.02.2015",
        "city": {
            "name": "Махачкала",
            "id": "01000000-ac12-0242-ffd2-08dcd1ad717b"
        },
        "school": {
            "name": "МБОУ \"Лицей №5\" г. Махачкала",
            "id": "01000000-ac12-0242-5149-08dcd1ad8b43"
        },
        "classObj": {
            "number": 4,
            "letter": "Г",
            "id": "01000000-ac12-0242-11b9-01dcd1adce13"
        },
    },
]