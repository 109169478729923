import PasswordIcon from '../../../../../img/autorization-password-none-icon.svg'
import PasswordActiveIcon from '../../../../../img/autorization-password-check-icon.svg'


const ProfileDataBlock = ({data, label, displayPass, setDisplayPass, currentData={}, isUpdateData=false}) =>{
    return(
        <div className={`user-popup__current-changes-data wd-50-m16`}>
            <div className='user-popup__current-changes-data-subtitle'>{label}</div>

            <label htmlFor="" className='popup__label mb-4'>ФИО</label>
            <input type="text" className={`popup__input mb-16 ${(isUpdateData && currentData.name !== data.name)? 'border-active': ''}`} readOnly value={data.name}/>


            <label htmlFor="" className='popup__label mb-4'>Телефон</label>
            <input type="text" className={`popup__input popup__disable-input mb-16 ${(isUpdateData && currentData.phone !== data.phone)? 'border-active': ''}`} readOnly value={data.phone}/>

            <label htmlFor="" className='popup__label mb-4'>E-mail</label>
            <input type="text" className={`popup__input popup__disable-input  ${(isUpdateData && currentData.email !== data.email)? 'border-active': ''}`} readOnly value={data.email}/>
        </div>
    )
}

export default ProfileDataBlock;