import { useEffect } from 'react';
import EditEmail from './EditEmail';
import EditPassword from './EditPassword';
import './index.scss'
import PersonalInform from './PersonalInform';
import TeachingInfrom from './TeachingInform';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeacherAboutData } from '../../../redux/teacher/about/asyncActions';
import { selectTeacherProfile } from '../../../redux/teacher/about/selectors';
import { setUserNavBarActive } from '../../../redux/users/navigations/userNavigationsSlice';

const About = () =>{
    const dispatch = useDispatch()

    const {teacherProfileData, teacherProfileTeachingData} = useSelector(selectTeacherProfile)

    useEffect(()=>{
        dispatch(fetchTeacherAboutData())
        dispatch(setUserNavBarActive(1))
    }, [])
    return (
        <div className={`teacher__about teacher-wrapper`}>
            <div className='teacher__about-title mb-24'>Профиль</div>

            <PersonalInform data={teacherProfileData}/>

            <TeachingInfrom teachingData={teacherProfileTeachingData}/>

            <EditPassword email={teacherProfileData.email}/>

            <EditEmail profileData={teacherProfileData}/>
            
        </div>
    )
}

export default About;