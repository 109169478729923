import { useDispatch, useSelector } from "react-redux"
import { setPopupBack } from "../../../../redux/popup/popupSlice"
import { useEffect, useState } from "react"
import Header from "../../Header"
import { selectAdminTeachers } from "../../../../redux/admin/teachers/teachers/selectors"
import { fetchAdminTeachersData } from "../../../../redux/admin/teachers/teachers/asyncActions"
import { useDebouncedValue } from "../../../../services/clientHome"
import TeachersTableElem from "../TeachersTableElem"
import AdminPopup from "../../Popup/AdminPopup"
import { setAdminTeacherChecked, setAdminTeachersDataDeleteElem, setAdminTeachersFilters, setAdminTeachersScrollReset } from "../../../../redux/admin/teachers/teachers/teachersSlice"
import DeleteBlock from "../../Popup/DeleteBlock/DeleteBlock"
import WarningBlock from "../../Popup/WarningBlock/WarningBlock"
import { scrollingPagination } from './../../../../services/clientHome';
import { axiosProxyCatchPost } from './../../../../axiosProxy';

const Teachers = () =>{
    const dispatch = useDispatch()

    const { 
        adminTeachersData, pageLast, scrollReset, loading, adminTecahersFilter:filters, adminTeacherChecked
    } = useSelector(selectAdminTeachers);

    const [popupType, setPopupType] = useState('')
    const [page, setPage] = useState(0)
    const [query, setQuery] = useState('')
    const [error, setError] = useState('')

    const searchText = useDebouncedValue(query, 600)

    useEffect(()=>{
        dispatch(fetchAdminTeachersData({query, count: 30, page: 0, isScrolling: false, filters: filters.post}))
        setPage(0)
    }, [searchText, filters])

    useEffect(()=>{
        dispatch(setAdminTeachersFilters({city: {id: 0, name: ''}, school: {id: 0, name: ''}, post: {}}))
    }, [])


    const editTeacherFunc = (item) =>{
        dispatch(setAdminTeacherChecked(item))
        dispatch(setPopupBack('open'))
        setPopupType('EditTeacher')
    }

    const deletedTeacher = (setLoading) =>{
        axiosProxyCatchPost({
            url:'/api/v1/administrative/teachers/delete-teacher',
            data: {id: adminTeacherChecked.id},
            resolved: (data)=>{
                dispatch(setAdminTeachersDataDeleteElem(adminTeacherChecked.id))
                setPopupType('')
                setLoading(false)
            },
            rejected: (error)=>{
                if(error && error.data && typeof error.data.detail === 'string') setError(error.data.detail)
                else setError('Что-то пошло не так*')
                setLoading(false) 
            }
        })
    }

    return (
        <div className='admin__teachers-teachers'>
            <Header query={query} setQuery={setQuery}/>
            
            <div className='admin-wrapper'>
                <div className="admin__teachers-top">
                    <div className="admin__teachers-top-title">
                        Преподаватели
                    </div>
                    <div className='admin__teachers-top-buttons d-flex'>
                        <button className='admin__teachers-top-button d-flex align-center' onClick={()=>{
                            dispatch(setPopupBack('open'))
                            setPopupType('TeachersFilters')
                        }}>
                            <div className='admin__teachers-top-button-label'>Фильтры</div>
                            <div className='admin__teachers-top-button-icon filters'/>
                        </button>
                        <button className='admin__teachers-top-button d-flex align-center' onClick={()=>{
                            dispatch(setPopupBack('open'))
                            setPopupType('AddTeacher')
                        }}>
                            <div className='admin__teachers-top-button-label'>Добавить преподователя</div>
                            <div className='admin__teachers-top-button-icon plus'/>
                        </button>
                    </div>
                </div>

                <div className={`admin__teachers-table`}>
                    <div className='admin__teachers-table-line title d-flex justify-between align-center'>
                        <div className="admin__teachers-table-line-item name">ФИО</div>
                        <div className="admin__teachers-table-line-item city">Город</div>
                        <div className="admin__teachers-table-line-item school">Школа</div>
                        <div className="admin__teachers-table-line-item email">Логин</div>
                        <div className="admin__teachers-table-line-item password">Пароль</div>
                    </div>

                    <div className='admin__teachers-table-container'onScroll={
                        (e)=>scrollingPagination(
                            e, 
                            ()=>setPage(page+1), 
                            ()=>dispatch(fetchAdminTeachersData({query, count: 30, page: page+1, isScrolling: true, filters: filters.post})),
                            pageLast,
                            loading,
                            scrollReset, 
                            ()=>dispatch(setAdminTeachersScrollReset(false))
                        )}>
                        {
                            adminTeachersData.map(item=><TeachersTableElem item={item} key={`admin__teachers-table-line-item-key-${item.id}`} clickFunc={()=>editTeacherFunc(item)}/>)
                        }
                    </div>
                </div>
            </div>
            <AdminPopup type={popupType} props={{typeEdit: setPopupType}}/>
            <DeleteBlock popupBack={(popupType === 'Delete')? 'open': ''} props={{title: 'Удаление преподователя', subtitle:<>Вы действительно хотите удалить <span>{adminTeacherChecked.name}</span>?</>, closePopup: setPopupType, clickFunk:deletedTeacher}}/>
            <WarningBlock popupBack={(popupType === 'Warning')? 'open': ''} props={{title:'Ошибка', subtitle: error, closePopup: setPopupType}}/>
        </div>
    )
}

export default Teachers;

// 'Не удалось удалить преподователя, попробуйте еще раз.'