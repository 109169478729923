// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../img/user-profile-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teacher__about-personal-inform-top {
  width: 100%; }
  .teacher__about-personal-inform-top-name-icon {
    width: 56px;
    height: 56px;
    margin-right: 16px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
    background-size: contain; }
  .teacher__about-personal-inform-top-name {
    font-weight: 600;
    font-size: 20px;
    min-width: max-content; }

.teacher__about-personal-inform-save-buttons {
  width: 100%;
  max-height: 0;
  transition: max-height 0.3s ease-in-out, margin-top 0.3s ease-in-out;
  overflow: hidden; }
  .teacher__about-personal-inform-save-buttons.active {
    max-height: 120px;
    margin-top: 24px; }
`, "",{"version":3,"sources":["webpack://./src/components/Teacher/About/PersonalInform/index.scss"],"names":[],"mappings":"AACI;EACI,WAAW,EAAA;EAEX;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,oEAAuE;IACvE,wBAAwB,EAAA;EAG5B;IACI,gBAAgB;IAChB,eAAe;IACf,sBAAsB,EAAA;;AAI9B;EACI,WAAW;EACX,aAAa;EACb,oEAAoE;EACpE,gBAAgB,EAAA;EAJnB;IAOO,iBAAiB;IACjB,gBAAgB,EAAA","sourcesContent":[".teacher__about-personal-inform{\n    &-top{\n        width: 100%;\n\n        &-name-icon{\n            width: 56px;\n            height: 56px;\n            margin-right: 16px;\n            background: url(../../../../img/user-profile-icon.svg) no-repeat center;\n            background-size: contain;\n        }\n\n        &-name{\n            font-weight: 600;\n            font-size: 20px;\n            min-width: max-content;\n        }\n    }\n\n    &-save-buttons{\n        width: 100%;\n        max-height: 0;\n        transition: max-height 0.3s ease-in-out, margin-top 0.3s ease-in-out;\n        overflow: hidden;\n\n        &.active{\n            max-height: 120px;\n            margin-top: 24px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
