export const additionalDocumentsData = [
    {
        "id": "e7260596-a357-4f84-8fc6-92fcb47b30d77777",
        "name": "Соглашение о расторжении договора",
        "size": "14 КБ",
        "docType": "PDF",
        "status": null,
        "link": null,
        "type": 2,
        "layoutId": "4c7b3f72-bfe6-4d7b-ac31-f2dc4d5a197b",
        "comment": null
    },
    {
        "id": "4c7b3f72-bfe6-4d7b-ac31-f2dc4d5a197b",
        "name": "Доп соглашение на мат капитал",
        "size": "14 КБ",
        "docType": "PDF",
        "status": null,
        "link": null,
        "type": 2,
        "layoutId": "4c7b3f72-bfe6-4d7b-ac31-f2dc4d5a197b",
        "comment": null
    },
]

export const documentsData = [
    {
        "id": "01000000-ac12-0242-46c0-08dd0c86d46e",
        "name": "Договор на доп образование КФ 21",
        "size": "3746 КБ",
        "docType": ".pdf",
        "status": 1,
        "link": "temp/66b61f6a-7017-4b79-b36c-b1d87b8e5a58_Дегавцов Роберт Тимурович_..pdf",
        "type": 1,
        "layoutId": "d48a8dea-70e3-445b-a2da-2a5158e91bef",
        "comment": "Тестовый Комментарий"
    },
    {
        "id": "01000000-ac12-0242-dff2-08dd0c7b6008",
        "name": "Согласие на зачисление на программы",
        "size": "4736 КБ",
        "docType": ".pdf",
        "status": 1,
        "link": "temp/859d4e39-a313-4a72-9bea-288c45ea2b91_Дегавцов Роберт Тимурович_..pdf",
        "type": 1,
        "layoutId": "3c1100d2-1ed5-4c58-80c2-ede13f02f46a",
        "comment": ""
    },
    {
        "id": "01000000-ac12-0242-bb27-08dd0c7b9609",
        "name": "Согласие на фото (дополнительно)",
        "size": "5673 КБ",
        "docType": ".pdf",
        "status": 1,
        "link": "temp/78fe95f0-2b9a-4e75-973e-3b262b8bb8b8_Дегавцов Роберт Тимурович_..pdf",
        "type": 1,
        "layoutId": "829125e8-aa33-4f89-9a2d-f4c1905ee7a8",
        "comment": ""
    }
]