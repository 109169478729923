import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RefusalComment from './../../RefusalComment';

import { selectAdminAplicationConfirm } from '../../../../../redux/admin/aplications/aplicationConfirm/selectors';
import { fetchAdminReContractChildConfirmAplication } from '../../../../../redux/admin/aplications/aplicationConfirm/asyncActions';


import './index.scss'
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import { setAdminAplicationsDataDeleteElem } from '../../../../../redux/admin/aplications/aplications/aplicationsSlice';
import { checkIsEditingChild, getDateFormatFuncServer } from '../../../../../services/clientHome';
import { isCorrectDate, correctorName, getMonthName } from './../../../../../services/clientHome';
import MaskedInput from '../../../../MaskedInput/MaskedInput';
import { ChildClass, City, PaginationMonth, School } from '../../../../Filters/FiltersList';



const programElem = (item) =>{
    const {id, name, color} = item;
    return(
        <div className={`admin-popup__application-user-verification-programs-item ${color}`} key={`admin-popup__application-user-verification-programs-item-${id}`}>{name}</div>
    )
}


const ReContractChildConfirm = ({closePopup, popupBack}) =>{
    const dispatch = useDispatch();

    const { adminReContractChildConfirmAplication:checkData } = useSelector(selectAdminAplicationConfirm);    

    const [activeList, setActiveList] = useState('')
    const [loading, setLoading] = useState(false)
    const [childEdited, setChildEdited] = useState(false)

    const [commentIsActive, setCommentIsActive] = useState(false)
    const [error, setError] = useState('')

    const [searchData, setSearchData] = useState({
        city: '',
        school: ''
    })

    const [childformData, setChildFormData] = useState({
        id: 0,
        name: '',
        birthDay: '',
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        classNumber: '',
        classLetter: '',
        classObj: {id: 0, number: '', letter: ''},
        programs: [],
        month: {id: 0, label: '', year: ''},////
        discount: ''
    })

    useEffect(()=>{
        dispatch(fetchAdminReContractChildConfirmAplication({id: checkData.applicationId}))
        setActiveList('')
        setError('')
        setCommentIsActive(false)
    }, [popupBack])

    useEffect(()=>{
        cancelChanges()
    }, [checkData])


    useEffect(()=>{
        if(checkIsEditingChild(childformData, {...checkData, birthDay: new Date(checkData.birthDay).toLocaleDateString()}))
            setChildEdited(true)
        else if(childEdited)
            setChildEdited(false)
    }, [childformData])
    

    const cancelChanges = () =>{
        setChildFormData({
            ...checkData,
            classNumber: checkData.classObj.number,
            classLetter: checkData.classObj.letter,
            birthDay: checkData.birthDay? new Date(checkData.birthDay).toLocaleDateString(): '',
            // month: (checkData.month && checkData.month.id)? checkData.month: {id: new Date(checkData.startDate).getMonth()+1, label: getMonthName(new Date(checkData.startDate).getMonth()+1), year: new Date(checkData.startDate).getFullYear()}
            month: (checkData.month && checkData.month.id)? checkData.month: {id: checkData.startMonth, label: getMonthName(checkData.startMonth), year: new Date(checkData.startDate).getFullYear()},
            discount: ''
            // discount: checkData.discount
        })
    }

    const confirmReContractChildFunc = (confirm, comment='') =>{
        setError('')
        let postData = {
            id: checkData.id,
            status: confirm? 1: -1,
        }


        if(!confirm && comment) postData.comment = comment;
        


        if(!confirm || !childEdited){
            setLoading(true)
            confirmReContractChildPostFunc(postData)
        }
        else{
            if(childEdited){
                setError('')
                let fieldVal = {}
                if(!((childformData.birthDay.length === 10 || childformData.birthDay.length === 11) && childformData.birthDay.indexOf('_') === -1 && isCorrectDate(childformData.birthDay)))
                    fieldVal.birthDay = true;

                if(childformData.name !== '' && childformData.city.id && childformData.school.id && childformData.classLetter && childformData.classNumber && !Object.keys(fieldVal).length && (childformData.month && childformData.month.id)){
                    setLoading(true)
                    let studentPostData = {
                        id: childformData.id, 
                        name: correctorName(childformData.name), 
                        birthDay: getDateFormatFuncServer(childformData.birthDay),
                        cityId: childformData.city.id,
                        schoolId: childformData.school.id,
                        classId: childformData.classObj.id,
                        startDate: getDateFormatFuncServer(new Date(childformData.month.year, childformData.month.id - 1, 1).toLocaleDateString()),
                        sale: +childformData.discount
                    }
                        
                    axiosProxyCatchPost({
                        url: '/api/v1/administrative/finance/update-students-profile',
                        data: {data: [studentPostData]},
                        resolved: (data)=>{
                            confirmReContractChildPostFunc(postData)               
                        },
                        rejected: (error)=>{
                            if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                            else setError('Что-то пошло не так*')
                            setLoading(false)
                        }
                    })  
                }
                else if(Object.keys(fieldVal).length){
                    if(!error) setError('Некорректная дата рождения*')
                }
                else{
                    if(!error) setError('Необходимо заполнить все поля*')
                } 
            }
        }
        
    }

    const confirmReContractChildPostFunc = (postData) =>{
        setLoading(true)
        axiosProxyCatchPost({
            url: '/api/v1/administrative/applications/studenr-verify',
            data: {...postData, studentId: postData.id},
            resolved: (data)=>{
                axiosProxyCatchPost({
                    url: '/api/v1/administrative/applications/update-application-status',
                    data: { ...postData, id: checkData.applicationId},
                    resolved: (data)=>{
                        dispatch(setAdminAplicationsDataDeleteElem(checkData.applicationId))
                        closePopup()
                        setLoading(false)
                    },
                    rejected: (error)=>{
                        if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                        else setError('Что-то пошло не так*')
                        setLoading(false)                        
                    }
                })
            },
            rejected: (error)=>{     
                if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                else setError('Что-то пошло не так*')           
                setLoading(false)                
            }
        })
    }

    return(
        <div>
            <div className={`admin-popup__application-user-verification-container additionaly-popup ${commentIsActive? 'deactive': 'active'} d-flex justify-center`} onClick={(e)=>{
                if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__button') && e.target.closest('.popup__drop-list-checked') === null){
                    setActiveList('')
                }
            }}>
                <div className={`popup__container pr-14 popup-wd-780`}>
                    <div className='d-flex justify-center align-center mb-24'>
                        <div className='popup__title'>
                            Вторичный договор
                        </div>
                    </div>
                    
                    <div className='admin-popup__application-user-verification-child-scroll'>

                        <div className='popup__info-label mb-24'>
                            {/* Родитель: <span>{checkData.parent.name}</span> */}
                            Родитель: <span>{checkData.parentName}</span>
                        </div>

                        <label className="popup__label mb-4">ФИО</label>
                        <input type={'text'} readOnly={false} value={childformData.name} className="popup__input mb-16" onInput={(e)=>{
                            setChildFormData({...childformData, name: e.target.value})
                        }}/>

                        <label className="popup__label mb-4">Дата рождения</label>
                        <MaskedInput
                            className={`popup__input mb-16`}
                            mask="__.__.____"
                            replacement={{_ : /[0-9]/}}
                            showMask={true}
                            separate={true}
                            defaultValue={childformData.birthDay}
                            name = 'endDate'
                            readOnly={false}
                            placeholder=''
                            onChange={(e)=>{
                                setChildFormData({...childformData, birthDay: e.target.value})
                            }}
                        />

                        <City
                            activeList={activeList} 
                            setActiveList={setActiveList} 
                            formData={childformData} 
                            setFormData={setChildFormData} 
                            searchData={searchData} 
                            setSearchData={setSearchData}
                            setError={setError}
                            classes={`mb-16`}
                            url='/api/v1/administrative/programs'
                        />

                        <School
                            activeList={activeList} 
                            setActiveList={setActiveList} 
                            formData={childformData} 
                            setFormData={setChildFormData} 
                            searchData={searchData} 
                            setSearchData={setSearchData}
                            setError={setError}
                            classes={`mb-16`}
                            url='/api/v1/administrative/programs'
                        />

                        <ChildClass 
                            activeList={activeList} 
                            setActiveList={setActiveList} 
                            formData={childformData} 
                            setFormData={setChildFormData}
                            setSearchData={setSearchData}
                            setError={setError}
                            classes={`mb-16`}
                            url='/api/v1/administrative/programs'
                            // classes={''}
                        />

                        <PaginationMonth 
                            activeList={activeList} 
                            setActiveList={setActiveList} 
                            formData={childformData} 
                            setFormData={setChildFormData}
                            setError={setError}
                            classes={`mb-16`}
                            isLastBlock={true}
                            label={'Месяц начала занятий'}
                        />

                        <label className="popup__label mb-4">Скидка</label>
                        <div className={`admin-popup__application-discount-input-additionaly-container mb-16`}>
                            <input type="text" value={childformData.discount === ''? childformData.discount: +childformData.discount} className={`admin-popup__application-discount-input-additionaly`} onChange={(e)=>{
                                if((e.target.value < 100 && e.target.value.match(/^[0-9]+$/) && e.target.value >= 0) || e.target.value === '') setChildFormData({...childformData, discount: e.target.value})
                            }}/>
                            <div className='admin-popup__application-discount-input-additionaly-val'>{childformData.discount === ''? childformData.discount: +childformData.discount}{childformData.discount? '%': ''}</div>
                        </div>

                        <label className='popup__label mb-12'>Программы</label>
                        <div className='admin-popup__application-user-verification-programs d-flex align-center flex-wrap'>
                            {
                                childformData.programs.map(programElem)
                            }
                        </div>

                        {
                            childEdited?
                            <div className='d-flex mt-24'>
                                <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={()=>cancelChanges()}>Отменить изменения</button>
                                <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={()=>confirmReContractChildFunc(true)}>Подтвердить с изменениями</button>
                            </div>

                            :<div className='d-flex mt-24'>
                                <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={()=>setCommentIsActive(true)}>Отклонить</button>
                                <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={()=>confirmReContractChildFunc(true)}>Подтвердить</button>
                            </div>
                        }
                    </div>

                    <div className={`popup__error height-auto ${error? 'active': ''}`}>{error}</div> 
                </div>
               
            </div>
            <RefusalComment setCommentClose={()=>setCommentIsActive(false)} classes={`additionaly-popup verification-comment ${commentIsActive? 'active': 'deactive'}`} clickFunc={(comment)=>confirmReContractChildFunc(false, comment)}/>
        </div>
    )
}

export default ReContractChildConfirm;