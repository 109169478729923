import MaskedInput from './../../../../../MaskedInput/MaskedInput';
import { City, School, ChildClass } from './../../../../../Filters/FiltersList';
import { useState } from 'react';


const EditDataBlock = ({formData, setFormData, currentData, activeList, setActiveList, setError, subTitleNone = false}) =>{

    const [searchData, setSearchData] = useState({
        city: '',
        school: ''
    })

    return(
        <div className={`admin-popup__edit-data-confirm-edit ${subTitleNone? '': 'wd-50-m16'} `} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__button') && !e.target.classList.contains('popup__input') && e.target.closest('.popup__drop-list-checked') === null){
                setActiveList('')
                setSearchData({city: '', school: ''})
            }
        }}>
            <div className={`admin-popup__edit-data-confirm-subtitle ${subTitleNone? 'd-none': ''}`}>Новые данные</div>
                
            <label className="popup__label mb-4">ФИО</label>
            <input type={'text'} value={formData.name} className={`popup__input mb-16 ${(currentData.name !== formData.name)? 'border-active': ''}`} onInput={(e)=>{
                setFormData({...formData, name: e.target.value})
            }}/>

            <label className="popup__label mb-4">Дата рождения</label>
            <MaskedInput
                className={`popup__input mb-16 ${(new Date(currentData.birthDay).toLocaleDateString() !== new Date(formData.birthDay).toLocaleDateString())? 'border-active': ''}`}
                mask="__.__.____"
                replacement={{_ : /[0-9]/}}
                showMask={true}
                separate={true}
                defaultValue={formData.birthDay}
                name = 'endDate'
                readOnly={false}
                placeholder=''
                onChange={(e)=>{
                    setFormData({...formData, birthDay: e.target.value})
                }}
            />

            <City
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                classes={`mb-16 `}
                borderIsActive={(currentData.city.id !== formData.city.id)? true :false}
                url='/api/v1/administrative/programs'
            />

            <School
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                classes={`mb-16`}
                borderIsActive={(currentData.school.id !== formData.school.id)? true :false}
                url='/api/v1/administrative/programs'
            />

            <ChildClass 
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData}
                setSearchData={setSearchData}
                setError={setError}
                classes={``}
                numberBorderIsActive={(currentData.classObj.number !== formData.classObj.number)? true :false}
                letterBorderIsActive={(currentData.classObj.letter !== formData.classObj.letter)? true :false}
                url={'/api/v1/administrative/programs'}
            />
        </div>
    )
}

export default EditDataBlock;