import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosProxyCatchGet } from '../../../../axiosProxy';
import axiosProxy from './../../../../axiosProxy';


export const fetchAdminFinanceChildsData = createAsyncThunk(
  'AdminFinanceChilds/fetchAdminFinanceChildsData',
  async (dataObj={studentId: 0}) => {

    try {
      const response = await axiosProxy.get(`/api/v1/administrative/finance/get-client-students?`+ new URLSearchParams(dataObj))
      if(response.status < 400){
        return {childs: response.data.data, discount: response.data.sale};                      
        // return {childs: response.data.data};                      
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);

export const fetchAdminFinanceDocumentsChildsData = createAsyncThunk(
  'AdminFinanceChilds/fetchAdminFinanceDocumentsChildsData',
  async (dataObj={clientId: 0}) => {

    try {
      const response = await axiosProxy.get(`/api/v1/administrative/finance/get-students-slim?`+ new URLSearchParams(dataObj))
      if(response.status < 400){
        return {childs: response.data.data};                      
        // return {childs: response.data.data};                      
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


