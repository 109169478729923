import ChildListElem from './ChildElem';
import { useDispatch } from 'react-redux';
import { setPopupBack } from '../../../../../redux/popup/popupSlice';

import './index.scss';
import { setAdminFinanceCheckedChild, setAdminFinanceCheckedChildRestore } from '../../../../../redux/admin/finance/childs/childsSlice';
import { getMonthName, getDateFormatFunc } from './../../../../../services/clientHome';



const Childs = ({setPopupType, childs}) =>{
    const dispatch = useDispatch()

    const editChild = (item) =>{
        dispatch(setPopupBack('open'))
        setPopupType('FinanceEditChild')
        dispatch(setAdminFinanceCheckedChild({...item, month: {id: item.startMonth, label: getMonthName(item.startMonth), year: getDateFormatFunc(item.startDate).getFullYear()}}))
    }
    const addChild = () =>{
        dispatch(setPopupBack('open'))
        setPopupType('FinanceCreateChild')
        dispatch(setAdminFinanceCheckedChildRestore())
    }
    const deleteChildFunc = (item) =>{
        setPopupType('DeleteChild')
        dispatch(setAdminFinanceCheckedChild(item))
    }
    const matcapPaymentOpenFunc = (item, type) =>{
        dispatch(setAdminFinanceCheckedChild(item))
        dispatch(setPopupBack('open'))
        setPopupType(type)
    }

    return(
        <div className={`admin__finance-container childs`}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='admin__finance-title'>Дети</div>
                <button className='admin__finance-user-card-button mr-12' onClick={addChild}>Добавить ребенка</button>
            </div>
            <div className='admin__finance-user-card-child-list'>
                {
                    childs.map(item => <ChildListElem item={item} matcapOpen={matcapPaymentOpenFunc} clickFunc={() => editChild(item)} deleteChildFunc={() => deleteChildFunc(item)} key={`admin__finance-user-card-child-list-item-container-${item.id}`}/>)
                }
            </div>
        </div>
    )
}

export default Childs;