import './index.scss'
import RefusalComment from './../../RefusalComment/index';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { selectAdminAplicationConfirm } from './../../../../../redux/admin/aplications/aplicationConfirm/selectors';
import CurrentDataBlock from './CurrentDataBlock';
import EditDataBlock from './EditDataBlock';
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import { correctorName, getDateFormatFuncServer, isCorrectDate } from '../../../../../services/clientHome';
import { setAdminAplicationsDataDeleteElem } from '../../../../../redux/admin/aplications/aplications/aplicationsSlice';
import { fetchAdminEditChildConfirmAplication } from './../../../../../redux/admin/aplications/aplicationConfirm/asyncActions';

const childIsEdited = (data, formData) =>{
    let dataObj = {name: data.name, city: data.city.id, school: data.school.id, class: data.classObj.id, birthDay: data.birthDay? new Date(data.birthDay).toLocaleDateString(): data.birthDay};
    let formDataObj = {name: formData.name, city: formData.city.id, school: formData.school.id, class: formData.classObj.id, birthDay: formData.birthDay};
    return JSON.stringify(dataObj) === JSON.stringify(formDataObj)? false: true;
}

const EditChildConfirm = ({closePopup, popupBack}) =>{
    const dispatch = useDispatch()

    const { adminEditChildConfirmAplication:checkData } = useSelector(selectAdminAplicationConfirm);

    const [commentIsActive, setCommentIsActive] = useState(false)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [childEdited, setChildEdited] = useState(false)
    const [activeList, setActiveList] = useState(false)
    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        classObj: {id: 0, number: '', letter: ''},
        classNumber: '',
        classLetter: '',
        birthDay: '',
    })

    useEffect(()=>{
        setChildEdited(childIsEdited(checkData.update, formData))
    }, [formData])

    useEffect(()=>{
        dispatch(fetchAdminEditChildConfirmAplication({id: checkData.applicationId}))
    }, [popupBack])

    useEffect(()=>{
        if(checkData.current.id){
            setFormData({
                id: checkData.update.id, 
                name: checkData.update.name, 
                city: checkData.update.city, 
                school: checkData.update.school, 
                classObj: checkData.update.classObj, 
                birthDay: checkData.update.birthDay? new Date(checkData.update.birthDay).toLocaleDateString(): '',
                classNumber: checkData.update.classObj.number, 
                classLetter: checkData.update.classObj.letter
            })
        }
        else{
            setFormData({
                id: 0,
                name: '',
                city: {id: 0, name: ''},
                school: {id: 0, name: ''},
                classObj: {id: 0, number: '', letter: ''},
                classNumber: '',
                classLetter: '',
                birthDay: '',
            })
        }
    }, [checkData])

    const confirmUserFunc = (confirm, comment='', setCommentLoading=()=>{}) =>{
        setError('')
        let postData = {
            id: checkData.current.id,
            status: confirm? 1: -1,
        }
        if(!confirm && comment) postData.comment = comment;


        if((confirm && !childEdited) || !confirm){
            setLoading(true)
            confirmUserPostFunc(postData, setCommentLoading)
        }
        else{
            let fieldVal = {}

            if(!((formData.birthDay.length === 10 || formData.birthDay.length === 11) && formData.birthDay.indexOf('_') === -1 && isCorrectDate(formData.birthDay)))
                fieldVal.birthDay = true;

            if(formData.name !== '' && formData.city.id && formData.school.id && formData.classLetter && formData.classNumber && !Object.keys(fieldVal).length){
                setLoading(true)
                // axiosProxyCatchPost({
                //     url: '/api/v1/administrative/finance/update-students-profile',
                //     data: {data: [
                //         {
                //             id: checkData.current.id, 
                //             name: correctorName(formData.name), 
                //             birthDay: getDateFormatFuncServer(formData.birthDay),
                //             cityId: formData.city.id,
                //             schoolId: formData.school.id,
                //             classId: formData.classObj.id,
                //         }
                //     ]},
                //     resolved: (data)=>{
                //         confirmUserPostFunc(postData, setCommentLoading)                       
                //     },
                //     rejected: (error)=>{
                //         setLoading(false)
                //         if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                //         else setError('Что-то пошло не так*')
                //     }
                // })

                confirmUserPostFunc(
                {
                    ...postData,
                    name: correctorName(formData.name), 
                    birthDay: getDateFormatFuncServer(formData.birthDay),
                    cityId: formData.city.id,
                    schoolId: formData.school.id,
                    classId: formData.classObj.id,
                }, 
                setCommentLoading)


            }
            else if(Object.keys(fieldVal).length){
                if(!error) setError('Некорректная дата рождения*')
            }
            else{
                if(!error) setError('Необходимо заполнить все поля*')
            }
        }
        
    }

    const confirmUserPostFunc = (postData, setCommentLoading) =>{
        setCommentLoading(true)
        axiosProxyCatchPost({
            url: '/api/v1/administrative/applications/update-student-edit-status',
            data: postData,
            resolved: (data)=>{
                axiosProxyCatchPost({
                    url: '/api/v1/administrative/applications/update-application-status',
                    data: { ...postData, id: checkData.applicationId},
                    resolved: (data)=>{
                        dispatch(setAdminAplicationsDataDeleteElem(checkData.applicationId))
                        closePopup()
                        setLoading(false)
                        setCommentLoading(false)
                    },
                    rejected: (error)=>{
                        setLoading(false)
                        setCommentIsActive(false)
                        setCommentLoading(false)
                        if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                        else setError('Что-то пошло не так*')
                    }
                })
            },
            rejected: (error)=>{
                setLoading(false)
                setCommentIsActive(false)
                setCommentLoading(false)
                if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                else setError('Что-то пошло не так*')
            }
        })
    }

    const cancelChangesFunc = () =>{
        setFormData({
            id: checkData.current.id,
            name: checkData.update.name, 
            city: checkData.update.city, 
            school: checkData.update.school, 
            classObj: checkData.update.classObj, 
            birthDay: checkData.update.birthDay? new Date(checkData.update.birthDay).toLocaleDateString(): '',
            classNumber: checkData.update.classObj.number, 
            classLetter: checkData.update.classObj.letter
        })
    }

    return(
        <div>
            <div className={`popup__container admin-popup__edit-data-confirm additionaly-popup ${commentIsActive? 'deactive': 'active'} d-flex justify-center`} onClick={(e)=>{
                if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__button') && e.target.closest('.popup__drop-list-checked') === null){
                    setActiveList('')
                }
            }}>
                <div className='wd-100'>
                    <div className='d-flex justify-between align-center mb-24'>
                        <div className='wd-24'/>
                        <div className='popup__title'>
                            Ребенок
                        </div>
                        <div className='popup__close' onClick={closePopup}/>
                    </div>

                    <div className='d-flex justify-between h-maxcontent'>
                        <CurrentDataBlock data={checkData.current}/>
                        <div className='admin-popup__edit-data-confirm-line'/>
                        <EditDataBlock formData={formData} currentData={checkData.current} setFormData={setFormData} activeList={activeList} setActiveList={setActiveList} setError={setError}/>
                    </div>

                    <div className='popup__error'>{error}</div>

                    {
                        childEdited?
                        <div className='d-flex mt-24'>
                            <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={()=>cancelChangesFunc()}>Отменить изменения</button>
                            <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={()=>confirmUserFunc(true)}>Подтвердить с изменениями</button>
                        </div>

                        :<div className='d-flex mt-24'>
                            <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={()=>setCommentIsActive(true)}>Отклонить</button>
                            <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={()=>confirmUserFunc(true)}>Подтвердить</button>
                        </div>
                    }
                </div>
            </div>

            <RefusalComment setCommentClose={()=>setCommentIsActive(false)} classes={`additionaly-popup verification-comment ${commentIsActive? 'active': 'deactive'}`} clickFunc={(comment, setCommentLoading)=>confirmUserFunc(false, comment, setCommentLoading)}/>
        </div>
    )
}

export default EditChildConfirm;