import { getChildPaymentStatus } from "../../../../../../services/clientHome";
import ProgramElem from "../ProgramElem";
import { getMonthName, getStringAcademicYear } from './../../../../../../services/clientHome';


const ChildListElem = ({item, clickFunc, matcapOpen=()=>{}, deleteChildFunc}) =>{
    const {id, name, status, matcap, city, school, classObj, programs, startMonth:month, endDate, isFinish} = item;
    let statusData = getChildPaymentStatus(status)

    return(
        <div className={`admin__finance-user-card-child-list-item`}>
            <div className='admin__finance-user-card-child-list-item-top d-flex mb-16'>
                <div className='admin__finance-user-card-child-list-item-child-data-icon' onClick={clickFunc}/>
                <div className='admin__finance-user-card-child-list-item-child-data'>
                    <div className='admin__finance-user-card-child-list-item-child-data-name mb-8 d-flex align-top justify-between'>
                        <div className={`admin__finance-user-card-child-list-item-child-data-name-label ${(endDate && isFinish)? 'graduated-active': ''}`}>
                            {name} <span>({getMonthName(month)} - {(endDate && isFinish)? getMonthName(new Date(endDate).getMonth() === 0? 12: new Date(endDate).getMonth()) :'Май'})</span>
                        </div>
                        {
                            (endDate && isFinish)
                            ?<div className="admin__finance-user-card-child-list-item-child-data-name-status-container d-flex justify-end align-top graduated">
                                {getStringAcademicYear(new Date(endDate))}
                            </div>
                            :<div className={`admin__finance-user-card-child-list-item-child-data-name-status-container d-flex justify-end align-center`}>
                                <div className={`admin__finance-user-card-child-list-item-child-data-name-status ${statusData.color}`}>{(matcap)? 'Мат.Капитал': statusData.label}</div>
                            </div>
                        }

                    </div>
                    <div className='admin__finance-user-card-child-list-item-child-data-address-school-class d-flex align-center'>
                        <div>{city.name}</div> <span/> <div>{school.name}</div> <span/> <div>{classObj.number}{classObj.letter}</div>
                    </div>
                </div>

                <div className={`admin__finance-user-card-child-list-item-delete-icon ${false? 'deactive-button': ''}`} onClick={deleteChildFunc} />
                
            </div>
            <div className="admin__finance-user-card-child-list-item-program-list">
                {
                    programs.map(item => <ProgramElem item={item} key={`admin__finance-user-card-child-list-item-program-list-container-${item.id}`}/>)
                }
            </div>
            {
                ((matcap))?
                <div className="d-flex justify-between align-center flex-wrap">
                    <div className={`admin__finance-user-card-child-list-item-child-data-name-status-mat-cap`} onClick={()=>{
                        matcapOpen(item, 'FinanceMatcapPayment')  
                    }}>Внести платеж</div>
                    <div className={`admin__finance-user-card-child-list-item-child-data-name-status-mat-cap close-payment`} onClick={()=>{
                        matcapOpen(item, 'FinanceCloseMatcapPayment')  
                    }}>Завершить оплату мат.капиталом</div>
                </div>
                :null
            }

        </div>
    )
}

export default ChildListElem;