import axios from "axios";
import { logout } from "./redux/store";


const baseUrl = 'http://172.28.138.71'


const axiosProxy = axios.create({
    baseURL: process.env.REACT_APP_SERVER ? '' : baseUrl + ':8081', //proxy
    // baseURL: process.env.REACT_APP_SERVER ? '' : baseUrl + ':5195', //authiorization
    // baseURL: process.env.REACT_APP_SERVER ? '' : baseUrl + ':5050', //parental-api
    // baseURL: process.env.REACT_APP_SERVER ? '' : baseUrl + ':5016', //admin-api
    // baseURL: process.env.REACT_APP_SERVER ? '' : baseUrl, //server
})

axiosProxy.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
axiosProxy.defaults.headers.get['Access-Control-Allow-Methods'] = '*';
axiosProxy.defaults.headers.get['Access-Control-Allow-Headers'] = "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'";
if (localStorage.getItem('save-autorize') === 'true' && localStorage.getItem('AthorizationUserData') || sessionStorage.getItem('AthorizationUserData')) axiosProxy.defaults.headers.get['Authorization'] = `Bearer ${(localStorage.getItem('save-autorize') === 'true') ? JSON.parse(localStorage.getItem('AthorizationUserData')).token : JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}`;

const logoutFunc = () => {
    logout()
    localStorage.setItem('authorize', 'false')
    localStorage.setItem('AthorizationUserData', JSON.stringify({ name: '', token: '' }))
    localStorage.setItem('token', '')
    sessionStorage.setItem('authorize', 'false')
    sessionStorage.setItem('AthorizationUserData', JSON.stringify({ name: '', token: '' }))
    sessionStorage.setItem('token', '')
}

axiosProxy.interceptors.response.use(response => {

    if (localStorage.getItem('save-autorize') === 'true' && localStorage.getItem('AthorizationUserData') || sessionStorage.getItem('AthorizationUserData')) axiosProxy.defaults.headers.get['Authorization'] = `Bearer ${(localStorage.getItem('save-autorize') === 'true') ? JSON.parse(localStorage.getItem('AthorizationUserData')).token : JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}`;
    
    // console.log(response);
    let result = (localStorage.getItem('save-autorize')  === 'true')? JSON.parse(localStorage.getItem('AthorizationUserData')): JSON.parse(sessionStorage.getItem('AthorizationUserData'))
    if(result && !result.role) logout()

        
    if (response && response.status && response.status === 401) {
        logoutFunc()
    } else {
        return response;
    }
}, error => {
    console.log(error);

    if (error && error.response && error.response.status && error.response.status === 401) {
        logoutFunc()
    }
    else if (error && error.response && error.response.status && error.response.status !== 500) {
        return error;
    }
    else if (!(error && error.response && error.response.status)) {
        logoutFunc()
    }
    else return error

});


export const axiosProxyCatchPost = async ({ url, data, resolved, rejected, isFormData = false, isNotAuthorize = false }) => {
    try {
        const response = await axiosProxy.post(url, data,
            {
                headers: {
                    ...(!isFormData ? {
                        "Accept": 'application/json',
                        'Content-Type': 'application/json',
                    }
                        : {
                            'Content-Type': 'multipart/form-data',
                        }),
                    ...{
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': '*',
                        'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
                    },
                    ...(isNotAuthorize ? {}
                        : {
                            'Authorization': `Bearer ${(localStorage.getItem('save-autorize') === 'true') ? JSON.parse(localStorage.getItem('AthorizationUserData')).token : JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}`
                        })
                },
            })
        if (response && response.status && response.status < 400) {
            // console.log(response);
            resolved(response.data)
            if (response.data && response.data.token) axiosProxy.defaults.headers.get['Authorization'] = `Bearer ${response.data.token}`;
        }
        else {
            if (response && response.response) rejected(response.response)
        }
    } catch (error) {
        rejected(error)
    }
}

export const axiosProxyCatchGet = async ({ url, resolved, rejected, isNotAuthorize = false }) => {
    try {
        const response = await axiosProxy.get(url,
            {
                headers: {
                    ...{
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': '*',
                        'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'",
                    },
                    ...(isNotAuthorize ? {}
                        : {
                            'Authorization': `Bearer ${(localStorage.getItem('save-autorize') === 'true') ? JSON.parse(localStorage.getItem('AthorizationUserData')).token : JSON.parse(sessionStorage.getItem('AthorizationUserData')).token}`
                        })
                },
            }
        )
        if(response && response.status && response.status < 400) {
            resolved(response)
        }
        else if(response && response.status && (response.status === 401 || response.status === 403)){
            logoutFunc()
        }
        else {
            if (response && response.response){
                rejected(response.response)
            }
        }
    } catch (error) {
        rejected(error)
    }
}


export default axiosProxy;
