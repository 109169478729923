

const CurrentDataBlock = ({data}) =>{
    return(
        <div className={`admin-popup__edit-data-confirm-current wd-50-m16`}>
            <div className='admin-popup__edit-data-confirm-subtitle'>Старые данные</div>

            <label htmlFor="" className='popup__label mb-4'>ФИО</label>
            <div className="popup__input mb-16">{data.name}</div>

            <label htmlFor="" className='popup__label mb-4'>Дата рождения</label>
            <div className="popup__input mb-16">{new Date(data.birthDay).toLocaleDateString()}</div>

            <label htmlFor="" className='popup__label mb-4'>Город</label>
            <div className="popup__input mb-16">{data.city.name}</div>

            <label htmlFor="" className='popup__label mb-4'>Школа</label>
            <div className="popup__input textarea mb-16">{data.school.name}</div>

            <label htmlFor="" className='popup__label mb-4'>Класс</label>
            <div className="popup__input">{data.classObj.number}{data.classObj.letter}</div>
        </div>
    )
}

export default CurrentDataBlock;