// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../img/discount-purple-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-popup__container.finanse-page-history-filter {
  width: 540px; }

.admin-popup__application-discount-input-additionaly, .admin-popup__application-discount-input-additionaly-val {
  padding: 16px;
  padding-right: 46px;
  border-radius: 8px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  height: 100%;
  display: block;
  position: absolute;
  top: 0px;
  left: 0; }

.admin-popup__application-discount-input-additionaly {
  z-index: 1;
  opacity: 0; }
  .admin-popup__application-discount-input-additionaly:focus {
    opacity: 1; }
  .admin-popup__application-discount-input-additionaly:focus + .admin-popup__application-discount-input-additionaly-val {
    opacity: 0; }

.admin-popup__application-discount-input-additionaly-container {
  border: 1px solid #E6E6E6;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  position: relative; }
  .admin-popup__application-discount-input-additionaly-container::before {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center; }
  .admin-popup__application-discount-input-additionaly-container::before {
    content: '';
    width: 24px;
    height: 24px;
    right: 16px;
    top: calc(50% - 12px);
    position: absolute;
    background-size: contain; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Popup/index.scss"],"names":[],"mappings":"AACI;EACI,YAAY,EAAA;;AAIZ;EACI,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,QAAQ;EACR,OAAO,EAAA;;AAGX;EACI,UAAU;EACV,UAAU,EAAA;EAFb;IAKO,UAAU,EAAA;EALjB;IAQO,UAAU,EAAA;;AAMlB;EACI,yBAAyB;EACzB,WAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,kBAAkB,EAAA;EALrB;IAQO,oEAAyE,EAAA;EARhF;IAYO,WAAW;IACX,WAAW;IACX,YAAY;IACZ,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,wBAAwB,EAAA","sourcesContent":[".admin-popup{\n    &__container.finanse-page-history-filter{\n        width: 540px;\n    }\n\n    &__application-discount{\n        &-input-additionaly, &-input-additionaly-val{\n            padding: 16px;\n            padding-right: 46px;\n            border-radius: 8px;\n            width: 100%;\n            font-size: 18px;\n            font-weight: 500;\n            height: 100%;\n            display: block;\n            position: absolute;\n            top: 0px;\n            left: 0;\n        }\n    \n        &-input-additionaly{\n            z-index: 1;\n            opacity: 0;\n            \n            &:focus{\n                opacity: 1;\n            }\n            &:focus + &-val{\n                opacity: 0;\n            }\n        }\n    \n        \n    \n        &-input-additionaly-container{\n            border: 1px solid #E6E6E6;\n            width:100%;\n            height: 56px;\n            border-radius: 8px;\n            position: relative;\n    \n            &::before{\n                background: url(../.././../img/discount-purple-icon.svg) no-repeat center;\n            }\n            \n            &::before{\n                content: '';\n                width: 24px;\n                height: 24px;\n                right: 16px;\n                top: calc(50% - 12px);\n                position: absolute;\n                background-size: contain;\n            }\n        }\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
