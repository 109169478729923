import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ChildClass, City, School } from '../../../../Filters/FiltersList';
import { selectAdminFinanceChilds } from '../../../../../redux/admin/finance/childs/selectors';
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import { getDateFormatFuncServer } from '../../../../../services/clientHome';
import { setAdminFinanceChildsDataAdd, setAdminFinanceChildsDataEdit } from '../../../../../redux/admin/finance/childs/childsSlice';
import { isCorrectDate, correctorName } from './../../../../../services/clientHome';
import MaskedInput from './../../../../MaskedInput/MaskedInput';
import { PaginationMonth } from './../../../../Filters/FiltersList';
import { selectAdminFinanceUser } from '../../../../../redux/admin/finance/user/selectors';

const title = {
    'FinanceEditChild': 'О ребенке',
    'FinanceCreateChild': 'Добавление ребенка',
}

const buttonTitle = {
    'FinanceEditChild': 'Сохранить',
    'FinanceCreateChild': 'Подтвердить',
}

const FinanceEditChild = ({popupBack, closePopup, type}) =>{
    const dispatch = useDispatch()

    const { adminFinanceCheckedChild:checkChild } = useSelector(selectAdminFinanceChilds);
    const { adminFinanceUserData:userData } = useSelector(selectAdminFinanceUser);


    const [activeList, setActiveList] = useState('')
    const [loading, setLoading] = useState(false)

    const [error, setError] = useState('')

    const [formData, setFormData] = useState({
        name: '',
        birthDay: '',
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        classNumber: '',
        classLetter: '',
        classObj: {id: 0, number: '', letter: ''},
        month: {id: 0, label: '', year: ''}
    })

    const [searchData, setSearchData] = useState({
        city: '',
        school: '',
    })

    useEffect(()=>{
        if(checkChild.id){
            setFormData({...checkChild, classNumber: checkChild.classObj.number, classLetter: checkChild.classObj.letter,})
        }
        else{
            setFormData({
                name: '',
                birthDay: '',
                city: {id: 0, name: ''},
                school: {id: 0, name: ''},
                classNumber: '',
                classLetter: '',
                classObj: {id: 0, number: '', letter: ''},
                month: {id: 0, label: '', year: ''}
            })
        }
        
        setSearchData({
            city: '',
            school: '',
        })
        setActiveList('')
    }, [popupBack])

    const checkForCompletionFunc = () =>{
        setError('')
        let {id, name, city, school, classObj, birthDay, month} = formData;
        let postData = {
            name: correctorName(name),
            birthDay: getDateFormatFuncServer(birthDay),
            cityId: city.id,
            schoolId: school.id,
            classId: classObj.id,
            startDate: getDateFormatFuncServer(new Date(month.year, month.id - 1, 1).toLocaleDateString()), 
        }

        let fieldVal = {}
        if(!(birthDay.length === 10 && birthDay.indexOf('_') === -1 && isCorrectDate(birthDay)))
            fieldVal.birthDay = true;

        if(type === 'FinanceCreateChild' || name !== checkChild.name || city.id !== checkChild.city.id || school.id !== checkChild.school.id || classObj.id !== checkChild.classObj.id || birthDay !== checkChild.birthDay || (month.id !== checkChild.month.id || month.year !== checkChild.month.year)){            
            if(correctorName(name) !== '' && city.id && school.id && classObj.id && !Object.keys(fieldVal).length && (formData.month && formData.month.id)){
                setLoading(true)
                if(type === 'FinanceEditChild'){
                    editChildPostFunc({...postData, id: id})
                }
                else if(type === 'FinanceCreateChild'){
                    createChildPostFunc(postData)
                }
            }
            else if(name && !correctorName(name)){
                setError('Некорректное имя ребенка*')
            }
            else if(birthDay && Object.keys(fieldVal).length){
                setError('Некорректная дата рождения*')
            }
            else setError('Необходимо заполнить все поля*')
        }
        else setError('Изменений не найдено*')
    }

    const editChildPostFunc = (postData) =>{
        axiosProxyCatchPost({
            url: '/api/v1/administrative/finance/update-students-profile',
            data: {data: [postData]},
            resolved: (data)=>{
                dispatch(setAdminFinanceChildsDataEdit({...formData, startMonth: formData.month.id, startDate: new Date(formData.month.year, formData.month.id - 1, 1).toLocaleDateString()}))
                setLoading(false)
                closePopup()
            },
            rejected: (error)=>{
                if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                else setError('Что-то пошло не так*')
                setLoading(false)                      
            }
        })
    }
    const createChildPostFunc = (postData) =>{
        axiosProxyCatchPost({
            url: '/api/v1/administrative/finance/create-student',
            data: {
                clientId: userData.id,
                ...postData
            },
            resolved: (data)=>{
                dispatch(setAdminFinanceChildsDataAdd({id: data.id, programs: data.programs, ...formData, startMonth: formData.month.id, startDate: new Date(formData.month.year, formData.month.id - 1, 1).toLocaleDateString()}))
                setLoading(false)
                closePopup()
            },
            rejected: (error)=>{
                if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                else setError('Что-то пошло не так*')
                setLoading(false)
            }
        })
    }

    return(
        <div className={`popup__container admin-popup__container program-edit`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__drop-list-checked-input') && !e.target.classList.contains('popup__drop-list-elem-checkbox') && !e.target.classList.contains('popup__drop-list-open') && !e.target.classList.contains('popup__input')){
                setActiveList('')
                setSearchData({
                    city: '',
                    school: '',
                })
            }
        }}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title'>
                    {title[type]}
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>

            <label htmlFor="" className='popup__label mb-4'>ФИО</label>
            <input type="text" value={formData.name} className='popup__input mb-16' onChange={(e)=>{
                if(e.target.value.match(/^[a-zA-ZА-Яа-я- ]+$/) || !e.target.value) setFormData({...formData, name: e.target.value})
            }}/>

            <label className="popup__label mb-4">Дата рождения</label>
            <MaskedInput
                className={`popup__input mb-16`}
                mask="__.__.____"
                replacement={{_ : /[0-9]/}}
                showMask={true}
                separate={true}
                defaultValue={formData.birthDay}
                name = 'endDate'
                // readOnly={false}
                readOnly={false}
                placeholder=''
                onChange={(e)=>{
                    setFormData({...formData, birthDay: e.target.value})
                }}
            />

            <City
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/administrative/programs'
            />

            <School
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/administrative/programs'
            />

            <ChildClass 
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/administrative/programs'
            />

            <PaginationMonth
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData}
                setError={setError}
                classes={`${checkChild.isFullVerify? 'popup__disable-input': ''}`}
                isLastBlock={true}
                label={'Месяц начала занятий'}
            />


            <div className='popup__error'>{error}</div>     

            <div className='d-flex'>
                <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={()=>closePopup('')}>Отменить</button>
                <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={checkForCompletionFunc}>{buttonTitle[type]}</button>
            </div>       
        </div>
    )
}

export default FinanceEditChild;