import { useDispatch, useSelector } from 'react-redux';

import ProfileImage from '../../img/user-profile-icon.svg'
import Arrow from '../../img/black-arrow-down.svg'

import './index.scss'
import { setUserData } from '../../redux/authorization/authorization';
import { selectDropDownListData } from './../../redux/dropDownList/selectors';
import { setDropListName } from '../../redux/dropDownList/dropDownListSlice';
import { useNavigate } from 'react-router-dom';
import { setUserProfile } from '../../redux/users/profile/profileSlice';


const getNameFormat = (name) =>{
    if(name){
        let result = name.split(' ')
        if(result.length > 1) result = result[0] + ' ' + result[1][0] + '.'
        else result = result[0]
        return result;
    }
    else
        return ''
}

const HeaderProfileBlock = ({user}) =>{
    const dispatch = useDispatch()
    const { listName } = useSelector(selectDropDownListData)
    const navigate = useNavigate()

    return(
        <div className='header-profile-container popupBlock-click'>
            <div className='header-profile d-flex align-center popupBlock-click' onClick={()=>dispatch(setDropListName(listName === 'header-profile-popup-list'? '': 'header-profile-popup-list'))}>
                <img src={ProfileImage} className='header-profile-photo mr-12 popupBlock-click'/>
                <div className='header-profile-user popupBlock-click'>
                    <div className='header-profile-user-name popupBlock-click'>{getNameFormat(user.name)}</div>
                    {/* <div className='header-profile-user-role popupBlock-click'>Родитель</div> */}
                </div>
                <img src={Arrow} alt="" className='header-profile-arrow ml-12 popupBlock-click'/>
            </div>

            <div className={`header-profile-popup ${(listName === 'header-profile-popup-list')? 'active': ''}`}>
                <div className='header-profile-popup-elem d-flex align-center justify-center' onClick={()=>{
                    dispatch(setUserData({id: 0, name: '', token: '', role: ''}))
                    dispatch(setUserProfile({
                        id: 0, 
                        name: '',
                        status: 0, 
                        email: '', 
                        phone: '',
                        financeAccess: false,
                        isUpdate: false,
                    }))
                    dispatch(setDropListName(''))
                    navigate('/')
                }}>
                    <div className='header-profile-popup-elem-label'>Выход</div>
                    <div className='header-profile-popup-elem-icon'/>
                </div>
            </div>
        </div>
    )
}

export default HeaderProfileBlock;