
const DownloadSampleBlock = ({deactiveButton, clickFunc, activeList, setActiveList}) => {
    
    return (
        <div className={`admin-finance-popup__documents-line-item-button download-sample ${deactiveButton? 'deactive-button' : ''}`} onClick={setActiveList}>
            <div className={`admin-finance-popup__documents-line-item-button-drop-list-container ${activeList? 'active': ''}`}>
                <div className="admin-finance-popup__documents-line-item-button-drop-list">
                    <div className="admin-finance-popup__documents-line-item-button-drop-list-item" onClick={()=>clickFunc('.pdf', 1)}>
                         Скачать шаблон в формате PDF
                    </div>
                    <div className="admin-finance-popup__documents-line-item-button-drop-list-item" onClick={()=>clickFunc('.docx', 2)}>
                         Скачать шаблон в формате Word
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadSampleBlock;