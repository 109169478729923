import { useDispatch, useSelector } from 'react-redux';
import './index.scss'
import PaymentHistoryItem from './PaymentHistoryItem/index';
import { useState, useEffect } from 'react';
import { selectUserPaymentHistory } from '../../../../redux/users/paymentHistory/selectors';
import { fetchPaymentHistoryData } from '../../../../redux/users/paymentHistory/asyncActions';
import { scrollingPagination } from '../../../../services/clientHome';
import { setUserPaymentHistoryScrollReset } from '../../../../redux/users/paymentHistory/paymentHistorySlice';

const PaymentHistory = () =>{
    const dispatch = useDispatch();

    const {paymentHistoryData, pageLast, scrollReset, loading} = useSelector(selectUserPaymentHistory)
    const [page, setPage] = useState(0)

    const [isActiveMore, setIsActiveMore] = useState(false)

    useEffect(()=>{
        dispatch(fetchPaymentHistoryData({count: 10, page: 0, isScrolling: false}))
    }, [])
    let startElemLength = window.innerWidth > 680? 5:2;

    return(
        <div className={`user-page__finance-payment-history-container ${isActiveMore? 'more-history': ''}`}>
            <div className={`user-page__finance-payment-history-table ${isActiveMore? 'more-history': ''}`}>
                <div className={`user-page__finance-payment-history-table-line title ${isActiveMore? 'more-history': ''} d-flex align-center justify-between`}>
                    <div className='user-page__finance-payment-history-table-line-item name'>Имя</div>
                    <div className='user-page__finance-payment-history-table-line-item date'>Дата платежа</div>
                    <div className='user-page__finance-payment-history-table-line-item summ'>Сумма платежа</div>
                </div>
                <div className={`user-page__finance-payment-history-table-${isActiveMore? 'scroll': 'container'}`}  
                onScroll={(e)=>scrollingPagination(
                    e, 
                    ()=>setPage(page+1), 
                    ()=>dispatch(fetchPaymentHistoryData({count: 10, page: page+1, isScrolling: true})), 
                    pageLast,
                    loading,
                    scrollReset, 
                    ()=>dispatch(setUserPaymentHistoryScrollReset(false))
                )}>
                    {
                        (isActiveMore? paymentHistoryData :paymentHistoryData.slice(0, startElemLength)).map((item, key)=> <PaymentHistoryItem key={`user-page__finance-payment-history-table-line-${key}`} {...item}/>)
                    }
                </div>
            </div>
            <div className={`user-page__finance-payment-history-more d-flex align-center ${isActiveMore? 'd-none': ''}`} onClick={()=>setIsActiveMore(true)}>
                <div className='user-page__finance-payment-history-more-label'>Показать больше</div>
                <div className='user-page__finance-payment-history-more-icon'/>
            </div>
        </div>
    )
}
// key={`user-page__finance-payment-history-table-line-${id}`}
export default PaymentHistory;