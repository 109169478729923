import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import dropDownList from './dropDownList/dropDownListSlice';
import userPopup from './users/popup/userPopupSlice';
import popup from './popup/popupSlice';
import userProfile from './users/profile/profileSlice';
import userChildren from './users/children/childrenSlice';
import userDocuments from './users/documents/documentsSlice';
import userPrograms from './users/programs/programsSlice';
import userPaymentHistory from './users/paymentHistory/paymentHistorySlice';
import userNavigations from './users/navigations/userNavigationsSlice';
import authorization, { setUserData } from './authorization/authorization';
import adminSlise from './admin';
import teacherSlise from './teacher';

import { setAdminFinanceClientsFilters } from './admin/finance/clients/clientsSlice';
import { setAdminFinancePaymentHistoryFilters, setAdminFinanceUserPaymentHistoryFilters } from './admin/finance/paymentsHistory/paymentsHistorySlice';
import { setAdminFinanceSearchVal } from './admin/finance/search/searchSlice';



export const store = configureStore({
  reducer: {
    authorization,
    popup, 
    dropDownList, 
    userNavigations, 
    userPopup, 
    userProfile,
    userChildren,
    userDocuments,
    userPrograms,
    userPaymentHistory,
    ...adminSlise,
    ...teacherSlise
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export const ResetAdminFilters = () =>{
  store.dispatch(setAdminFinanceClientsFilters({
    city: {id: 0, name: ''},
    school: {id: 0, name: ''},
    classObj: {id: 0, number: '', letter: ''},
    maternalСapital: false,
    month: {id: 0, label: ''},
    year: '',
    post: {}
  }))
  
  store.dispatch(setAdminFinancePaymentHistoryFilters({
      city: {id: 0, name: ''},
      school: {id: 0, name: ''},
      parrent: {id: 0, name: ''},
      child: {id: 0, name: ''},
      paymentType: {id: 0, name: '', color: ''},
      dates: [],
      classObj: {id: 0, number: '', letter: ''},
      post: {},
      postDates: {}
  }))

  store.dispatch(setAdminFinanceUserPaymentHistoryFilters({
    child: {id: 0, name: ''},
    paymentType: {id: 0, name: '', color: ''},
    date: '',
    post: {}
  }))

  store.dispatch(setAdminFinanceSearchVal(''))
}

export const logout = () =>{
  store.dispatch(setUserData({id: 0, name: '', token: '', role: ''}))
}

export default store;

export const useAppDispatch = () => useDispatch();

