import React, { useEffect, useState } from 'react';

import './index.scss'
import { useDispatch } from 'react-redux';
import { axiosProxyCatchGet, axiosProxyCatchPost } from './../../axiosProxy';

import MaskedInput from './../MaskedInput/MaskedInput';
import ChildBlock from './ChildBlock';
import { checkIsEditingChildRegistration, correctorName, getDateFormatFunc, getDateFormatFuncServer, getDatePostFormatFunc } from '../../services/clientHome';
import { checkIsEditingChild } from './../../services/clientHome';
import PasswordIcon from '../../img/autorization-password-none-icon.svg'
import PasswordActiveIcon from '../../img/autorization-password-check-icon.svg'



const Registration = ({setAuthPage, setEmailChecked}) =>{
    const dispatch = useDispatch()

    const [activeList, setActiveList] = useState('')
    const [checkChildId, setCheckChildId] = useState(0)
    const [agreement, setAgreement] = useState({
        agree: false,
        familiarization: false
    })
    const [isEditChild, setIsEditChild] = useState(false)
    const [displayPass, setDisplayPass] = useState(false)
    const [loading, setLoading] = useState(false)

    const [addChildIsActive, setAddChildIsActive] = useState(false)

    const [userformData, setUserFormData] = useState({
        id: 0,
        name: '',
        mail: '',
        password: '',
        phone: '',
        notMemberProgram: false
    })

    const [childformData, setChildFormData] = useState({
        id: 0,
        name: '',
        birthDate: '',
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        classNumber: '',
        classLetter: '',
    })

    const [childs, setChilds] = useState([])

    useEffect(()=>{
        if(!checkChildId){
            setChildFormData({
                id: 0,
                name: '',
                birthDate: '',
                city: {id: 0, name: ''},
                school: {id: 0, name: ''},
                classNumber: '',
                classLetter: '',
                classObj: {id: 0, number: '', letter: ''}
            })
        }
        else{
            setChildFormData(childs.filter(item=>item.id === checkChildId)[0])
        }
    }, [checkChildId])

    useEffect(()=>{
        if(!addChildIsActive && checkChildId) setCheckChildId(0)
        else if(!addChildIsActive){
            setChildFormData({
                id: 0,
                name: '',
                birthDate: '',
                city: {id: 0, name: ''},
                school: {id: 0, name: ''},
                classNumber: '',
                classLetter: '',
            })
        }
    }, [addChildIsActive])

    useEffect(()=>{
        if(checkChildId && childs.filter(item => item.id === checkChildId).length && checkIsEditingChildRegistration(childformData, childs.filter(item => item.id === checkChildId)[0])){
            setIsEditChild(true)
        }
    }, [childformData])

    const [error, setError] = useState('')

    const childAddFunc = ()=>{
        if(childformData.id === 0){
            setLoading(true)
            axiosProxyCatchGet({
                url: '/api/v1/parental/profile/get-identificate-student?' + new URLSearchParams({name: childformData.name, birthDay: getDatePostFormatFunc(getDateFormatFunc(childformData.birthDate)), classId: childformData.classObj.id}),
                resolved:({data})=>{ 
                    console.log(5555);
                    console.log(data);
                                                        
                    if(!data.data){                        
                        setChilds([...childs, { ...childformData, id: childs.length+1,}])
                        setChildFormData({
                            id: 0,
                            name: '',
                            birthDate: '',
                            city: {id: 0, name: ''},
                            school: {id: 0, name: ''},
                            classNumber: '',
                            classLetter: '',
                        })
                        setCheckChildId(0)
                        setAddChildIsActive(false)
                    }
                    else{
                        setError('Ребёнок с идентичными данными уже числится на сайте, пожалуйста, свяжитесь с администрацией.')
                    }
                    setLoading(false)
                },
                rejected: ()=>{
                    setLoading(false)
                },
                isNotAuthorize: true,
            })
        }
        else{
            childs.map(item=>{
                if(item.id === childformData.id)
                    return childformData;
                else
                    return item
            })
        }
    }

    const editChild = (child) =>{
        setLoading(true)
        axiosProxyCatchGet({
            url: '/api/v1/parental/profile/get-identificate-student?' + new URLSearchParams({name: child.name, birthDay: getDatePostFormatFunc(getDateFormatFunc(child.birthDate)), classId: child.classObj.id}),
            resolved:({data})=>{
                if(!data.data){
                    setChilds(childs.map(item => {
                        if(child.id === item.id)
                            return child
                        else
                            return item
                    }))                    
                    setCheckChildId(0)
                    setAddChildIsActive(false)
                }
                else{
                    setError('Ребёнок с идентичными данными уже числится на сайте, пожалуйста, свяжитесь с администрацией.')
                }
                setLoading(false)                                                      
            },
            rejected: ()=>{
                setLoading(false)
            },
            isNotAuthorize: true,
        })
    }


    const registration = async (e) => {
        e.preventDefault()         

        if(agreement.agree && agreement.familiarization){
            if(childs.length === 0 && !userformData.notMemberProgram){
                setError('Необходимо добавить ребенка*')
            }
            else{
                if(correctorName(userformData.name) && userformData.mail && userformData.phone.length === 17 && userformData.phone.indexOf('_') === -1 && userformData.password && userformData.password.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/) && userformData.password.length >= 6 && (/[a-zA-Z]/.test(userformData.password))){
                    axiosProxyCatchPost({
                        url: '/api/v1/identity/Create', 
                        data: {
                            parent: {
                                name: correctorName(userformData.name),
                                email: userformData.mail,
                                phone: userformData.phone,
                                password: userformData.password
                            }, 
                            childrens: childs.map(item => {
                                return {
                                    name: correctorName(item.name),
                                    birthDay: getDateFormatFuncServer(item.birthDate),
                                    citiId: item.city.id,
                                    schoolId: item.school.id,
                                    classId: item.classObj.id
                                }
                            })
                        },
                        resolved: (data)=>{
                            setEmailChecked(userformData.mail)
                            setAuthPage('confirmEmail')
                        },
                        rejected: (error)=>{
                            if(error && error.data && typeof error.data.detail === 'string')setError(error.data.detail)
                            else{
                                setError('Что-то пошло не так, попробуйте еще раз*')
                            }
                        },
                        isNotAuthorize: true,
                    
                    })
                }
                else if(!correctorName(userformData.name)) setError('Некорректное имя родителя*')
                else if(userformData.password.length < 6 || !(/[a-zA-Z]/.test(userformData.password))) setError('Пароль не должен быть короче 6 символов и должен содержать хотя бы одну букву латинского алфавита*')
                else if(!userformData.password.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/)) setError('Невалидный пароль*')
                else setError('Необходимо заполнить все поля*')
            }
        }
        else{
            setError('Необходимо дать согласие на обработку персональных данных и принять политику обработки персональных данных*')
        }
    }

	return (
        <div className='autorization__content-form-container registration-page d-flex justify-center' onClick={(e)=>{
            if(error) setError('')
            if(!e.target.classList.contains('popup__drop-list-checked') && e.target.closest('.popup__drop-list-checked') === null){
                setActiveList('')
            }
        }}>
            <div className={`autorization__content-form-registartion-container d-flex justify-center`}>
                <form className={`autorization__content-form registartion ${addChildIsActive? 'deactive': ''}`} onSubmit={registration}>
                    <div className="autorization__content-form-title">Регистрация</div>
                    <div className="autorization__content-form-label">ФИО</div>
                    <div className="autorization__content-form-field h-54 d-flex align-center justify-between mb-16">
                        <input type={'text'} value={userformData.name} className="autorization__content-form-field-input pr-16" onInput={(event)=>{
                            if(event.target.value.match(/^[a-zA-ZА-Яа-я- ]+$/) || !event.target.value) setUserFormData({...userformData, name: event.target.value})
                        }}/>
                    </div>

                    <div className="autorization__content-form-label">E-mail</div>
                    <div className="autorization__content-form-field h-54 d-flex align-center justify-between mb-16">
                        <input type='email' value={userformData.mail} className="autorization__content-form-field-input pr-16" onInput={(event)=>{
                            if((event.target.value.match(/^[a-zA-Z0-9.@_-]+$/) && event.target.value.indexOf('..') === -1) || !event.target.value) setUserFormData({...userformData, mail: event.target.value})
                        }}/>
                    </div>

                    <div className="autorization__content-form-label">Пароль</div>
                    <div className="autorization__content-form-field d-flex align-center justify-between mb-16">
                        <input autoComplete="new-password" type={(displayPass?'text':'password')} value={userformData.password} className="autorization__content-form-field-input" onInput={(event)=>{
                            if(!/[а-яА-я]/.test(event.target.value) || event.target.value === '') setUserFormData({...userformData, password: event.target.value.replaceAll(' ', '')})
                        }}/>
                        <img src={(displayPass? PasswordActiveIcon: PasswordIcon)} alt="" className="autorization__content-form-field-icon password" onClick={()=>setDisplayPass(!displayPass)}/>
                        <span></span>
                    </div>

                    <div className="autorization__content-form-label">Телефон</div>
                    <div className={`autorization__content-form-field h-54 d-flex align-center justify-between ${childs.length === 0? '': 'mb-16'}`}>
                    {/* <div className={`autorization__content-form-field h-54 d-flex align-center justify-between ${addChildIsActive && childs.length === 0? '': 'mb-16'}`}> */}
                        <MaskedInput
                            className={`autorization__content-form-field-input pr-16`}
                            mask="+7(___) ___-__-__"
                            replacement={{_ : /[0-9]/}}
                            showMask={true}
                            // separate={true}
                            defaultValue={userformData.phone}
                            name = 'phone'
                            readOnly={false}
                            placeholder=''
                            onChange={(event)=>{
                                setUserFormData({...userformData, phone: event.target.value})
                            }}
                        />
                    </div>

                    {/* <div className={`autorization__content-form-checkbox-container d-flex justify-between align-center ${addChildIsActive || childs.length !== 0? 'h-0': ''}`}>
                        <input type="checkbox" name="registration-not-member-program" value="Не являюсь участником программы" id="registration-not-member-program" className="autorization__content-form-checkbox" onClick={() => {
                            setUserFormData({...userformData, notMemberProgram: !userformData.notMemberProgram})
                        }}/>
                        <label className={`autorization__content-form-checkbox-label`} htmlFor="registration-not-member-program">Не являюсь участником программы</label>
                    </div> */}

                    <div className={`popup__checkbox docs-link mt-16 ${agreement.agree? 'active': ''} mb-16`} onClick={(e)=>{
                        if(e.target === e.currentTarget){
                            setAgreement({...agreement, agree: !agreement.agree})
                        }
                    }}>
                        Я согласен на обработку <a className='popup__checkbox-link' href="/processing-agreement"  target={'_blank'}>персональных данных</a>.
                    </div>

                    <div className={`popup__checkbox docs-link ${agreement.familiarization? 'active': ''} ${childs.length === 0? '': 'mb-16'}`} onClick={(e)=>{
                        if(e.target === e.currentTarget){
                            setAgreement({...agreement, familiarization: !agreement.familiarization})
                        }
                    }}>
                        Я ознакомился с <a className='popup__checkbox-link' href="/confidential-policy" target={'_blank'}>политикой обработки персональных данных</a>.
                    </div>

                    <div className='autorization__content-form-child-list'>
                        {
                            childs.map(item=>{
                                const {id, name} = item;
                                return(
                                    <div className={`autorization__content-form-child-list-item d-flex align-center ${checkChildId === id? 'active': ''}`} key={`autorization__content-form-child-list-item-${id}`} onClick={()=>{
                                        if(checkChildId && childformData !== childs.filter(item=>item.id === checkChildId)[0]){
                                            setError('Чтобы сменить ребенка необходимо сохранить или отменить изменения*')
                                        }
                                        else{
                                            setCheckChildId(id)
                                            setAddChildIsActive(true)
                                        }
                                    }}>
                                        <div className='autorization__content-form-child-list-item-image'/>
                                        <div className='autorization__content-form-child-list-item-name'>{name}</div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className='popup__error'>{error}</div>

                    <div className={`autorization__content-form-btn add-btn d-flex justify-center align-center ${userformData.notMemberProgram? 'deactive-button' : ''}`} onClick={()=>{
                        setAddChildIsActive(true)
                        setCheckChildId(0)
                    }}>
                        <div>Добавить детей</div>
                        <div className='autorization__content-form-btn-icon'/>
                    </div>
                    <button type="submit" className="autorization__content-form-btn mt-24">Зарегистрироваться</button>
                </form>
                
                <ChildBlock
                    formData={childformData} 
                    setFormData={setChildFormData} 
                    addChildIsActive={addChildIsActive}
                    setAddChildIsActive={()=>setAddChildIsActive(false)} 
                    activeList={activeList} 
                    setActiveList={setActiveList}
                    setError={setError}
                    childAddFunc={childAddFunc}
                    formDataReset={()=>setCheckChildId(0)}
                    isEdit={isEditChild}
                    checkChildId={checkChildId}
                    editChild={editChild}
                    setIsEditChild={setIsEditChild}
                    loading={loading}
                />
                
                <div className='autorization__content-form-registration-btn registration-page cursor-def d-flex align-center justify-center'>
                    <div className='autorization__content-form-registration-btn-label'>Есть аккаунт?</div>
                    <div className='cursor-pointer' onClick={()=>setAuthPage('')}> Войти</div>
                </div>
            </div>
        </div>
	)
}

export default Registration;