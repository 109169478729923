import { NavLink } from 'react-router-dom';
import './index.scss'
import { useDispatch, useSelector } from 'react-redux';
import { setUserNavBarActive } from '../../../../redux/users/navigations/userNavigationsSlice';
import { setUserPopupBack } from '../../../../redux/users/popup/userPopupSlice';
import { selectUserPopup } from '../../../../redux/users/popup/selectors';



const MobileNavbar = ({navbarData, financeAccess}) =>{
    const dispatch = useDispatch();
    const {popupBack} = useSelector(selectUserPopup)

    const addNavbarItem = (item, key) =>{
        const {label, link, name} = item;
        return(
            <NavLink to={link} className={`user-page__mobile-navbar-item d-flex align-center justify-center flex-column ${(link === '/finance' && !financeAccess)? 'deactive-button': ''}`} key={'user-page__mobile-navbar-'+key} onClick={()=>{
                dispatch(setUserNavBarActive(key))
                if(popupBack) dispatch(setUserPopupBack(''))
            }}>
                <div className={`user-page__mobile-navbar-item-icon ${name}`}/>
                <div className={`user-page__mobile-navbar-item-name`}>{label}</div>
            </NavLink>
        )
    }

    return (
        <div className={`user-page__mobile-navbar d-flex justify-between align-center`}>
            {
                navbarData.map(addNavbarItem)
            }
        </div>
    )
}

export default MobileNavbar;