import AegrotatElem from './../AegrotatElem/index';
import DocumentIcon from '../../../../img/document-grey-icon.svg';

import './index.scss';
import DocumentDefaultElem from '../DocumentDefaultElem';

const AegrotatsContainer = ({keyVal, title, aegrotats, sendFunc, onScrollFunc, pageLast, childIsFinish}) =>{
    return (
        <div className={`user-page__back-container aegrotat documents-fixed-height ${aegrotats.length > 3? 'aegrotat-scroll': ''}`} key={keyVal}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='user-page__title back-aegrotat-container-title'>{title}</div>
                <div className={`user-page__aegrotat-add-button d-flex align-center justify-center ${childIsFinish? 'deactive-button': ''}`} onClick={()=>sendFunc()}>
                    <div className='user-page__aegrotat-add-button-label'>Добавить документ</div>
                    <div className='user-page__aegrotat-add-button-icon'/>
                </div>
            </div>
            

            <div className={`user-page__aegrotat-list ${(aegrotats.length > 3)? 'scroll-active': ''}`}  onScroll={(e)=>onScrollFunc(e)}>
                {
                    aegrotats.map((item)=><AegrotatElem key={keyVal + '-' + item.id} keyVal={keyVal} item={item}/>)
                }
                {
                    (aegrotats.length < 3)? (
                        <>
                            {
                                [1, 2, 3,].slice(aegrotats.length).map(item=><DocumentDefaultElem key={`user-page__aegrotat-default-${item}`} keyVal={`user-page__aegrotat-default-${item}`}/>)
                            }
                        </>
                    ): null
                }
            </div>

            
        </div>
    )
}

export default AegrotatsContainer;