import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserPopup from '../Popup/UserPopup';
import Child from './Child';
import Profile from './Profile';

import { setUserPopupBack } from '../../../redux/users/popup/userPopupSlice';

import './index.scss'
import { selectUserProfile } from './../../../redux/users/profile/selectors';
import { setUserProfileCheckData } from '../../../redux/users/profile/profileSlice';
import { selectUserChildren } from './../../../redux/users/children/selectors';
import { setUserCheckChild, setUserFinanceChildrenDataEdit } from '../../../redux/users/children/childrenSlice';
import { fetchUserProfileData } from '../../../redux/users/profile/asyncActions';
import { fetchUserChildsData } from '../../../redux/users/children/asyncActions';
import { setUserNavBarActive } from '../../../redux/users/navigations/userNavigationsSlice';


const About = () =>{
    const dispatch = useDispatch();

    const { profileData } = useSelector(selectUserProfile);
    const { homeChildrenData } = useSelector(selectUserChildren);

    const [popupType, setPopupType] = useState('')

    const editProfileFunc = (elem) =>{
        dispatch(setUserPopupBack('open'))
        setPopupType((elem.isUpdate)? 'ProfileCurrentChages': 'EditProfile')
        // setPopupType((elem.status > 1 && elem.status < 5)? 'ProfileCurrentChages': 'EditProfile')
        dispatch(setUserProfileCheckData(elem))
    }

    const editChildFunc = (elem) =>{
        dispatch(setUserPopupBack('open'))
        setPopupType(elem.isUpdate? 'ChildCurrentChanges': 'EditChild')
        dispatch(setUserCheckChild(elem))
    }

    useEffect(()=>{
        dispatch(fetchUserProfileData())
        dispatch(fetchUserChildsData())
        dispatch(setUserNavBarActive(0))
    }, [])

    return (
        <div className={`user-page__about`}>
            <div className='user-page__title'>Профиль</div>
            <Profile user={profileData} clickFunc={editProfileFunc}/>

            <div className='user-page__title'>Дети</div>
            <div className='user-page__about-children d-flex flex-wrap'>
                {
                    homeChildrenData.map((item)=><Child key={'user-page__about-children-' + item.id} item={item} profileStatus={profileData.status} clickFunk={editChildFunc}/>)
                }
            </div>
            <UserPopup type={popupType} props={{typeEdit: setPopupType}}/>
        </div>
    )
}

export default About;