import './index.scss'
import { useState, useEffect } from 'react';
import MaskedInput from './../../../MaskedInput/MaskedInput';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserChildren } from './../../../../redux/users/children/selectors';
import { setUserCheckChild, setUserChildrenDataAddElem, setUserChildrenDataElemEdit } from '../../../../redux/users/children/childrenSlice';
import { axiosProxyCatchPost } from '../../../../axiosProxy';
import { ChildClass, City, School } from '../../../Filters/FiltersList';
import { isCorrectDate, getDateFormatFuncServer, correctorName } from './../../../../services/clientHome';
import { selectUserProfile } from './../../../../redux/users/profile/selectors';

const title = {
    'EditChild': 'Редактирование ребенка',
    'ChildNewAgreement': 'Заключение нового договора',
    'CreateChild': 'Добавление ребенка',
}

const buttonTitle = {
    'EditChild': 'Сохранить',
    'ChildNewAgreement': 'Подтвердить',
    'CreateChild': 'Подтвердить',
}


const EditChild = ({closePopup, popupBack, type}) =>{
    const dispatch = useDispatch()
    const { checkChild } = useSelector(selectUserChildren);
    const { profileData } = useSelector(selectUserProfile);

    const [error, setError] = useState('')

    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        birthDay: '',
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        classNumber: '',
        classLetter: '',
        classObj: {id: 0, number: '', letter: ''},
    })

    const [activeList, setActiveList] = useState('')
    const [loading, setLoading] = useState(false)

    const [searchData, setSearchData] = useState({
        city: '',
        school: ''
    })

    useEffect(()=>{
        if(checkChild.id !== 0) setFormData({...checkChild, classNumber: checkChild.classObj.number, classLetter: checkChild.classObj.letter})
        else {
            setFormData({
                id: 0,
                name: '',
                birthDay: '',
                city: {id: 0, name: ''},
                school: {id: 0, name: ''},
                classNumber: '',
                classLetter: '',
                classObj: {id: 0, number: '', letter: ''},
            })            
        }
    }, [popupBack, checkChild.id])

    useEffect(()=>{
        setActiveList('')
        setError('')
    }, [popupBack, type, checkChild.id])

    const checkForCompletionFunc = (func) =>{
        const {id, name, birthDay, city, school, classObj} = formData;

        let editData = {
            id, 
            name: correctorName(name),
            birthDay: getDateFormatFuncServer(birthDay), 
            cityId: city.id, 
            schoolId: school.id, 
            classId: classObj.id
        };        

        if(correctorName(name) && (editData.birthDay && formData.birthDay !== '' && formData.birthDay.indexOf('_') === -1 && isCorrectDate(formData.birthDay)) && editData.cityId && editData.schoolId && editData.classId){
            setLoading(true)
            func(editData)
        }
        else if(!isCorrectDate(formData.birthDay) && formData.birthDay !== '' && formData.birthDay.indexOf('_') === -1){
            setError('Некорректная дата рождения*')
        }
        else{
            setError('Необходимо заполнить все поля*')
        }
    }

    const concludeNewAgreementFunc = (editData) =>{
        const {id, name, birthDay, city, school, classObj} = formData;
        axiosProxyCatchPost({
            url: '/api/v1/parental/profile/create-refresh-student', 
            data: {...editData, ownId: editData.id},
            resolved: (data) =>{                    
                dispatch(setUserChildrenDataElemEdit({id: data.id, name, birthDay, city, school, classObj, editId: id, application: data.application}))
                closePopup()
                setLoading(false)
                resetData()
            },
            rejected: (error) =>{                    
                if(error.data && error.data.detail)setError(error.data.detail)
                setLoading(false)
            }
        })
    }

    const editChildFunc = (editData) =>{
        const {id, name, birthDay, city, school, classObj} = formData;
        axiosProxyCatchPost({
            url: profileData.status === -1 ? '/api/v1/parental/profile/update-refused-students': '/api/v1/parental/profile/update-student-application', 
            data: profileData.status === -1 ? {data: [editData]}: editData,
            resolved: (data) =>{
                dispatch(setUserChildrenDataElemEdit(
                    profileData.status === -1 ?{id, name, birthDay, city, school, classObj}
                    :{id: id, isUpdate: true, update: {id, name, birthDay, city, school, classObj, status: 0}, application: data.application}
                ))
                closePopup()
                setLoading(false)
                resetData()
            },
            rejected: (error) =>{                    
                if(error.data && error.data.detail)setError(error.data.detail)
                setLoading(false)
            }
        })
    }

    const addChildFunc = (editData) =>{
        const {id, name, birthDay, city, school, classObj} = formData;
        axiosProxyCatchPost({
            url: '/api/v1/parental/profile/create-refresh-student', 
            data: editData,
            resolved: (data) =>{
                dispatch(setUserChildrenDataAddElem({id: data.id, name, birthDay, city, school, classObj, update: null, isUpdate: false, application: data.application}))
                closePopup()
                setLoading(false)
                resetData()
            },
            rejected: (error) =>{                    
                if(error.data && error.data.detail)setError(error.data.detail)
                setLoading(false)
            }
        })
    }

    const childIsEdited = (formData, checkData) =>{
        const {name, birthDay, city, school, classObj} = formData;
        let editData = {}
        if(name !== checkData.name) editData.name = name;
        if(birthDay !== checkData.birthDay) editData.birthDay = birthDay;
        if(city.id !== checkData.city.id) editData.cityId = city;
        if(school.id !== checkData.school.id) editData.schoolId = school;
        if(classObj.id !== checkData.classObj.id) editData.classId = classObj.id;
        
        return Object.keys(editData).length
    }

    const clickButtonFunc = () =>{
        if(type === 'EditChild'){
            checkForCompletionFunc(editChildFunc)
        }
        else if(type === 'ChildNewAgreement'){
            checkForCompletionFunc(concludeNewAgreementFunc)
        }
        else if(type === 'CreateChild'){
            checkForCompletionFunc(addChildFunc)
        }
    }

    const resetData = ()=>{
        setFormData({
            id: 0,
            name: '',
            birthDay: '',
            city: {id: 0, name: ''},
            school: {id: 0, name: ''},
            classNumber: '',
            classLetter: '',
            classObj: {id: 0, number: '', letter: ''},
        })
        dispatch(setUserCheckChild({id: 0, name: '', birthDay: '', city: {id: 0, name: ''}, school: {id: 0, name: ''}, classObj: {id: 0, number: '', letter: ''}}))
    }

    return(
        <div className={`popup__container user-popup__container edit-profile`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__button') && e.target.closest('.popup__drop-list-checked') === null){
                setActiveList('')
                setSearchData({city: '', school: ''})
            }
        }}>
            <div className='popup__title mb-24 fs-24' onClick={closePopup}>{title[type]}</div>
            <label className="popup__label mb-4">ФИО</label>
            <input type={'text'} value={formData.name} readOnly={type === 'ChildNewAgreement'} className={`popup__input mb-16 ${type === 'ChildNewAgreement'? 'popup__disable-input': ''}`} onInput={(e)=>{
                if(e.target.value.match(/^[a-zA-ZА-Яа-я- ]+$/) || !e.target.value) setFormData({...formData, name: e.target.value})
            }}/>

            <label className="popup__label mb-4">Дата рождения</label>
            <MaskedInput
                className={`popup__input mb-16 ${type === 'ChildNewAgreement'? 'popup__disable-input': ''}`}
                mask="__.__.____"
                replacement={{_ : /[0-9]/}}
                showMask={true}
                separate={true}
                defaultValue={formData.birthDay}
                name = 'endDate'
                readOnly={type === 'ChildNewAgreement'}
                placeholder=''
                onChange={(e)=>{
                    setFormData({...formData, birthDay: e.target.value})
                }}
            />

            <City
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/parental/profile'
            />

            <School
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/parental/profile'
            />

            <ChildClass 
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/parental/profile'
                classes={''}
            />    

            <div className='popup__error'>{error}</div>     

            <div className='popup__button-container d-flex justify-between'>
                <button className={`popup__button cancel ${loading? 'deactive-button': ''}`} onClick={closePopup}>Отменить</button>
                <button className={`popup__button save ${loading || (!childIsEdited(formData, checkChild) && type === 'EditChild')? 'deactive-button': ''}`} onClick={clickButtonFunc}>{buttonTitle[type]}</button>
            </div>
        </div>
    )
}

export default EditChild;