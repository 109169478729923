import { useDeferredValue, useEffect, useState, useTransition } from 'react';
import PopupDropListElem from '../PopupDropListElem';
import { axiosProxyCatchGet } from '../../axiosProxy';
import { setTeachersName } from '../Admin/Programs/Programs/ProgramsListElem/ProgramsListElem';
import { scrollingPagination, useDebouncedValue } from '../../services/clientHome';
import { getAcademicYear } from './../../services/clientHome';



const paymentTypeData = [
    {
        id: 1,
        name: 'СБП',
        color: 'sbp'
    },
    {
        id: 2,
        name: 'Тинькофф',
        color: 'tincoff'
    },
    {
        id: 3,
        name: 'СБЕР',
        color: 'sber'
    },
    {
        id: 4,
        name: 'ВТБ',
        color: 'vtb'
    },
    {
        id: 5,
        name: 'Альфа банк',
        color: 'alfabank'
    }
]

const monthData = [
    {
        id: 1,
        label: 'Январь',
    },
    {
        id: 2,
        label: 'Февраль',
    },
    {
        id: 3,
        label: 'Март',
    },
    {
        id: 4,
        label: 'Апрель',
    },
    {
        id: 5,
        label: 'Май',
    },
    {
        id: 6,
        label: 'Июнь',
    },
    {
        id: 7,
        label: 'Июль',
    },
    {
        id: 8,
        label: 'Август',
    },
    {
        id: 9,
        label: 'Сентябрь',
    },
    {
        id: 10,
        label: 'Октябрь',
    },
    {
        id: 11,
        label: 'Ноябрь',
    },
    {
        id: 12,
        label: 'Декабрь',
    },
]

const yearsData = () =>{
    let year = new Date().getFullYear()
    let years = [];
    for (let i = 2024; i <= year + 5; i++) {
       years.push(i)        
    }
    return years;
}

const SearchDropListBlock = ({searchVal, label, searchFunc=()=>{}, setSearch, setLabel, listIsActive, keyVal, searchData,  classes='', textarea=false, inputType='text', isFilter, borderIsActive=false, scrollFunc=(e)=>{}}) =>{
    return(
        <div className={`popup__drop-list-container ${textarea? 'textarea': ''} ${classes}`}>
            <div className={`popup__drop-list-checked popup__additionaly-input ${textarea? 'textarea': ''} ${listIsActive? 'active': ''} ${borderIsActive? 'border-active': ''}`} onClick={()=>{
                searchFunc()
            }}>
                {
                    (!textarea)? (
                        <>
                            <input name='' type={inputType} placeholder='' value={searchVal} className={`popup__drop-list-checked-input popup__additionaly-input-input ${(listIsActive)? 'active': ''}`} onChange={(e) => {
                                setSearch(e.target.value)
                            }}/>
                            <div className='popup__additionaly-input-val'>{label.name}</div>
                        </>
                    ):(
                        <>
                            <textarea name='' type="text" placeholder='' value={searchVal} className={`popup__drop-list-checked-input popup__additionaly-input-input textarea ${(listIsActive)? 'active': ''}`} onChange={(e) => setSearch(e.target.value)}/>
                            <div className='popup__additionaly-input-val textarea'>{label.name}</div>
                        </>
                    )
                }
            </div>
            <div className={`popup__drop-list ${textarea? 'textarea': ''} ${listIsActive? 'active': ''}`}>
                <div className='popup__drop-list-scroll' onScroll={(e) => scrollFunc(e)}>
                    {
                        searchData.map(item=>PopupDropListElem(item.name, ()=>{
                                setLabel(item)
                            },
                            (label.id === item.id? 'active': ''),
                            `popup-droplist-${keyVal}-${item.id}`
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

const DropListBlock = ({label, setLabel, listData, listIsActive, keyVal, openListFunc, classes='', textarea=false, isFilter=false, borderIsActive=false}) =>{
    const checkElem = (elem)=>{
        return (typeof elem === 'string' || typeof elem === 'number' || elem === undefined)? true: false
    }
    return(
        <div className={`popup__drop-list-container ${textarea? 'textarea': ''} ${classes}`}>
            <div className={`popup__drop-list-checked popup__additionaly-input cursor-pointer ${textarea? 'textarea': ''} ${listIsActive? 'active': ''} ${borderIsActive? 'border-active': ''}`} onClick={()=>{
                openListFunc()
            }}>
                <div className='popup__input not-border cursor-pointer'>{checkElem(label)? label: label.name}</div>
            </div>
            <div className={`popup__drop-list ${listIsActive? 'active': ''}`}>
                <div className='popup__drop-list-scroll'>
                    {
                        listData.map((item, index)=>PopupDropListElem(checkElem(item)? item: item.name, ()=>{
                                setLabel(item)
                            },
                            ((checkElem(item)? item: (item.id)? item.id: item.name) === (checkElem(label)? label: (label.id)? label.id: label.name)? 'active': ''),
                            `popup-droplist-${keyVal}-${checkElem(item)? item: (item.id)? item.id: index}`
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export const City = ({formData, setFormData, searchData, setSearchData, activeList, setActiveList, setError=()=>{}, url='', classes='mb-16', isFilter = false, isNotAuthorize=false, recalculate=false, borderIsActive=false}) =>{
    const [citySearchList, setCitySearchList] = useState([]);
    const [page, setPage] = useState(0);
    const [pageLast, setPageLast] = useState(false);
    const [scrollReset, setScrollReset] = useState(false);
    const [loading, setLoading] = useState(false);

    const [isPending, startTransition] = useTransition();
    const searchText = useDebouncedValue(searchData.city, 300)

    useEffect(()=>{
        citySearchFunc(searchText)
    }, [searchText])


    const citySearchFunc = (query) =>{
        setError('')
        setPage(0)
        setLoading(true)
        setScrollReset(true)
        setPageLast(false)
        if(query){
            let postData = {query, page: 0, count: 30}
            if(recalculate) postData.recalculate = recalculate;
            axiosProxyCatchGet({
                url: ((url)? url: '/api/v1/identity') + `/get-cities?` + new URLSearchParams(postData),
                resolved:({data:{data:cityData}})=>{                    
                    if(Array.isArray(cityData)){
                        startTransition(() => {
                            setCitySearchList(cityData)
                            setActiveList('cityList')
                            if(cityData.length < 30) setPageLast(true)
                        })
                    }
                    setScrollReset(false)
                    setLoading(false)
                    setPage(1)
                },
                rejected: ()=>{
    
                },
                isNotAuthorize: isNotAuthorize,
            })
        }
        else{
            setCitySearchList([])
        }
    };

    const cityLoadingFunc = () =>{
        if(page){
            setLoading(true)
            
            let postData = {query: searchData.city? searchData.city: [], page: page, count: 30}
            if(recalculate) postData.recalculate = recalculate;
            axiosProxyCatchGet({
                url: (url? url: '/api/v1/identity') + `/get-cities?` + new URLSearchParams(postData),
                resolved:({data:{data:cityData}})=>{
                    if(Array.isArray(cityData)){
                        startTransition(() => {
                            setCitySearchList([...citySearchList, ...cityData])
                            setLoading(false)
                            if(cityData < 30) setPageLast(true)
                        })
                    }                    
                },
                rejected: ()=>{
                    setLoading(false)
                },
                isNotAuthorize: isNotAuthorize,
            })
        }
    };

    

    return(
        <>
            <div className="autorization__content-form-label">Город</div>
            <SearchDropListBlock 
                title={'Город'} 
                searchVal={searchData.city} 
                label={formData.city}
                searchFunc={()=>{if(!(citySearchList.length && activeList==='cityList')) citySearchFunc([])}}
                setSearch={(val)=>setSearchData({...searchData, city: val})} 
                setLabel={(elem)=>{
                    let result = {...formData, city: elem}
                    if(result.school) result.school =  {id: 0, name: ''}
                    if(result.classNumber) result.classNumber =  ''
                    if(result.classLetter) result.classLetter =  ''
                    if(result.classObj) result.classObj =  {id: 0, number: '', letter: ''}
                    if(result.parrent) result.parrent =  {id: 0, name: ''}
                    if(result.child) result.child =  {id: 0, name: ''}
                    setFormData(result)
                    setSearchData({...searchData, city: ''})
                }} 
                listIsActive={activeList==='cityList' && citySearchList.length} keyVal={'city'} 
                searchData={citySearchList}
                classes={classes}
                borderIsActive={borderIsActive}
                scrollFunc={
                    (e)=>scrollingPagination(
                        e, 
                        ()=>setPage(page+1), 
                        ()=>cityLoadingFunc(),
                        pageLast,
                        loading,
                        scrollReset, 
                        ()=>setScrollReset(true),
                        40
                    )
                }
            />
        </>
    )
};

export const School = ({formData, setFormData, searchData, setSearchData, activeList, setActiveList, setError=()=>{}, url='', classes='mb-16', isFilter = false, isNotAuthorize=false, recalculate=false, borderIsActive=false}) =>{
    const [schoolSearchList, setSchoolSearchList] = useState([])
    const [page, setPage] = useState(0);
    const [pageLast, setPageLast] = useState(false);
    const [scrollReset, setScrollReset] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isPending, startTransition] = useTransition();

    
    const searchText = useDebouncedValue(searchData.school, 300)

    useEffect(()=>{
        schoolSearchFunc(searchText)
    }, [searchText])


    const schoolSearchFunc = (query) =>{
        setError('')
        setPage(0)
        setLoading(true)
        setScrollReset(true)
        setPageLast(false)
        if(query){
            let postData = {query, page: 0, count: 30}
            if(formData.city && formData.city.id) postData.cityId = formData.city.id;
            if(recalculate) postData.recalculate = recalculate;
            axiosProxyCatchGet({
                url: ((url)? url: '/api/v1/identity') + `/get-school` + (`?`+ new URLSearchParams(postData)),
                resolved:({data:{data:schoolData}})=>{
                    if(Array.isArray(schoolData)){
                        startTransition(() => {
                            setSchoolSearchList(schoolData)
                            setActiveList('schoolList')
                            if(schoolData.length < 30) setPageLast(true)
                        })
                    }
                    setScrollReset(false)
                    setLoading(false)
                    setPage(1)
                },
                rejected: ()=>{
            
                },
                isNotAuthorize: isNotAuthorize,
            })
        }
        else{
            setSchoolSearchList([])
        }      
    }
    const inputClick = () =>{
        if(((formData.city && formData.city.id) || !formData.city || isFilter)){
            if(!(schoolSearchList.length && activeList === 'schoolList')) schoolSearchFunc([])
        }
        else{
            setError('Необходимо выбрать город*')
        }
    }

    const schoolLoadingFunc = () =>{
        if(page){
            let postData = {query: searchData.school? searchData.school: [], page: page, count: 30}
            if(formData.city && formData.city.id) postData.cityId = formData.city.id            
            setLoading(true)
            axiosProxyCatchGet({
                url: (url? url: '/api/v1/identity') + `/get-school?` + new URLSearchParams(postData),
                resolved:({data:{data:schoolData}})=>{                    
                    startTransition(() => {
                        if(Array.isArray(schoolData)){
                            setSchoolSearchList([...schoolSearchList, ...schoolData])
                            setLoading(false)
                            if(schoolData.length < 30) setPageLast(true)
                        }
                    })
                },
                rejected: ()=>{
                    setLoading(false)
                },
                isNotAuthorize: isNotAuthorize,
            })
        }
    };

    return(
        <>
            <div className="autorization__content-form-label">Школа</div>
            <SearchDropListBlock 
                searchVal={searchData.school} 
                label={formData.school}
                searchFunc={inputClick}
                setSearch={(val)=>{if((formData.city && formData.city.id) || !formData.city || isFilter) setSearchData({...searchData, school: val})}} 
                setLabel={(elem)=>{
                    let result = {...formData, school: elem}
                    if(result.classNumber) result.classNumber = ''
                    if(result.classLetter) result.classLetter = ''
                    if(result.classObj) result.classObj = {id: 0, number: '', letter: ''}
                    if(result.parrent) result.parrent = {id: 0, name: ''}
                    if(result.child) result.child = {id: 0, name: ''}
                    setFormData(result)
                    setSearchData({...searchData, school: ''})
                }}
                listIsActive={activeList==='schoolList' && schoolSearchList.length} keyVal={'school'} 
                searchData={schoolSearchList}
                classes={classes}
                borderIsActive={borderIsActive}
                textarea={true}
                scrollFunc={
                    (e)=>scrollingPagination(
                        e, 
                        ()=>setPage(page+1), 
                        ()=>schoolLoadingFunc(),
                        pageLast,
                        loading,
                        scrollReset, 
                        ()=>setScrollReset(true),
                        40
                    )
                }
            />
        </>
    )
}

export const ChildClass = ({formData, setFormData, activeList, setActiveList, setError=()=>{}, url='', classes='mb-16', isFilter = false, isNotAuthorize=false, numberBorderIsActive=false, letterBorderIsActive=false}) =>{
    const [classList, setClassList] = useState([])

    const getClassesFunc = (query) =>{
        if(formData.school && formData.school.id || !formData.school || isFilter){
            let postData = {}
            if(isFilter && query) postData.query = query 
            if(formData.school && formData.school.id) postData.schoolId = formData.school.id;
            if(url === '/api/v1/administrative/programs'){
                postData.page = 0;
                postData.count=1000;
            }
            axiosProxyCatchGet({
                url:  ((url? url: '/api/v1/identity') + `/get-class`) + (Object.keys(postData).length ? (`?`+ new URLSearchParams(postData)): ''),
                resolved:({data:{data:classesData}})=>{
                    setClassList(classesData)
                },
                rejected: ()=>{
            
                },
                isNotAuthorize: isNotAuthorize,
            })
        }
    }

    useEffect(()=>{
       getClassesFunc()
    }, [formData.school.id])

    const classNumberSearchFunc = () =>{
        setError('')
        if((formData.school && formData.school.id) || !formData.school || isFilter){
            setActiveList('classNumber')
        }
        else{
            setError('Необходимо выбрать школу*')
        }
    }
    
    const classLetterSearchFunc = () =>{
        setError('')
        if(formData.classNumber){
            if(classList.length !== 0 && activeList !== 'classLetter'){
                setActiveList('classLetter')
            }
            else{
                setActiveList('')
            }
        }
        else{
            setError('Необходимо выбрать номер класса*')
        }
    }

    return(
        <>
            <label htmlFor="" className='popup__label mb-4'>Класс</label>      
            <div className='d-flex'>
                <div className='popup__drop-list-container wd-50-mr-16 popup__drop-list-open'>
                    <DropListBlock 
                        label={formData.classNumber} 
                        openListFunc={classNumberSearchFunc} 
                        setLabel={(elem)=>{
                            let result = {...formData, classNumber: elem, classObj: {id: 0, number: elem, letter: ''}}
                            if(result.classLetter) result.classLetter = ''
                            if(result.parrent) result.parrent = {id: 0, name: ''}
                            if(result.child) result.child = {id: 0, name: ''}
                            setFormData(result)
                            // getClassesFunc(elem)
                        }}
                        listIsActive={activeList==='classNumber' && classList.length} keyVal={'classnumber'} 
                        listData={[...new Set(classList.map(item => item.number))]}
                        classes={classes}
                        borderIsActive={numberBorderIsActive}
                    />
                </div>
                <div className='popup__drop-list-container wd-50-mr-16 popup__drop-list-open'>
                    <DropListBlock 
                        label={formData.classLetter} 
                        openListFunc={classLetterSearchFunc} 
                        setLabel={(elem)=>{
                            let result = {...formData, classLetter: elem, classObj: classList.filter(item=> item.number === formData.classNumber && item.letter === elem)[0]}
                            if(result.parrent) result.parrent = {id: 0, name: ''}
                            if(result.child) result.child = {id: 0, name: ''}
                            setFormData(result)
                        }}
                        listIsActive={activeList==='classLetter' && classList.length} keyVal={'classletter'} 
                        listData={[...new Set(classList.filter(item => item.number === formData.classNumber).map(item => item.letter))]}
                        classes={classes}
                        borderIsActive={letterBorderIsActive}
                    />
                </div>
            </div>
        </>
    )
}

export const TabelChildClass = ({formData, setFormData, activeList, setActiveList, setError=()=>{}, url='', classes='mb-16', isNotAuthorize=false}) =>{
    const [classList, setClassList] = useState([])

    const getClassesFunc = () =>{
            let postData = {}
            axiosProxyCatchGet({
                url:  ((url? url: '/api/v1/identity') + `/get-classes`) + (Object.keys(postData).length ? (`?`+ new URLSearchParams(postData)): ''),
                resolved:({data:{data:classesData}})=>{
                    setClassList(classesData)
                },
                rejected: ()=>{
            
                },
                isNotAuthorize: isNotAuthorize,
            })
    }

    const classNumberSearchFunc = () =>{
        setError('')
        getClassesFunc()
        if(activeList !== 'classNumber'){
            setActiveList('classNumber')
        }
        else{
            setActiveList('')
        }
    }
    
    const classLetterSearchFunc = () =>{
        setError('')
        if(formData.classNumber){
            if(activeList !== 'classLetter'){
                setActiveList('classLetter')
            }
            else{
                setActiveList('')
            }
        }
        else{
            setError('Необходимо выбрать номер класса*')
        }
    }

    return(
        <div className='d-flex'>
            <div className='popup__drop-list-container wd-50-mr-16 popup__drop-list-open'>
                <DropListBlock 
                    label={formData.classNumber? formData.classNumber: 'Класс'} 
                    openListFunc={classNumberSearchFunc} 
                    setLabel={(elem)=>{
                        let result = {...formData, classNumber: elem, classObj: {id: 0, number: elem, letter: ''}}
                        if(result.classLetter) result.classLetter = ''
                        if(result.program) result.program = {id: 0, name: '', teacherName: '', isContainsSubgroup: false}
                        if(result.subgroup) result.subgroup = {id: 0, name: ''}
                        setFormData(result)
                        // getClassesFunc(elem)
                    }}
                    listIsActive={activeList==='classNumber' && classList.length} keyVal={'classnumber'} 
                    listData={[...new Set(classList.map(item => item.number))]}
                    classes={`${classes} ${formData.classNumber? '': 'color-grey'}`}
                />
            </div>
            <div className='popup__drop-list-container wd-50-mr-16 popup__drop-list-open'>
                <DropListBlock 
                    label={formData.classLetter? formData.classLetter: 'Литера'}
                    openListFunc={classLetterSearchFunc} 
                    setLabel={(elem)=>{
                        let result = {...formData, classLetter: elem, classObj: classList.filter(item=> item.number === formData.classNumber && item.letter === elem)[0]}
                        if(result.program) result.program = {id: 0, name: '', teacherName: '', isContainsSubgroup: false}
                        if(result.subgroup) result.subgroup = {id: 0, name: ''}
                        setFormData(result)
                    }}
                    listIsActive={activeList==='classLetter' && classList.length} keyVal={'classletter'} 
                    listData={[...new Set(classList.filter(item => item.number === formData.classNumber).map(item => item.letter))]}
                    classes={`${classes} ${formData.classLetter? '': 'color-grey'}`}
                />
            </div>
        </div>
    )
}

export const Parrent = ({formData, setFormData, searchData, setSearchData, activeList, setActiveList, setError=()=>{}, url='', classes='mb-16', isFilter = false}) =>{
    const [parrentSearchList, setParrentSearchList] = useState([]);
    const [page, setPage] = useState(0);
    const [pageLast, setPageLast] = useState(false);
    const [scrollReset, setScrollReset] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isPending, startTransition] = useTransition();

    const searchText = useDebouncedValue(searchData.parrent, 300)

    useEffect(()=>{
        parrentSearchFunc(searchText)
    }, [searchText])

    const parrentSearchFunc = (query) =>{
        setError('')
        setPage(0)
        setLoading(true)
        setScrollReset(true)
        setPageLast(false)
        if(query){
            let postData = {query, page: 0, count: 30}
            if(formData.city && formData.city.id) postData.cityId = formData.city.id;
            if(formData.school && formData.school.id) postData.schoolId = formData.school.id;
            if((formData.classObj && formData.classObj.id) || (formData.classObj && (formData.classObj.number))) postData.classObj = formData.classObj.number + formData.classObj.letter;   
            axiosProxyCatchGet({
                url: ((url)? url: '/api/v1/identity') + `/get-clients` + (!query? '': `?`+ new URLSearchParams(postData)),
                resolved:({data:{data:parrentsData}})=>{
                    if(Array.isArray(parrentsData)){
                        startTransition(() => {
                            setParrentSearchList(parrentsData)
                            setActiveList('parrentsList')
                            if(parrentsData.length < 30) setPageLast(true)
                        })
                    }
                    setScrollReset(false)
                    setLoading(false)
                    setPage(1)
                },
                rejected: ()=>{
            
                }
            })
        }
        else setParrentSearchList([])
        
    }

    const inputClick = () =>{
        if((formData.classObj && formData.classObj.id) || !formData.classObj || isFilter){
            if(!(parrentSearchList.length && activeList === 'parrentsList')) parrentSearchFunc([])
        }
        else{
            setError('Необходимо выбрать класс*')
        }
    }

    const parrentLoadingFunc = () =>{
        if(page){
            let postData = {query: searchData.parrent? searchData.parrent: [], page: page, count: 30}
            if(formData.city && formData.city.id) postData.cityId = formData.city.id;
            if(formData.school && formData.school.id) postData.schoolId = formData.school.id;
            if(formData.classObj && formData.classObj.id) postData.classObj = formData.classObj.number + formData.classObj.letter;        
            setLoading(true)
            axiosProxyCatchGet({
                url: (url? url: '/api/v1/identity') + `/get-clients?` + new URLSearchParams(postData),
                resolved:({data:{data:parrentsData}})=>{                    
                    startTransition(() => {
                        if(Array.isArray(parrentsData)){
                            setParrentSearchList([...parrentSearchList, ...parrentsData])
                            setLoading(false)
                            if(parrentsData.length < 30) setPageLast(true)
                        }                        
                    })
                },
                rejected: ()=>{
                    setLoading(false)
                }
            })
        }
    };

    return(
        <>
            <div className="autorization__content-form-label">Родитель</div>
            <SearchDropListBlock 
                searchVal={searchData.parrent} 
                label={formData.parrent} 
                searchFunc={inputClick} 
                setSearch={(val)=>{if((formData.classObj && formData.classObj.id) || !formData.classObj || isFilter) setSearchData({...searchData, parrent: val})}} 
                setLabel={(elem)=>{
                    let result = {...formData, parrent: elem}
                    if(result.child) result.child = {id: 0, name: ''}
                    setFormData(result)
                    setSearchData({...searchData, parrent: ''})
                }} 
                listIsActive={activeList==='parrentsList' && parrentSearchList.length} keyVal={'parrent'} 
                searchData={parrentSearchList}
                classes={classes}
                scrollFunc={
                    (e)=>scrollingPagination(
                        e, 
                        ()=>setPage(page+1), 
                        ()=>parrentLoadingFunc(),
                        pageLast,
                        loading,
                        scrollReset, 
                        ()=>setScrollReset(true),
                        30
                    )
                }
            />
        </>
    )
};

export const Child = ({formData, setFormData, searchData, setSearchData, activeList, setActiveList, setError=()=>{}, url='', classes='mb-16', isFilter = false}) =>{
    const [childSearchList, setChildSearchList] = useState([]);
    const [page, setPage] = useState(0);
    const [pageLast, setPageLast] = useState(false);
    const [scrollReset, setScrollReset] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isPending, startTransition] = useTransition();

    const searchText = useDebouncedValue(searchData.child, 300)

    useEffect(()=>{
        childSearchFunc(searchText)
    }, [searchText])

    const childSearchFunc = (query) =>{
        setError('')
        setPage(0)
        setLoading(true)
        setScrollReset(true)
        setPageLast(false)
        if(query){            
            let postData = {query, page: 0, count: 30}
            if(formData.city && formData.city.id) postData.cityId = formData.city.id;
            if(formData.school && formData.school.id) postData.schoolId = formData.school.id;
            if(formData.classObj && formData.classObj.id) postData.classObj = formData.classObj.number + formData.classObj.letter;
            if(formData.parrent && formData.parrent.id) postData.clientId = formData.parrent.id;     
            axiosProxyCatchGet({
                url: ((url)? url: '/api/v1/identity') + `/get-student-low` + (!query? '': `?`+ new URLSearchParams(postData)),
                resolved:({data:{data:childsData}})=>{
                    if(Array.isArray(childsData)){
                        startTransition(() => {
                            setChildSearchList(childsData)
                            setActiveList('childsList')
                            if(childsData.length < 30) setPageLast(true)
                        })
                    }
                    setScrollReset(false)
                    setLoading(false)
                    setPage(1)
                },
                rejected: ()=>{
            
                }
            })
        }
        else{
            setChildSearchList([])
        }
        
    }

    const inputClick = () =>{
        if((formData.parrent && formData.parrent.id) || !formData.parrent || isFilter){
            if(!(childSearchList.length && activeList === 'childsList')) childSearchFunc([])
        }
        else{
            setError('Необходимо выбрать родителя*')
        }
    }

    const childLoadingFunc = () =>{
        if(page){
            let postData = {query: searchData.child, page: page, count: 30}
            if(formData.city && formData.city.id) postData.cityId = formData.city.id;
            if(formData.school && formData.school.id) postData.schoolId = formData.school.id;
            if(formData.classObj && formData.classObj.id) postData.classObj = formData.classObj.number + formData.classObj.letter;
            if(formData.parrent && formData.parrent.id) postData.clientId = formData.parrent.id;        
            setLoading(true)
            axiosProxyCatchGet({
                url: ((url)? url: '/api/v1/identity') + `/get-student-low` + `?`+ new URLSearchParams(postData),
                resolved:({data:{data:childsData}})=>{
                    if(Array.isArray(childsData)){
                        startTransition(() => {
                            setChildSearchList([...childSearchList, ...childsData])
                            setLoading(false)
                            if(childsData.length < 30) setPageLast(true)
                        })
                    }                 
                },
                rejected: ()=>{
                    setLoading(false)
                }
            })
        }
    };

    return(
        <>
            <div className="autorization__content-form-label">Ребенок</div>
            <SearchDropListBlock 
                searchVal={searchData.child} 
                label={formData.child} 
                searchFunc={inputClick} 
                setSearch={(val)=>{if((formData.parrent && formData.parrent.id) || !formData.parrent || isFilter) setSearchData({...searchData, child: val})}} 
                setLabel={(elem)=>{
                    setFormData({...formData, child: elem})
                    setSearchData({...searchData, child: ''})
                }} 
                listIsActive={activeList==='childsList' && childSearchList.length} keyVal={'child'} 
                searchData={childSearchList}
                classes={classes}
                scrollFunc={
                    (e)=>scrollingPagination(
                        e, 
                        ()=>setPage(page+1), 
                        ()=>childLoadingFunc(),
                        pageLast,
                        loading,
                        scrollReset, 
                        ()=>setScrollReset(true),
                        30
                    )
                }
            />
        </>
    )
};

export const PaymentType = ({formData, setFormData, searchData, setSearchData, activeList, setActiveList, setError=()=>{}, url='', classes='mb-16'}) =>{
    return(
        <>
            <label htmlFor="" className='popup__label mb-4'>Способ оплаты</label>
            <div className={`popup__drop-list-container ${classes}`}>
                <div className={`popup__input input-container popup__drop-list-checked cursor-pointer ${(activeList === 'paymentTypeList')? 'active': ''}`} onClick={()=>{
                    setActiveList(activeList === 'paymentTypeList'? '': 'paymentTypeList')
                    
                }}>
                    <div className='admin-popup__finance-page-payment-type-val d-flex align-center' onClick={()=>{
                        setActiveList(activeList === 'paymentTypeList'? '': 'paymentTypeList')
                    }}>
                        <div className={`admin-popup__finance-page-payment-type-val-elem ${formData.paymentType.color}`}>{formData.paymentType.name}</div>
                    </div>
                </div>
                <div className={`popup__drop-list ${(activeList === 'paymentTypeList')? 'active': ''} ${classes}`}>
                    <div className='popup__drop-list-scroll'>
                        {
                            paymentTypeData.map(item=>
                                <div 
                                    className={`popup__drop-list-elem`} 
                                    onClick={()=>{
                                        setFormData({...formData, paymentType: item})
                                    }} 
                                    key={`admin-popup__finance-page-payment-type-drop-list-elem-${item.id}`}
                                >
                                    <div className={`admin-popup__finance-page-payment-type-drop-list-elem-label ${item.color} ${formData.paymentType.id === item.id? 'active': ''}`}>
                                        {item.name}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export const Month = ({formData, setFormData, activeList, setActiveList, setError=()=>{}, classes='mb-16'}) =>{
    return(
        <>
            <label htmlFor="" className='popup__label mb-4'>Месяц</label>
            <div className={`popup__drop-list-container ${classes}`}>
                <div className={`popup__input input-container popup__drop-list-checked popup__drop-list-open cursor-pointer ${(activeList === 'monthList')? 'active': ''}`} onClick={()=>{
                    setActiveList(activeList === 'monthList'? '': 'monthList')          
                }}>
                    <div className='popup__additionaly-input-val reduction popup__drop-list-open'>{formData.month.map(item=> {return item.label}).join(', ')}</div>
                </div>
                <div className={`popup__drop-list ${(activeList === 'monthList')? 'active': ''}`}>
                    <div className='popup__drop-list-scroll'>
                        {
                            monthData.map(item=>PopupDropListElem(item.label, 
                                ()=>{
                                    if(formData.month.filter(({id}) => id === item.id).length === 0) setFormData({...formData, month: [...formData.month, item]})
                                    else setFormData({...formData, month: formData.month.filter(({id}) => id !== item.id)})
                                },
                                (formData.month.filter(({id}) => id === item.id).length !== 0? 'active': ''),
                                `popup-droplist-month-${item.id}`,
                                'checkboxElem'
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export const ProgramTeachers = ({formData, setFormData, searchData, setSearchData, activeList, setActiveList, schoolId, classId, setError=()=>{}, url='', classes='mb-16', isFilter = false, isNotAuthorize=false}) =>{

    const [teachersSearchList, setTeachersSearchList] = useState([])
    const [isPending, startTransition] = useTransition();
    const [page, setPage] = useState(0);
    const [pageLast, setPageLast] = useState(false);
    const [scrollReset, setScrollReset] = useState(false);
    const [loading, setLoading] = useState(false);

    const searchText = useDebouncedValue(searchData.school, 300)

    useEffect(()=>{
        programTeachersSearchFunc(searchText)
    }, [searchText])


    const programTeachersSearchFunc = (query) =>{
        setError('')
        setPage(0)
        setLoading(true)
        setScrollReset(true)
        setPageLast(false)
        if(query){
            if(activeList === 'teachersList') setActiveList('')
            let postData = {query, schoolId, classId, page: 0, count: 30}
            axiosProxyCatchGet({
                url: ((url)? url: '/api/v1/identity') + `/teachers/get-teachers-list` + (`?`+ new URLSearchParams(postData)),
                resolved:({data:{data:teachersData}})=>{
                    if(Array.isArray(teachersData)){
                        setTeachersSearchList(teachersData)
                        setActiveList('teachersList')
                    }
                    setScrollReset(false)
                    setLoading(false)
                    setPage(1)
                },
                rejected: ()=>{
            
                },
                isNotAuthorize: isNotAuthorize,
            })
        }
        else{
            setTeachersSearchList([])
        }      
    }
    const inputClick = () =>{
        if(((formData.city && formData.city.id) || !formData.city || isFilter)){
            if(!(teachersSearchList.length && activeList === 'teachersList'))programTeachersSearchFunc([])
        }
        else{
            setError('Необходимо выбрать город*')
        }
    }

    const programTeachersLoadingFunc = () =>{
        if(page){
            let postData = {query: searchData.teacher? searchData.teacher: [], page: page, count: 30}
            if(formData.city && formData.city.id) postData.cityId = formData.city.id;           
            if(formData.school && formData.school.id) postData.schoolId = formData.school.id;           
            if(formData.program && formData.program.id) postData.programId = formData.program.id;           
            setLoading(true)
            axiosProxyCatchGet({
                url: (url? url: '/api/v1/identity') + `/teachers/get-teachers-list` + (`?`+ new URLSearchParams(postData)),
                resolved:({data:{data:teachersData}})=>{                    
                    startTransition(() => {
                        if(Array.isArray(teachersData)){
                            setTeachersSearchList([...teachersSearchList, ...teachersData])
                            setLoading(false)
                            if(teachersData.length < 30) setPageLast(true)
                        }
                    })
                },
                rejected: ()=>{
                    setLoading(false)
                },
                isNotAuthorize: isNotAuthorize,
            })
        }
    };


    return(
        <>
            <label htmlFor="" className='popup__label mb-4'>Преподаватели</label>
            <div className={`popup__drop-list-container ${classes}`}>
                <div className={`popup__drop-list-checked cursor-pointer popup__additionaly-input ${(activeList==='teachersList' && teachersSearchList.length)? 'active': ''}`} onClick={inputClick}>
                    <div className='popup__additionaly-input-val'>{setTeachersName(formData.teachers)}</div>
                </div>
                <div className={`popup__drop-list ${(activeList==='teachersList' && teachersSearchList.length)? 'active': ''}`}>
                    <div className='popup__drop-list-scroll' onScroll={(e) => (e)=>scrollingPagination(
                        e, 
                        ()=>setPage(page+1), 
                        ()=>programTeachersLoadingFunc(),
                        pageLast,
                        loading,
                        scrollReset, 
                        ()=>setScrollReset(true),
                        30
                    )}>
                        {
                            teachersSearchList.map(item=>PopupDropListElem(item.name, ()=>{
                                    let itemIsChecked = formData.teachers.filter(teacher => teacher.id === item.id).length;
                                    let result = {...formData, teachers: itemIsChecked? formData.teachers.filter(teacher => teacher.id !== item.id) :[...formData.teachers, item]}
                                    if(result.teachers.length <=2)setFormData(result)
                                    setSearchData({...searchData, teacher: ''})
                                },
                                (formData.teachers.filter(teacher => teacher.id === item.id).length? 'active': ''),
                                `popup-droplist-teachersList-${item.id}`,
                                'checkedElem'
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export const MonthYear = ({formData, setFormData, activeList, setActiveList, setError=()=>{}, classes='mb-16', labelIsDisable=false}) =>{
    return(
        <div className={`d-flex align-center ${classes}`}>
            <div className='wd-50-mr-16'>
                <label htmlFor="" className={`popup__label mb-4 ${labelIsDisable? 'd-none': ''}`}>Месяц</label>
                <div className={`popup__drop-list-container`}>
                    <div className={`popup__input input-container popup__drop-list-checked popup__drop-list-open cursor-pointer ${(activeList === 'monthList')? 'active': ''} ${(labelIsDisable && !formData.month.label)? 'color-grey': ''}`} onClick={()=>{
                        setActiveList(activeList === 'monthList'? '': 'monthList')          
                    }}>
                        <div className='popup__additionaly-input-val reduction popup__drop-list-open'>{(!labelIsDisable || labelIsDisable && formData.month.label)? formData.month.label: 'Месяц'}</div>
                    </div>
                    <div className={`popup__drop-list ${(activeList === 'monthList')? 'active': ''}`}>
                        <div className='popup__drop-list-scroll'>
                            {
                                monthData.map(item=>PopupDropListElem(item.label, 
                                    ()=>{
                                        setFormData({...formData, month: item})
                                    },
                                    (formData.month.id === item.id? 'active': ''),
                                    `popup-droplist-month-${item.id}`
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
    
            <div className='wd-50-mr-16'>
                <label htmlFor="" className={`popup__label mb-4 ${labelIsDisable? 'd-none': ''}`}>Год</label>
                <div className={`popup__drop-list-container`}>
                    <div className={`popup__input input-container popup__drop-list-checked popup__drop-list-open cursor-pointer ${(activeList === 'yearList')? 'active': ''} ${(labelIsDisable && !formData.year)? 'color-grey': ''}`} onClick={()=>{
                        setActiveList(activeList === 'yearList'? '': 'yearList')          
                    }}>
                        <div className='popup__additionaly-input-val reduction popup__drop-list-open'>{(!labelIsDisable || labelIsDisable && formData.year)? formData.year: 'Год'}</div>
                    </div>
                    <div className={`popup__drop-list ${(activeList === 'yearList')? 'active': ''}`}>
                        <div className='popup__drop-list-scroll'>
                            {
                                yearsData().map(item=>PopupDropListElem(item,
                                    ()=>{
                                        setFormData({...formData, year: item})
                                    },
                                    (formData.year === item? 'active': ''),
                                    `popup-droplist-year-${item}`
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export const SubGroup = ({formData, setFormData, activeList, setActiveList, setError=()=>{}, url='', classes='mb-16', isFilter = false, isNotAuthorize=false, labelIsDisable=false}) =>{
    const [subgroupList, setSubgroupList] = useState([])

    const subGroupSearchFunc = () =>{
        setError('')
        if(formData.program.id){
            let postData = {programId: formData.program.id, classId: formData.classObj.id}

            axiosProxyCatchGet({
                url:  ((url? url: '/api/v1/identity') + `/get-subgroups`) + (Object.keys(postData).length ? (`?`+ new URLSearchParams(postData)): ''),
                resolved:({data:{data:subgroupData}})=>{
                    setSubgroupList(subgroupData)
                    setActiveList('subgroupList')
                },
                rejected: ()=>{
            
                },
                isNotAuthorize: isNotAuthorize,
            })
        }
        else{
            setError('Необходимо выбрать программу*')
        }
    }

    return(
        <>
            <label htmlFor="" className={`popup__label mb-4 ${labelIsDisable? 'd-none': ''}`}>Подгруппа</label>      
            <div className='popup__drop-list-container popup__drop-list-open'>
                <DropListBlock 
                    label={!labelIsDisable? (formData.program.isContainsSubgroup? formData.subgroup.name: 'Нет'): ((!formData.program.id || formData.program.isContainsSubgroup)? (formData.subgroup.name? formData.subgroup.name: 'Подгруппа'): 'Подгруппа отсутствует')} 
                    openListFunc={subGroupSearchFunc} 
                    setLabel={(elem)=>{
                        setFormData({...formData, subgroup: elem, program: {...formData.program, teacherName: (elem.teacher && elem.teacher.id)? elem.teacher.name: formData.program.teacherName}})
                    }}
                    listIsActive={activeList==='subgroupList' && subgroupList.length} keyVal={'subgroupList'} 
                    listData={subgroupList}
                    classes={classes + ` ${!labelIsDisable? (formData.program.isContainsSubgroup? '': 'not-click'): ((!formData.program.id || formData.program.isContainsSubgroup)? (formData.subgroup.name? formData.subgroup.name: 'color-grey'): 'color-grey not-click')}`}
                />
            </div>
        </>
    )
}

export const Programs = ({formData, setFormData, activeList, setActiveList, setError=()=>{}, url='', classes='mb-16', isFilter = false, isNotAuthorize=false, labelIsDisable=false, isTabel=false}) =>{
    const [programsList, setProgramsList] = useState([])

    const programsSearchFunc = () =>{
        setError('')
        if((formData.classObj.number && formData.classObj.letter) && ((isTabel && formData.month.id) || !isTabel)){
            let postData = {page: 0, count: 100}
            if(formData.classObj && formData.classObj.id) postData.classId = formData.classObj.id;
            if(isTabel) postData = {...postData, month: formData.month.id, year: formData.year};
            axiosProxyCatchGet({
                url: ((url? url: '/api/v1/identity') + `/get-programs`) + (Object.keys(postData).length ? (`?`+ new URLSearchParams(postData)): ''),
                resolved:({data:{data:programsData}})=>{
                    setProgramsList(programsData)
                    setActiveList('programsList')
                },
                rejected: ()=>{
            
                },
                isNotAuthorize: isNotAuthorize,
            })
        }
        else{
            if(isTabel && formData.classObj.id){
                setError('Необходимо выбрать месяц*')
            }
            else setError('Необходимо выбрать класс*')
        }
    }

    return(
        <>
            <label htmlFor="" className={`popup__label mb-4 ${labelIsDisable? 'd-none': ''}`}>Программа</label>      
            <div className='popup__drop-list-container popup__drop-list-open'>
                <DropListBlock 
                    label={labelIsDisable? (formData.program.id? formData.program: 'Программа'): formData.program}
                    openListFunc={programsSearchFunc} 
                    setLabel={(elem)=>{
                        let result = {...formData, program: {id: elem.id, name: elem.name, isContainsSubgroup:  elem.isContainsSubgroup, teacherName: (elem.teachers && elem.teachers.length)? setTeachersName(elem.teachers): 'Без преподавателя'}}
                        if(result.subgroup) result.subgroup = {id: 0, name: ''}
                        setFormData(result)
                    }}
                    listIsActive={activeList==='programsList' && programsList.length} keyVal={`programsList`} 
                    listData={programsList}
                    classes={`${classes} ${(labelIsDisable && !formData.program.id)? 'color-grey': ''}`}
                />

                {/* <div className={`popup__drop-list-container ${classes} ${(labelIsDisable && !formData.program.id)? 'color-grey': ''}`}>
                    <div className={`popup__drop-list-checked popup__additionaly-input cursor-pointer ${(activeList==='programsList' && programsList.length)? 'active': ''}`} onClick={programsSearchFunc}>
                        <div className='popup__input not-border cursor-pointer'>{labelIsDisable? (formData.program.name? formData.program.name: 'Программа'): formData.program.name}</div>
                    </div>
                    <div className={`popup__drop-list ${(activeList==='programsList' && programsList.length)? 'active': ''}`}>
                        <div className='popup__drop-list-scroll'>
                            {
                                programsList.map(item=>PopupDropListElem(item, ()=>{
                                        let result = {...formData, program: {id: item.id, name: item.name, isContainsSubgroup: item.isContainsSubgroup, teacherName: (item.teachers && item.teachers.length)? item.teachers[0].name: 'Без преподавателя'}}
                                        if(result.program) result.program = {id: 0, name: '', teacherName: '', isContainsSubgroup: false}
                                        if(result.subgroup) result.subgroup = {id: 0, name: ''}
                                        setFormData(result)
                                    },
                                    ((labelIsDisable? (formData.program.name? formData.program.name: 'Программа'): formData.program.name) === item? 'active': ''),
                                    `popup-droplist-programsList-${item}`
                                ))
                            }
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export const PaginationMonth = ({formData, setFormData, activeList, setActiveList, label='Месяц', setError=()=>{}, classes='mb-16', labelIsDisable=false, isLastBlock=false}) =>{
    const [loading, setLoading] = useState(false);
    const [monthList, setMonthList] = useState([])
    let date = new Date()

    useEffect(()=>{
        let month = date.getMonth()
        setMonthList(getAcademicYear((month >= 5)? date.getFullYear(): date.getFullYear() - 1))
    }, [])

    const MonthPaginationFunc = () =>{
        if(!loading){       
            setLoading(true)
            setMonthList([...monthList, ...getAcademicYear(monthList[monthList.length-1].year, setLoading)])
        }
    };

    return(
        <>
            <label htmlFor="" className={`popup__label mb-4 ${labelIsDisable? 'd-none': ''}`}>{label}</label>
            <div className={`popup__drop-list-container ${classes}`}>
                <div className={`popup__input input-container popup__drop-list-checked pagination-month popup__drop-list-open cursor-pointer ${(activeList === 'monthList')? 'active': ''} ${(labelIsDisable && !formData.month.label)? 'color-grey': ''}`} onClick={()=>{
                    setActiveList(activeList === 'monthList'? '': 'monthList')          
                }}>
                    <div className='popup__additionaly-input-val reduction popup__drop-list-open'>{(!labelIsDisable || labelIsDisable && formData.month.label)? `${formData.month.label} ${formData.month.year}`: 'Месяц'}</div>
                </div>
                <div className={`popup__drop-list  ${(activeList === 'monthList')? 'active': ''} ${isLastBlock? 'open-list-top': ''}`}>
                    <div className='popup__drop-list-scroll' onScroll={
                        (e)=>scrollingPagination(
                            e, 
                            ()=>{}, 
                            ()=>MonthPaginationFunc(),
                            false,
                            loading,
                            false, 
                            ()=>{},
                            30
                        )
                    }>
                        {
                            monthList.map(item=>PopupDropListElem(`${item.label} ${item.year}`, 
                                ()=>{
                                    setFormData({...formData, month: item})
                                },
                                ('text-center ' + ((formData.month.id === item.id && formData.month.year === item.year)? 'active': '')),
                                `popup-droplist-month-${item.id}-${item.year}`
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}