import PasswordIcon from '../../../../../img/autorization-password-none-icon.svg'
import PasswordActiveIcon from '../../../../../img/autorization-password-check-icon.svg'
import { useEffect, useState } from 'react';
import { City, School } from '../../../../Filters/FiltersList';
import { useDispatch, useSelector } from 'react-redux';
import { selectAdminTeachers } from '../../../../../redux/admin/teachers/teachers/selectors';
import { axiosProxyCatchPost } from '../../../../../axiosProxy';
import { setAdminTeachersDataAdd, setAdminTeachersDataEdit } from '../../../../../redux/admin/teachers/teachers/teachersSlice';
import MaskedInput from '../../../../MaskedInput/MaskedInput';

const EditTeacher = ({popupBack, closePopup, type, setPopupType}) =>{
    const dispatch = useDispatch()
    const { adminTeacherChecked } = useSelector(selectAdminTeachers);

    const [error, setError] = useState('')

    const [activeList, setActiveList] = useState('')

    const [displayPass, setDisplayPass] = useState(false)
    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        login: '',
        password: '',
        phone: '',
    })

    const [searchData, setSearchData] = useState({
        city: '',
        school: ''
    })

    useEffect(()=>{
        setFormData(adminTeacherChecked)
        if(type === 'EditTeacher') setFormData(adminTeacherChecked)
        else setFormData({
            id: 0,
            name: '',
            city: {id: 0, name: ''},
            school: {id: 0, name: ''},
            login: '',
            password: ''
        })
        setSearchData({city: '', school: ''})
        setActiveList('')
        setDisplayPass(false)
        setError('')
    }, [popupBack, adminTeacherChecked])


    const editTeacherFunc = (e) =>{
        e.preventDefault()
        setError('')
        if(formData.name && formData.city.id && formData.school.id && formData.login && formData.password){
            if(formData.phone === '' || (formData.phone && formData.phone.length === 17 && formData.phone.indexOf('_') === -1)){
                if(teacherIsEditFunc() || type === 'AddTeacher'){
                    if(formData.password.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/) && formData.password.length >= 6 && (/[a-zA-Z]/.test(formData.password))){
                        let postData = {
                            name: formData.name,
                            cityId: formData.city.id,
                            schoolId: formData.school.id,
                            login: formData.login,
                            password: formData.password,                
                        }
                        let resultData = {
                            name: formData.name,
                            city: formData.city,
                            school: formData.school,
                            login: formData.login,
                            password: formData.password,    
                        }
            
                        if(type === 'EditTeacher'){
                            postData.id = formData.id;
                            resultData.id = formData.id;
                        }

                        if(formData.phone){
                            postData.phone = formData.phone;
                            resultData.phone = formData.phone;
                        }
                        
                        setLoading(true)          
                        axiosProxyCatchPost({
                            url: type === 'AddTeacher'? '/api/v1/administrative/teachers/create-teacher': '/api/v1/administrative/teachers/update-teacher',
                            data: postData,
                            resolved: (data)=>{
                                if(type === 'AddTeacher'){
                                    dispatch(setAdminTeachersDataAdd({...resultData, id: data.id}))
                                }
                                else{
                                    dispatch(setAdminTeachersDataEdit(resultData))
                                }
                                closePopup()
                                setLoading(false) 
                            },
                            rejected: (error)=>{
                                if(error && error.data && typeof error.data.detail === 'string') setError(error.data.detail)
                                else setError('Что-то пошло не так*')
                                setLoading(false) 
                            }
                        })
                    }
                    else if(formData.password.length < 6 || !(/[a-zA-Z]/.test(formData.password))) setError('Пароль не должен быть короче 6 символов и должен содержать хотя бы одну букву латинского алфавита*')
                    else if(!formData.password.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/)) setError('Невалидный пароль*')
                }
                else setError('Необходимо произвести какие-то изменения перед сохранением*')
            }
            else setError('Невалидный номер телефона*')
        }
        else setError('Необходимо заполнить все поля*')
    }

    const teacherIsEditFunc = () =>{
        return (
            formData.name === adminTeacherChecked.name 
            && formData.city.id === adminTeacherChecked.city.id 
            && formData.school.id === adminTeacherChecked.school.id 
            && formData.login === adminTeacherChecked.login 
            && formData.password === adminTeacherChecked.password
            && ((adminTeacherChecked.phone && formData.phone === adminTeacherChecked.phone) || (!formData.phone && !adminTeacherChecked.phone))
        )? false: true
    }

    return(
        <form className="popup__container" onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__drop-list-checked-input')){
                setActiveList('')
                setSearchData({city: ''})
            }
        }}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title fs-24'>
                    {(type==='AddTeacher')? 'Добавить преподавателя': 'Преподаватель'}
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>
            <label className="popup__label mb-4">ФИО<span>*</span></label>
            <input type={'text'} value={formData.name} className="popup__input mb-16" onInput={(event)=>{
                if(event.target.value.match(/^[a-zA-ZА-Яа-я- ]+$/) || !event.target.value) setFormData({...formData, name: event.target.value})
            }}/>

            <label htmlFor="" className='popup__label mb-4'>Телефон</label>
            <MaskedInput
                className={`popup__input mb-16`}
                mask="+7(___) ___-__-__"
                replacement={ { _ : /[0-9]/ }}
                defaultValue={formData.phone}
                name = 'phone'
                readOnly={false}
                placeholder=''
                showMask={true}
                separate={true}
                onChange={(event)=>{
                    setFormData({...formData, phone: event.target.value})
                }}
            />

            <City
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                recalculate={true}
                requeredParametr={true}
                url='/api/v1/administrative/programs'
            />

            <School
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                recalculate={true}
                requeredParametr={true}
                url='/api/v1/administrative/programs'
            />

            <label className="popup__label mb-4">Логин<span>*</span></label>
            <input type={'email'} value={formData.login} className={`popup__input mb-16`} onInput={(event)=>{
                if((event.target.value.match(/^[a-zA-Z0-9.@_-]+$/) && event.target.value.indexOf('..') === -1) || !event.target.value) setFormData({...formData, login: event.target.value})
            }}/>

            <label className="popup__label mb-4">Пароль<span>*</span></label>
            <div className="autorization__content-form-field d-flex align-center justify-between mb-16">
                <input autoComplete="new-password" type={(displayPass?'text':'password')} value={formData.password} className="autorization__content-form-field-input" onInput={(event)=>{
                    if(!/[а-яА-я]/.test(event.target.value) || event.target.value === '') setFormData({...formData, password: event.target.value})
                }}/>
                <img src={(displayPass? PasswordActiveIcon: PasswordIcon)} alt="" className="autorization__content-form-field-icon password" onClick={()=>setDisplayPass(!displayPass)}/>
            </div>


            <div className='popup__error'>{error}</div>
            
            <div className='d-flex align-center justify-between'>
            <button className={`popup__button ${loading? 'deactive-button': ''} ${type !== 'AddTeacher'? 'wd-50-mr-16': ''}`} onClick={editTeacherFunc}>{(type==='AddTeacher')? 'Добавить': 'Сохранить'}</button>
            {
                (type !== 'AddTeacher')? <button className={`popup__button cancel delete wd-50-mr-16 ${loading? 'deactive-button': ''}`} onClick={()=>{
                    closePopup()
                    setPopupType('Delete')
                }}>Удалить</button>: null
            }
            </div>
        </form>
    )
}

export default EditTeacher;