import { useState } from 'react';
import { getStatus } from '../../../../../User/Documents/DocumentElem';
import { downloadFilePost, getDateFormatFunc } from '../../../../../../services/clientHome';

const FinanceCertificateElem = ({item, calenDate, documentId, confirmLoading, deleteFunc=()=>{}, popupOpenCalendFunc=()=>{}, confirmFunc=()=>{}, refuseFunc=()=>{}}) =>{
    const {id, appId, startDate, endDate, date, extension, status, layout, comment='', link} = item;
    const [loading, setLoading] = useState(false)

    const statusObj = getStatus(status)
    return(
        <div className={`admin-finance-popup__documents-line d-flex justify-between align-center ${status === null? 'ds-none': ''} ${(status === 0)? 'confirm-line': ''}`}>
            <div className='admin-finance-popup__documents-line-item name d-flex align-center'>
                <div className='admin-finance-popup__documents-line-item-document-icon'/>
                {
                    (status === 0)?(
                        <div className='admin-popup__sertificate-confirm-line-item-validate d-flex'>
                            <div className={`admin-popup__sertificate-confirm-line-item-validate-item mr-6 ${calenDate.calendActive && calenDate.dateActive === 'start'? 'active': ''}`} onClick={(e)=>popupOpenCalendFunc(e, id, 'start', startDate, endDate)}>
                                {(calenDate.id === id && calenDate.startDate)? calenDate.startDate: startDate}
                            </div>

                            <div>-</div>

                            <div className={`admin-popup__sertificate-confirm-line-item-validate-item ml-6 ${calenDate.calendActive && calenDate.dateActive === 'end'? 'active': ''}`} onClick={(e)=>popupOpenCalendFunc(e, id, 'end', startDate, endDate)}>
                                {(calenDate.id === id && calenDate.endDate)? calenDate.endDate: endDate}
                            </div>
                        </div>
                    ): 
                    <div className='admin-finance-popup__documents-line-item-name'>{startDate} - {endDate}</div>
                }
            </div>
            <div className='admin-finance-popup__documents-line-item date'>{date}</div>
            <div className={`admin-finance-popup__documents-line-item status ${statusObj.color}`}>
                <div className='admin-finance-popup__documents-line-item-status-label'>{statusObj.label}</div>
                {
                    (status === -1 && comment)? <div className='admin-finance-popup__documents-line-item-status-title' data-tooltip={comment}/>: null
                }                    
            </div>
            <div className='admin-finance-popup__documents-line-item buttons d-flex justify-end align-center'>
                
                {
                    (status === 0)?(
                        <>
                            <div className={`admin-finance-popup__documents-line-item-button confirm ${(loading || confirmLoading)? 'deactive-button': ''}`} onClick={()=>confirmFunc(id, appId, startDate, endDate, status)}/>
                            <div className={`admin-finance-popup__documents-line-item-button refuse admin-finance-popup__documents-line-item-button-refuse ${(loading || confirmLoading)? 'deactive-button': ''}`} onClick={(e)=>refuseFunc(e, id, appId, status)}/>
                            <div className={`admin-finance-popup__documents-line-item-button-line`}/>
                        </>
                    ): null
                }

            <div className={`admin-finance-popup__documents-line-item-button download ${(loading || confirmLoading)? 'deactive-button': ''}`} onClick={() => downloadFilePost({url:'/api/v1/administrative/finance/get-file?', name: `${startDate} - ${endDate}`, type: (extension && extension.indexOf('.') === -1)? '.' + extension: (extension)? extension: '', postData: {documentId: id? id: layout}, setLoading: setLoading})}/>
            {/* <div className={`admin-popup__documnet-confirm-line-item-button download ${downloadLoading? 'deactive-button': ''}`} onClick={() => {
                if(documentData.document.id){
                    downloadFilePost({url:'/api/v1/administrative/finance/get-file?', name: `${startDate} - ${endDate}`, type: (extesion && extesion.indexOf('.') === -1)? '.'+extesion: (extesion)?extesion: '', postData: {documentId: id}, setLoading: setDownloadLoading})
                }
            }}/> */}
            </div>
        </div>
    )
}

export default FinanceCertificateElem;