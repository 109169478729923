export const editChildConfirmAplication = {
  id: 1,
  applicationId: 2,
  name: 'Name Name Name',
  city: {id: 1, name: 'Москва'},
  school: {id: 1, name: 'Моская гимназия №11'},
  classObj: {id: 1, number: 7, letter: 'A'},
  birthDay: '12.12.2010',

  update: {
    name: 'Name Name Name1',
    city: {id: 2, name: 'Москва2'},
    school: {id: 1, name: 'Моская гимназия №11'},
    classObj: {id: 9, number: 6, letter: 'A'},
    birthDay: '12.12.2011',
  }
}

export const editClientdConfirmAplication = {
  applicationId: 7,
  id: 1,
  name: 'Сергеев Максим Александрович',
  email: 'pochta@mail.ru',
  password: 'pass1234',
  phone: '+7(928) 765-47-27',

  update:{
    id: 1,
    name: 'Сергеев Максим Александрович',
    email: 'pochta@mail.ru',
    password: 'pass12341',
    phone: '+7(928) 666-47-27'
  }
}

export const reContractChildConfirmAplication = {
  id: 1,
  applicationId: 2,
  name: 'Name Name Name',
  city: {id: 1, name: 'Москва'},
  school: {id: 1, name: 'Моская гимназия №11'},
  classObj: {id: 1, number: 7, letter: 'A'},
  birthDay: '12.12.2010',
  programs: [{id: 12, name: 'Математика', color: 'blue'}],
  startDate: "2024-11-02T00:00:00Z",
  startMonth: 11,

  parent: {
    id: 1,
    name: 'Иван Иванов Иванович',
  }
}