import './index.scss'
import { convertNumber } from './../../../../services/clientHome';
import { useState, useEffect } from 'react';
import { selectUserChildren } from './../../../../redux/users/children/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { axiosProxyCatchGet } from './../../../../axiosProxy';
import { useNavigate } from 'react-router-dom';
import { setUserNavBarActive } from '../../../../redux/users/navigations/userNavigationsSlice';
import { setSelectionDocument } from '../../../../redux/users/documents/documentsSlice';
import { setUserPopupBack } from '../../../../redux/users/popup/userPopupSlice';


const PaymentTypeElem = ({item, clickFunc, checkedPaymentId, price = null}) =>{
    const {id, label} = item;
    return(
        <div className={`user-popup__payment-block mb-12 d-flex align-center justify-between ${(checkedPaymentId === id)? 'active': ''}`} key={`user-popup__payment-block-${id}`} onClick={()=>clickFunc(id)}>
            <div className="user-popup__payment-block-label">{label}</div>
            <div className="user-popup__payment-block-val">{(price)? (convertNumber(price) + '' + '₽'): ''}</div>
        </div>
    )
}

const paymentTypeData = [
    {
        id: 1,
        label: 'Оплатить за месяц',
    },
    {
        id: 2,
        label: 'Оплатить за год',
    },
    {
        id: 3,
        label: 'Буду оплачивать мат. капиталом',
    },
    {
        id: 4,
        label: 'Произвольная сумма',
    },
]


const Payment = ({popupBack, closePopup}) =>{
    const dispatch = useDispatch()
    const [checkedPaymentId, setCheckedPaymentId] = useState(1)

    const navigate = useNavigate()

    const { checkChild } = useSelector(selectUserChildren)    

    const [data, setData] = useState({
        monthPrice: checkChild.summ,
        yearPrice: checkChild.yearPrice
    })
    
    const [arbitraryPrice, setArbitraryPrice] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)


    useEffect(()=>{
        setArbitraryPrice('')
        setCheckedPaymentId(1)
    }, [popupBack])

    useEffect(()=>{
        setData({
            monthPrice: checkChild.summ,
            yearPrice: checkChild.yearPrice
        })
    }, [checkChild])

    const paymentFunc = () =>{
        if(checkedPaymentId !== 3){
            if(checkedPaymentId === 4){
                if(arbitraryPrice){
                    paymentPostFunc({studentId: checkChild.id, summ: arbitraryPrice})
                }
                else{
                    setError('Необходимо ввести сумму оплаты*')
                }
            }
            else paymentPostFunc({studentId: checkChild.id, summ: (checkedPaymentId === 1)? data.monthPrice: data.yearPrice})
            
        }
        else{
            closePopup()
            setTimeout(()=>{
                navigate('/user-documents')
                dispatch(setUserNavBarActive(1))
                dispatch(setSelectionDocument('4c7b3f72-bfe6-4d7b-ac31-f2dc4d5a197b'))
            }, 500)
        }
    }

    const paymentPostFunc = (postData) =>{    
        setLoading(true)  
        axiosProxyCatchGet({
            url:'/api/v1/parental/finance/generate-payment-url?' + new URLSearchParams(postData),
            resolved:(response) =>{
                setLoading(false)
                window.location.href = response.data.url;
                closePopup()
            },
            rejected: (error) =>{
                if(error && error.data && error.data.detail) setError(error.data.detail)
                else setError('Что-то пошло не так*')
                setLoading(false)
            }
        })
    }

    return(
        <div className={`popup__container user-popup__container payment`}>
            <div className='popup__title mb-24'>Оплата</div>
            <PaymentTypeElem item={paymentTypeData[0]} checkedPaymentId={checkedPaymentId} clickFunc={setCheckedPaymentId} price={data.monthPrice}/>
            <PaymentTypeElem item={paymentTypeData[1]} checkedPaymentId={checkedPaymentId} clickFunc={setCheckedPaymentId} price={data.yearPrice}/>
            <PaymentTypeElem item={paymentTypeData[2]} checkedPaymentId={checkedPaymentId} clickFunc={setCheckedPaymentId}/>
            <PaymentTypeElem item={paymentTypeData[3]} checkedPaymentId={checkedPaymentId} clickFunc={setCheckedPaymentId}/>
            <div className={`user-popup__payment-arbitrary-summ ${(checkedPaymentId === 4)? 'active': ''}`}>
                <label htmlFor="" className='popup__label mb-4'>Введите сумму</label>
                <div className='user-popup__payment-input-block'>
                    <input className='user-popup__payment-input-block-input' type="number" value={arbitraryPrice} onChange={(e)=>setArbitraryPrice(e.target.value)}/>
                    <div className='user-popup__payment-input-block-val'>{convertNumber(arbitraryPrice)}{arbitraryPrice? '₽': ''}</div>
                </div>
            </div>
            <div className='popup__error'>{error}</div>
            <button className={`popup__button ${loading? 'deactive-button': ''}`} onClick={paymentFunc}>Оплатить</button>
        </div>
    )
}

export default Payment;