import { createSlice } from '@reduxjs/toolkit';
import { additionalyDocuments } from '../../../../defaultData/admin/finance/documents';
import { fetchAdminFinanceCertificateData, fetchAdminFinanceClientsData, fetchAdminFinanceDocumentsData, fetchAdminFinanceFullDocumentsData } from './asyncActions';

const initialState = {
  adminFinanceFullDocumentsData: [
  ],


  adminFinanceDocumentsData: [
    // ...additionalyDocuments
  ],

  adminFinanceAdditionalyDocumentsData: [
    // ...additionalyDocuments
  ],

  adminFinanceCertificateData: [
    // {
    //     id: 2,
    //     startDate: '13.04.2024',
    //     endDate: '20.04.2024',
    //     date: '12.12.2023',
    //     status: -1,
    //     link: '/public/shablonLoad/Шаблон Договор_с_родителями_КФ21.docx',
    // },
    // {
    //     id: 3,
    //     startDate: '13.04.2024',
    //     endDate: '20.04.2024',
    //     date: '12.12.2023',
    //     status: 0,
    //     link: '/public/shablonLoad/Шаблон Договор_с_родителями_КФ21.docx',
    // },
    // {
    //     id: 4,
    //     startDate: '13.04.2024',
    //     endDate: '20.04.2024',
    //     date: '12.12.2023',
    //     status: 1,
    //     link: '/public/shablonLoad/Шаблон Договор_с_родителями_КФ21.docx',
    //     comment:'Комментарий к отказу справки на период - 13.04.24 - 20.04.24'
    // }
  ],


  adminFinanceCertificatePageLast: false,
  adminFinanceCertificateScrollReset: false,
  adminFinanceCertificateLoading: false,

};

const adminFinanceDocuments = createSlice({
  name: 'AdminFinanceDocuments',
  initialState,
  reducers: {
    setAdminFinanceFullDocumentsData(state, action) {
      state.adminFinanceFullDocumentsData = action.payload;      
    },



    setAdminFinanceDocumentsData(state, action) {
      state.adminFinanceDocumentsData = action.payload;      
    },
    setAdminFinanceDocumentsDataEdit(state, action) {
      state.adminFinanceDocumentsData = state.adminFinanceDocumentsData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
      state.adminFinanceFullDocumentsData = state.adminFinanceFullDocumentsData.filter(item=> item.id !== action.payload.id);
    },



    setAdminFinanceAdditionalyDocumentsData(state, action) {
      state.adminFinanceAdditionalyDocumentsData = action.payload;
    },
    setAdminFinanceAdditionalyDocumentsDataEdit(state, action) {
      state.adminFinanceAdditionalyDocumentsData = state.adminFinanceAdditionalyDocumentsData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
      state.adminFinanceFullDocumentsData = state.adminFinanceFullDocumentsData.filter(item=> item.id !== action.payload.id);
    },



    setAdminFinanceCertificateData(state, action) {
      state.adminFinanceCertificateData = action.payload;      
    },
    setAdminFinanceCertificateDataEdit(state, action) {
      state.adminFinanceCertificateData = state.adminFinanceCertificateData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
      state.adminFinanceFullDocumentsData = state.adminFinanceFullDocumentsData.filter(item=> item.id !== action.payload.id);
    },

    setAdminFinanceCertificateScrollReset(state, action) {
      state.adminFinanceCertificateScrollReset = action.payload;      
    },

  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminFinanceFullDocumentsData.pending, (state, action) => {
      state.adminFinanceFullDocumentsData = []
    });
    builder.addCase(fetchAdminFinanceFullDocumentsData.fulfilled, (state, action) => {
      if(action.payload && action.payload.documents && Array.isArray(action.payload.documents)) state.adminFinanceFullDocumentsData = action.payload.documents;
    });
    builder.addCase(fetchAdminFinanceFullDocumentsData.rejected, (state, action) => {
    });


    builder.addCase(fetchAdminFinanceDocumentsData.pending, (state, action) => {
      state.adminFinanceDocumentsData = []
      state.adminFinanceAdditionalyDocumentsData = []
    });
    builder.addCase(fetchAdminFinanceDocumentsData.fulfilled, (state, action) => {
      if(action.payload && action.payload.documents && Array.isArray(action.payload.documents)){
        if(action.payload.type === 1){
          state.adminFinanceDocumentsData = action.payload.documents;
        }
        else if(action.payload.type === 2){
          state.adminFinanceAdditionalyDocumentsData = action.payload.documents;
        }
      } 
    });
    builder.addCase(fetchAdminFinanceDocumentsData.rejected, (state, action) => {
    });

    builder.addCase(fetchAdminFinanceCertificateData.pending, (state, action) => {
      state.adminFinanceCertificateLoading = true;
      state.adminFinanceCertificateData = []
    });
    builder.addCase(fetchAdminFinanceCertificateData.fulfilled, (state, action) => {
      if(action.payload && action.payload.certificate && Array.isArray(action.payload.certificate)){
        if(action.payload.isScrolling){
          state.adminFinanceCertificateData = [...state.adminFinanceCertificateData, ...action.payload.certificate];
        }
        else
          state.adminFinanceCertificateData = action.payload.certificate;
  
        if(action.payload.page === 0) state.adminFinanceCertificateScrollReset = true;
  
        if(action.payload.certificate.length < 30) state.adminFinanceCertificatePageLast = true;
        else state.adminFinanceCertificatePageLast = false;

        state.adminFinanceCertificateLoading = false;
      }
    });
    builder.addCase(fetchAdminFinanceCertificateData.rejected, (state, action) => {
      state.adminFinanceCertificateLoading = false;
    });
  },

});

const { actions, reducer } = adminFinanceDocuments;


export default reducer;

export const { 
  setAdminFinanceFullDocumentsData,

  setAdminFinanceDocumentsData,
  setAdminFinanceDocumentsDataEdit,

  setAdminFinanceAdditionalyDocumentsData,
  setAdminFinanceAdditionalyDocumentsDataEdit,

  setAdminFinanceCertificateData,
  setAdminFinanceCertificateDataEdit,
  setAdminFinanceCertificateScrollReset,

} = actions;

