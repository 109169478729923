import './index.scss'

const SuccessfullyConfrim = ({closePopup, email}) =>{
    return(
        <div className='d-flex flex-column align-center'>
            <div className='autorization__content-form-successfully-icon'/> 
            <div className='popup__subtitle mb-24'>Ваш новый Email ({email}) будет успешно привязан к данному аккаунту в течение 5 минут.</div>
            <div className='d-flex wd-100'>
                <button className={`popup__button cancel wd-100`} onClick={closePopup}>Закрыть</button>
            </div>
        </div>
    )
}

export default SuccessfullyConfrim;