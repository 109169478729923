import React, { useState } from 'react';

import './index.scss'
import { useDispatch } from 'react-redux';
import { axiosProxyCatchPost } from './../../axiosProxy';
import PasswordIcon from '../../img/autorization-password-none-icon.svg'
import PasswordActiveIcon from '../../img/autorization-password-check-icon.svg'

import LoginIcon from '../../img/tick-circle-green-icon.svg'
import { useNavigate } from 'react-router-dom';




const RestorePassword = ({setAuthPage, restorePassData, setRestorePassDataReset}) =>{
    const dispatch = useDispatch()

    const navigation = useNavigate()

    const [inform, setInform] = useState({
        login: '',
        sending: '',
        restorePassSending: false,
        password: '',
        acceptPass: ''
    })

    const [error, setError] = useState('')
    const [displayPass, setDisplayPass] = useState(false)

    const postData = async () => {
        setError('')
        
        if(inform.login){
            axiosProxyCatchPost({
                url: '/api/v1/identity/gen-res-code',
                data: {email: inform.login},
                resolved: (data)=>{
                    setInform({...inform, sending: true})
                },
                rejected: (error)=>{
                    if(error && error.data && error.data.detail) setError(error.data.detail + '*')
                    else setError('Что-то пошло не так*')
                },
                isNotAuthorize: true
            })
        }
        else setError('Необходимо заполнить email*')
    }

    const restorePassFunc = () => {
        setError('')
        if(((displayPass && inform.password) || (!displayPass && inform.password && inform.password === inform.acceptPass)) && inform.password.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/) && inform.password.length >= 6 && (/[a-zA-Z]/.test(inform.password))){
            axiosProxyCatchPost({
                url: '/api/v1/identity/reset-password',
                data: {email: restorePassData.email, password: inform.password, code: restorePassData.code.replaceAll()},
                resolved: (data)=>{
                    setRestorePassDataReset()
                    setInform({...inform, restorePassSending: true})
                },
                rejected: (error)=>{
                    if(error && error.data && error.data.detail) setError(error.data.detail + '*')
                    else setError('Что-то пошло не так*')
                },
                isNotAuthorize: true
            })
        }
        else if(((displayPass && inform.password) || (!displayPass && inform.password && inform.password === inform.acceptPass)) &&(inform.password.length < 6 || !(/[a-zA-Z]/.test(inform.password)))) setError('Пароль не должен быть короче 6 символов и должен содержать хотя бы одну букву латинского алфавита*')
        else if(((displayPass && inform.password) || (!displayPass && inform.password && inform.password === inform.acceptPass)) && !inform.password.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/)) setError('Невалидный пароль*')
        else if(inform.password !== inform.acceptPass) setError('Пароли не совпадают*')
        else setError('Необходимо заполнить пароль*')
    }

	return (
        <form className="autorization__content-form information-block" onSubmit={(event)=>postData(event)}>
            <div className="autorization__content-form-title">Восстановить пароль</div>
            {
                (restorePassData.email)?(
                    <>
                        <div className={`autorization__content-form-field d-flex align-center justify-between`}>
                            <input autoComplete="new-password" type={(displayPass?'text':'password')} placeholder='Введите новый пароль' value={inform.password} className="autorization__content-form-field-input" onInput={(event)=>{
                                if(!/[а-яА-я]/.test(event.target.value) || event.target.value === '') setInform({...inform, password: event.target.value})
                            }}/>
                            <img src={(displayPass? PasswordActiveIcon: PasswordIcon)} alt="" className="autorization__content-form-field-icon password" onClick={()=>{
                                setDisplayPass(!displayPass)
                                setInform({...inform, acceptPass: ''})
                            }}/>
                        </div>
                        <div className={`autorization__content-form-field d-flex align-center justify-between mt-16 mb-16 ${displayPass? 'ds-none-animation': ''}`}>
                            <input autoComplete="new-password" type={(displayPass?'text':'password')} placeholder='Подтвердите новый пароль' value={inform.acceptPass} className="autorization__content-form-field-input" onInput={(event)=>{
                                if(!/[а-яА-я]/.test(event.target.value) || event.target.value === '') setInform({...inform, acceptPass: event.target.value})
                            }}/>
                        </div>
                        <div className='popup__error'>{error}</div>
                        <button type="button" className="autorization__content-form-btn" onClick={restorePassFunc}>Восстановить пароль</button>
                    </>
                )
                :(inform.restorePassSending)?(
                    <>
                        <div className='autorization__content-form-successfully-icon'/>
                        <div className='autorization__content-form-subtitle'>Вы успешно востановили пароль, можете произвести вход.</div>
                    </>
                )
                :(inform.sending)?(
                    <div className='autorization__content-form-subtitle'>Мы отправили ссылку на <span>{inform.login}</span> для сброса пароля. Проверьте почту.</div>
                )
                :(
                    <>
                        <div className="autorization__content-form-field d-flex align-center justify-between">
                            <input autoComplete="new-password" type="mail" placeholder="Введите логин / mail" className="autorization__content-form-field-input" onInput={(event)=>{
                                if((event.target.value.match(/^[a-zA-Z0-9.@_-]+$/) && event.target.value.indexOf('..') === -1) || !event.target.value) setInform({...inform, login: event.target.value})
                            }}/>
                            <img src={LoginIcon} alt="" className="autorization__content-form-field-icon login"/>
                        </div>
                        <div className='popup__error'>{error}</div>
                        <button type="button" className="autorization__content-form-btn" onClick={postData}>Отправить ссылку</button>
                    </>
                )
            }
            <div className='autorization__content-form-registration-btn' onClick={()=>{
                navigation('/')
                setAuthPage('')
                setRestorePassDataReset()
            }}>На главную</div>
        </form>
	)
}

export default RestorePassword;