import './index.scss'
import Header from '../../Header';
import { useRef, useState, useEffect } from 'react';
import SchoolListElem from './SchoolListElem/SchoolListElem';
import AdminPopup from './../../Popup/AdminPopup';
import { useDispatch, useSelector } from 'react-redux';
import { setPopupBack } from '../../../../redux/popup/popupSlice';
import { NavLink } from 'react-router-dom';
import DeleteBlock from './../../Popup/DeleteBlock/DeleteBlock';
import WarningBlock from './../../Popup/WarningBlock/WarningBlock';
import { selectAdminProgramsSchools } from './../../../../redux/admin/programs/schools/selectors';
import { fetchAdminProgramsSchoolsData } from './../../../../redux/admin/programs/schools/asyncActions';
import { axiosProxyCatchGet } from '../../../../axiosProxy';
import { setAdminProgramsSchoolFilters, setAdminProgramsSchoolsDataDelete, setAdminProgramsSchoolsScrollReset } from '../../../../redux/admin/programs/schools/schoolsSlice';
import { scrollingPagination, useDebouncedValue } from '../../../../services/clientHome';
import { axiosProxyCatchPost } from './../../../../axiosProxy';




const School = () =>{
    const dispatch = useDispatch();

    const { adminProgramsSchoolsData:schoolsData, adminProgramsCheckedSchool, adminProgramsSchoolsPageLast:pageLast, adminProgramsSchoolsScrollReset:scrollReset, adminProgramsSchoolsLoading:loading, adminProgramsSchoolFilters: filters } = useSelector(selectAdminProgramsSchools);

    const [query, setQuery] = useState('')
    const [page, setPage] = useState(0)
    const [popupType, setPopupType] = useState('')

    const searchText = useDebouncedValue(query, 600)

    useEffect(()=>{
        dispatch(fetchAdminProgramsSchoolsData({count:55, page:0, isScrolling: false, query: query, filters}))
        setPage(0)
    }, [filters, searchText])

    useEffect(()=>{
        dispatch(setAdminProgramsSchoolFilters({city: {id: 0, name: ''}}))
    }, [])

    const ClassesLinkRef = useRef()


    const deletedSchool = (setLoading) =>{
        axiosProxyCatchPost({
            url: '/api/v1/administrative/programs/delete-school',
            data: {id: adminProgramsCheckedSchool.id},
            resolved: (data)=>{
                dispatch(setAdminProgramsSchoolsDataDelete(adminProgramsCheckedSchool.id))
                setPopupType('')
                setLoading(false)
            },
            rejected: (error)=>{
                // console.log(error);
                setLoading(false)
                setPopupType('Warning')
            }
        })
    }


    return(
        <div className={`admin__programs-school`}>
            <NavLink to={'/schools/classes'} ref={ClassesLinkRef}/>
            <Header query={query} setQuery={setQuery}/>

            <div className='admin-wrapper'>
                <div className="admin__programs-top">
                    <div className="admin__programs-top-title">
                        Школы
                    </div>
                    <div className='admin__programs-top-buttons d-flex'>
                        <button className='admin__programs-top-button d-flex align-center' onClick={()=>{
                            dispatch(setPopupBack('open'))
                            setPopupType('SchoolFilters')
                        }}>
                            <div className='admin__programs-top-button-label'>Фильтры</div>
                            <div className='admin__programs-top-button-icon filters'/>
                        </button>
                        <button className='admin__programs-top-button d-flex align-center' onClick={()=>{
                            dispatch(setPopupBack('open'))
                            setPopupType('AddCity')
                        }}>
                            <div className='admin__programs-top-button-label'>Добавить город</div>
                            <div className='admin__programs-top-button-icon plus'/>
                        </button>
                        <button className='admin__programs-top-button d-flex align-center' onClick={()=>{
                            dispatch(setPopupBack('open'))
                            setPopupType('AddSchool')
                        }}>
                            <div className='admin__programs-top-button-label'>Добавить школу</div>
                            <div className='admin__programs-top-button-icon plus'/>
                        </button>
                    </div>
                </div>
            </div>
            <div className='admin-wrapper scroll-container'>
                <div className='admin__programs-school-list'   
                onScroll={(e)=>scrollingPagination(
                    e, 
                    ()=>setPage(page+1), 
                    ()=>dispatch(fetchAdminProgramsSchoolsData({query, count: 55, page: page+1, isScrolling: true, filters})),
                    pageLast,
                    loading,
                    scrollReset, 
                    ()=>dispatch(setAdminProgramsSchoolsScrollReset(false))
                )}>
                    {
                        schoolsData.map(item=><SchoolListElem item={item} setPopupType={setPopupType} classesLinkRef={ClassesLinkRef} key={`admin__programs-school-list-elem-container-${item.id}`}/>)
                    }
                </div>
            </div>
            <AdminPopup type={popupType} props={{typeEdit: setPopupType}}/>
            <DeleteBlock popupBack={(popupType === 'Delete')? 'open': ''} props={{title: 'Удаление школы', subtitle:<>Вы действительно хотите удалить <span>{adminProgramsCheckedSchool.name}</span>?</>, closePopup: setPopupType, clickFunk:deletedSchool}}/>
            {/* <WarningBlock popupBack={(popupType === 'Warning')? 'open': ''} props={{title:'ВНИМАНИЕ', subtitle:'Эту школу нельзя удалить, к ней привязаны классы.', closePopup: setPopupType}}/> */}
            <WarningBlock popupBack={(popupType === 'Warning')? 'open': ''} props={{title:'Ошибка', subtitle:'Не удалось удалить школу, попробуйте еще раз.', closePopup: setPopupType}}/>
        </div>
    )
}

export default School;