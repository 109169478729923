import React, { useState } from 'react';

import './index.scss'
import { useDispatch } from 'react-redux';
import { setUserData } from '../../redux/authorization/authorization';
import axiosProxy, { axiosProxyCatchPost } from './../../axiosProxy';

import LoginIcon from '../../img/autorization-login.svg'
import PasswordIcon from '../../img/autorization-password-none-icon.svg'
import PasswordActiveIcon from '../../img/autorization-password-check-icon.svg'
import { useLocation, useNavigate } from 'react-router-dom';



const SignIn = ({setAuthPage}) =>{
    const dispatch = useDispatch()
    const [inform, setInform] = useState({
        login: '',
        password: '',
        save: true
    })

    const [error, setError] = useState('')
    const [displayPass, setDisplayPass] = useState(false)
    const navigate = useNavigate()

    const autorize = async (event) => {
        event.preventDefault()
        navigate('/')

        axiosProxyCatchPost({
            url: '/api/v1/identity/LoginUser', 
            data: { email: inform.login, password: inform.password, rememberMe: inform.save }, 
            resolved: (data)=>{
                dispatch(setUserData({id: data.id, name: data.name, token: data.token, role:  data.role, save: inform.save}))
            },
            rejected: (error)=>{                
                if(error && error.data && error.data.detail) setError(error.data.detail)
                else setError('Что-то пошло не так*')
            },
            isNotAuthorize: true,
        })   
    }

	return (
        <form className="autorization__content-form" onSubmit={(event)=>autorize(event)}>
            <div className="autorization__content-form-title">Авторизация</div>
            <div className="autorization__content-form-field d-flex align-center justify-between mb-12">
                <input  type="text" placeholder="Введите логин" className="autorization__content-form-field-input" onInput={(event)=>{
                    setInform({...inform, login: event.target.value})
                }}/>
                <img src={LoginIcon} alt="" className="autorization__content-form-field-icon login"/>
            </div>
            <div className="autorization__content-form-field d-flex align-center justify-between">
                <input type={(displayPass?'text':'password')} placeholder="Введите пароль" className="autorization__content-form-field-input" onInput={(event)=>{
                    setInform({...inform, password: event.target.value})
                }}/>
                <img src={(displayPass? PasswordActiveIcon: PasswordIcon)} alt="" className="autorization__content-form-field-icon password" onClick={()=>setDisplayPass(!displayPass)}/>
                <span></span>
            </div>
            
            <div className='d-flex justify-between align-center mt-17'>
                <input type="checkbox" name="save_autorize" defaultChecked value="Сохранить данные" id="save_autorize" className="autorization__content-form-checkbox" onClick={() => {
                    setInform({...inform, save: !inform.save})
                }}/>
                <label className={`autorization__content-form-checkbox-label`} htmlFor="save_autorize">Сохранить данные</label>
                <div className='autorization__content-form-forgot-pass' onClick={()=>setAuthPage('restorePassword')}>Забыли пароль?</div>
            </div>
            <div className='popup__error'>{error}</div>
            <button type="submit" className="autorization__content-form-btn mb-16">Войти</button>
            <div className='autorization__content-form-registration-btn' onClick={()=>setAuthPage('registration')}>Зарегистрироваться</div>
        </form>
	)
}

export default SignIn;