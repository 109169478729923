import './index.scss'

const ClassElem = (item) =>{
    return(
        <div className={`teacher__about-teaching-inform-classes-item`} key={`teacher__about-teaching-inform-classes-item-${item.id}`}>
            {item.number}{item.letter}
        </div>
    )
}

const ProgramElem = (program) =>{
    const {id, name, color} = program;
    return(
        <div className={`teacher__about-teaching-inform-programs-item ${color}`} key={`teacher__about-teaching-inform-programs-item-${id}`}>
            {name}
        </div>
    )
}

const TeachingInfrom = ({teachingData}) =>{
    let {city, school, classes, programs} = teachingData;
    return (
        <div className={`teacher__about-container mb-32`}>
            <div className='teacher__about-container-title mb-24'>Данные о преподавании</div>
            
            <label className="teacher__about-container-label mb-8">Город</label>
            <div className='popup__input mb-24'>{city.name}</div>

            <label className="teacher__about-container-label mb-8">Школа</label>
            <div className='popup__input mb-24'>{school.name}</div>

            <label className="teacher__about-container-label mb-8">Классы</label>
            <div className='teacher__about-teaching-inform-classes-container d-flex align-center flex-wrap mb-24'>
                {
                    classes.map(item => ClassElem(item))
                }
                {
                    classes.length === 0 && <div className='teacher__about-teaching-inform-classes-empty-elem'>За вами пока не закреплены классы</div>
                }
            </div>

            <label className="teacher__about-container-label">Программы</label>
            <div className='teacher__about-teaching-inform-programs-container d-flex align-center flex-wrap'>
                {
                    programs.map((program) => ProgramElem(program))
                }
                {
                    programs.length === 0 && <div className='teacher__about-teaching-inform-classes-empty-elem'>За вами пока не закреплены программы</div>
                }
            </div>

        </div>
    )
}

export default TeachingInfrom;