import './index.scss'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfile } from './../../../../redux/users/profile/selectors';
import ProfileDataBlock from './ProfileDataBlock';
import { setUserProfile } from '../../../../redux/users/profile/profileSlice';
import { axiosProxyCatchPost } from './../../../../axiosProxy';

const ProfileCurrentChages = ({popupBack, closePopup}) =>{
    const dispatch = useDispatch()
    const { profileData } = useSelector(selectUserProfile);
    const [displayPass, setDisplayPass] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const viewedChangesFunc = () =>{        
        setError('')
        axiosProxyCatchPost({
            url: '/api/v1/parental/change-update-client-visible',
            data: {id: profileData.update.id},
            resolved: () =>{
                if(profileData.update.status === 1){
                    dispatch(setUserProfile({...profileData.update, id: profileData.id, status: profileData.status, financeAccess: profileData.financeAccess, isUpdate: false}))
                }
                else{
                    dispatch(setUserProfile({id: profileData.id, isUpdate: false}))
                }
                setLoading(false)
                closePopup()
            },
            rejected: (error) =>{
                if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                else setError('Что-то пошло не так*')
                setLoading(false)
            }
        })
    }

    return(
        <div className='popup__container user-popup__current-changes-data-container'>
            <div className={`d-flex justify-between`}>
                <ProfileDataBlock data={profileData} label={'Данные до редактирования'} displayPass={displayPass} setDisplayPass={setDisplayPass}/>
                <div className='user-popup__current-changes-data-line'/>
                <ProfileDataBlock data={profileData.update} currentData={profileData} label={'Данные после редактирования'} displayPass={displayPass} setDisplayPass={setDisplayPass} isUpdateData={true}/>
            </div>

            <div className={`popup__error ${(profileData.update && profileData.update.status !== 0)? '': 'd-none'}`}>{error}</div>
            <div className={`d-flex ${(profileData.update && profileData.update.status !== 0)? '': 'd-none'}`}>
                <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={closePopup}>Закрыть</button>
                <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={viewedChangesFunc}>Просмотрено</button>
            </div>
        </div>
    )
}

export default ProfileCurrentChages;