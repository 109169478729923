export let childs = [
    {
        "id": "01000000-ac12-0242-560b-08dcd2f11bbe",
        "name": "Абакарова Зульфия Абакаровна",
        "city": {
            "id": "01000000-ac12-0242-ffd2-08dcd1ad717b",
            "name": "Махачкала"
        },
        "school": {
            "id": "01000000-ac12-0242-5149-08dcd1ad8b43",
            "name": "МБОУ \"Лицей №5\" г. Махачкала"
        },
        "classObj": {
            "id": "01000000-ac12-0242-22b9-08dcd1adce16",
            "number": 1,
            "letter": "А"
        },
        "recalculate": false,
        "isFinish": false,
        "finishDate": null,
        "status": -1,
        "update": null,
        "birthDay": "20.02.2019"
    },
    {
        "id": "01000000-ac12-0242-560b-08dcd2f11bb3",
        "name": "Абакаров Газимагомед Абакарович",
        "birthDay": "27.07.2017",
        "city": {
            "name": "Махачкала",
            "id": "01000000-ac12-0242-ffd2-08dcd1ad717b"
        },
        "school": {
            "name": "МБОУ \"Лицей №5\" г. Махачкала",
            "id": "01000000-ac12-0242-5149-08dcd1ad8b43"
        },
        "classObj": {
            "number": 1,
            "letter": "А",
            "id": "01000000-ac12-0242-22b9-08dcd1adce16"
        },
        isUpdate: true,
        isFinish: true,
        isAwaitNewAgreementStatus: 0,
        recalculate: true,
        application:{
            "id": "01000000-ac12-0242-560b-08dcd",
            status: 1,
            comment: 'NTrcn sdfjsdjf sdf',
            type: 1
        },
        agreementsDates:[
            {
                id: "01000000-ac12-0242-560b-08dcd2f11bb1",
                from: '2024-09-01T12:00:00+00:00',
                to: '2024-10-30T12:00:00+00:00',
                isFinish: true,
            },
            {
                id: "01000000-ac12-0242-560b-08dcd2f11bb2",
                from: '2024-11-01T12:00:00+00:00',
                to: '2025-02-01T12:00:00+00:00',
                isFinish: true,
            },
            {
                id: "01000000-ac12-0242-560b-08dcd2f11bb3",
                from: '2025-03-15T12:00:00+00:00',
                to: '2025-05-31T12:00:00+00:00',
                isFinish: false,
            },
        ],
        "update": {
            "id": "01000000-ac12-0242-560b-08dcd2f11bbe",
            "name": "Абакаров Газимагамед Абакарович1",
            "birthDay": "27.09.2017",
            status: -1,
            "city": {
                "name": "Махачкала",
                "id": "01000000-ac12-0242-ffd2-08dcd1ad717b"
            },
            "school": {
                "name": "МБОУ \"Лицей №9\" г. Махачкала",
                "id": "01000000-ac12-0242-5149-08dcd1ad8b49"
            },
            "classObj": {
                "number": 1,
                "letter": "А",
                "id": "01000000-ac12-0242-22b9-08dcd1adce16"
            },
        }
    }
]

export let financeChilds = [
    {
        "id": "02000000-ac12-0242-560b-65dcd2f11bbe1",
        "name": "Абакарова Зульфия Абакаровна",
        "birthDay": "13.02.2015",
        "city": {
            "name": "Махачкала",
            "id": "01000000-ac12-0242-ffd2-08dcd1ad717b"
        },
        "school": {
            "name": "МБОУ \"Лицей №5\" г. Махачкала",
            "id": "01000000-ac12-0242-5149-08dcd1ad8b43"
        },
        "classObj": {
            "number": 4,
            "letter": "Г",
            "id": "01000000-ac12-0242-11b9-01dcd1adce13"
        },"programs": [
            {
                "color": "green",
                "name": "Занимательная математика, ступень 1",
                "id": "01000000-ac12-0242-b170-08dcd1ae0242",
                "price": 10494,
                "septPrice": 1166
            },
            {
                "color": "light_blue",
                "name": "Проектная деятельность (введение в науку), ступень 1",
                "id": "01000000-ac12-0242-d3b5-08dcd1ae0264",
                "price": 10503,
                "septPrice": 1167
            },
            {
                "color": "yellow",
                "name": "Логика, ступень 1",
                "id": "01000000-ac12-0242-f36a-08dcd1ae0288",
                "price": 10503,
                "septPrice": 1167
            }
        ],
        "summ": 4820,
        monthPrice: 4820,
        "status": 1,
        "yearPrice": 15745,
        "isFinish": false
    },
    {
        "id": "01000000-ac12-0242-560b-08dcd2f11bb3",
        "name": "Абакаров Газимагомед Абакарович",
        "datePay": "05.12.2024",
        "programs": [
            {
                "color": "green",
                "name": "Занимательная математика, ступень 1",
                "id": "01000000-ac12-0242-b170-08dcd1ae0242",
                "price": 10494,
                "septPrice": 1166
            },
            {
                "color": "light_blue",
                "name": "Проектная деятельность (введение в науку), ступень 1",
                "id": "01000000-ac12-0242-d3b5-08dcd1ae0264",
                "price": 10503,
                "septPrice": 1167
            },
            {
                "color": "yellow",
                "name": "Логика, ступень 1",
                "id": "01000000-ac12-0242-f36a-08dcd1ae0288",
                "price": 10503,
                "septPrice": 1167
            }
        ],
        "summ": 4820,
        monthPrice: 4820,
        "status": 1,
        "yearPrice": 15745,
        "isFinish": false
    }
]