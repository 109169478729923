import { useState } from 'react'
import './index.scss'
import { axiosProxyCatchPost } from '../../../../axiosProxy'

import PasswordIcon from '../../../../img/autorization-password-none-icon.svg'
import PasswordActiveIcon from '../../../../img/autorization-password-check-icon.svg'

const EditPassword = ({email}) =>{
    

    const [errorText, setErrorText] = useState('')
    const [loading, setLoading] = useState(false)
    const [displayOldPass, setDisplayOldPass] = useState(false)
    const [displayNewPass, setDisplayNewPass] = useState(false)
    const [editPasswordIsSuccessfully, setEditPasswordIsSuccessfully] = useState(false)
    const [passIsDontMatch, setPassIsDontMatch] = useState(false)
    const [formData, setFormData] = useState({
        oldPassword: '', 
        newPassword: '', 
        newPasswordConfirm: ''
    })

    const editProfileFunc = () =>{
        setErrorText('')
        setPassIsDontMatch(false)
        const {oldPassword, newPassword, newPasswordConfirm} = formData;
        let editData = {
            userName: email,
            currentPassword : oldPassword,
            password: newPassword
        };        

        if(oldPassword && newPassword && newPasswordConfirm){            
            if(newPassword === newPasswordConfirm){                
                if(oldPassword.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/) && oldPassword.length >= 6 && (/[a-zA-Z]/.test(oldPassword) && newPassword.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/) && newPassword.length >= 6 && (/[a-zA-Z]/.test(newPassword)))){                    
                    setLoading(true)
                    axiosProxyCatchPost({
                        url: 'api/v1/identity/ChangeUserPassword',
                        data: editData,
                        resolved: () =>{
                            setEditPasswordIsSuccessfully(true)
                            resetFormData()
                            setLoading(false)
                        },
                        rejected: (error) =>{
                            if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setErrorText(error.data.detail)
                            else setErrorText('Что-то пошло не так*')
                            setLoading(false)
                        }
                    })
                }
                else if((oldPassword.length < 6 || !(/[a-zA-Z]/.test(oldPassword))) || (newPassword.length < 6 || !(/[a-zA-Z]/.test(newPassword)))) setErrorText('Пароль не должен быть короче 6 символов и должен содержать хотя бы одну букву латинского алфавита*')
                else if(!oldPassword.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/) || !newPassword.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/)) setErrorText('Невалидный пароль*')
            }
            else{
                setErrorText('Пароли не совпадают*')
                setPassIsDontMatch(true)
            }
        }
        else setErrorText('Необходимо заполнить все поля*')
    }

    const resetFormData = () =>{
        setFormData({
            oldPassword: '', 
            newPassword: '', 
            newPasswordConfirm: ''
        })
        setErrorText('')
        setDisplayNewPass(false)
        setDisplayOldPass(false)
        setPassIsDontMatch(false)
    }

    return (
        <div className={`teacher__about-container mb-32`}>
            <div className='teacher__about-container-title mb-24'>{(editPasswordIsSuccessfully)? 'Пароль успешно изменен': 'Сменить пароль'}</div>

            {
                (editPasswordIsSuccessfully)?
                <>
                    <div className='teacher__about-container-successfully-icon'/> 
                    <div className='teacher__about-container-subtitle mb-24'>Вы успешно изменили пароль к аккаунту.</div>
                    <button className={`teacher__about-button wd-100`} onClick={()=>setEditPasswordIsSuccessfully(false)}>
                        Назад
                    </button>
                </>
                :
                <>
                    <label className="teacher__about-container-label mb-8">Старый пароль</label>
                    <div className="autorization__content-form-field d-flex align-center justify-between mb-24 h-54">
                        <input autoComplete="new-password" name='old-password' placeholder='Введите свой пароль' type={(displayOldPass?'text':'password')} value={formData.oldPassword} className="autorization__content-form-field-input" onInput={(event)=>{
                            if(!/[а-яА-я]/.test(event.target.value) || event.target.value === '') setFormData({...formData, oldPassword: event.target.value.replaceAll(' ', '')})
                        }}/>
                        <img src={(displayOldPass? PasswordActiveIcon: PasswordIcon)} alt="" className="autorization__content-form-field-icon password" onClick={()=>setDisplayOldPass(!displayOldPass)}/>
                    </div>

                    <label className="teacher__about-container-label mb-8">Новый пароль</label>
                    <div className={`autorization__content-form-field d-flex align-center justify-between mb-24 h-54 ${passIsDontMatch? 'input-field-empty' : ''}`}>
                        <input autoComplete="new-password" name='new-password' placeholder='Введите новый пароль' type={(displayNewPass?'text':'password')} value={formData.newPassword} className={`autorization__content-form-field-input`} onInput={(event)=>{
                            if(!/[а-яА-я]/.test(event.target.value) || event.target.value === '') setFormData({...formData, newPassword: event.target.value.replaceAll(' ', '')})
                        }}/>
                        <img src={(displayNewPass? PasswordActiveIcon: PasswordIcon)} alt="" className="autorization__content-form-field-icon password" onClick={()=>setDisplayNewPass(!displayNewPass)}/>
                    </div>

                    <label className="teacher__about-container-label mb-8">Подтвердите новый пароль</label>
                    <div className={`autorization__content-form-field d-flex align-center justify-between mb-24 h-54 ${passIsDontMatch? 'input-field-empty' : ''}`}>
                        <input autoComplete="new-password" name='new-password-confirm' placeholder='Повторите новый пароль' type={(displayNewPass?'text':'password')} value={formData.newPasswordConfirm} className={`autorization__content-form-field-input`} onInput={(event)=>{
                            if(!/[а-яА-я]/.test(event.target.value) || event.target.value === '') setFormData({...formData, newPasswordConfirm: event.target.value.replaceAll(' ', '')})
                        }}/>
                        <img src={(displayNewPass? PasswordActiveIcon: PasswordIcon)} alt="" className="autorization__content-form-field-icon password" onClick={()=>setDisplayNewPass(!displayNewPass)}/>
                    </div>

                    <div className={`teacher__about-container-error height-auto ${errorText? 'active': ''}`}>{errorText}</div>

                    <div className={`d-flex`}>
                        <button className={`teacher__about-button wd-50-mr-16 ${(loading || !(formData.oldPassword || formData.newPassword || formData.newPasswordConfirm))? 'deactive-button': ''}`} onClick={resetFormData}>
                            Сбросить
                        </button>
                        <button className={`teacher__about-button purple wd-50-mr-16 ${(loading || !(formData.oldPassword && formData.newPassword && formData.newPasswordConfirm))? 'deactive-button': ''}`} onClick={editProfileFunc}>
                            Подтвердить
                        </button>
                    </div>
                </>
            }
            
        </div>
    )
}

export default EditPassword;