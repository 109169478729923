import './index.scss'

const SuccessfullyConfrim = ({email, resetFullData}) =>{
    return(
        <>
            <div className='teacher__about-container-successfully-icon'/> 
            <div className='teacher__about-container-subtitle mb-24'>Ваш новый Email ({email}) будет успешно привязан к данному аккаунту в течение 5 минут.</div>
            <button className={`teacher__about-button wd-100`} onClick={resetFullData}>
                Завершить
            </button>           
        </>
    )
}

export default SuccessfullyConfrim;