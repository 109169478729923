import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setDropListName } from '../../redux/dropDownList/dropDownListSlice';
import { selectDropDownListData } from '../../redux/dropDownList/selectors';

import './index.scss'
import Header from './Header/Header';
import Tabel from './Tabel/index';
import About from './About';


const Teacher = () =>{
    const dispatch = useDispatch();
    const { listName } = useSelector(selectDropDownListData)


    return (
        <div className={`teacher`} onClick={(e)=>{
            if(listName !== '' && !e.target.classList.contains('popupBlock-click')){
                dispatch(setDropListName(''))
            }
        }}>
            <Header/>
            <Routes>
                <Route path='/' element={<Tabel/>} exact/>
                <Route path='/about' element={<About/>} exact/>
                <Route path='/*' element={<div>Страница не найдена</div>} exact/>
            </Routes>
        </div>
    )
}

export default Teacher;