

const ChildDataBlock = ({data, label, currentData={}, isUpdateData=false}) =>{
    return(
        <div className={`user-popup__current-changes-data wd-50-m16`}>
            <div className='user-popup__current-changes-data-subtitle'>{label}</div>

            <label htmlFor="" className='popup__label mb-4'>ФИО</label>
            <div className={`popup__input mb-16 ${(isUpdateData && currentData.name !== data.name)? 'border-active': ''}`}>{data.name}</div>

            <label htmlFor="" className='popup__label mb-4'>Дата рождения</label>
            <div className={`popup__input mb-16 ${(isUpdateData && currentData.birthDay !== data.birthDay)? 'border-active': ''}`}>{data.birthDay}</div>

            <label htmlFor="" className='popup__label mb-4'>Город</label>
            <div className={`popup__input mb-16 ${(isUpdateData && currentData.city.id !== data.city.id)? 'border-active': ''}`}>{data.city.name}</div>

            <label htmlFor="" className='popup__label mb-4'>Школа</label>
            <div className={`popup__input mb-16 ${(isUpdateData && currentData.school.id !== data.school.id)? 'border-active': ''}`}>{data.school.name}</div>

            <label htmlFor="" className='popup__label mb-4'>Класс</label>
            <div className={`popup__input mb-16 ${(isUpdateData && currentData.classObj.id !== data.classObj.id)? 'border-active': ''}`}>{data.classObj.number}{data.classObj.letter}</div>
        </div>
    )
}

export default ChildDataBlock;


/// id, name, classObj, birthDay, city, school