// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-popup__application-create-child-scroll {
  max-height: calc(90vh - 220px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px; }
  .admin-popup__application-create-child-scroll::-webkit-scrollbar {
    width: 10px; }
  .admin-popup__application-create-child-scroll::-webkit-scrollbar-track {
    background-color: #f3f3f3;
    border-radius: 5px; }
  .admin-popup__application-create-child-scroll::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid #f3f3f3; }
`, "",{"version":3,"sources":["webpack://./src/components/Admin/Popup/Applications/CreateChildConfirm/index.scss"],"names":[],"mappings":"AACI;EACI,8BAA8B;EAC9B,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB,EAAA;EAJtB;IAMO,WAAW,EAAA;EANlB;IASO,yBAAyB;IACzB,kBAAkB,EAAA;EAVzB;IAaO,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB,EAAA","sourcesContent":[".admin-popup{\n    &__application-create-child-scroll{\n        max-height: calc(90vh - 220px);\n        overflow-y: scroll;\n        overflow-x: hidden;\n        padding-right: 10px;\n        &::-webkit-scrollbar{\n            width: 10px;\n        }\n        &::-webkit-scrollbar-track {\n            background-color: #f3f3f3;\n            border-radius: 5px;    \n        }\n        &::-webkit-scrollbar-thumb {\n            background-color: #fff;\n            border-radius: 10px;\n            border: 2px solid #f3f3f3;\n        }\n    }    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
