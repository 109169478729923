export let paymentsHistory = [
    {
        id: 1, 
        name: 'Газимагомед3we werwrwer', 
        date: '12.03.2025', 
        summ: 3000
    },
    {
        id: 2, 
        name: 'Газимагомед2', 
        date: '12.03.2025', 
        summ: 3000
    },
    {
        id: 3, 
        name: 'Газимагомед3', 
        date: '12.03.2025', 
        summ: 6700
    },
    {
        id: 4, 
        name: 'Газимагомед4', 
        date: '12.03.2025', 
        summ: 3000
    },
    {
        id: 5, 
        name: 'Газимагомед5', 
        date: '12.03.2025', 
        summ: 3000
    },
    {
        id: 6, 
        name: 'Газимагомед6', 
        date: '12.03.2025', 
        summ: 30000
    },
]