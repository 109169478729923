import ApplicationFilters from './ApplicationFilters';
import UserVerification from './UserVerification';
import EditUserConfirm from './EditUserConfirm/index';
import ProgramConnection from './ProgramConnection/index';
import DocumentConfirm from './DocumentConfirm/index';
import CertificateConfirm from './CertificateConfirm/index';
import Tabel from './Tabel/Tabel';
import TeachersApplicationFilters from './TeachersApplicationFilters/index';
import EditChildConfirm from './EditChildConfirm/index';
import ReContractChildConfirm from './ReContractChildConfirm';
import CreateChildConfirm from './CreateChildConfirm';

const ApplicationPopup = ({unDisplay, popupBack, type, props}) =>{
    return(
        (type === "ApplicationFilters")? <ApplicationFilters closePopup={unDisplay} popupBack={popupBack} type={type} setPopupType={props.typeEdit}/>
        :(type === "TeacherApplicationFilters")? <TeachersApplicationFilters closePopup={unDisplay} popupBack={popupBack}/>
        :(type === "UserVerification")? <UserVerification closePopup={unDisplay} popupBack={popupBack}/>
        :(type === "EditUserConfirm")? <EditUserConfirm closePopup={unDisplay} popupBack={popupBack}/>
        :(type === "ProgramConnection" || type === "ProgramDisconnection")? <ProgramConnection type={type} closePopup={unDisplay} popupBack={popupBack}/>
        :(type === "DocumentConfirm")? <DocumentConfirm closePopup={unDisplay} popupBack={popupBack}/>
        :(type === "CertificateConfirm")? <CertificateConfirm closePopup={unDisplay} popupBack={popupBack}/>
        :(type === "TabelConfirm")? <Tabel closePopup={unDisplay} popupBack={popupBack}/>
        :(type === "EditChildConfirm")? <EditChildConfirm closePopup={unDisplay} popupBack={popupBack}/>
        :(type === "ReContractChildConfirm")? <ReContractChildConfirm closePopup={unDisplay} popupBack={popupBack}/>
        :(type === "AddChildConfirm")? <CreateChildConfirm closePopup={unDisplay} popupBack={popupBack}/>
        : null
    )
}

export default ApplicationPopup;