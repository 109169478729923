import Timer from '../../../Filters/Timer';
import './index.scss'

const ConfirmEmail = ({formData, setFormData, errorText, loading, setLoading, resetFormData, requestConfirmationCodeFunc, date, setDate, setConfirmationCodeIsSent}) =>{    
    return(
        <> 
            <label className="teacher__about-container-label mb-8">Email</label>
            <input name='' type="mail" placeholder='Введите новую почту' value={formData.email} className="popup__input mb-24" onChange={(e) =>{
                if((e.target.value.match(/^[a-zA-Z0-9.@_-]+$/) && e.target.value.indexOf('..') === -1) || !e.target.value) setFormData({...formData, email: e.target.value})}
            }/>
            {
                (date)?
                    <div className='teacher__about-container-subtitle mb-24 mt-24 text-center'>
                        Повторная отправка кода будет доступна через <span className='popup__confirmation-code-timer'><Timer date={date} setDate={setDate}/></span>
                    </div>
                :null
            }
            
            <div className={`teacher__about-container-error height-auto ${errorText? 'active': ''}`}>{errorText}</div>

            <div className={`d-flex wd-100`}>
                <button className={`teacher__about-button wd-50-mr-16 ${(loading || formData.email === '')? 'deactive-button': ''}`} onClick={resetFormData}>
                    Сбросить
                </button>
                <button className={`teacher__about-button purple wd-50-mr-16 ${(loading)? 'deactive-button': ''}`} onClick={date? ()=>setConfirmationCodeIsSent(true) :()=>requestConfirmationCodeFunc()}>
                    {date? 'Ввести код': 'Отправить код'}
                </button>
            </div>
        </>
    )
}

export default ConfirmEmail;