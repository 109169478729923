import DocumentIcon from '../../../../img/document-grey-icon.svg';

const DocumentDefaultElem = ({sendBtn=false, keyVal}, key) =>{
    return (
        <div className="user-page__documents-doc-line-default d-flex align-center" key={`${keyVal}-${key}`}>
            <div className='user-page__documents-doc-line-default-left d-flex align-center'>
                <img className="user-page__documents-doc-line-default-image mr-16" src={DocumentIcon} alt=""/>
                <div className='user-page__documents-doc-line-default-information'>
                    <div className='user-page__documents-doc-line-default-information-name'/>
                    <div className='user-page__documents-doc-line-default-information-size-and-type'/>
                </div>
            </div>
            <div className={`user-page__documents-doc-line-default-status `}></div>
            <div className='user-page__documents-doc-line-default-buttons-container d-flex align-center'>
                <div className={`user-page__documents-doc-line-default-button ${!sendBtn? 'aegrotat-button': ''} d-flex justify-center align-center`}>
                    <div className='user-page__documents-doc-line-default-button-label mr-8'>Скачать</div>
                    <div className={`user-page__documents-doc-line-default-button-icon download`}/>
                </div>
                {
                    (sendBtn)? (
                        <div className={`user-page__documents-doc-line-default-button d-flex  justify-center align-center`}>
                            <div className='user-page__documents-doc-line-default-button-label mr-8'>Отправить</div>
                            <div className={`user-page__documents-doc-line-default-button-icon send`}/>
                        </div>
                    ): null
                }
            </div>
        </div>
    )
}

export default DocumentDefaultElem;