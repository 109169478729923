import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy from '../../../axiosProxy';


export const fetchTeacherAboutData = createAsyncThunk(
  'TeacherProfile/fetchTeacherAboutData',
  async () => {    
    try {
      const response = await axiosProxy.get('/api/v1/teaching/profile/get-teacher-data')
      if(response.status < 400){
        return {teacherData: response.data.profileData, teacherTeachingData: response.data.teachingData}                       
      }
      else{
        // console.log(response.response.statusText)
      }      
    } catch (error) {
        console.log(error);
    }
  },
);