import { useState } from 'react';
import { ChildClass, City, School, TerminationMonth } from '../../../../../Filters/FiltersList';
import MaskedInput from './../../../../../MaskedInput/MaskedInput';
import { PaginationMonth } from './../../../../../Filters/FiltersList';



const programElem = (item) =>{
    const {id, name, color} = item;
    return(
        <div className={`admin-popup__application-user-verification-programs-item ${color}`} key={`admin-popup__application-user-verification-programs-item-${id}`}>{name}</div>
    )
}

const IsReadOnlyActive = false;

const ChildBlock = ({formData, setFormData, childBlockIsActive, setChildBlockIsActive, activeList, setActiveList, childEdited, setError, title='О ребенке', classes='', discount, setDiscount}) =>{    
    const [searchData, setSearchData] = useState({
        city: '',
        school: ''
    })
    

    const [childBlockError, setChildBlockError] = useState('')

    return(
        <div className={`admin-popup__application-user-verification-child ${childBlockIsActive? 'active': ''} ${activeList? 'visible': ''} ${classes}`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__button') && !e.target.classList.contains('popup__input') && e.target.closest('.popup__drop-list-checked') === null){
                setActiveList('')
                setSearchData({city: '', school: ''})
            }
        }}>
            <div className={`${classes? '': 'popup__container pr-14'}`}>
                <div className='d-flex justify-between align-center mb-24'>
                    <div className='wd-24'/>
                    <div className='popup__title'>{title}</div>
                    <div className='popup__close' onClick={()=>{
                        if(childEdited) setError('Необходимо сохранить или отменить изменения*')
                        else setChildBlockIsActive(false)
                    }}/>
                </div>
                
                <div className='admin-popup__application-user-verification-child-scroll'>
                    <label className="popup__label mb-4">ФИО</label>
                    <input type={'text'} readOnly={IsReadOnlyActive} value={formData.name} className="popup__input mb-16" onInput={(e)=>{
                        setFormData({...formData, name: e.target.value})
                    }}/>

                    <label className="popup__label mb-4">Дата рождения</label>
                    <MaskedInput
                        className={`popup__input mb-16`}
                        mask="__.__.____"
                        replacement={{_ : /[0-9]/}}
                        showMask={true}
                        separate={true}
                        defaultValue={formData.birthDay}
                        name = 'endDate'
                        // readOnly={false}
                        readOnly={IsReadOnlyActive}
                        placeholder=''
                        onChange={(e)=>{
                            setFormData({...formData, birthDay: e.target.value})
                        }}
                    />

                    <City
                        activeList={activeList} 
                        setActiveList={setActiveList} 
                        formData={formData} 
                        setFormData={setFormData} 
                        searchData={searchData} 
                        setSearchData={setSearchData}
                        setError={setChildBlockError}
                        classes={`mb-16 ${IsReadOnlyActive? 'deactive-input': ''}`}
                        url='/api/v1/administrative/programs'
                    />

                    <School
                        activeList={activeList} 
                        setActiveList={setActiveList} 
                        formData={formData} 
                        setFormData={setFormData} 
                        searchData={searchData} 
                        setSearchData={setSearchData}
                        setError={setChildBlockError}
                        classes={`mb-16 ${IsReadOnlyActive? 'deactive-input': ''}`}
                        url='/api/v1/administrative/programs'
                    />

                    <ChildClass 
                        activeList={activeList} 
                        setActiveList={setActiveList} 
                        formData={formData} 
                        setFormData={setFormData}
                        setSearchData={setSearchData}
                        setError={setChildBlockError}
                        classes={`mb-16`}
                        url='/api/v1/administrative/programs'
                        // classes={''}
                    />

                    <PaginationMonth 
                        activeList={activeList} 
                        setActiveList={setActiveList} 
                        formData={formData} 
                        setFormData={setFormData}
                        setError={setChildBlockError}
                        classes={`mb-16`}
                        isLastBlock={true}
                        label={'Месяц начала занятий'}
                    />
                    {/* <TerminationMonth 
                        activeList={activeList} 
                        setActiveList={setActiveList} 
                        formData={formData} 
                        setFormData={setFormData}
                        setError={setChildBlockError}
                        classes={`mb-16`}
                        isLastBlock={true}
                        label={'Месяц начала занятий'}
                    /> */}

                    <label className="popup__label mb-4">Скидка</label>
                    <div className={`admin-popup__application-discount-input-additionaly-container mb-16`}>
                        <input type="text" value={formData.discount === ''? formData.discount: +formData.discount} className={`admin-popup__application-discount-input-additionaly`} onChange={(e)=>{
                            if(e.target.value < 100 && e.target.value.match(/^[0-9]+$/) || e.target.value === '') setFormData({...formData, discount: e.target.value})
                        }}/>
                        <div className='admin-popup__application-discount-input-additionaly-val'>{formData.discount === ''? formData.discount: +formData.discount}{formData.discount? '%': ''}</div>
                    </div>

                    <label className='popup__label mb-12'>Программы</label>
                    <div className='admin-popup__application-user-verification-programs d-flex align-center flex-wrap'>
                        {
                            formData.programs.map(programElem)
                        }
                    </div>
                </div>

                <div className={`popup__error height-auto ${childBlockError? 'active': ''}`}>{childBlockError}</div> 
            </div>
        </div>
    )
}

export default ChildBlock;