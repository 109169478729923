import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PopupDropListElem from './../../../../PopupDropListElem';
import { ChildClass, City, Month, School } from '../../../../Filters/FiltersList';
import { setAdminApplicationFilters } from '../../../../../redux/admin/aplications/aplications/aplicationsSlice';
import { selectAdminAplications } from '../../../../../redux/admin/aplications/aplications/selectors';

const applicationTypeData = [
    {
        id: 0,
        name: 'Верификация'
    },
    {
        id: 1,
        name: 'Профиль'
    },
    {
        id: 2,
        name: 'Документы'
    },
    {
        id: 3,
        name: 'Программа'
    },
    {
        id: 4,
        name: 'Справка'
    },
    {
        id: 5,
        name: 'Ребенок'
    },
    {
        id: 6,
        name: 'Вторичный договор'
    },
    {
        id: 7,
        name: 'Добавление ребенка'
    },
]


const ApplicationFilters = ({popupBack, closePopup}) =>{
    const dispatch = useDispatch()

    const { adminAplicationFilter:filters } = useSelector(selectAdminAplications);

    const [activeList, setActiveList] = useState('')

    const [error, setError] = useState('')

    const [formData, setFormData] = useState({
        applicationType: {id: '', name: ''},
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        month: [],
        classNumber: '',
        classLetter: '',
        classObj: {id: 0, number: '', letter: ''}
    })

    const [searchData, setSearchData] = useState({
        city: '',
        school: '',
    })

    useEffect(()=>{
        setFormData({
            ...filters,
            classNumber: filters.classObj.number,
            classLetter: filters.classObj.letter,
        })
        setSearchData({
            city: '',
            school: '',
        })
        setActiveList('')
    }, [popupBack])

    const saveFuilters = () =>{
        setError('')
        let filterActiveData = {
            applicationType: formData.applicationType,
            city: formData.city,
            school: formData.school,
            month: formData.month,
            classObj: formData.classObj,
            // stringMonth: formData.month.map(item=>item.id).join('&month=')
        }
        let filtersPostData = {}
        if(formData.applicationType.name) filtersPostData.type = formData.applicationType.id;
        if(formData.city.id) filtersPostData.cityId = formData.city.id;
        if(formData.school.id) filtersPostData.schoolId = formData.school.id;
        if(formData.classObj.number) filtersPostData.classObj = formData.classObj.number;
        if(formData.classObj.letter) filtersPostData.classObj += formData.classObj.letter;

        if(formData.applicationType.name || formData.city.id || formData.school.id || formData.month.length > 0 || formData.classObj.number){
            dispatch(setAdminApplicationFilters({...filterActiveData, post: filtersPostData}))
            closePopup()     
        }
        else{            
            setError('Необходимы что-то выбрать*')
        }
    }

    const cleanFuilters = () =>{        
        dispatch(setAdminApplicationFilters({
            applicationType: {id: '', name: ''},
            city: {id: 0, name: ''},
            school: {id: 0, name: ''},
            month: [],
            classObj: {id: 0, number: '', letter: ''},
            post: {}
        }))
        setFormData({
            applicationType: {id: '', name: ''},
            city: {id: 0, name: ''},
            school: {id: 0, name: ''},
            month: [],
            classNumber: '',
            classLetter: '',
            classObj: {id: 0, number: '', letter: ''}
        })
        setSearchData({
            city: '',
            school: '',
        })
        closePopup()
    }

    return(
        <div className={`popup__container admin-popup__container program-edit`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__drop-list-checked-input') && !e.target.classList.contains('popup__drop-list-elem-checkbox') && !e.target.classList.contains('popup__drop-list-open')  && !e.target.classList.contains('popup__input')){
                setActiveList('')
            }
        }}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title'>
                    Фильтры
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>

            <label htmlFor="" className='popup__label mb-4'>Тип заявки</label>
            <div className='popup__drop-list-container mb-16'>
                <div className={`popup__input input-container popup__drop-list-checked cursor-pointer ${(activeList === 'applicationTypeList')? 'active': ''}`} onClick={()=>{
                    setActiveList(activeList === 'applicationTypeList'? '': 'applicationTypeList')                       
                }}>
                    <div className='popup__additionaly-input-val popup__drop-list-open'>{formData.applicationType.name}</div>
                </div>
                <div className={`popup__drop-list ${(activeList === 'applicationTypeList')? 'active': ''}`}>
                    <div className='popup__drop-list-scroll'>
                        {
                            applicationTypeData.map(item=>PopupDropListElem(item.name, ()=>{
                                    setFormData({...formData, applicationType: item})
                                },
                                (formData.applicationType.id === item.id? 'active': ''),
                                `popup-droplist-application-type-${item.id}`,
                            ))
                        }
                    </div>
                </div>
            </div>

            <City
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                isFilter={true}
                url='/api/v1/administrative/programs'
            />

            <School
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                isFilter={true}
                url='/api/v1/administrative/programs'
            />

            <Month
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData}
            />

            <ChildClass 
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                setError={setError}
                classes={''}
                isFilter={true}
                url='/api/v1/administrative/programs'
            /> 

            <div className='popup__error'>{error}</div>     


            <div className='d-flex'>
                <button className='popup__button wd-50-mr-16 cancel' onClick={cleanFuilters}>Очистить</button>
                <button className={`popup__button wd-50-mr-16 save ${!(formData.applicationType.name || formData.city.id || formData.school.id || formData.month.length > 0 || formData.classObj.number)? 'deactive-button': ''}`} onClick={saveFuilters}>Применить</button>
            </div>       
        </div>
    )
}

export default ApplicationFilters;