import { useState } from 'react';
import ProfileImage from '../../../../img/user-profile-icon.svg'
import { setUserProfile } from '../../../../redux/users/profile/profileSlice';
import { useDispatch } from 'react-redux';
import { axiosProxyCatchPost } from './../../../../axiosProxy';
import { getUserProfileStatus } from '../../../../services/clientHome';

const Profile = ({user, clickFunc, editEmailOrPasswordFunc}) =>{
    const dispatch = useDispatch()

    const {id, name, status, email, phone, comment, isUpdate} = user.isUpdate? {...user, ...user.update}: user;
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const statusObj = getUserProfileStatus(status, isUpdate)

    const reverification = () =>{
        setLoading(true)
        setError('')
        axiosProxyCatchPost({
            url: '/api/v1/parental/profile/send-reapplication',
            data: {},
            resolved: () =>{
                dispatch(setUserProfile({id, status: 0}))
                setLoading(false)
                setError('')
            },
            rejected: (error) =>{                    
                if(error.data && error.data.detail)setError(error.data.detail)
                else setError('Что-то пошло не так, повторите попытку*')
                setLoading(false)
            }
        })
    }

    return (
        <div className="user-page__about-container profile" key={`user-page__about-container-profile-${id}`}>
            <div className='d-flex justify-between about-profile-underline'>
                <div className='d-flex align-center'>
                    <img src={ProfileImage} alt="" className='user-page__about-profile-photo mr-16'/>
                    <div>
                        <div className='user-page__about-profile-name mb-8'>{name}</div>
                        {
                            (status === -1 && comment)?
                                <div className={`user-page__about-profile-confirm refuse d-flex align-center`}>
                                    <div>{statusObj.label}.</div> 
                                    <div className={`user-page__about-profile-confirm-refuse-title ${comment? '': 'd-none'}`} data-tooltip={comment}>Почему?</div>
                                </div>
                            :<div className={`user-page__about-profile-confirm ${(status === 1 && !isUpdate)? 'confirm': ''}`}>{statusObj.label}</div>
                        }
                    </div>
                </div>
                <div className={`user-page__about-profile-buttons computer d-flex align-center`}>
                    <button className={`user-page__about-profile-btn edit ${(status === 0 && !isUpdate)? 'deactive-button': ''}`} onClick={()=>clickFunc(user)}>{!isUpdate? 'Редактировать профиль': 'Посмотреть изменения'}</button>
                    {
                        (status === -1 && !isUpdate)? (
                            <button className={`user-page__about-profile-btn edit ml-16 purple-btn ${loading? 'deactive-button': ''}`} onClick={reverification}>Отправить на верификацию</button>
                        ): null
                    }

                    <button className={`user-page__about-profile-btn edit ml-16 edit-mail  ${(loading || (status === 0 && !isUpdate) || status === -1)? 'deactive-button': ''}`} onClick={()=> editEmailOrPasswordFunc(user, 'EditEmail')}></button>
                    <button className={`user-page__about-profile-btn edit ml-16 edit-password  ${(loading || (status === 0 && !isUpdate) || status === -1)? 'deactive-button': ''}`} onClick={()=> editEmailOrPasswordFunc(user, 'EditPassword')}></button>

                </div>
            </div>
            <div className='user-page__underline'/>
            <div className='user-page__about-profile-email-and-phone d-flex justify-between align-center'>
                 <div className='user-page__field user-page__about-profile-field profile-inputs email d-flex justify-between align-center'>
                    <div className='user-page__field-text'>{email}</div>
                    <div className='user-page__field-icon email'/>
                 </div>
                 <div className='user-page__field user-page__about-profile-field profile-inputs phone d-flex justify-between align-center'>
                    <div className='user-page__field-text'>{phone}</div>
                    <div className='user-page__field-icon phone'/>
                 </div>
            </div>
            <div className={`user-page__about-profile-buttons mobile mt-16 d-flex align-center`}>
                <div className='d-flex justify-between flex-wrap'>
                    <button className={`user-page__about-profile-btn edit ${(status === 0 && !isUpdate)? 'deactive-button': ''} ${!(loading || (status === 0 && !isUpdate) || status === -1)? 'wd-buttons-active': ''}`} onClick={()=>clickFunc(user)}>{!isUpdate? 'Редактировать профиль': 'Посмотреть изменения'}</button>
                    
                    {
                        !((status === 0 && !isUpdate) || status === -1)? <>
                            <button className={`user-page__about-profile-btn edit ml-16 edit-mail  ${loading || isUpdate? 'deactive-button': ''}`} onClick={()=> editEmailOrPasswordFunc(user, 'EditEmail')}></button>
                            <button className={`user-page__about-profile-btn edit ml-16 edit-password  ${loading || isUpdate? 'deactive-button': ''}`} onClick={()=> editEmailOrPasswordFunc(user, 'EditPassword')}></button>                
                        </>: null
                    }
                
                </div>
                {
                    (status === -1 && !isUpdate)? (
                        <button className={`user-page__about-profile-btn edit mt-16 purple-btn ${loading? 'deactive-button': ''}`} onClick={reverification}>Отправить на верификацию</button>
                    ): null
                }
            </div>

            <div className='user-page__about-profile-error'>{error}</div>

        </div>
    )
}

export default Profile;