import { useEffect, useState } from 'react';
import './index.scss'
import { useDispatch, useSelector } from 'react-redux';
import { selectAdminFinanceUser } from '../../../../../redux/admin/finance/user/selectors';
import { axiosProxyCatchPost } from '../../../../../axiosProxy';
import { fetchAdminFinanceChildsData } from './../../../../../redux/admin/finance/childs/asyncActions';
import { selectAdminFinanceChilds } from './../../../../../redux/admin/finance/childs/selectors';
import DropDownList from '../../../Popup/DropDownList';


const Discount = ({isFullVerify, childsData, openDropListName, setOpenDropListName}) =>{

    const dispatch = useDispatch()

    const { adminFinanceUserData:userData } = useSelector(selectAdminFinanceUser);
    const { discount:checkDiscount } = useSelector(selectAdminFinanceChilds);

    const [checkChild, setCheckChild] = useState({id: 0, name: 'На всех детей', discount: ''})
    const [discount, setDiscount] = useState('')
    const [fieldEmpty, setFieldEmpty] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        if(checkChild.id){
            let result = childsData.filter(item => item.id === checkChild.id)
            if(result.length) setCheckChild(result[0])
            else setCheckChild({id: 0, name: 'На всех детей', discount: ''})
        }
    }, [childsData])

    useEffect(()=>{
        if(checkChild.id){
            setDiscount(checkChild.discount? checkChild.discount : '')
        }
        else if(checkDiscount) setDiscount(checkDiscount)
        else setDiscount('')
    }, [checkChild, checkDiscount])

    const sendDiscountFunc = () =>{
        setFieldEmpty(false)

        let postData = {clientId: userData.id, procent: +discount}
        if(checkChild.id) postData.studentId = checkChild.id
        if(discount !== ''){
            setLoading(true)
            // if(checkChild.id) dispatch(setAdminFinanceChildsDataEdit({id: checkChild.id, discount: discount}))

            axiosProxyCatchPost({
                url: '/api/v1/administrative/finance/set-sale',
                data: postData,
                resolved: (data)=>{
                    setDiscount('')
                    dispatch(fetchAdminFinanceChildsData({clientId: userData.id}))
                    setLoading(false)
                },
                rejected: (error)=>{
                    
                }
            })   
        }
        else setFieldEmpty(true)
    }

    return(
        <div className={`admin__finance-container discount`} onClick={(e)=>{
            if(e.target.closest(".admin__finance-user-card-discount-input-additionaly-container") === null && !e.target.classList.contains('admin__finance-user-card-discount-button')){
                setFieldEmpty(false)
            }
        }}>
            <div className='admin__finance-title mb-24'>Скидка</div>
            {/* <div className='admin__finance-title mb-24'>Скидка {checkDiscount? checkDiscount: ''}{checkDiscount? '%': ''}</div> */}
            <DropDownList 
                listData={[{id: 0, name: 'На всех детей'}, ...childsData]} 
                label='name' 
                checkedElem={checkChild} 
                clickFunk={(item)=>{
                    setCheckChild(item)
                    setOpenDropListName('')
                }}  
                listName={'admin__finance-user-card-discount-checked-child'} 
                listIsOpen={openDropListName} 
                setListIsOpen={setOpenDropListName} 
                classes='admin__finance-user-card-discount-checked-child-drop-list mb-16'
            />
            <div className='d-flex align-center'>
                <div className={`admin__finance-user-card-discount-input-additionaly-container ${isFullVerify? '': ''} ${(fieldEmpty)? 'input-field-empty': ''}`}>
                    <input type="text" readOnly={(isFullVerify && checkChild.id === 0) || (checkChild.id && checkChild.isFullVerify)} value={discount} className={`admin__finance-user-card-discount-input-additionaly`} onScroll={null} onChange={(e)=>{
                        if(e.target.value < 100 && e.target.value.match(/^[0-9]+$/) || e.target.value === '') setDiscount(e.target.value)
                    }}/>
                    <div className='admin__finance-user-card-discount-input-additionaly-val'>{discount}{discount? '%': ''}</div>
                </div>
                <button className={`admin__finance-user-card-discount-button ${loading || !discount || ((discount && +discount === checkDiscount && checkChild.id === 0) || (checkChild.id && +checkChild.discount === +discount))? 'deactive-button': ''}`} onClick={sendDiscountFunc}>Применить</button>
            </div>
        </div>
    )
}

export default Discount;