import { convertNumber } from './../../../../../services/clientHome';

const PaymentHistoryItem = (item, key) =>{
    const {id, name, date, summ} = item;

    return(
        (window.innerWidth > 680)? 
            <div className='user-page__finance-payment-history-table-line d-flex align-center justify-between' key={`user-page__finance-payment-history-table-line-${key}`}>
                <div className='user-page__finance-payment-history-table-line-item name'>{name}</div>
                <div className='user-page__finance-payment-history-table-line-item date'>{date}</div>
                <div className='user-page__finance-payment-history-table-line-item summ'>{convertNumber(summ)}₽</div>
            </div>
            :<div className='user-page__finance-payment-history-table-line-mobile' key={`user-page__finance-payment-history-table-line-${key}`}>
                <div className='user-page__finance-payment-history-table-line-mobile-item d-flex align-center justify-between'>
                    <div className='user-page__finance-payment-history-table-line-mobile-item-label'>Имя:</div>
                    <div className='user-page__finance-payment-history-table-line-mobile-item-val'>{name}</div>
                </div>
                <div className='user-page__finance-payment-history-table-line-mobile-item d-flex align-center justify-between'>
                    <div className='user-page__finance-payment-history-table-line-mobile-item-label'>Дата платежа:</div>
                    <div className='user-page__finance-payment-history-table-line-mobile-item-val'>{date}</div>
                </div>
                <div className='user-page__finance-payment-history-table-line-mobile-item d-flex align-center justify-between'>
                    <div className='user-page__finance-payment-history-table-line-mobile-item-label'>Сумма платежа:</div>
                    <div className='user-page__finance-payment-history-table-line-mobile-item-val'>{convertNumber(summ)}₽</div>
                </div>
            </div>
    )
}

export default PaymentHistoryItem;