import { useEffect, useState } from 'react';
import './index.scss'
import MaskedInput from '../../../MaskedInput/MaskedInput';
import { axiosProxyCatchPost } from '../../../../axiosProxy';
import { setTeacherProfileDataEdit } from '../../../../redux/teacher/about/aboutSlice';
import { useDispatch } from 'react-redux';


const PersonalInform = ({data}) =>{
    const dispatch = useDispatch()
    const {name, phone, email} = data;        

    const [editProfileIsActive, setEditProfileIsActive] = useState(false)

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState({
        name: name,
        phone: phone,
        email: email
    })

    useEffect(()=>{
        setFormData({
            name: data.name,
            phone: data.phone,
            email: data.email
        })
    }, [data])

    const editProfileFunc = () =>{
        setError('')        

        if(formData.name && formData.phone && formData.phone.length === 17 && formData.phone.indexOf('_') === -1){
            setLoading(true)
            axiosProxyCatchPost({
                url: '/api/v1/teaching/profile/edit-profile',
                data: {name: formData.name, phone: formData.phone},
                resolved: (data)=>{
                    setLoading(false)
                    setEditProfileIsActive(false)
                    dispatch(setTeacherProfileDataEdit({name: formData.name, phone: formData.phone}))
                },
                rejected: (error)=>{
                    if(error && error.data && typeof error.data.detail === 'string') setError(error.data.detail)
                    else setError('Что-то пошло не так*')
                    setLoading(false) 
                }
            })
        }
        else if(formData.name && !(formData.phone && formData.phone.length === 17 && formData.phone.indexOf('_') === -1)){
            setError('Невалидный номер телефона*')
        }
        else{
            setError('Необходимо заполнить все поля*')            
        }
    }

    return (
        <div className={`teacher__about-container mb-32`}>
            <div className='teacher__about-personal-inform-top d-flex align-center justify-between'>
                <div className='teacher__about-personal-inform-top-container d-flex align-center'>
                    <div className='teacher__about-personal-inform-top-name-icon'/>
                    <div className='teacher__about-personal-inform-top-name'>{name}</div>
                </div>

                <button className={`teacher__about-button ${editProfileIsActive? 'deactive-button': ''}`} onClick={() => setEditProfileIsActive(true)}>
                    Редактировать профиль
                </button>
            </div>
            <div className='teacher__about-container-underline'/>
            <div className='teacher__about-container-title mb-24'>Основная информация</div>

            <label className="teacher__about-container-label mb-8">ФИО</label>
            <input type={'text'} readOnly={!editProfileIsActive} value={formData.name} className={`popup__input mb-24 ${editProfileIsActive? '': 'cursor-deafult'}`} onInput={(event)=>{
                if(event.target.value.match(/^[a-zA-ZА-Яа-я- ]+$/) || !event.target.value) setFormData({...formData, name: event.target.value})
            }}/>
            <label htmlFor="" className='teacher__about-container-label mb-8'>Телефон</label>
            <MaskedInput
                className={`popup__input mb-24 ${editProfileIsActive? '': 'cursor-deafult'}`}
                mask="+7(___) ___-__-__"
                replacement={ { _ : /[0-9]/ }}
                defaultValue={formData.phone}
                name = 'phone'
                readOnly={!editProfileIsActive}
                placeholder=''
                showMask={true}
                separate={true}
                onChange={(event)=>{
                    setFormData({...formData, phone: event.target.value})
                }}
            />
            <label className="teacher__about-container-label mb-8">Email</label>
            <input type={'email'} readOnly={true} value={formData.email} className={`popup__input popup__disable-input`}/>

            <div className={`teacher__about-container-error height-auto ${error? 'active': ''}`}>{error}</div>

            <div className={`teacher__about-personal-inform-save-buttons d-flex ${editProfileIsActive? 'active': ''}`}>
                <button className={`teacher__about-button wd-50-mr-16 ${loading? 'deactive-button': ''}`} onClick={() => setEditProfileIsActive(false)}>
                    Отменить
                </button>
                <button className={`teacher__about-button purple wd-50-mr-16 ${(loading || (formData.name === name && formData.phone === phone))? 'deactive-button': ''}`} onClick={editProfileFunc}>
                    Сохранить
                </button>
            </div>
        </div>
    )
}

export default PersonalInform;