import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserPopup from '../Popup/UserPopup';
import Child from './Child';
import Profile from './Profile';

import { setUserPopupBack } from '../../../redux/users/popup/userPopupSlice';

import './index.scss'
import { selectUserProfile } from './../../../redux/users/profile/selectors';
import { setUserProfileCheckData } from '../../../redux/users/profile/profileSlice';
import { selectUserChildren } from './../../../redux/users/children/selectors';
import { setUserCheckChild, setUserChildrenDataDeleteElem, setUserChildrenDataElemEdit, setUserFinanceChildrenDataEdit } from '../../../redux/users/children/childrenSlice';
import { fetchUserProfileData } from '../../../redux/users/profile/asyncActions';
import { fetchUserChildsData } from '../../../redux/users/children/asyncActions';
import { setUserNavBarActive } from '../../../redux/users/navigations/userNavigationsSlice';
import { axiosProxyCatchPost } from '../../../axiosProxy';
import MessageBlock from '../Popup/MessageBlock/MessageBlock';

const About = () =>{
    const dispatch = useDispatch();

    const { profileData } = useSelector(selectUserProfile);
    const { homeChildrenData } = useSelector(selectUserChildren);

    const [popupType, setPopupType] = useState('')
    const [messageBLockData, setMessageBLockData] = useState({title: '', subtitle: ''})
    const [loading, setLoading] = useState(false)

    const editProfileFunc = (elem) =>{
        dispatch(setUserPopupBack('open'))
        setPopupType((elem.isUpdate)? 'ProfileCurrentChages': 'EditProfile')
        dispatch(setUserProfileCheckData(elem))
    }

    const editEmailOrPasswordFunc = (elem, type) =>{
        dispatch(setUserPopupBack('open'))
        setPopupType(type)
        dispatch(setUserProfileCheckData(elem))
    }

    const editChildFunc = (elem, type='') =>{
        dispatch(setUserPopupBack('open'))        
        if(type === 'ChildNewAgreement' && elem.isFinish)
            setPopupType('ChildNewAgreement')
        else
            setPopupType(elem.isUpdate? 'ChildCurrentChanges': 'EditChild')

            dispatch(setUserCheckChild(elem))
    }

    const addChildFunc = () =>{
        dispatch(setUserPopupBack('open'))
        setPopupType('CreateChild')
        dispatch(setUserCheckChild({id: 0, name: '', birthDay: '', city: {id: 0, name: ''}, school: {id: 0, name: ''}, classObj: {id: 0, number: '', letter: ''}}))
    }

    const deletedChildApplication = (id, editData, type='edit') => {
        axiosProxyCatchPost({
            url: '/api/v1/parental/application/delete-application', 
            data: {id},
            resolved: (data) =>{
                dispatch(type === 'edit'? setUserChildrenDataElemEdit(editData): setUserChildrenDataDeleteElem(editData))
                setLoading(false)
                if(type === 'delete-child'){            
                    setPopupType('MessageBlock')
                    setMessageBLockData({title: 'Заявка отклонена', subtitle: 'Заявка на добавление нового ребенка была отклонена.' + (editData.comment? `\n Причина отклонения заявки: ${editData.comment}`: '')})
                }          
            },
            rejected: (error) =>{                    
                setLoading(false)
            }
        })
    }

    useEffect(()=>{
        dispatch(fetchUserProfileData())
        dispatch(fetchUserChildsData())
        dispatch(setUserNavBarActive(0))
    }, [])

    return (
        <div className={`user-page__about`}>
            <div className='user-page__top'>
                <div className='user-page__title'>Профиль</div>
            </div>

            <Profile user={profileData} clickFunc={editProfileFunc} editEmailOrPasswordFunc={editEmailOrPasswordFunc}/>

            <div className='user-page__title-container d-flex justify-between align-center'>
                <div className='user-page__title not-margins'>Дети</div>
                {
                    (profileData.status > 0)? <button className='user-page__button added-child' onClick={addChildFunc}>
                        <div classes='user-page__button-text added-child'>Добавить ребенка</div>
                    </button>: null 
                }
            </div>
            <div className='user-page__about-children d-flex flex-wrap'>
                {
                    homeChildrenData.map((item)=><Child key={'user-page__about-children-' + item.id} item={item} loading={loading} profileStatus={profileData.status} clickFunc={editChildFunc} deletedChildApplication={deletedChildApplication}/>)
                }
            </div>
            <UserPopup type={popupType} props={{typeEdit: setPopupType}}/>
            <MessageBlock popupBack={(popupType === 'MessageBlock')? 'open': ''} props={{title:messageBLockData.title, subtitle: messageBLockData.subtitle, closePopup: setPopupType}}/>
        </div>
    )
}

export default About;