import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminConectedProgramAplication, fetchAdminConfirmCertificateAplication, fetchAdminConfirmDocumentAplication, fetchAdminUserEditedAplication, fetchAdminUserVerificationAplication, fetchAdminEditChildConfirmAplication, fetchAdminReContractChildConfirmAplication, fetchAdminAddChildConfirmAplication } from './asyncActions';
import { editChildConfirmAplication, editClientdConfirmAplication, reContractChildConfirmAplication } from './../../../../defaultData/admin/aplications/aplicatonsConfirm';

const initialState = {
  adminUserVerificationAplication:{
    id: 0,
    client: {
      id: 0,
      name: '',
      email: '',
      password: '',
      phone: ''
    },
    students: [
    ]
  },

  // adminUserVerificationAplication:{
  //   id: 1,
  //   client: {
  //       id: 1,
  //       name: 'Name Name Name',
  //       email: 'ggggg@ggg.gg',
  //       password: '12345yyy',
  //       phone: '+7(928)685-12-34'
  //   },
  //   students: [
  //     {
  //       id: 1,
  //       name: 'Name Name Name',
  //       city: {id: 1, name: 'Москва'},
  //       school: {id: 1, name: 'Моская гимназия №11'},
  //       classObj: {id: 1, number: 7, letter: 'A'},
  //       classNumber: 7,
  //       classLetter: 'A',
  //       birthDay: '12.12.2010',
  //       programs: [{id: 12, name: 'Математика', color: 'blue'}],
  //       startDate: "2024-11-02T00:00:00Z",
  //       startMonth: 11
  //     },
  //     {
  //       id: 2,
  //       name: 'Name Name Name2',
  //       city: {id: 1, name: 'Москва'},
  //       school: {id: 1, name: 'Моская гимназия №11'},
  //       classObj: {id: 1, number: 7, letter: 'A'},
  //       classNumber: 7,
  //       classLetter: 'A',
  //       birthDay: '12.12.2010',
  //       programs: [{id: 12, name: 'Математика', color: 'blue'}],
  //       startDate: "2024-11-02T00:00:00Z",
  //       startMonth: 11
  //     },
  //   ]
  // },

  // adminUserEditedAplication:{
  //   applicationId: 0,
  //   id: 0,
  //   name: '',
  //   email: '',
  //   password: '',
  //   phone: '',

  //   update:{
  //     id: 0,
  //     name: '',
  //     email: '',
  //     password: '',
  //     phone: ''
  //   }
  // },


  adminUserEditedAplication:{
    applicationId: 0,
    
    current: {
      id: 0,
      name: '',
      email: '',
      password: '',
      phone: '',
    },

    update:{
      id: 0,
      name: '',
      email: '',
      password: '',
      phone: ''
    }
  },
  
  adminConectedProgramAplication:{
    applicationId: 0,
    student: {id: 0, name: ''},
    city: {id: 0, name: ''},
    school: {id: 0, name: ''},
    classObj: {id: 0, number: '', letter: ''},
    program: {
        id: 0,
        name: '',
        color: ''
    },
    date: '',
    status: 1
  },

  // '2024-12-06T12:00:00+00:00'

  adminConfirmDocumentAplication:{
    id: 0,
    clientName: '',
    type: 0,
    document: {
        id: 0,
        name: '',
        date: '',
        status: null,
        link: '',
        extension: ''
    },
  },
  
  adminConfirmCertificateAplication:{
    id: 0,
    clientName: '',
    type: 0,
    document: {
        id: 0,
        startDate: '',
        endDate: '',
        date: '',
        status: null,
        link: ''
    },
  },

  adminEditChildConfirmAplication:{
    applicationId: 0,
    
    current: {
      id: 0,
      name: '',
      city: {id: 0, name: ''},
      school: {id: 0, name: ''},
      classObj: {id: 0, number: '', letter: ''},
      birthDay: '',
    },

    update: {
      id: 0,
      name: '',
      city: {id: 0, name: ''},
      school: {id: 0, name: ''},
      classObj: {id: 0, number: '', letter: ''},
      birthDay: '',
    }
  },

  adminAddChildConfirmAplication:{
    applicationId: 0,
    id: 0,
    name: '',
    city: {id: 0, name: ''},
    school: {id: 0, name: ''},
    classObj: {id: 0, number: '', letter: ''},
    classNumber: '',
    classLetter: '',
    birthDay: '',
    startDate: '',
    startMonth: ''
  },

  // adminAddChildConfirmAplication:{
  //   applicationId: 666,
  //   id: 660,
  //   name: 'Bvz ht,tyrf',
  //   city: {id: 1, name: 'retetert'},
  //   school: {id: 33, name: 'jjjjjjjjjj'},
  //   classObj: {id: 2323, number: 8, letter: 'K'},
  //   classNumber: 8,
  //   classLetter: 'K',
  //   birthDay: '12.06.2015',
  //   startDate: '2025-12-02T12:00:00+00:00',
  //   startMonth: 2
  // },

  adminReContractChildConfirmAplication:{
  //  ...reContractChildConfirmAplication
    id: 0,
    applicationId: 0,
    name: '',
    city: {id: 0, name: ''},
    school: {id: 0, name: ''},
    classObj: {id: 0, number: '', letter: ''},
    birthDay: '',
    programs: [],
    startDate: '',
    startMonth: 0,

    parentName: '',
  },

  // adminEditChildConfirmAplication:{
  //  ...editChildConfirmAplication
  // },

  


};

const adminAplicationConfirm = createSlice({
  name: 'AdminAplicationConfirm',
  initialState,
  reducers: {
    setAdminUserVerificationAplication(state, action) {
      state.adminUserVerificationAplication = {...state.adminUserVerificationAplication, ...action.payload};
    },

    setAdminUserEditedAplication(state, action) {      
      state.adminUserEditedAplication = {...state.adminUserEditedAplication, ...action.payload};
    },

    setAdminConectedProgramAplication(state, action) {
      state.adminConectedProgramAplication = {...state.adminConectedProgramAplication, ...action.payload};
    },

    setAdminConfirmDocumentAplication(state, action) {
      state.adminConfirmDocumentAplication = {...state.adminConfirmDocumentAplication, ...action.payload};
    },

    setAdminConfirmCertificateAplication(state, action) {
      state.adminConfirmCertificateAplication = {...state.adminConfirmCertificateAplication, ...action.payload};
    },

    setAdminEditChildConfirmAplication(state, action) {
      state.adminEditChildConfirmAplication = {...state.adminEditChildConfirmAplication, ...action.payload};
    },

    setAdminAddChildConfirmAplication(state, action) {
      state.adminAddChildConfirmAplication = {...state.adminAddChildConfirmAplication, ...action.payload};
    },

    setAdminReContractChildConfirmAplication(state, action) {
      state.adminReContractChildConfirmAplication = {...state.adminReContractChildConfirmAplication, ...action.payload};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminUserVerificationAplication.pending, (state, action) => {
      state.adminUserVerificationAplication = {
        ...state.adminUserVerificationAplication,
        client: {
            id: 0,
            name: '',
            email: '',
            password: '',
            phone: ''
        },
        students: [
        ]
      }
    });
    builder.addCase(fetchAdminUserVerificationAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.userVerificationData){
        let students = action.payload.userVerificationData.students;
        if(students.length){
          students = students.map(item=>{
            return {
              ...item,
              birthDay: new Date(item.birthDay).toLocaleDateString(),
              classNumber: item.classObj.number,
              classLetter: item.classObj.letter,
            }
          })
        }
        state.adminUserVerificationAplication = {...state.adminUserVerificationAplication, ...action.payload.userVerificationData, students: students};
      }
    });
    builder.addCase(fetchAdminUserVerificationAplication.rejected, (state, action) => {
    });


    builder.addCase(fetchAdminUserEditedAplication.pending, (state, action) => {
      state.adminUserEditedAplication = {
        ...state.adminUserEditedAplication,
        current: {
          id: 0,
          name: '',
          email: '',
          password: '',
          phone: '',
        },    
        update:{
          id: 0,
          name: '',
          email: '',
          password: '',
          phone: ''
        }
      }
    });
    builder.addCase(fetchAdminUserEditedAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.userData) state.adminUserEditedAplication = {...state.adminUserEditedAplication, ...action.payload.userData};
    });
    builder.addCase(fetchAdminUserEditedAplication.rejected, (state, action) => {
    });


    builder.addCase(fetchAdminEditChildConfirmAplication.pending, (state, action) => {
      state.adminEditChildConfirmAplication = {
        ...state.adminEditChildConfirmAplication,
        current: {
          id: 0,
          name: '',
          city: {id: 0, name: ''},
          school: {id: 0, name: ''},
          classObj: {id: 0, number: '', letter: ''},
          birthDay: '',
        },
    
        update: {
          id: 0,
          name: '',
          city: {id: 0, name: ''},
          school: {id: 0, name: ''},
          classObj: {id: 0, number: '', letter: ''},
          birthDay: '',
        }
      }
    });
    builder.addCase(fetchAdminEditChildConfirmAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.childData) state.adminEditChildConfirmAplication = {...state.adminEditChildConfirmAplication, ...action.payload.childData};
    });
    builder.addCase(fetchAdminEditChildConfirmAplication.rejected, (state, action) => {
    });

    
    builder.addCase(fetchAdminAddChildConfirmAplication.pending, (state, action) => {
      state.adminAddChildConfirmAplication = {
        ...state.adminAddChildConfirmAplication,
        id: 0,
        name: '',
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        classObj: {id: 0, number: '', letter: ''},
        classNumber: '',
        classLetter: '',
        birthDay: '',
        startDate: '',
        startMonth: ''
      }
    });
    builder.addCase(fetchAdminAddChildConfirmAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.childData) state.adminAddChildConfirmAplication = {
        ...state.adminAddChildConfirmAplication, 
        ...action.payload.childData, 
        birthDay: new Date(action.payload.childData.birthDay).toLocaleDateString(),
        classNumber: action.payload.childData.classObj.number,
        classLetter: action.payload.childData.classObj.letter,
        startMonth: new Date(action.payload.childData.startDate).getMonth()+1
      };
    });
    builder.addCase(fetchAdminAddChildConfirmAplication.rejected, (state, action) => {
    });


    builder.addCase(fetchAdminConectedProgramAplication.pending, (state, action) => {
      state.adminConectedProgramAplication = {
        ...state.adminConectedProgramAplication,
        id: 0,
        name: '',
        city: '',
        school: '',
        classNumber: '',
        classLetter: '',
        program: {
            id: 0,
            name: '',
            color: ''
        }
      }

    });
    builder.addCase(fetchAdminConectedProgramAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.programData) state.adminConectedProgramAplication = {...state.adminConectedProgramAplication, ...action.payload.programData};
    });
    builder.addCase(fetchAdminConectedProgramAplication.rejected, (state, action) => {
    });

    
    builder.addCase(fetchAdminConfirmDocumentAplication.pending, (state, action) => {
      state.adminConfirmDocumentAplication = {
        ...state.adminConfirmDocumentAplication,
        clientName: '',
        type: 0,
        document: {
            id: 0,
            name: '',
            date: '',
            status: null,
            link: ''
        },
      }
    });
    builder.addCase(fetchAdminConfirmDocumentAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.documentData) state.adminConfirmDocumentAplication = {...state.adminConfirmDocumentAplication, ...action.payload.documentData};
    });
    builder.addCase(fetchAdminConfirmDocumentAplication.rejected, (state, action) => {
    });


    builder.addCase(fetchAdminConfirmCertificateAplication.pending, (state, action) => {
      state.adminConfirmCertificateAplication = {
        ...state.adminConfirmCertificateAplication,
        clientName: '',
        type: {
            id: 0,
            name: ''
        },
        document: {
            id: 0,
            validity: {
                startDate: '',
                endDate: '',
            },
            date: '',
            status: null,
            link: ''
        },
      }
    });
    builder.addCase(fetchAdminConfirmCertificateAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.certificateData) state.adminConfirmCertificateAplication = {...state.adminConfirmCertificateAplication, ...action.payload.certificateData};
    });
    builder.addCase(fetchAdminConfirmCertificateAplication.rejected, (state, action) => {
    });


    builder.addCase(fetchAdminReContractChildConfirmAplication.pending, (state, action) => {
      state.adminReContractChildConfirmAplication = {
        ...state.adminReContractChildConfirmAplication,
        id: 0,
        name: '',
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        classObj: {id: 0, number: '', letter: ''},
        birthDay: '',
        programs: [],
        startDate: '',
        startMonth: 0,

        parent: {
          id: 0,
          name: '',
        }
      }
    });
    builder.addCase(fetchAdminReContractChildConfirmAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.data){
        state.adminReContractChildConfirmAplication = {...state.adminReContractChildConfirmAplication, ...action.payload.data, startMonth: new Date(action.payload.data.startDate).getMonth() + 1};
      }
    });
    builder.addCase(fetchAdminReContractChildConfirmAplication.rejected, (state, action) => {
    });
  },

});

const { actions, reducer } = adminAplicationConfirm;


export default reducer;

export const { 
    setAdminUserVerificationAplication,
    setAdminUserEditedAplication,
    setAdminConfirmDocumentAplication,
    setAdminConfirmCertificateAplication,
    setAdminConectedProgramAplication,
    setAdminEditChildConfirmAplication,
    setAdminAddChildConfirmAplication,
    setAdminReContractChildConfirmAplication
} = actions;

