import './index.scss'
import { convertNumber } from './../../../../services/clientHome';


const Program = ({item, conectedButtonFunc, childsList, programChildChecked, setProgramChildChecked, programChildOpenList, setProgramChildOpenList, conectedLoadingChilds}) =>{
    const {id, name, price, permittedChildren} = item;
    let programChildsList = childsList.filter(item => permittedChildren.indexOf(item.id) !== -1);

    return(
        <div className={`user-page__finance-program d-flex justify-between flex-column`} key={`user-page__finance-program-${id}`}>
            <div className='user-page__finance-program-name'>{name}</div>
            <div>
                <div className='user-page__underline finance-child-underline'/>
                <div className='user-page__finance-program-price d-flex justify-between align-center'>
                    <div className='user-page__finance-program-price-label'>Цена:</div>
                    <div className='user-page__finance-program-price-val'>
                        {convertNumber(price)}₽<span>/год</span>
                    </div>
                </div>
                <div className='user-page__finance-program-childs'>
                    <div className={`user-page__finance-program-childs-checked d-flex align-center justify-between ${programChildChecked.programId === id && programChildChecked.id? 'active' : ''}`} onClick={() => setProgramChildOpenList(programChildOpenList === id? 0: id)}>
                        <div className='user-page__finance-program-childs-checked-text'>
                            {programChildChecked.programId === id && programChildChecked.id? programChildChecked.name.split(' ')[1]: 'Выберите ребенка'}
                        </div>
                        <div className={`user-page__finance-program-childs-checked-icon ${programChildOpenList === id && programChildsList.length? 'active' : ''}`}/>
                    </div>
                    <div className={`user-page__finance-program-childs-droplist ${programChildOpenList === id && programChildsList.length? 'active' : ''}`}>
                        <div className='user-page__finance-program-childs-droplist-scroll'>
                            {
                                programChildsList.map(item =>
                                    <div className={`user-page__finance-program-childs-droplist-item ${programChildChecked.programId === id && programChildChecked.id === item.id? 'active': ''}`} onClick={()=>setProgramChildChecked({programId: id, id: item.id, name: item.name})} key={`user-page__finance-program-childs-droplist-item-${item.id}-${id}`}>
                                        {item.name.split(' ')[1]}
                                    </div> 
                                )
                            }
                        </div>
                    </div>
                </div>
                <button className={`user-page__finance-program-button connect ${(programChildChecked.programId === id && conectedLoadingChilds.indexOf(programChildChecked.id) !== -1)? 'deactive-button': ''}`} onClick={()=>conectedButtonFunc('conected', item)}>Подключить</button>
                <button className={`user-page__finance-program-button disable ${(programChildChecked.programId === id && conectedLoadingChilds.indexOf(programChildChecked.id) !== -1)? 'deactive-button': ''}`} onClick={()=>conectedButtonFunc('disabled', item)}>Отключить</button>
            </div>
        </div>
    )
}

export default Program;