// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 940px) {
  .user-page__header-navbar {
    display: none; } }
`, "",{"version":3,"sources":["webpack://./src/components/User/Header/Navbar/width-744-adaptive.scss"],"names":[],"mappings":"AAAA;EACI;IACI,aAAa,EAAA,EAChB","sourcesContent":["@media (max-width: 940px){\n    .user-page__header-navbar{\n        display: none; \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
