import MaskedInput from '../../../MaskedInput/MaskedInput';
import './index.scss'

const TeachersTableElem = ({item, clickFunc}) =>{
    const {name, city, school, login, phone} = item;
        
    return (
        <div className={`admin__teachers-table-line d-flex justify-between align-center`} onClick={clickFunc}>
            <div className="admin__teachers-table-line-item name">{name}</div>
            <div className="admin__teachers-table-line-item city">{city.name}</div>
            <div className="admin__teachers-table-line-item school">{school.name}</div>
            <div className="admin__teachers-table-line-item email">{login}</div>
            {
                (phone)? 
                    <MaskedInput
                        className={`admin__teachers-table-line-item phone`}
                        mask="+7(___) ___-__-__"
                        replacement={ { _ : /[0-9]/ }}
                        defaultValue={phone}
                        name = 'phone'
                        readOnly={true}
                        placeholder=''
                        showMask={true}
                        separate={true}
                        onChange={()=>{}}
                    />
                : <div className="admin__teachers-table-line-item phone">Не заполнено</div>
            }
        </div>
    )
}

export default TeachersTableElem;