export let profileData = {
    "id": 111,
    "name": "John Doe",
    "email": "johndoe@example.com",
    "phone": "+7(928) 899-23-49",

}

export let teachingData = {
    city: {
        id: 33333,
        name: 'Грозный'
    },
    school: {
        id: 44444,
        name: 'МБОУ Гудермесская СШ № 12 им. А. А. Кадырова'
    },
    learningClasses: [
        {
            id: 1,
            number: '1',
            letter: 'A'
        },
        {
            id: 2,
            number: '2',
            letter: 'A'
        },
        {
            id: 3,
            number: '9',
            letter: 'ХИМБИО'
        },
        {
            id: 4,
            number: '7',
            letter: 'В'
        },
    ],
    programsData: [
        {
            id: 1,
            name: 'Программа обучения 1',
            color: 'blue'
        },
        {
            id: 2,
            name: 'Программа обучения 3',
            color: 'red'
        },
        {
            id: 3,
            name: 'Программа обучения 3',
            color: 'light_blue'
        },
        {
            id: 4,
            name: 'Программа обучения 4',
            color: 'purple'
        },
        {
            id: 5,
            name: 'Программа обучения 5',
            color: 'yellow'
        },
        {
            id: 6,
            name: 'Программа обучения 6',
            color: 'orange'
        },
        {
            id: 7,
            name: 'Программа обучения 7',
            color: 'yellow'
        },
        {
            id: 8,
            name: 'Программа обучения 8',
            color: 'brown'
        },
        {
            id: 9,
            name: 'Программа обучения 9',
            color: 'red'
        },
        {
            id: 10,
            name: 'Программа обучения 8',
            color: 'yellow'
        },
    ]
}