import Child from './Child';
import './index.scss'
import Program from './Program/index';
import PaymentHistory from './PaymentHistory/index';
import { useDispatch, useSelector } from 'react-redux';
import { setUserPopupBack } from '../../../redux/users/popup/userPopupSlice';
import { useState, useEffect } from 'react';
import UserPopup from './../Popup/UserPopup';
import { selectUserChildren } from './../../../redux/users/children/selectors';
import { selectUserPrograms } from './../../../redux/users/programs/selectors';
import { setUserProgramsConectedChild, setUserProgramsDisableChild } from '../../../redux/users/programs/programsSlice';
import { setUserCheckChild } from '../../../redux/users/children/childrenSlice';
import { fetchUserFinanceChildsData } from '../../../redux/users/children/asyncActions';
import { fetchUserProgramsData } from '../../../redux/users/programs/asyncActions';
import { axiosProxyCatchPost } from '../../../axiosProxy';
import { fetchUserProfileData } from './../../../redux/users/profile/asyncActions';
import { setUserNavBarActive } from '../../../redux/users/navigations/userNavigationsSlice';
import MessageBlock from '../Popup/MessageBlock/MessageBlock';


const Finance = () =>{
    const dispatch = useDispatch();

    const { financeChildrenData } = useSelector(selectUserChildren)
    const { programsData } = useSelector(selectUserPrograms)


    const [conectedLoadingChilds, setConectedLoadingChilds] = useState([])
    const [programChildOpenList, setProgramChildOpenList] = useState(0)
    const [programChildChecked, setProgramChildChecked] = useState({programId: 0, id: 0, name: ''})
    

    const [warningBlockData, setWarningBlockData] = useState({title: '', subTitle: ''})
    const [popupType, setPopupType] = useState('')
    const [conectedProgramActive, setConectedProgramActive] = useState(false)

    const paymentFunc = (elem) =>{
        dispatch(setUserPopupBack('open'))
        setPopupType('')
        setPopupType('Payment')
        dispatch(setUserCheckChild(elem))
    }

    const childProgramConectedAndDisabled = (type, program) =>{
        const {id, awaitsDisabledChildren, awaitsConectedChildren, conectedChildren} = program;        

        if(!(programChildChecked.id && programChildChecked.programId === id)){
            setWarningBlockData({
                active: true,
                title: type === 'conected'? 'Подключение программы': 'Отключение программы',
                subTitle: type === 'conected'? 'Для подключения программы необходимо выбрать ребенка.': 'Для отключения программы необходимо выбрать ребенка.'
            })
        }
        else if(awaitsConectedChildren.indexOf(programChildChecked.id) !== -1){
            setWarningBlockData({
                active: true, 
                ...((type === 'conected')? 
                {
                    title: 'Подключение программы', 
                    subTitle: 'Ваша заявка на подключение программы уже отправлена. Пожалуйста, ожидайте, пока администратор не обработает Вашу заявку.'
                }
                :{
                    title: 'Отключение программы', 
                    subTitle: 'Программа ожидает обработки заявки на подключение, отключение пока недоступно.'
                })
            })
        }
        else if(awaitsDisabledChildren.indexOf(programChildChecked.id) !== -1){
            setWarningBlockData({
                active: true, 
                ...((type === 'conected')? 
                {
                    title: 'Подключение программы', 
                    subTitle: 'Программа ожидает обработки заявки на отключение, подключение пока недоступно.'
                }
                :{
                    title: 'Отключение программы', 
                    subTitle: 'Ваша заявка на отключение программы уже отправлена. Пожалуйста, ожидайте, пока администратор не обработает Вашу заявку.'
                })
            })
        }
        else if(conectedChildren.indexOf(programChildChecked.id) !== -1 && type === 'conected'){
            setWarningBlockData({
                active: true, 
                title: 'Подключение программы', 
                subTitle: 'Программа уже подключена.'
            })            
        }
        else if(conectedChildren.indexOf(programChildChecked.id) === -1 && type === 'disabled'){
            setWarningBlockData({
                active: true, 
                title: 'Отключение программы', 
                subTitle: 'Программа не подключена, отключение недоступно.'
            })
        }
        else {
            childProgramConectedAndDisabledPost(type, programChildChecked.id, id)
        }
    }

    const childProgramConectedAndDisabledPost = (type, childId, programId) =>{
        setConectedLoadingChilds([...conectedLoadingChilds, childId])
        axiosProxyCatchPost({
            url: type === 'conected'? '/api/v1/parental/finance/Create-additional': '/api/v1/parental/finance/shutdown-additional', 
            
            data:{studentId: childId, programId: programId},
            resolved: () =>{
                if(type === 'conected'){
                    dispatch(setUserProgramsConectedChild({programId: programId, childId: childId}))
                }
                else if(type === 'disabled'){
                    dispatch(setUserProgramsDisableChild({programId: programId, childId: childId}))
                }
                setConectedLoadingChilds(conectedLoadingChilds.filter(item=> item.id !== childId))
                setWarningBlockData({
                    active: true, 
                    ...((type === 'conected')? 
                    {
                        title: 'Подключение программы', 
                        subTitle: 'Ваша заявка на подключение программы отправлена. Пожалуйста, ожидайте, пока администратор не обработает Вашу заявку.'
                    }
                    :{
                        title: 'Отключение программы', 
                        subTitle: 'Ваша заявка на отключение программы отправлена. Пожалуйста, ожидайте, пока администратор не обработает Вашу заявку.'
                    }
                )})
                setProgramChildChecked({programId: 0, id: 0, name: ''})
            },
            rejected: (error) =>{
                if(error && error.data && typeof error.data.detail === 'string') 
                    setWarningBlockData({
                        active: true, 
                        title: 'Ошибка', 
                        subTitle: error.data.detail
                    })
                setConectedLoadingChilds(conectedLoadingChilds.filter(item=> item.id !== childId))
                setProgramChildChecked({programId: 0, id: 0, name: ''})
            }
        })
    }

    useEffect(()=>{
        dispatch(fetchUserFinanceChildsData())
        dispatch(fetchUserProfileData())
        dispatch(setUserNavBarActive(2))
        dispatch(fetchUserProgramsData())
    }, [])
    

    return (
        <div className="user-page__finance" onClick={(e) =>{
            if(e.target.closest('.user-page__finance-program-childs-checked') === null){
                setProgramChildOpenList(0)
            }
        }}>
            <div className='user-page__top'>
                <div className='user-page__title'>Дети</div>
            </div>

            <div className='user-page__finance-children d-flex flex-wrap'>
                {
                    financeChildrenData.map((item)=> 
                        <Child 
                            key={`user-page__finance-child-component-${item.id}`} 
                            item={item} 
                            clickFunc={paymentFunc} 
                        />
                    )
                }
            </div>

            <div className={`user-page__title mt-30 mb-20 ${programsData.length? '': 'd-none'}`}>Дополнительные программы</div>

            <div className='user-page__finance-programs d-flex flex-wrap'>
                {
                    programsData.map((item)=>
                        <Program 
                            key={`user-page__finance-program-component-${item.id}`} 
                            item={item} 
                            childsList={financeChildrenData} 
                            programChildOpenList={programChildOpenList} 
                            setProgramChildOpenList={setProgramChildOpenList} 
                            programChildChecked={programChildChecked} 
                            setProgramChildChecked={setProgramChildChecked} 
                            conectedButtonFunc={childProgramConectedAndDisabled} 
                            conectedLoadingChilds={conectedLoadingChilds}
                        />
                    )
                }
            </div>

            <div className='user-page__title mt-30 mb-20'>История платежей</div>
            <PaymentHistory/>
            <UserPopup type={popupType} props={{typeEdit: setPopupType, setConectedProgramActive: setConectedProgramActive}} />
            <MessageBlock popupBack={warningBlockData.active? 'open': ''} classes={'user-popup__back'} props={{title:warningBlockData.title, subtitle: warningBlockData.subTitle, closePopup: ()=>{
                setWarningBlockData({...warningBlockData, active: false})
            }}}/>

        </div>
    )
}

export default Finance;