// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-popup__underline {
  width: 100%;
  height: 1px;
  background: #E6E6E6;
  margin: 28px 0 24px; }
`, "",{"version":3,"sources":["webpack://./src/components/User/Popup/EditPassword/index.scss"],"names":[],"mappings":"AACI;EACI,WAAW;EACX,WAAW;EACX,mBAAmB;EACnB,mBAAmB,EAAA","sourcesContent":[".user-popup{\n    &__underline{\n        width: 100%;\n        height: 1px;\n        background: #E6E6E6;\n        margin: 28px 0 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
