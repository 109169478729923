import './index.scss'


const getStatus = (id) =>{
    if(id === 1)
        return {color: 'green', label: 'Оплачено'}
    else if(id === 0)
        return {color: 'red', label: 'Не оплачено'}
    else
        return {color: '', label: 'Не найден'}
}

const Child = ({item, clickFunc}) =>{
    const {id, name, status, datePay, programs, summ, isFinish, isMatCap} = item;

    const statusObj = getStatus(status)

    const programElem = (item) =>{
        const {id, name, color} = item;
        return(
            <div className={`user-page__finance-child-programs-item ${color}`} key={`user-page__finance-child-programs-item-${id}`}>{name}</div>
        )
    }

    return (
        <div className={`user-page__finance-child d-flex justify-between flex-column`} key={`user-page__finance-child-${id}`}>
            <div>
                <div className='d-flex justify-between align-center flex-wrap'>
                    <div className='user-page__finance-child-name'>{name.split(' ')[1]}</div>
                    {/* <div className={`user-page__finance-child-status`}>Не обучается</div> */}
                    <div className={`user-page__finance-child-status ${isFinish? '': statusObj.color}`}>{isFinish? 'Не обучается': isMatCap? 'Мат.Капитал': statusObj.label}</div>
                </div>

                <div className='user-page__underline finance-child-underline'/>

                <div className='user-page__finance-child-payment d-flex justify-between align-center mb-16'>
                    <div className='user-page__finance-child-label'>Следующий платеж:</div>
                    <div className='user-page__finance-child-payment-date'>{datePay}</div>
                </div>

                <div className='user-page__finance-child-programs d-flex align-center flex-wrap'>
                    <div className='user-page__finance-child-label programs-label mr-10'>Программы:</div>
                    {
                        programs.map(programElem)
                    }
                </div>
            </div>
            <div>
                <div className='user-page__underline finance-child-programs-underline'/>

                <div className='user-page__finance-child-summ d-flex justify-between align-center'>
                    <div className='user-page__finance-child-label'>Сумма к оплате:</div>
                    <div className='user-page__finance-child-summ-val'>{summ}₽</div>
                </div>

                <button className={`user-page__finance-child-button mt-16 ${(isFinish || isMatCap)? 'deactive-button': ''}`} onClick={()=>{clickFunc(item)}}>Оплатить</button>

            </div>
        </div>
    )
}

export default Child;