import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { selectAdminFinanceChilds } from '../../../../../redux/admin/finance/childs/selectors';
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import { setAdminFinanceChildsDataEdit } from '../../../../../redux/admin/finance/childs/childsSlice';


const FinanceCloseMatcapPayment = ({closePopup}) =>{
    const dispatch = useDispatch()

    const { adminFinanceCheckedChild:checkChild } = useSelector(selectAdminFinanceChilds);

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState('')

    const closePaymentPostFunk = () =>{
        setError('')
        setLoading(true)
        axiosProxyCatchPost({
            url: '/api/v1/administrative/finance/mat-cap-finish',
            data: {studentId: checkChild.id},
            resolved: (data)=>{
                dispatch(setAdminFinanceChildsDataEdit({id: checkChild.id, matcap: false}))
                setLoading(false)
                closePopup()
            },
            rejected: (error)=>{
                setLoading(false)
                if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                else setError('Что-то пошло не так*')                      
            }
        })
        
    }

    return(
        <div className={`popup__container admin-popup__container program-edit`}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title'>
                    Мат.Капитал
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>

            <div className='popup__subtitle mb-24'>
                Вы действительно хотите отключить возможность оплаты мат.капиатлом для ребенка <span>{checkChild.name}</span>?
            </div>

            <div className='popup__error'>{error}</div>     

            <div className='d-flex'>
                <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={()=>closePopup('')}>Отменить</button>
                <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={closePaymentPostFunk}>Подтвердить</button>
            </div>       
        </div>
    )
}

export default FinanceCloseMatcapPayment;