// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teacher__about-edit-email-confirmation-code-input {
  width: 74px;
  height: 74px;
  padding: 18px;
  border-radius: 8px;
  border: 1px solid #E6E6E6;
  font-size: 24px;
  font-weight: 600;
  margin-right: 24px;
  display: block;
  text-align: center; }
  .teacher__about-edit-email-confirmation-code-input:last-child {
    margin-right: 0; }

.teacher__about-edit-email-confirmation-code-timer {
  width: 50px;
  display: inline-block;
  text-align: left;
  color: #1a1a1a;
  font-weight: 500; }
`, "",{"version":3,"sources":["webpack://./src/components/Teacher/About/EditEmail/index.scss"],"names":[],"mappings":"AACI;EACI,WAAW;EACX,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,kBAAkB,EAAA;EAVrB;IAYO,eAAe,EAAA;;AAIvB;EACI,WAAW;EACX,qBAAqB;EACrB,gBAAgB;EAChB,cAAc;EACd,gBAAgB,EAAA","sourcesContent":[".teacher__about-edit-email{\n    &-confirmation-code-input{\n        width: 74px;\n        height: 74px;\n        padding: 18px;\n        border-radius: 8px;\n        border: 1px solid #E6E6E6;\n        font-size: 24px;\n        font-weight: 600;\n        margin-right: 24px;\n        display: block;\n        text-align: center;\n        &:last-child{\n            margin-right: 0;\n        }\n    }\n\n    &-confirmation-code-timer{\n        width: 50px;\n        display: inline-block;\n        text-align: left;\n        color: #1a1a1a;\n        font-weight: 500;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
