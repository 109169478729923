import { useDispatch, useSelector } from 'react-redux';
import { setPopupBack } from '../../../../../redux/popup/popupSlice';

import './index.scss'
import HistoryListElem from '../HistoryListElem';
import { useEffect, useState } from 'react';
import { selectAdminFinancePaymentHistory } from '../../../../../redux/admin/finance/paymentsHistory/selectors';
import { fetchFinancePaymentHistoryData } from '../../../../../redux/admin/finance/paymentsHistory/asyncActions';
import { setAdminFinancePaymentHistoryScrollReset } from '../../../../../redux/admin/finance/paymentsHistory/paymentsHistorySlice';
import { scrollingPagination, useDebouncedValue, downloadFilePost, getDateFormatFuncServer, getDatePostFormatFunc, getDateFormatFunc } from './../../../../../services/clientHome';
import ExportButton from '../../../../Export';


const History = ({setPopupType, query, activeList, setActiveList}) =>{
    const dispatch = useDispatch();
    const { adminFinancePaymentHistoryData, adminFinancePaymentHistoryPageLast:pageLast, adminFinancePaymentHistoryScrollReset:scrollReset, adminFinancePaymentHistoryLoading:loading, adminFinancePaymentHistoryFilters:filters } = useSelector(selectAdminFinancePaymentHistory);
    const [page, setPage] = useState(0)
    const [exportXlsLoading, setExportXlsLoading] = useState(false)
    
    const searchText = useDebouncedValue(query, 600)

    useEffect(()=>{
        dispatch(fetchFinancePaymentHistoryData({query, count: 30, page: 0, pageLast, isScrolling: false, filters: filters.post, dates: filters.postDates}))
        setPage(0)
    }, [filters, searchText])

    const excelExportFunc = () =>{
        downloadFilePost({url:'/api/v1/administrative/finance/export-payments?', name:'Список оплат', type:'.xlsx', postData: {...filters.post, ...(Object.keys(filters.postDates).length? {from: getDatePostFormatFunc(getDateFormatFunc(filters.postDates.from)), to:  getDatePostFormatFunc(getDateFormatFunc(filters.postDates.to))}: {})}, setLoading:(result)=>setExportXlsLoading(result)})
    }
    
    return (
        <div className='admin__finance-container payments-history'>
            <div className='admin__finance-container-top d-flex justify-between align-center mb-16'>
                <div className='admin__finance-title'>История платежей</div>

                <div className='d-flex align-center'>
                    <button className='admin__finance-button d-flex align-center mr-8' onClick={()=>{
                        dispatch(setPopupBack('open'))
                        setPopupType('FinanceHistoryFilters')
                    }}>
                        <div className='admin__finance-button-label'>Фильтры</div>
                        <div className='admin__finance-button-icon filters'/>
                    </button>

                    <ExportButton name={'admin-finance-history-export'} activeList={activeList} setActiveList={setActiveList} 
                        elems={[
                                {
                                    name: '1С выгрузка',
                                    clickFunc: ()=>{
                                        dispatch(setPopupBack('open'))
                                        setPopupType('FinanceExport1C')
                                    }
                                }, 
                                {
                                    name: 'Excel - Список оплат',
                                    loading: exportXlsLoading,
                                    clickFunc: excelExportFunc
                                }
                        ]}
                    />

                </div>
            </div>

            <div className='admin__finance-table-line title d-flex justify-between'>
                <div className='admin__finance-page-history-table-line-item name'>ФИО</div>
                <div className='admin__finance-page-history-table-line-item date-summ'>Дата и сумма</div>
                <div className='admin__finance-page-history-table-line-item payment-type'>Способ оплаты</div>
            </div>

            <div className='admin__finance-table'   
                onScroll={(e)=>scrollingPagination(
                    e, 
                    ()=>setPage(page+1), 
                    ()=>dispatch(fetchFinancePaymentHistoryData({query, count: 30, page: page+1, isScrolling: true, filters: filters.post, dates: filters.postDates})),
                    pageLast,
                    loading,
                    scrollReset, 
                    ()=>dispatch(setAdminFinancePaymentHistoryScrollReset(false))
                )}
            >
                {
                    adminFinancePaymentHistoryData.map(item=><HistoryListElem item={item} key={`admin__finance-page-history-table-list-elem-conatiner-${item.id}`}/>)
                }
            </div>
        </div>
    )
}

export default History;