import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import './index.scss'
import { selectAdminAplicationConfirm } from '../../../../../redux/admin/aplications/aplicationConfirm/selectors';
import { axiosProxyCatchPost } from '../../../../../axiosProxy';
import { setAdminAplicationsDataDeleteElem } from '../../../../../redux/admin/aplications/aplications/aplicationsSlice';
import { fetchAdminUserEditedAplication } from '../../../../../redux/admin/aplications/aplicationConfirm/asyncActions';
import CurrentDataBlock from './CurrentDataBlock/index';
import EditDataBlock from './EditDataBlock/index';
import RefusalComment from './../../RefusalComment/index';
import { correctorName } from '../../../../../services/clientHome';

const EditUserConfirm = ({popupBack, closePopup}) =>{
    const dispatch = useDispatch();

    const { adminUserEditedAplication:checkData } = useSelector(selectAdminAplicationConfirm);

    const [commentIsActive, setCommentIsActive] = useState(false)

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [edited, setEdited] = useState(false)
    const [displayPass, setDisplayPass] = useState(false)
    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        email: '',
        phone: '',
    })

    useEffect(()=>{
        if(checkData.applicationId) {
            dispatch(fetchAdminUserEditedAplication({id: checkData.applicationId}))
        }
        else{
            setFormData({
                id: 0,
                name: '',
                email: '',
                phone: '',
            }) 
        }
           
    }, [popupBack])

    useEffect(()=>{
        console.log(checkData);
        if(checkData.current.id){
            setFormData(checkData.update)
        }
    }, [checkData])

    useEffect(()=>{
        let current = {...checkData.update}
        let update = {...formData, id:checkData.current.id}
        setEdited((JSON.stringify(current) === JSON.stringify(update)) ?false :true)
    }, [formData])

    const confirmUserFunc = (confirm, comment='', setCommentLoading=()=>{}) =>{
        setError('')
        let postData = {
            id: checkData.current.id,
            status: confirm? 1: -1,
        }
        if(!confirm && comment) postData.comment = comment;

        console.log(confirm, edited);

        if((confirm && !edited) || !confirm){
            setLoading(true)
            confirmUserPostFunc(postData, setCommentLoading)
        }
        else{
            if(correctorName(formData.name) !== '' && formData.email !== '' && formData.phone && formData.phone.length === 17 && formData.phone.indexOf('_') === -1){
                setLoading(true) 
                confirmUserPostFunc(
                    {
                        ...postData,
                        name: correctorName(formData.name),
                        email: formData.email,
                        phone: formData.phone,
                    }, 
                setCommentLoading)                      
            }
            else if(confirm && (checkData.current.name !== formData.name || checkData.current.email !== formData.email || checkData.current.phone !== formData.phone)) setError('Необходимо заполнить все поля*')
        }
        
    }

    const confirmUserPostFunc = (postData, setCommentLoading) =>{
        setCommentLoading(true)
        axiosProxyCatchPost({
            url: '/api/v1/administrative/applications/update-client-edit-status',
            data: postData,
            resolved: (data)=>{
                axiosProxyCatchPost({
                    url: '/api/v1/administrative/applications/update-application-status',
                    data: { ...postData, id: checkData.applicationId},
                    resolved: (data)=>{
                        dispatch(setAdminAplicationsDataDeleteElem(checkData.applicationId))
                        closePopup()
                        setLoading(false)
                        setCommentLoading(false)
                    },
                    rejected: (error)=>{
                        setLoading(false)
                        setCommentIsActive(false)
                        setCommentLoading(false)
                        if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                        else setError('Что-то пошло не так*')
                    }
                })
            },
            rejected: (error)=>{
                setLoading(false)
                setCommentIsActive(false)
                setCommentLoading(false)
                if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                else setError('Что-то пошло не так*')
            }
        })
    }

    const cancelChangesFunc = () =>{
        setFormData(checkData.update)
    }

    return(
        <div>
            <div className={`popup__container admin-popup__edit-data-confirm additionaly-popup ${commentIsActive? 'deactive': 'active'} d-flex justify-center`}>
                <div className='wd-100'>
                    <div className='d-flex justify-between align-center mb-24'>
                        <div className='wd-24'/>
                        <div className='popup__title'>
                            Профиль
                        </div>
                        <div className='popup__close' onClick={closePopup}/> 
                    </div>

                    <div className='d-flex justify-between h-maxcontent'>
                        <CurrentDataBlock data={checkData.current} displayPass={displayPass}/>
                        <div className='admin-popup__edit-data-confirm-line'/>
                        <EditDataBlock formData={formData} setFormData={setFormData} currentData={checkData.current} displayPass={displayPass} setDisplayPass={setDisplayPass}/>
                    </div>

                    <div className='popup__error'>{error}</div>

                    {
                        edited?
                        <div className='d-flex mt-24'>
                            <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={()=>cancelChangesFunc()}>Отменить изменения</button>
                            <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={()=>confirmUserFunc(true)}>Подтвердить с изменениями</button>
                        </div>

                        :<div className='d-flex mt-24'>
                            <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={()=>setCommentIsActive(true)}>Отклонить</button>
                            <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={()=>confirmUserFunc(true)}>Подтвердить</button>
                        </div>
                    }
                </div>
            </div>

            <RefusalComment setCommentClose={()=>setCommentIsActive(false)} classes={`additionaly-popup verification-comment ${commentIsActive? 'active': 'deactive'}`} clickFunc={(comment, setCommentLoading)=>confirmUserFunc(false, comment, setCommentLoading)}/>
        </div>
    )
}

export default EditUserConfirm;