import { createSlice } from '@reduxjs/toolkit';
import { fetchTeacherAboutData } from './asyncActions';
import { profileData, teachingData } from './../../../defaultData/teacher/about/about';


const initialState = {
  teacherProfileData: {
    id: 0,
    name: '',
    email: '',
    password: '',
    phone: '',

    // ...profileData
  },
  teacherProfileTeachingData: {
    city: {
      id: 0,
      name: ''
    },
    school: {
        id: 0,
        name: ''
    },
    classes: [],
    programs: []

    // ...teachingData
  }
};

const teacherProfile = createSlice({
  name: 'TeacherProfile',
  initialState,
  reducers: {
    setTeacherProfileDataEdit(state, action) {
      state.teacherProfileData = {...state.teacherProfileData, ...action.payload};
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTeacherAboutData.pending, (state, action) => {
      state.teacherProfileData = {
        id: 0,
        name: '',
        email: '',
        password: '',
        phone: '',
      };

      state.teacherProfileTeachingData = {
        city: {
          id: 0,
          name: ''
        },
        school: {
            id: 0,
            name: ''
        },
        classes: [],
        programs: []
      };
    });
    builder.addCase(fetchTeacherAboutData.fulfilled, (state, action) => {            
      if(action.payload && action.payload.teacherData){
        if(action.payload.teacherData){
          state.teacherProfileData = action.payload.teacherData;
        }
        
        if(action.payload.teacherTeachingData){
          console.log(action.payload.teacherTeachingData);
          
          state.teacherProfileTeachingData = action.payload.teacherTeachingData;
        } 
      }
    });
    builder.addCase(fetchTeacherAboutData.rejected, (state, action) => {
    });
  },

});

const { actions, reducer } = teacherProfile;


export default reducer;

export const { 
  setTeacherProfileDataEdit,
} = actions;

