import './index.scss'
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { selectTeacherTabel } from './../../../redux/teacher/tabel/selectors';
import { getDateFormatFunc, getPageElems, getPagesCount, getPagesMass, getWordMonthDate } from './../../../services/clientHome';
// import Header from '../Header/Header';
import { setPopupBack } from '../../../redux/popup/popupSlice';
import TabelBlock from './../../Admin/Tabel/Tabel/TabelBlock/index';
import DefaultTabel from './../../Admin/Tabel/Tabel/DefaultTabel/DefaultTabel';
import PagesBlock from './../../Admin/Tabel/Tabel/PagesBlock/index';
import TabelCalendar from './../Popup/Tabel/Calendar/index';
import TeacherPopup from './../Popup/TeacherPopup';
import DeleteBlock from './../Popup/DeleteBlock/DeleteBlock';
import WarningBlock from './../Popup/WarningBlock/WarningBlock';
import { MonthYear, TabelChildClass, SubGroup, Programs } from './../../Filters/FiltersList';
import { setTeacherTabelDataReplace, setTeacherTabelFilters } from '../../../redux/teacher/tabel/tabelSlice';
import ConfirmCancel from './../Popup/ConfirmCancel/ConfirmCancelBlock';
import { fetchTeacherTabelData } from './../../../redux/teacher/tabel/asyncActions';
import { axiosProxyCatchPost } from './../../../axiosProxy';

const Tabel = () =>{
    const dispatch = useDispatch()

    const { 
        teacherTabelData,
        teacherTabelTeacherPassDates,
        teacherTabelIsClosed
    } = useSelector(selectTeacherTabel);

    const [tabelData, setTabelData] = useState(teacherTabelData)
    const [tabelTeacherPassDates, setTabelTeacherPassDates] = useState(teacherTabelTeacherPassDates)

    const [editingPassDate, setEditingPassDate] = useState({
        date: '',
        isTeacherPass: false,
        comment: ''
    })

    const [calendDeleteDate, setCalendDeleteDate] = useState('')
    const [calendActive, setCalendActive] = useState(false)
    const [calendError, setCalendError] = useState('')
    const [tabelIsEdit, setTabelIsEdit] = useState(false)
    const [filtersIsEdit, setFiltersIsEdit] = useState(false)

    const [popupType, setPopupType] = useState('')
    const [error, setError] = useState('')
    const [filtersError, setFiltersError] = useState('')
    const [page, setPage] = useState(1)
    const [widthSize, setWidthSize] = useState(window.innerWidth)
    const [pages, setPages] = useState({pages: 1, elems: 3});
    const [newDate, setNewDate] = useState('');

    const [activeList, setActiveList] = useState('')

    const [filtersSaving, setFiltersSaving] = useState({
        classObj: {id: 0, number: '', letter: ''},
        classNumber: '',
        classLetter: '',
        subgroup: {id: 0, name: ''},
        program: {id: 0, name: '', teacherName: '', isContainsSubgroup: false}, 
        month: {id: 0, label: ''},
        year: new Date().getFullYear(),
        post: {},
    })
    const [filtersData, setFiltersData] = useState({
        classObj: {id: 0, number: '', letter: ''},
        classNumber: '',
        classLetter: '',
        subgroup: {id: 0, name: ''},
        program: {id: 0, name: '', teacherName: '', isContainsSubgroup: false}, 
        month: {id: 0, label: ''},
        year: new Date().getFullYear(),
        post: {},
    })

    const [calendPopupPosition, setCalendPopupPosition] = useState({
        top: 0,
        left: 0,        
        calendDate: '',
        date: '',
    })

    useEffect(()=>{
        setActiveList('')
        cleanFilters()
    }, [])


    useEffect(()=>{
        if(teacherTabelData && teacherTabelData.length && teacherTabelData[0].lessons && teacherTabelData[0].lessons.length){
            setPages(getPagesCount({dataLength: teacherTabelData[0].lessons.length, maxSize: widthSize, elemsSize: 140, minusVal: 724}))
        }
        else{
            setPages({...pages, elems: getPagesCount({dataLength: 1, maxSize: widthSize, elemsSize: 140, minusVal: 724}).elems})
        }              
    }, [teacherTabelData])

    useEffect(()=>{
        if(!calendActive){
            editDateFunc()
        }
    }, [calendActive])

    useEffect(()=>{
        if(JSON.stringify({...filtersData, post:''}) !== JSON.stringify({...filtersSaving, post:''})){
            setFiltersIsEdit(true)
        }
        else{
            setFiltersIsEdit(false)
        }
    }, [filtersData])

    useEffect(()=>{
        if(tabelData && tabelData.length && tabelData[0].lessons && tabelData[0].lessons.length){
            let resultPages = getPagesCount({dataLength: tabelData[0].lessons.length, maxSize: widthSize, elemsSize: 140, minusVal: 724})
            setPages(resultPages)
            if(page > resultPages.pages) setPage(resultPages.pages)
        }
        else{
            setPages({pages: 1, elems: 3})
        }
        
    }, [widthSize])

    useEffect(() => {
        const resizeHandler = () =>setWidthSize(window.innerWidth);
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, []);

    useEffect(()=>{
        setTabelData(teacherTabelData)
        setTabelTeacherPassDates(teacherTabelTeacherPassDates)        
    }, [teacherTabelData])

    useEffect(()=>{
        if(JSON.stringify({tabel:tabelData, passDates:tabelTeacherPassDates}) !== JSON.stringify({tabel:teacherTabelData, passDates:teacherTabelTeacherPassDates}))
            setTabelIsEdit(true)
        else
            setTabelIsEdit(false)            
    }, [tabelData, tabelTeacherPassDates])

    const popupOpenCalendFunc = (elem, date) => {
        setCalendError('')
        if(!calendActive || calendActive && date !== calendPopupPosition.date){
            let passDates = tabelTeacherPassDates.filter(item=> item.date === date);
            setEditingPassDate((passDates.length)? passDates[0]: {date: date, isTeacherPass: false, comment: ''})

            const position = elem.getBoundingClientRect()
            let x = position.left + 40;
            let y = position.top + 60;
            if(window.innerWidth < x + 466){
                x -= (window.innerWidth - (x + 466)) * -1;
            }
            if(window.innerHeight < y + 440){
                y -= (window.innerHeight - (y + 440 + 64 + 30)) * -1;
                x += 400 
            }
            setCalendActive(true)            
            setCalendPopupPosition({
                date: date,
                calendDate: getDateFormatFunc(date),
                top: y, 
                left: x,
            })
        }
        else{
            setCalendActive(false)
            setCalendPopupPosition({
                ...calendPopupPosition,
                date: ''
            })
        }
    }

    const editDateFunc = () =>{
        setCalendError('')
        if(calendPopupPosition.calendDate && calendPopupPosition.date !== calendPopupPosition.calendDate.toLocaleDateString()){
            if(tabelTeacherPassDates.filter(item=> item.date === calendPopupPosition.date).length){
                setTabelTeacherPassDates(tabelTeacherPassDates.map(item=>{
                    if(item.date === calendPopupPosition.date){
                        if(editingPassDate.date === item.date)
                            return{...editingPassDate, date: calendPopupPosition.calendDate.toLocaleDateString(), comment: editingPassDate.isTeacherPass? editingPassDate.comment: ''}
                        else
                            return{...item, date: calendPopupPosition.calendDate.toLocaleDateString()}
                    }
                    else return item
                }))
            }
            else if(!tabelTeacherPassDates.filter(item=> item.date === editingPassDate.date).length && editingPassDate.isTeacherPass){
                setTabelTeacherPassDates([...tabelTeacherPassDates, {...editingPassDate, date:calendPopupPosition.calendDate.toLocaleDateString(), newElem: true}])
            }

            let resultData = tabelData.map(item=>{
                let lessons = item.lessons.map(lesson=>{
                    if(lesson.date === calendPopupPosition.date)
                        return {...lesson, date: calendPopupPosition.calendDate.toLocaleDateString()}
                    else return lesson
                }).sort((a, b) => {
                    return getDateFormatFunc(a.date) - getDateFormatFunc(b.date);
                })

                return {...item, lessons}
            })
            
            let editElemPage = getPagesCount({
                dataLength: resultData[0].lessons.findIndex(item=> item.date === calendPopupPosition.calendDate.toLocaleDateString()) + 1, //editElemNumber
                maxSize: window.innerWidth, 
                elemsSize: 140, 
                minusVal: 724}
            ).pages;
            
            setPage(editElemPage)
            setTabelData(resultData)
            setCalendActive(false)

            setNewDate(calendPopupPosition.calendDate.toLocaleDateString())
            setTimeout(()=>{
                setNewDate('')
            }, 2000)
        }
        else if(tabelTeacherPassDates.filter(item=> item.date === editingPassDate.date).length){
            setTabelTeacherPassDates(tabelTeacherPassDates.map(item=>{
                if(editingPassDate.date === item.date)
                    return {...item, ...editingPassDate, comment: editingPassDate.isTeacherPass? editingPassDate.comment: ''}
                else
                    return item
            }))
        }
        else if(!tabelTeacherPassDates.filter(item=> item.date === editingPassDate.date).length && editingPassDate.isTeacherPass){
            setTabelTeacherPassDates([...tabelTeacherPassDates, {...editingPassDate, newElem: true}])
        }
        setCalendPopupPosition({top: 0, left: 0, calendDate: '', date: '',})   
    }

    const getNewDate = () =>{
        let newDate;
        if(tabelData.length && tabelData[0].lessons && tabelData[0].lessons.length){
            let dates = tabelData[0].lessons.map(item=> item.date)
            let lastDate = getDateFormatFunc(dates.at(-1))
            newDate = new Date(lastDate)
            newDate.setDate(newDate.getDate() + 1)

            if(newDate.getMonth() !== lastDate.getMonth()){
                let ending = false;
                let result = new Date(lastDate);
                for(let i = 1; !ending; i++){
                    result.setDate(result.getDate() - 1)
                    if(dates.indexOf(result.toLocaleDateString()) === -1 && result.getMonth() === lastDate.getMonth()){
                        newDate = result;
                        ending=true;
                    }
                    else if(i === 31){
                        ending = true;
                        newDate = null
                    }
                }
            }
        }
        else{
            newDate = getWordMonthDate(filtersSaving.month.label)
            newDate = newDate? newDate.date: newDate
        }
        
        return newDate;
    }

    const setCalendPopupPositionFunc = (date) =>{
        setCalendError('')
        if(!tabelData[0].lessons.filter(item => item.date === date.toLocaleDateString()).length){
            setCalendPopupPosition({...calendPopupPosition, calendDate: date})
        }
        else setCalendError('Такая дата уже есть*')
    }

    const editLessonFunc = (studentId, date, lessonVal) =>{        
        setTabelData(tabelData.map(item=>{
            if(item.id === studentId){
                return {...item, lessons: item.lessons.map(lesson=>{
                    if(lesson.date === date){
                        return {...lesson, value: lessonVal}
                    }
                    else return lesson
                })}
            }
            else return item;
        }))
    }

    const deleteDateFunc = (date, setLoading) =>{
        if(tabelData && tabelData.length && tabelData[0].lessons && tabelData[0].lessons.length && (tabelData[0].lessons.length % pages.elems) === 1){
            if(page === pages.pages) setPage(page-1)
            setPages(getPagesCount({dataLength: tabelData[0].lessons.length-1, maxSize: window.innerWidth, elemsSize: 140, minusVal: 724}))
        }

        setTabelTeacherPassDates(tabelTeacherPassDates.map(item=>{
            if(item.date === date){
                return{...item, isTeacherPass: false, comment: ''}
            }
            else return item
        }))
        
        setTabelData(tabelData.map(item=>{
            let lessons = item.lessons.filter(lesson=> lesson.date !== date)
            return {...item, lessons}
        }))
        setCalendActive(false)
        setPopupType('')
        setLoading(false)        
    }

    const createNewDateFunc = () =>{
        let date = getNewDate();
        if(date){ 
            let resultData = [...tabelData];
            resultData = resultData.map(student=>{
                return {
                    ...student, 
                    lessons: [...(student.lessons? student.lessons: []), {date: date.toLocaleDateString(), value: ''}].sort((a, b) => {
                            return getDateFormatFunc(a.date) - getDateFormatFunc(b.date);
                    })
                }
            })

            setTabelData(resultData)

            let newElemPage = getPagesCount({
                dataLength: resultData[0].lessons.findIndex(item=> item.date === date.toLocaleDateString()) + 1, //newElemNumber
                maxSize: window.innerWidth, 
                elemsSize: 140, 
                minusVal: 724}
            ).pages;  

            
            if(tabelData && tabelData.length && tabelData[0].lessons && tabelData[0].lessons.length){
                if((tabelData[0].lessons.length+1) % pages.elems === 1){
                    setPages(getPagesCount({dataLength: tabelData[0].lessons.length+1, maxSize: window.innerWidth, elemsSize: 140, minusVal: 724}))
                }
                setPage(newElemPage)
            }        

            setNewDate(date.toLocaleDateString())
            setTimeout(()=>{
                setNewDate('')
            }, 2000)
        }
        else{
            setPopupType('Warning')
            setError('Нельзя создать занятие, достигнуто максимальное колличество занятий в этом месяце.')
        }
    }

    const saveFilters = () =>{
        setFiltersIsEdit(false)
        setFiltersError('')
        let filterActiveData = {
            classObj: filtersData.classObj,
            classNumber: filtersData.classObj.number,
            classLetter: filtersData.classObj.letter,
            subgroup: filtersData.subgroup,
            program: filtersData.program, 
            month: filtersData.month,
            year: filtersData.year,
        }
        let filtersPostData = {
            classId: filtersData.classObj.id,
            programId: filtersData.program.id,
            month: filtersData.month.id,
            year: filtersData.year,
        }

        if(filtersData.program.isContainsSubgroup) filtersPostData.groupId = filtersData.subgroup.id;

        if(filtersData.classObj.number && filtersData.classObj.letter && filtersData.program.id && (!filtersData.program.isContainsSubgroup || filtersData.subgroup.id) && filtersData.month.id && filtersData.year){
            // dispatch(setTeacherTabelFilters({...filterActiveData, post: filtersPostData}))
            setFiltersSaving({...filterActiveData, post: filtersPostData})
            if(Object.keys(filtersPostData).length >= 4) dispatch(fetchTeacherTabelData({filters: filtersPostData}))
        }
        else{
            setFiltersError('Необходимо заполнить все поля*')
        }
    }

    const cleanFilters = () =>{
        setFiltersIsEdit(false)
        // dispatch(setTeacherTabelFilters({
        //     classObj: {id: 0, number: '', letter: ''},
        //     subgroup: {id: 0, name: ''},
        //     program: {id: 0, name: '', isContainsSubgroup: false}, 
        //     month: {id: 0, label: ''},
        //     year: new Date().getFullYear(),
        //     post: {},
        // }))
        setFiltersData({
            classObj: {id: 0, number: '', letter: ''},
            classNumber: '',
            classLetter: '',
            subgroup: {id: 0, name: ''},
            program: {id: 0, name: '', isContainsSubgroup: false}, 
            month: {id: 0, label: ''},
            year: new Date().getFullYear(),
            post: {},
        })
        setFiltersSaving({
            classObj: {id: 0, number: '', letter: ''},
            classNumber: '',
            classLetter: '',
            subgroup: {id: 0, name: ''},
            program: {id: 0, name: '', isContainsSubgroup: false}, 
            month: {id: 0, label: ''},
            year: new Date().getFullYear(),
            post: {},
        })
        dispatch(setTeacherTabelDataReplace({tabel: [], passDates: []}))
    }

    const saveCancelTabelFunc = (setLoading, isSave) =>{
        if(isSave){
            axiosProxyCatchPost({
                url: '/api/v1/teaching/table/set-tabel',
                data: {...filtersSaving.post, data: JSON.stringify({tabel: tabelData, passDates: tabelTeacherPassDates})},
                resolved: () =>{
                    setLoading(false)
                    dispatch(setTeacherTabelDataReplace({tabel: tabelData, passDates: tabelTeacherPassDates}))
                    setPopupType('')
                    setTabelIsEdit(false)
                },
                rejected: (error) =>{
                    if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                    else setError('Что-то пошло не так*')
                    setLoading(false)
                    setPopupType('Warning')
                }
            })
        }
        else{
            setTabelData(teacherTabelData)
            setTabelTeacherPassDates(teacherTabelTeacherPassDates)
            setLoading(false)
            setPopupType('')
        }
    }

    return (
        <div className='teacher__tabel' onClick={(e)=>{
            if(calendActive && !e.target.classList.contains("admin__tabel-table-line-item-date") && e.target.closest(".admin__tabel-calendar-container") === null){
                setCalendActive(false)
                setCalendPopupPosition({...calendPopupPosition, dateActive: ''})                
            }
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__drop-list-checked-input') && !e.target.classList.contains('popup__drop-list-elem-checkbox') && !e.target.classList.contains('popup__drop-list-open')  && !e.target.classList.contains('popup__input')){
                setActiveList('')
            }
            if(filtersError)setFiltersError('')
        }}>
            <div className='teacher-wrap'>

                <div className='teacher__tabel-filters'>
                    <div className='d-flex align-center justify-between mb-16'>
                        <div className='teacher__tabel-filters-item-container d-flex align-center justify-between'>
                            <div className='wd-50-mr-16'>
                                <TabelChildClass 
                                    activeList={activeList} 
                                    setActiveList={setActiveList} 
                                    formData={filtersData} 
                                    setFormData={setFiltersData}                
                                    setError={setFiltersError}
                                    url='/api/v1/teaching/tabel'
                                    classes={''}
                                />
                            </div>
                            <div className='wd-50-mr-16'>
                                <MonthYear
                                    activeList={activeList} 
                                    setActiveList={setActiveList} 
                                    formData={filtersData} 
                                    setFormData={setFiltersData}                
                                    setError={setFiltersError}
                                    classes={''}
                                    labelIsDisable={true}
                                />
                            </div>
                        </div>
                        <button className={`teacher__tabel-filters-button apply ${(!(filtersData.classObj.number && filtersData.classObj.letter && filtersData.program.id && (!filtersData.program.isContainsSubgroup || filtersData.subgroup.id) && filtersData.month.id && filtersData.year) || !filtersIsEdit)? 'deactive-button': ''}`} onClick={saveFilters}>Применить</button>
                    </div>

                    <div className='d-flex align-center justify-between'>
                        <div className='teacher__tabel-filters-item-container d-flex align-center justify-between'>
                            <div className='teacher__tabel-filters-program'>
                                <Programs
                                    activeList={activeList} 
                                    setActiveList={setActiveList} 
                                    formData={filtersData} 
                                    setFormData={setFiltersData}
                                    setError={setFiltersError}
                                    url='/api/v1/teaching/tabel'
                                    labelIsDisable={true}
                                    classes={''}
                                    isTabel={true}
                                />
                            </div>
                            <div className='teacher__tabel-filters-subgroup'>
                                <SubGroup
                                    activeList={activeList} 
                                    setActiveList={setActiveList} 
                                    formData={filtersData} 
                                    setFormData={setFiltersData}
                                    setError={setFiltersError}
                                    url='/api/v1/teaching/tabel'
                                    labelIsDisable={true}
                                    classes={''}
                                />
                            </div>
                        </div>
                        <button className={`teacher__tabel-filters-button reset ${!(filtersData.classObj.number || filtersData.classObj.letter || filtersData.subgroup.id || filtersData.program.id || filtersData.month.id || filtersData.year !== filtersSaving.year)? 'deactive-button': ''}`} onClick={cleanFilters}>Сбросить</button>
                    </div>
                    <div className='teacher__tabel-filters-error'>{filtersError}</div>
                </div>       

                {
                    tabelData.length? <TabelBlock pagesIsActive={pages.pages > 1} isClosed={teacherTabelIsClosed} tabelTeacherPassDates={tabelTeacherPassDates} tabelIsEdit={tabelIsEdit} setPopupType={setPopupType} tabelData={tabelData} newDate={newDate} getLessonsPageFunc={(data)=>getPageElems({data, page: page, elemsCount: pages.elems})} dateActive={calendPopupPosition.date} editLessonFunc={editLessonFunc} dateClickFunc={popupOpenCalendFunc} createNewDateFunc={createNewDateFunc} classes={`teacher-tabel`}/> :<DefaultTabel classes={`teacher-tabel`}/>
                }

                {
                    (pages.pages > 1)? <PagesBlock activePage={page} pages={getPagesMass(pages.pages)} setPage={setPage}/>: null
                }               
            </div>

            <TabelCalendar checkDate={calendPopupPosition.calendDate? calendPopupPosition.calendDate: null} error={calendError} setCheckDate={setCalendPopupPositionFunc} deleteDateFunc={(date)=>{
                setPopupType('Delete')
                setCalendDeleteDate(date)
            }} passDate={editingPassDate} setPassDate={setEditingPassDate} classes={`calendar-not-neighboring-month-click calendar-not-month-navigation ${calendActive? 'active': 'deactive'}`} style={{top: calendPopupPosition.top, left: calendPopupPosition.left}}/>
            <TeacherPopup type={popupType} props={{typeEdit: setPopupType}}/>
            <DeleteBlock popupBack={(popupType === 'Delete')? 'open': ''} props={{title: 'Удаление', subtitle:<>Вы действительно хотите удалить занятие?</>, closePopup: setPopupType, clickFunk:(setLoading)=>deleteDateFunc(calendDeleteDate, setLoading)}}/>
            <ConfirmCancel popupBack={(popupType === 'Confirm')? 'open': ''}  props={{title: 'Подтверждение', subtitle:<>Вы действительно хотите подтвердить изменения табеля?</>, closePopup: setPopupType, clickFunk:(setLoading)=>saveCancelTabelFunc(setLoading, true)}}/>
            <ConfirmCancel popupBack={(popupType === 'Cancel')? 'open': ''} props={{title: 'Отмена', subtitle:<>Вы действительно хотите отменить изменения табеля?</>, closePopup: setPopupType, clickFunk:(setLoading)=>saveCancelTabelFunc(setLoading, false)}}/>
            <WarningBlock popupBack={(popupType === 'Warning')? 'open': ''} props={{title:'Ошибка', subtitle: error, closePopup: setPopupType}}/>
        </div>
    )
}

export default Tabel;