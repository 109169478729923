import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChildBlock from './CildBlock';
import UserBlock from './UserBlock';
import RefusalComment from './../../RefusalComment';

import { selectAdminAplicationConfirm } from '../../../../../redux/admin/aplications/aplicationConfirm/selectors';
import { fetchAdminUserVerificationAplication } from '../../../../../redux/admin/aplications/aplicationConfirm/asyncActions';


import './index.scss'
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import { setAdminAplicationsDataDeleteElem } from '../../../../../redux/admin/aplications/aplications/aplicationsSlice';
import { checkIsEditingChild, getDateFormatFunc, getDateFormatFuncServer } from '../../../../../services/clientHome';
import { isCorrectDate, correctorName, getMonthName } from './../../../../../services/clientHome';




const UserVerification = ({closePopup, popupBack}) =>{
    const dispatch = useDispatch();

    const { adminUserVerificationAplication:checkData } = useSelector(selectAdminAplicationConfirm);

    const [activeList, setActiveList] = useState('')
    const [loading, setLoading] = useState(false)

    const [checkChildId, setCheckChildId] = useState(0)
    const [childEdited, setChildEdited] = useState([])
    const [commentIsActive, setCommentIsActive] = useState(false)
    const [error, setError] = useState('')

    const [userformData, setUserFormData] = useState({
        id: 0,
        name: '',
        email: '',
        password: '',
        phone: ''
    })

    const [childformData, setChildFormData] = useState({
        id: 0,
        name: '',
        birthDay: '',
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        classNumber: '',
        classLetter: '',
        classObj: {id: 0, number: '', letter: ''},
        programs: [],
        month: {id: 0, label: '', year: ''}////
    })

    const [childList, setChildList] = useState([])

    const [editUserDataFuncSuccessfully, setEditUserDataFuncSuccessfully] = useState(false)
    const [editChildDataFuncSuccessfully, setEditChildDataFuncSuccessfully] = useState(false)
    const [editPostDataFuncSuccessfully, setEditPostDataFuncSuccessfully] = useState({})

    useEffect(()=>{
        dispatch(fetchAdminUserVerificationAplication({id: checkData.id}))
        setChildEdited([])
        setActiveList('')
        setCheckChildId(0)
        setError('')
        setCommentIsActive(false)
    }, [popupBack])

    useEffect(()=>{
        if(checkData.id){
        // if(checkData.client && checkData.client.id){
            setUserFormData(checkData.client)
            setChildList(checkData.students)
        }
    }, [checkData])


    useEffect(()=>{
        if(checkChildId && checkIsEditingChild(childformData, childList.filter(item => item.id === checkChildId)[0])){
            if(childEdited.filter(item => item.id === checkChildId).length){
                setChildEdited(childEdited.map(item => {
                    if(item.id === checkChildId){
                        return {...item, edited: true}
                    }
                    else{
                        return item
                    }
                }))
            }
            else{
                setChildEdited([...childEdited, {id: checkChildId, edited: true}])
            }
        }        
    }, [childformData])
    

    useEffect(()=>{
        if(checkChildId){
            let checkChildData = childList.filter(item=> item.id === checkChildId)[0];
            setChildFormData({...checkChildData, month: (checkChildData.month && checkChildData.month.id)? checkChildData.month: {id: checkChildData.startMonth, label: getMonthName(checkChildData.startMonth), year: new Date(checkChildData.startDate).getFullYear()}})
        }
        else{
            setChildFormData({
                id: 0,
                name: '',
                birthDay: '',
                city: {id: 0, name: ''},
                school: {id: 0, name: ''},
                classNumber: '',
                classLetter: '',
                classObj: {id: 0, number: '', letter: ''},
                programs: [],
                month: {id: 0, label: '', year: ''}////
            })
        }
    }, [checkChildId])

    const userIsEdit = () =>{        
        return (checkData.client.name !== userformData.name || checkData.client.email !== userformData.email || checkData.client.password !== userformData.password || checkData.client.phone !== userformData.phone)? true: false;
    }

    const cancelChanges = () =>{
        setChildEdited(childEdited.filter(item => item.id !== checkChildId))
        setUserFormData(checkData.client)
        setError('')
        if(checkChildId)setChildFormData({...checkData.students.filter(item=> item.id === checkChildId)[0], month: {id: checkData.students.filter(item=> item.id === checkChildId)[0].startMonth, label: getMonthName(checkData.students.filter(item=> item.id === checkChildId)[0].startMonth), year: new Date(checkData.students.filter(item=> item.id === checkChildId)[0].startDate).getFullYear()}})
    }

    useEffect(()=>{
        if(editUserDataFuncSuccessfully && editChildDataFuncSuccessfully){
            if(editUserDataFuncSuccessfully !== 'error' && editChildDataFuncSuccessfully !== 'error'){
                confirmUserPostFunc(editPostDataFuncSuccessfully)
            }
            else{
                setLoading(false)
                setError('Что-то пошло не так, повторите попытку*')
            }
        }
    }, [editUserDataFuncSuccessfully, editChildDataFuncSuccessfully])

    const confirmUserFunc = (confirm, comment='') =>{
        setError('')
        let postData = {
            id: checkData.client.id,
            status: confirm? 1: -1,
        }


        if(!confirm && comment) postData.comment = comment;

        if(userIsEdit() || childEdited.length){                        
            setEditChildDataFuncSuccessfully(!childEdited.length? true: false,)
            setEditUserDataFuncSuccessfully(!userIsEdit())     
        }

        setEditPostDataFuncSuccessfully(postData)
        


        if(!confirm || (!userIsEdit() && !childEdited.length)){
            setLoading(true)
            confirmUserPostFunc(postData)
        }
        else{
            if(userIsEdit()){                
                if(correctorName(userformData.name) !== '' && userformData.email !== '' && userformData.password && userformData.password.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/) && userformData.password.length >= 6 && (/[a-zA-Z]/.test(userformData.password)) && userformData.phone && userformData.phone.length === 17 && userformData.phone.indexOf('_') === -1){
                    setLoading(true)
                    let userPostData = {
                        id: userformData.id,
                        name: correctorName(userformData.name),
                        email: userformData.email,
                        password: userformData.password,
                        phone: userformData.phone,
                    }          
        
                    axiosProxyCatchPost({
                        url: '/api/v1/administrative/finance/update-client-profile',
                        data: userPostData,
                        resolved: (data)=>{
                            setEditUserDataFuncSuccessfully(true)
                        },
                        rejected: (error)=>{
                            setEditUserDataFuncSuccessfully('error')
                        }
                    })                       
                }
                else if(userformData.password.length < 6 || !(/[a-zA-Z]/.test(userformData.password))) setError('Пароль не должен быть короче 6 символов и должен содержать хотя бы одну букву латинского алфавита*')
                else if(!userformData.password.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/)) setError('Невалидный пароль*')
                else if(confirm && (checkData.client.name !== userformData.name || checkData.client.email !== userformData.email || checkData.client.password !== userformData.password || checkData.client.phone !== userformData.phone)) setError('Необходимо заполнить все поля*')
            }
            
    
            if(childEdited.length){
                setLoading(true)
                let editChildsId = childEdited.map(item => item.id);
                let studentPostData = childList.filter(item => editChildsId.indexOf(item.id) !== -1)
                studentPostData = studentPostData.map(item =>{
                    const {id, name, birthDay, city, school, classObj, month} = item;
                    return {
                        id, 
                        name: correctorName(name), 
                        birthDay: getDateFormatFuncServer(birthDay),
                        cityId: city.id,
                        schoolId: school.id,
                        classId: classObj.id,
                        startDate: getDateFormatFuncServer(new Date(month.year, month.id - 1, 1).toLocaleDateString()),
                    }
                })    
                axiosProxyCatchPost({
                    url: '/api/v1/administrative/finance/update-students-profile',
                    data: {data: studentPostData},
                    resolved: (data)=>{
                        setEditChildDataFuncSuccessfully(true)
                        setChildEdited([])                       
                    },
                    rejected: (error)=>{
                        setEditChildDataFuncSuccessfully('error')                        
                    }
                })  
            }
        }
        
    }

    const confirmUserPostFunc = (postData) =>{
        axiosProxyCatchPost({
            url: '/api/v1/administrative/applications/update-client-status',
            data: postData,
            resolved: (data)=>{
                axiosProxyCatchPost({
                    url: '/api/v1/administrative/applications/update-application-status',
                    data: { ...postData, id: checkData.id},
                    resolved: (data)=>{
                        dispatch(setAdminAplicationsDataDeleteElem(checkData.id))
                        setEditPostDataFuncSuccessfully({})
                        setEditChildDataFuncSuccessfully(false)
                        setEditUserDataFuncSuccessfully(false)
                        closePopup()
                        setLoading(false)
                    },
                    rejected: (error)=>{
                        setLoading(false)                        
                    }
                })
            },
            rejected: (error)=>{                
                setLoading(false)                
            }
        })
    }

    const editChild = () =>{
        setError('')
        let fieldVal = {}
        if(!((childformData.birthDay.length === 10 || childformData.birthDay.length === 11) && childformData.birthDay.indexOf('_') === -1 && isCorrectDate(childformData.birthDay)))
            fieldVal.birthDay = true;
        if(childformData.name !== '' && childformData.city.id && childformData.school.id && childformData.classLetter && childformData.classNumber && !Object.keys(fieldVal).length && (childformData.month && childformData.month.id)){
        // if(childformData.name !== '' && childformData.city.id && childformData.school.id && childformData.classLetter && childformData.classNumber && !Object.keys(fieldVal).length){
            setChildList(childList.map(item=>{
                if(item.id === checkChildId)
                    return childformData;
                else 
                    return item
            }))
            setChildEdited(childEdited.map(item =>{
                if(item.id === checkChildId)
                    return {...item, edited: false}
                else 
                    return item
            }))
            setCheckChildId(0)
        }
        else if(Object.keys(fieldVal).length){
            if(!error) setError('Некорректная дата рождения*')
        }
        else{
            if(!error) setError('Необходимо заполнить все поля*')
        }                    
    }

    return(
        <div>
            <div className={`admin-popup__application-user-verification-container additionaly-popup ${commentIsActive? 'deactive': 'active'} d-flex justify-center`} onClick={(e)=>{
                if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__button') && e.target.closest('.popup__drop-list-checked') === null){
                    setActiveList('')
                }
            }}>
                <UserBlock 
                    formData={userformData} 
                    setFormData={setUserFormData} 
                    error={error}
                    setError={setError} 
                    closePopup={closePopup} 
                    childList={childList}
                    checkChildId={checkChildId} 
                    setCheckChildId={setCheckChildId}
                    childEdited={childEdited.filter(item => item.edited === true).length}
                    cancelChanges={cancelChanges}
                    setCommentIsActive={()=>setCommentIsActive(true)}
                    postFunc={()=>confirmUserFunc(true)}
                    editChild={editChild}
                    loading={loading}
                />
                
                <ChildBlock 
                    formData={childformData} 
                    setFormData={setChildFormData} 
                    childBlockIsActive={checkChildId !== 0} 
                    setChildBlockIsActive={()=>setCheckChildId(0)} 
                    activeList={activeList} 
                    setActiveList={setActiveList}
                    childEdited={childEdited.length && childEdited.filter(item => item.id === checkChildId && item.edited === true).length !== 0}
                    setError={setError}
                />
            </div>
            <RefusalComment setCommentClose={()=>setCommentIsActive(false)} classes={`additionaly-popup verification-comment ${commentIsActive? 'active': 'deactive'}`} clickFunc={(comment)=>confirmUserFunc(false, comment)}/>
        </div>
    )
}

export default UserVerification;