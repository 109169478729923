// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-popup__current-changes-data-container {
  width: 1000px; }

.user-popup__current-changes-data-subtitle {
  font-size: 14px;
  font-weight: 500;
  padding-left: 16px;
  margin-bottom: 16px;
  color: #727272; }

.user-popup__current-changes-data-line {
  background: #E6E6E6;
  width: 1px; }

@media (max-width: 940px) {
  .user-popup__current-changes-data {
    width: 100%; }
    .user-popup__current-changes-data-container-item {
      flex-direction: column; }
    .user-popup__current-changes-data-line {
      width: 100%;
      height: 1px;
      margin: 28px 0 24px; } }

@media (max-width: 500px) {
  .user-popup__current-changes-data-line {
    margin: 18px 0 14px; } }
`, "",{"version":3,"sources":["webpack://./src/components/User/Popup/ProfileCurrentChages/index.scss"],"names":[],"mappings":"AACI;EACI,aAAa,EAAA;;AAGjB;EACI,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,cAAc,EAAA;;AAGlB;EACI,mBAAmB;EACnB,UAAU,EAAA;;AAIlB;EACI;IACI,WAAW,EAAA;IAEX;MACI,sBAAsB,EAAA;IAG1B;MACI,WAAW;MACX,WAAW;MACX,mBAAmB,EAAA,EACtB;;AAIT;EAEQ;IACI,mBAAmB,EAAA,EACtB","sourcesContent":[".user-popup__current-changes-data{\n    &-container{\n        width: 1000px;\n    }\n\n    &-subtitle{\n        font-size: 14px;\n        font-weight: 500;\n        padding-left: 16px;\n        margin-bottom: 16px;\n        color: #727272;\n    }\n\n    &-line{\n        background: #E6E6E6;\n        width: 1px;\n    }\n}\n\n@media (max-width: 940px){\n    .user-popup__current-changes-data{\n        width: 100%;\n\n        &-container-item{\n            flex-direction: column;\n        }\n    \n        &-line{\n            width: 100%;\n            height: 1px;\n            margin: 28px 0 24px;\n        }\n    }\n}\n\n@media (max-width: 500px){\n    .user-popup__current-changes-data{    \n        &-line{\n            margin: 18px 0 14px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
