import { createPortal } from 'react-dom';

import '../../../../main.scss'
import { useState } from 'react';


const WarningBlockContainer = ({popupBack, title, subtitle, closePopup, type, refundOverPaymentFunc, classes=''}) => {
    const ClosePopup = (event) =>{
        if(event.currentTarget === event.target){            
            closePopup('')
        }         
    }

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')


	return (
        <div className={`popup__back ${popupBack} ${classes}`}>
            <div className="popup__back-wrap d-flex justify-center align-center" onClick={(event)=> ClosePopup(event)}>
                <div className={`popup__container admin-popup__container termination-warning`}>
                    <div className='popup__title mb-24' onClick={closePopup}>
                        {title}
                    </div>
                    <div className='popup__subtitle mb-24'>
                        {subtitle}
                    </div>
                    <div className={`popup__error height-auto ${error? 'active': ''}`}>{error}</div>
                    {
                        (type === 'OverPayment')? 
                        <div className='popup__button-container d-flex justify-between'>
                            <button className={`popup__button wd-50-mr-16 cancel border ${loading? 'deactive-button': ''}`} onClick={()=>closePopup('')}>Закрыть</button>
                            <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={refundOverPaymentFunc}>Средства возвращены</button>
                        </div>
                        :<button className={`popup__button cancel border`} onClick={()=>closePopup('')}>Закрыть</button>
                    }
                </div>
            </div>
        </div>
	)
}

const TerminationDocumentWarningBlock = ({popupBack, props = null, classes}) =>{
    return createPortal(<WarningBlockContainer popupBack={popupBack} classes={classes} title={props.title} subtitle={props.subtitle} closePopup={props.closePopup} type={props.type} refundOverPaymentFunc={props.refundOverPaymentFunc? props.refundOverPaymentFunc: ()=>{}}/>, document.body)
}
export default TerminationDocumentWarningBlock;