import { useState, useEffect } from 'react';
import './index.scss'
import { selectDropDownListData } from '../../../redux/dropDownList/selectors';
import { useDispatch, useSelector } from 'react-redux';
import DropDownList from '../DropDownList';
import { setDropListName } from '../../../redux/dropDownList/dropDownListSlice';
import DocumentsContainer from './DocumentsContainer';
import AegrotatsContainer from './AegrotatContainer';
import { setUserPopupBack } from '../../../redux/users/popup/userPopupSlice';
import UserPopup from './../Popup/UserPopup';
import { selectUserDocuments } from './../../../redux/users/documents/selectors';
import { setUserAdditionallyDocumentsElemEdit, setUserAegrotatsScrollReset, setUserDocumentsElemEdit } from '../../../redux/users/documents/documentsSlice';
import { selectUserChildren } from './../../../redux/users/children/selectors';
import { fetchUserAegrotatsData, fetchUserDocumentsData } from '../../../redux/users/documents/asyncActions';
import { convertNumber, scrollingPagination } from '../../../services/clientHome';
import { axiosProxyCatchGet, axiosProxyCatchPost } from '../../../axiosProxy';
import { fetchDocumentsChildsData } from '../../../redux/users/children/asyncActions';
import { selectUserProfile } from './../../../redux/users/profile/selectors';
import { fetchUserProfileData } from './../../../redux/users/profile/asyncActions';
import { setUserNavBarActive } from '../../../redux/users/navigations/userNavigationsSlice';
import TerminationDocumentWarningBlock from '../Popup/TerminationDocumentWarningBlock/TerminationDocumentWarningBlock';



const Documents = () =>{
    const dispatch = useDispatch();

    const { listName } = useSelector(selectDropDownListData)
    const { documentsData, additionallyDocumentsData, aegrotatsData, loading, pageLast, scrollReset, selectionDocument, blockedDocuments } = useSelector(selectUserDocuments)
    const { profileData } = useSelector(selectUserProfile);


    const { documentsChildrenData, checkChild } = useSelector(selectUserChildren);    

    const [terminationWarning, setTerminationWarning] = useState({
        refundOverPaymentStudents: [],
        type: '',
        subtitle: '',
    })

    const [page, setPage] = useState(0)

    const [checkedChild, setCheckedChild] = useState((documentsChildrenData.length && documentsChildrenData[0].name)? {...documentsChildrenData[0], label: documentsChildrenData[0].name.split(' ')[1]}: {
        id: 0, 
        name: '',
        classObj: {id: 0, number: '', letter: ''}, 
        birthDay: '',
        city: {id: 0, name: ''}, 
        school: {id: 0, name: ''},
        recalculate: false
    })
    const [popupType, setPopupType] = useState('')

    const [agreementDateChecked, setAgreementDateChecked] = useState({
        id: 0,
        from: '',
        to: '',
        label: '',
        isFinish: false,
    })

    const childrenCheckedFunc = (item) =>{
        dispatch(setDropListName(''))
        setCheckedChild({...item, label: item.name.split(' ')[1]})
    }
    const agreementDateCheckedFunc = (item) =>{
        dispatch(setDropListName(''))
        setAgreementDateChecked({...item, label: `${new Date(item.from).toLocaleDateString()} - ${new Date(item.to).toLocaleDateString()}`})
    }

    const addAegrotatFunc = () =>{
        dispatch(setUserPopupBack('open'))
        setPopupType('AddAegrotat')
    }

    const sendDocumentsFunc = (file, docTypeId, setLoading) =>{
        
        let postData = {
            file: file.fileBytes,
            extension: file.extension,
            layoutId: file.id,
            studentId: agreementDateChecked.id? agreementDateChecked.id : checkedChild.id,
            // studentId: checkedChild.id,
        }
        // setLoading(true)
        axiosProxyCatchPost({
            url:'/api/v1/parental/document/create-document', 
            data: postData,
            resolved: (response) =>{
                setLoading(false)
                
                if(docTypeId === 1){                    
                    dispatch(setUserDocumentsElemEdit({
                        id: file.id,
                        documentId: response.id,
                        size: file.size, 
                        docType: file.extension, 
                        status: 0,
                        link: true
                        // documentLink: file.link,
                    }))
                }
                else if(docTypeId === 2){                    
                    dispatch(setUserAdditionallyDocumentsElemEdit({
                        id: file.id, 
                        documentId: response.id,
                        size: file.size, 
                        docType: file.extension, 
                        status: 0,
                        link: true
                        // documentLink: file.link,
                    }))
                }
            },
            rejected: (error) =>{
                setLoading(false)
            },
        })
        
    }

    const sendOrDownoloadTerminationDocumentFunc = ({isDownload, sendFunc, downloadFunc, status}) =>{
        if(typeof status !== 'number'){
            axiosProxyCatchGet({
                url: `/api/v1/parental/profile/get-finance-position?id=${agreementDateChecked.id? agreementDateChecked.id : checkedChild.id}`,
                // url: `/api/v1/parental/profile/get-finance-position?id=${checkedChild.id}`,
                resolved:({data:{data}})=>{                                        
                    if(data.summ === 0 || (data.summ > 0 && terminationWarning.refundOverPaymentStudents.indexOf(agreementDateChecked.id? agreementDateChecked.id : checkedChild.id) !== -1)){
                        if(isDownload) downloadFunc()
                        else sendFunc()
                    }
                    else if (data.summ > 0){
                        setTerminationWarning({
                            ...terminationWarning, 
                            type: 'OverPayment',
                            subtitle: `У Вас имеется переплата за обучение в размере ${convertNumber(data.summ)} руб. Пожалуйста, свяжитесь с администрацией для возвращения Ваших средств. После возврата средств нажмите на кнопку "Средства возвращены" и документ откроется.`
                        })
                    }
                    else if (data.summ < 0){
                        setTerminationWarning({
                            ...terminationWarning, 
                            type: 'Debt',
                            subtitle: `У Вас имеется задолженность по оплате в размере ${convertNumber(data.summ * -1)} руб. Пожалуйста, совершите оплату и документ станет доступным`
                        })
                    }
                },
                rejected: ()=>{
    
                }
            })
        }
        else{
            if(isDownload) downloadFunc()
            else sendFunc()            
        }
    }

    useEffect(()=>{        
        if(checkChild.id && documentsChildrenData.length > 1 && selectionDocument){            
            let checkChildElem = documentsChildrenData.filter(item=>(item.id === checkChild.id))
            setCheckedChild((checkChildElem.length)? {...checkChildElem[0], label: checkChildElem[0].name.split(' ')[1]}: {
                id: 0, 
                name: '',
                classObj: {id: 0, number: '', letter: ''}, 
                birthDay: '',
                city: {id: 0, name: ''}, 
                school: {id: 0, name: ''},
                recalculate: false,
            })
        }
        else{
            setCheckedChild((documentsChildrenData.length && documentsChildrenData[0].name)? {...documentsChildrenData[0], label: documentsChildrenData[0].name.split(' ')[1]}: {
                id: 0, 
                name: '',
                classObj: {id: 0, number: '', letter: ''}, 
                birthDay: '',
                city: {id: 0, name: ''}, 
                school: {id: 0, name: ''},
                recalculate: false,
            })
        }        
    }, [documentsChildrenData])

    useEffect(()=>{
        dispatch(fetchUserProfileData())            
        dispatch(fetchDocumentsChildsData())
        dispatch(setUserNavBarActive(1))
    }, [])

    useEffect(()=>{
        if(checkedChild.id && agreementDateChecked.id !== checkedChild.id){
            let data =(checkedChild.agreementsDates && checkedChild.agreementsDates.length)? checkedChild.agreementsDates.filter(item=> !item.isFinish): null            
            if(data) data = (data.length? data[0]: checkedChild.agreementsDates[checkedChild.agreementsDates.length - 1])
            else data = {id: 0, from: '', to: '', isFinish: false}
            setAgreementDateChecked({
                ...data,
                label: data.id? `${new Date(data.from).toLocaleDateString()} - ${new Date(data.to).toLocaleDateString()}`: ''
            })
            if(!data.id){
                dispatch(fetchUserDocumentsData({childId: checkedChild.id}))
                if(checkedChild.recalculate) dispatch(fetchUserAegrotatsData({count: 10, page: 0, isScrolling: false, childId: checkedChild.id}))
            }            
        }      
    }, [checkedChild])

    useEffect(()=>{        
        if(agreementDateChecked.id){ 
            dispatch(fetchUserDocumentsData({childId: agreementDateChecked.id}))
            if(checkedChild.recalculate) dispatch(fetchUserAegrotatsData({count: 10, page: 0, isScrolling: false, childId: agreementDateChecked.id}))
        }
    }, [agreementDateChecked])    

    return (
        <div className={`user-page__documents`}>
            <div className='user-page__top d-flex justify-between align-center flex-wrap'>
                <div className='user-page__title documnets-title'>Документы</div>
                {
                    window.innerWidth > 980?
                    <div className='user-page__documents-droplist-container d-flex align-center'>
                        {
                            (checkedChild.agreementsDates && checkedChild.agreementsDates.length)?
                            <DropDownList 
                                listData={checkedChild.agreementsDates.map(item=>{return {...item, label: `${new Date(item.from).toLocaleDateString()} - ${new Date(item.to).toLocaleDateString()}`}})} 
                                label='label' checkedElem={agreementDateChecked} 
                                clickFunk={(item)=>agreementDateCheckedFunc(item)} 
                                listName={'user-page__documents-agreements-dates-drop-list'} 
                                listIsOpen={listName} 
                                setListIsOpen={(name)=>dispatch(setDropListName(name))} 
                                classes='mr-16 wd-300 user-page__documents-agreements-dates-drop-list-block' 
                                labelText='Период обучения:' 
                                labelPosition='label-left' 
                                labelClasses='user-page__documents-agreements-dates-drop-list'
                            />:null
                        }
                        <DropDownList 
                            listData={documentsChildrenData.map(item=>{return {...item, label: item.name.split(' ')[1]}})} 
                            label='label' 
                            personName={true} 
                            checkedElem={checkedChild} 
                            clickFunk={(item)=>childrenCheckedFunc(item)} 
                            listName={'user-page__documents-children-drop-list'} 
                            listIsOpen={listName} 
                            setListIsOpen={(name)=>dispatch(setDropListName(name))}
                            classes='user-page__documents-childs' 
                        />
                    </div>
                    :<div className='user-page__documents-droplist-container mobile d-flex align-center flex-wrap'>
                        <DropDownList 
                            listData={documentsChildrenData.map(item=>{return {...item, label: item.name.split(' ')[1]}})} 
                            label='label' 
                            personName={true} 
                            checkedElem={checkedChild} 
                            clickFunk={(item)=>childrenCheckedFunc(item)} 
                            listName={'user-page__documents-children-drop-list'} 
                            listIsOpen={listName} 
                            setListIsOpen={(name)=>dispatch(setDropListName(name))}
                            classes='wd-50-mr-16 user-page__documents-childs-drop-list' 
                            labelText='Ребенок' 
                            labelPosition='label-top'
                        />

                        {
                            (checkedChild.agreementsDates && checkedChild.agreementsDates.length)?
                            <DropDownList 
                                listData={checkedChild.agreementsDates.map(item=>{return {...item, label: `${new Date(item.from).toLocaleDateString()} - ${new Date(item.to).toLocaleDateString()}`}})} 
                                label='label' checkedElem={agreementDateChecked} 
                                clickFunk={(item)=>agreementDateCheckedFunc(item)} 
                                listName={'user-page__documents-agreements-dates-drop-list'} 
                                listIsOpen={listName} 
                                setListIsOpen={(name)=>dispatch(setDropListName(name))} 
                                classes='wd-50-mr-16 user-page__documents-agreements-dates-drop-list-block' 
                                labelText='Период обучения' 
                                labelPosition='label-top'
                            />:null
                        }
                    </div>
                }
            </div>
            <DocumentsContainer keyVal='basic-documents' title='Основные' documents={documentsData} sendFunc={sendDocumentsFunc} checkedChild={checkedChild} docTypeId={1} childIsFinish={((agreementDateChecked.id && agreementDateChecked.isFinish) || (!agreementDateChecked.id && checkedChild.isFinish))? true: false}/>
            
            {
                (additionallyDocumentsData.length !== 0)?<DocumentsContainer terminationDocumentLoadFunc={sendOrDownoloadTerminationDocumentFunc} access={profileData.financeAccess} keyVal='additionally-documents' title='Дополнительные'  checkedChild={checkedChild} selectionDocument={selectionDocument} blockedDocuments={blockedDocuments} documents={additionallyDocumentsData} sendFunc={sendDocumentsFunc} docTypeId={2} childIsFinish={((agreementDateChecked.id && agreementDateChecked.isFinish) || (!agreementDateChecked.id && checkedChild.isFinish))? true: false}/>
                : null
            }

            {
                (checkedChild.recalculate)? 
                <AegrotatsContainer 
                    keyVal='aegrotats' 
                    title='Справки о болезни' 
                    aegrotats={aegrotatsData} 
                    sendFunc={addAegrotatFunc}
                    pageLast={pageLast}
                    onScrollFunc={
                        (e)=>scrollingPagination(
                            e, 
                            ()=>setPage(page+1), 
                            ()=>dispatch(fetchUserAegrotatsData({count: 10, page: page+1, isScrolling: true, childId: checkedChild})), 
                            pageLast,
                            loading,
                            scrollReset, 
                            ()=>dispatch(setUserAegrotatsScrollReset(false))
                        )
                    }
                    childIsFinish={(agreementDateChecked.id && agreementDateChecked.isFinish) || (!agreementDateChecked.id && checkedChild.isFinish)}
                />: null
            }
            
            <UserPopup type={popupType} props={{typeEdit: setPopupType, checkedChild: checkedChild}}/>
            <TerminationDocumentWarningBlock 
                popupBack={(terminationWarning.type === 'OverPayment')? 'open': ''} 
                classes={'user-popup__back'}
                props={
                    {
                        title:'Переплата', 
                        subtitle: terminationWarning.subtitle, 
                        closePopup: (val)=>setTerminationWarning({...terminationWarning, type: val}),
                        refundOverPaymentFunc: () => setTerminationWarning({...terminationWarning, type: '', refundOverPaymentStudents: [...terminationWarning.refundOverPaymentStudents, agreementDateChecked.id? agreementDateChecked.id : checkedChild.id]}),
                        type: 'OverPayment'
                    }
                }
            />
            <TerminationDocumentWarningBlock 
                popupBack={(terminationWarning.type === 'Debt')? 'open': ''}
                classes={'user-popup__back'}
                props={
                    {
                        title: 'Задолженность', 
                        subtitle: terminationWarning.subtitle, closePopup: (val)=>setTerminationWarning({...terminationWarning, type: val}),
                        type: 'Debt'
                    }
                }
            />
        </div>
    )
}

export default Documents;