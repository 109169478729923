// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-page__header {
  height: 120px;
  width: 100%;
  padding: 16px 0; }
  .user-page__header-logo {
    width: 113px;
    height: 56px;
    margin-right: 25px; }

@media (max-width: 940px) {
  .user-page__header {
    padding: 10px 0;
    height: 70px;
    position: relative;
    z-index: 20;
    background-color: #F5F5F5;
    box-shadow: 0px 19px 30px -39px rgba(34, 60, 80, 0.6);
    border-bottom: 1px solid #E5E5E5;
    height: 80px; }
    .user-page__header-profile-block {
      margin-top: 14px; } }

@media (max-width: 480px) {
  .user-page__header-logo {
    width: 90px;
    height: 36px; }
  .user-page__header-profile-block {
    margin-top: 8px; }
    .user-page__header-profile-block .header-profile-photo {
      margin-right: 12px; }
    .user-page__header-profile-block .header-profile-arrow {
      margin-left: 0px; }
    .user-page__header-profile-block .header-profile-user-name {
      display: none; } }
`, "",{"version":3,"sources":["webpack://./src/components/User/Header/index.scss","webpack://./src/components/User/Header/adaptive.scss"],"names":[],"mappings":"AACA;EACI,aAAa;EACb,WAAW;EACX,eAAe,EAAA;EAEf;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB,EAAA;;ACT1B;EACI;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,WAAW;IAEX,yBAAyB;IACzB,qDAAqD;IACrD,gCAAgC;IAChC,YAAY,EAAA;IAEZ;MACI,gBAAgB,EAAA,EACnB;;AAWT;EAEQ;IACI,WAAW;IACX,YAAY,EAAA;EAGhB;IACI,eAAe,EAAA;IADlB;MAGO,kBAAkB,EAAA;IAHzB;MAMO,gBAAgB,EAAA;IANvB;MASO,aAAa,EAAA,EAChB","sourcesContent":["\n.user-page__header{\n    height: 120px;\n    width: 100%;\n    padding: 16px 0;\n    \n    &-logo{\n        width: 113px;\n        height: 56px;\n        margin-right: 25px;\n    }\n}\n\n@import './adaptive.scss';\n","@media (max-width: 940px){\n    .user-page__header{\n        padding: 10px 0;\n        height: 70px;\n        position: relative;\n        z-index: 20;\n\n        background-color: #F5F5F5;\n        box-shadow: 0px 19px 30px -39px rgba(34, 60, 80, 0.6);\n        border-bottom: 1px solid #E5E5E5;\n        height: 80px;\n\n        &-profile-block{\n            margin-top: 14px;\n        }\n    }\n\n    // body:has(.user-popup__back.open) .user-page__header{\n    //     background-color: #F5F5F5;\n    //     box-shadow: 0px 19px 30px -39px rgba(34, 60, 80, 0.6);\n    //     border-bottom: 1px solid #E5E5E5;\n    //     height: 90px;\n    // }\n}\n\n@media (max-width: 480px){\n    .user-page__header{    \n        &-logo{\n            width: 90px;\n            height: 36px;\n        }\n\n        &-profile-block{\n            margin-top: 8px;\n            & .header-profile-photo{\n                margin-right: 12px;\n            }\n            & .header-profile-arrow{\n                margin-left: 0px;\n            }\n            & .header-profile-user-name{\n                display: none;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
