// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../img/email-edit-purple-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-popup__container.edit-email {
  width: 540px; }

.user-popup__edit-profile-email-container {
  width: 100%;
  position: relative;
  cursor: pointer; }

.user-popup__edit-profile-email-input {
  padding-right: 50px !important; }

.user-popup__edit-profile-email-edit-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  top: calc(50% - 15px);
  right: 14px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
  background-size: 28px; }

@media (max-width: 940px) {
  .user-popup__container.edit-email {
    width: 100%; }
  .user-popup__edit-profile-email-input {
    padding-right: 34px !important; }
  .user-popup__edit-profile-email-edit-icon {
    width: 24px;
    height: 24px;
    top: calc(50% - 12px);
    right: 12px;
    background-size: contain; } }
`, "",{"version":3,"sources":["webpack://./src/components/User/Popup/EditEmail/index.scss"],"names":[],"mappings":"AACI;EACI,YAAY,EAAA;;AAIZ;EACI,WAAW;EACX,kBAAkB;EAClB,eAAe,EAAA;;AAGnB;EACI,8BAA6B,EAAA;;AAGjC;EACI,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,qBAAqB;EACrB,WAAW;EACX,oEAA4E;EAC5E,qBAAqB,EAAA;;AAKjC;EAEQ;IACI,WAAW,EAAA;EAIX;IACI,8BAA6B,EAAA;EAGjC;IACI,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,WAAW;IACX,wBAAwB,EAAA,EAC3B","sourcesContent":[".user-popup{\n    &__container.edit-email{\n        width: 540px;\n    }\n\n    &__edit-profile-email{\n        &-container{\n            width: 100%;\n            position: relative;\n            cursor: pointer;\n        }\n\n        &-input{\n            padding-right: 50px!important;\n        }\n\n        &-edit-icon{\n            width: 30px;\n            height: 30px;\n            position: absolute;\n            cursor: pointer;\n            top: calc(50% - 15px);\n            right: 14px;\n            background: url(../../../../img/email-edit-purple-icon.svg) no-repeat center;\n            background-size: 28px;\n        }\n    }\n}\n\n@media (max-width: 940px){\n    .user-popup{ \n        &__container.edit-email{\n            width: 100%;\n        }\n\n        &__edit-profile-email{\n            &-input{\n                padding-right: 34px!important;\n            }\n    \n            &-edit-icon{\n                width: 24px;\n                height: 24px;\n                top: calc(50% - 12px);\n                right: 12px;\n                background-size: contain;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
