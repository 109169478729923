import './index.scss'
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUserProfile } from './../../../../redux/users/profile/selectors';


import PasswordIcon from '../../../../img/autorization-password-none-icon.svg'
import PasswordActiveIcon from '../../../../img/autorization-password-check-icon.svg'
import { axiosProxyCatchPost } from '../../../../axiosProxy';

const EditPassword = ({popupBack, closePopup}) =>{
    const { profileData } = useSelector(selectUserProfile);

    const [errorText, setErrorText] = useState('')
    const [loading, setLoading] = useState(false)
    const [displayOldPass, setDisplayOldPass] = useState(false)
    const [displayNewPass, setDisplayNewPass] = useState(false)
    const [editPasswordIsSuccessfully, setEditPasswordIsSuccessfully] = useState(false)
    const [passIsDontMatch, setPassIsDontMatch] = useState(false)
    const [formData, setFormData] = useState({
        oldPassword: '', 
        newPassword: '', 
        newPasswordConfirm: ''
    })    

    useEffect(()=>{
        if(popupBack){
            setFormData({
                oldPassword: '', 
                newPassword: '', 
                newPasswordConfirm: ''
            })
            setErrorText('')
            setDisplayOldPass(false)
            setDisplayNewPass(false)
            setEditPasswordIsSuccessfully(false)
            setPassIsDontMatch(false)
        }
    }, [popupBack])

    const editProfileFunc = () =>{
        setErrorText('')
        const {oldPassword, newPassword, newPasswordConfirm} = formData;
        let editData = {
            userName: profileData.email,
            currentPassword : oldPassword,
            password: newPassword
        };        

        if(oldPassword && newPassword && newPasswordConfirm){
            if(newPassword === newPasswordConfirm){
                if(oldPassword.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/) && oldPassword.length >= 6 && (/[a-zA-Z]/.test(oldPassword) && newPassword.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/) && newPassword.length >= 6 && (/[a-zA-Z]/.test(newPassword)))){
                    axiosProxyCatchPost({
                        url: 'api/v1/identity/ChangeUserPassword',
                        data: editData,
                        resolved: () =>{
                            setEditPasswordIsSuccessfully(true)
                        },
                        rejected: (error) =>{
                            if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setErrorText(error.data.detail)
                            else setErrorText('Что-то пошло не так*')
                            setLoading(false)
                        }
                    })
                }
                else if((oldPassword.length < 6 || !(/[a-zA-Z]/.test(oldPassword))) || (newPassword.length < 6 || !(/[a-zA-Z]/.test(newPassword)))) setErrorText('Пароль не должен быть короче 6 символов и должен содержать хотя бы одну букву латинского алфавита*')
                else if(!oldPassword.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/) || !newPassword.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/)) setErrorText('Невалидный пароль*')
            }
            else{
                setErrorText('Пароли не совпадают*')
                setPassIsDontMatch(true)
            }
        }
        else setErrorText('Необходимо заполнить все поля*')
    }

    return(
        <div className={`popup__container user-popup__container edit-profile`}>
            <div className='popup__title mb-16 fs-24' onClick={closePopup}>{(editPasswordIsSuccessfully)? 'Пароль успешно изменен': 'Изменить пароль'}</div>  

            {
                (editPasswordIsSuccessfully)?
                <div className='d-flex flex-column align-center'>
                    <div className='autorization__content-form-successfully-icon'/> 
                    <div className='popup__subtitle mb-24'>Вы успешно изменили пароль к аккаунту.</div>
                    <div className='d-flex wd-100'>
                        <button className={`popup__button cancel`} onClick={closePopup}>Закрыть</button>
                    </div>
                </div>
                :
                <>
                    <label htmlFor="" className='popup__label mb-4'>Старый пароль</label>
                    <div className="autorization__content-form-field d-flex align-center justify-between mb-16 h-54">
                        <input autoComplete="new-password" name='old-password' type={(displayOldPass?'text':'password')} value={formData.oldPassword} className="autorization__content-form-field-input" onInput={(event)=>{
                            if(!/[а-яА-я]/.test(event.target.value) || event.target.value === '') setFormData({...formData, oldPassword: event.target.value.replaceAll(' ', '')})
                        }}/>
                        <img src={(displayOldPass? PasswordActiveIcon: PasswordIcon)} alt="" className="autorization__content-form-field-icon password" onClick={()=>setDisplayOldPass(!displayOldPass)}/>
                    </div>
                    <div className='user-popup__underline'/>

                    <label htmlFor="" className='popup__label mb-4'>Новый пароль</label>
                    <div className={`autorization__content-form-field d-flex align-center justify-between mb-16 h-54 ${passIsDontMatch? 'input-field-empty' : ''}`}>
                        <input autoComplete="new-password" name='new-password' type={(displayNewPass?'text':'password')} value={formData.newPassword} className={`autorization__content-form-field-input`} onInput={(event)=>{
                            if(!/[а-яА-я]/.test(event.target.value) || event.target.value === '') setFormData({...formData, newPassword: event.target.value.replaceAll(' ', '')})
                        }}/>
                        <img src={(displayNewPass? PasswordActiveIcon: PasswordIcon)} alt="" className="autorization__content-form-field-icon password" onClick={()=>setDisplayNewPass(!displayNewPass)}/>
                    </div>

                    <label htmlFor="" className='popup__label mb-4'>Подтвердите новый пароль</label>
                    <div className={`autorization__content-form-field d-flex align-center justify-between mb-16 h-54 ${passIsDontMatch? 'input-field-empty' : ''}`}>
                        <input autoComplete="new-password" name='new-password-confirm' type={(displayNewPass?'text':'password')} value={formData.newPasswordConfirm} className={`autorization__content-form-field-input`} onInput={(event)=>{
                            if(!/[а-яА-я]/.test(event.target.value) || event.target.value === '') setFormData({...formData, newPasswordConfirm: event.target.value.replaceAll(' ', '')})
                        }}/>
                        <img src={(displayNewPass? PasswordActiveIcon: PasswordIcon)} alt="" className="autorization__content-form-field-icon password" onClick={()=>setDisplayNewPass(!displayNewPass)}/>
                    </div>

                    <div className={`popup__error height-auto ${errorText? 'active': ''}`}>{errorText}</div>
                    <div className='popup__button-container d-flex justify-between'>
                        <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={closePopup}>Закрыть</button>
                        <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={editProfileFunc}>Подтвердить</button>
                    </div>
                </>
            }
        </div>
    )
}

export default EditPassword;