import './index.scss'
import RefusalComment from './../../RefusalComment/index';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { selectAdminAplicationConfirm } from './../../../../../redux/admin/aplications/aplicationConfirm/selectors';
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import { checkIsEditingChild, correctorName, getDateFormatFuncServer, getMonthName, isCorrectDate } from '../../../../../services/clientHome';
import { setAdminAplicationsDataDeleteElem } from '../../../../../redux/admin/aplications/aplications/aplicationsSlice';
import { fetchAdminAddChildConfirmAplication } from './../../../../../redux/admin/aplications/aplicationConfirm/asyncActions';
import MaskedInput from '../../../../MaskedInput/MaskedInput';
import { ChildClass, City, PaginationMonth, School } from '../../../../Filters/FiltersList';


const IsReadOnlyActive = false;

const CreateChildConfirm = ({closePopup, popupBack}) =>{
    const dispatch = useDispatch()

    const { adminAddChildConfirmAplication:checkData } = useSelector(selectAdminAplicationConfirm);

    const [commentIsActive, setCommentIsActive] = useState(false)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [childEdited, setChildEdited] = useState(false)
    const [activeList, setActiveList] = useState(false)
    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        birthDay: '',
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        classNumber: '',
        classLetter: '',
        classObj: {id: 0, number: '', letter: ''},
        month: {id: 0, label: '', year: ''},
        discount: ''
    })

    const [searchData, setSearchData] = useState({
        city: '',
        school: ''
    })

    useEffect(()=>{
        setChildEdited(checkIsEditingChild(formData, checkData))
        setCommentIsActive(false)
    }, [formData])

    useEffect(()=>{
        dispatch(fetchAdminAddChildConfirmAplication({id: checkData.applicationId}))
    }, [popupBack])

    useEffect(()=>{
        if(checkData.id){
            setFormData({
                ...checkData, 
                month: (checkData.month && checkData.month.id)? 
                    checkData.month
                    :{id: checkData.startMonth, label: getMonthName(checkData.startMonth), year: new Date(checkData.startDate).getFullYear()},
                discount: '',
            })            
        }
        else{
            setFormData({
                id: 0,
                name: '',
                birthDay: '',
                city: {id: 0, name: ''},
                school: {id: 0, name: ''},
                classNumber: '',
                classLetter: '',
                classObj: {id: 0, number: '', letter: ''},
                programs: [],
                month: {id: 0, label: '', year: ''},
                discount: ''
            })
        }
    }, [checkData])

    const confirmUserFunc = (confirm, comment='', setCommentLoading=()=>{}) =>{
        setError('')
        let postData = {
            id: checkData.applicationId,
            status: confirm? 1: -1,
        }
        if(!confirm && comment) postData.comment = comment;


        if((confirm && !childEdited) || !confirm){
            setLoading(true)
            confirmUserPostFunc(postData, setCommentLoading)
        }
        else{
            let fieldVal = {}

            if(!((formData.birthDay.length === 10 || formData.birthDay.length === 11) && formData.birthDay.indexOf('_') === -1 && isCorrectDate(formData.birthDay)))
                fieldVal.birthDay = true;

            if(formData.name !== '' && formData.city.id && formData.school.id && formData.classLetter && formData.classNumber && !Object.keys(fieldVal).length){
                setLoading(true)
                axiosProxyCatchPost({
                    url: '/api/v1/administrative/finance/update-students-profile',
                    data:{data: [{
                        id: checkData.id, 
                        name: correctorName(formData.name), 
                        birthDay: getDateFormatFuncServer(formData.birthDay),
                        cityId: formData.city.id,
                        schoolId: formData.school.id,
                        classId: formData.classObj.id,
                        startDate: getDateFormatFuncServer(new Date(formData.month.year, formData.month.id - 1, 1).toLocaleDateString()),
                        sale: +formData.discount
                    }]},
                    resolved: (data)=>{
                        confirmUserPostFunc(postData, setCommentLoading)                       
                    },
                    rejected: (error)=>{
                        setLoading(false)
                        if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                        else setError('Что-то пошло не так*')
                    }
                })
            }
            else if(Object.keys(fieldVal).length){
                if(!error) setError('Некорректная дата рождения*')
            }
            else{
                if(!error) setError('Необходимо заполнить все поля*')
            }
        }
        
    }

    const confirmUserPostFunc = (postData, setCommentLoading) =>{
        setCommentLoading(true)
        axiosProxyCatchPost({
            url: '/api/v1/administrative/applications/studenr-verify',
            data: {...postData, studentId: checkData.id},
            resolved: (data)=>{
                axiosProxyCatchPost({
                    url: '/api/v1/administrative/applications/update-application-status',
                    data: { ...postData},
                    resolved: (data)=>{
                        dispatch(setAdminAplicationsDataDeleteElem(checkData.applicationId))
                        closePopup()
                        setLoading(false)
                        setCommentLoading(false)
                    },
                    rejected: (error)=>{
                        setLoading(false)
                        setCommentIsActive(false)
                        setCommentLoading(false)
                        if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                        else setError('Что-то пошло не так*')
                    }
                })
            },
            rejected: (error)=>{
                setLoading(false)
                setCommentIsActive(false)
                setCommentLoading(false)
                if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                else setError('Что-то пошло не так*')
            }
        })
    }

    const cancelChangesFunc = () =>{
        setFormData({
            ...checkData, 
            month: (checkData.month && checkData.month.id)? checkData.month: {id: checkData.startMonth, label: getMonthName(checkData.startMonth), year: new Date(checkData.startDate).getFullYear()},
            discount: ''
        })

    }

    return(
        <div>
            <div className={`popup__container additionaly-popup ${commentIsActive? 'deactive': 'active'} d-flex justify-center`} onClick={(e)=>{
                if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__button') && e.target.closest('.popup__drop-list-checked') === null){
                    setActiveList('')
                }
            }}>
                <div className='d-flex justify-between align-center mb-24'>
                    <div className='wd-24'/>
                    <div className='popup__title' onClick={()=>{
                        console.log(formData);                        
                        console.log(checkData);                        
                    }}>
                        Добавление ребенка
                    </div>
                    <div className='popup__close' onClick={closePopup}/>
                </div>
                
                <div className='admin-popup__application-create-child-scroll'>
                    <label className="popup__label mb-4">ФИО</label>
                    <input type={'text'} readOnly={IsReadOnlyActive} value={formData.name} className="popup__input mb-16" onInput={(e)=>{
                        setFormData({...formData, name: e.target.value})
                    }}/>

                    <label className="popup__label mb-4">Дата рождения</label>
                    <MaskedInput
                        className={`popup__input mb-16`}
                        mask="__.__.____"
                        replacement={{_ : /[0-9]/}}
                        showMask={true}
                        separate={true}
                        defaultValue={formData.birthDay}
                        name = 'endDate'
                        readOnly={IsReadOnlyActive}
                        placeholder=''
                        onChange={(e)=>{
                            setFormData({...formData, birthDay: e.target.value})
                        }}
                    />

                    <City
                        activeList={activeList} 
                        setActiveList={setActiveList} 
                        formData={formData} 
                        setFormData={setFormData} 
                        searchData={searchData} 
                        setSearchData={setSearchData}
                        setError={setError}
                        classes={`mb-16 ${IsReadOnlyActive? 'deactive-input': ''}`}
                        url='/api/v1/administrative/programs'
                    />

                    <School
                        activeList={activeList} 
                        setActiveList={setActiveList} 
                        formData={formData} 
                        setFormData={setFormData} 
                        searchData={searchData} 
                        setSearchData={setSearchData}
                        setError={setError}
                        classes={`mb-16 ${IsReadOnlyActive? 'deactive-input': ''}`}
                        url='/api/v1/administrative/programs'
                    />

                    <ChildClass 
                        activeList={activeList} 
                        setActiveList={setActiveList} 
                        formData={formData} 
                        setFormData={setFormData}
                        setSearchData={setSearchData}
                        setError={setError}
                        classes={`mb-16`}
                        url='/api/v1/administrative/programs'
                        openTopList={true}
                        // classes={''}
                    />

                    <PaginationMonth 
                        activeList={activeList} 
                        setActiveList={setActiveList} 
                        formData={formData} 
                        setFormData={setFormData}
                        setError={setError}
                        // classes={``}
                        classes={`mb-16`}
                        isLastBlock={true}
                        label={'Месяц начала занятий'}
                    />

                    <label className="popup__label mb-4">Скидка</label>
                    <div className={`admin-popup__application-discount-input-additionaly-container mb-16`}>
                        <input type="text" value={formData.discount === ''? formData.discount: +formData.discount} className={`admin-popup__application-discount-input-additionaly`} onChange={(e)=>{
                            if(e.target.value < 100 && e.target.value.match(/^[0-9]+$/) || e.target.value === '') setFormData({...formData, discount: e.target.value})
                        }}/>
                        <div className='admin-popup__application-discount-input-additionaly-val'>{formData.discount === ''? formData.discount: +formData.discount}{formData.discount? '%': ''}</div>
                    </div>

                </div>

                <div className='popup__error'>{error}</div>

                {
                    childEdited?
                    <div className='mt-24'>
                        <button className={`popup__button mb-16 save ${loading? 'deactive-button': ''}`} onClick={()=>confirmUserFunc(true)}>Подтвердить с изменениями</button>
                        <button className={`popup__button cancel ${loading? 'deactive-button': ''}`} onClick={()=>cancelChangesFunc()}>Отменить изменения</button>
                    </div>

                    :<div className='mt-24'>
                        <button className={`popup__button mb-16 save ${loading? 'deactive-button': ''}`} onClick={()=>confirmUserFunc(true)}>Подтвердить</button>
                        <button className={`popup__button cancel ${loading? 'deactive-button': ''}`} onClick={()=>setCommentIsActive(true)}>Отклонить</button>
                    </div>
                }
            </div>

            <RefusalComment setCommentClose={()=>setCommentIsActive(false)} classes={`additionaly-popup verification-comment ${commentIsActive? 'active': 'deactive'}`} clickFunc={(comment, setCommentLoading)=>confirmUserFunc(false, comment, setCommentLoading)}/>
        </div>
    )
}

export default CreateChildConfirm;