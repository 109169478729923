import './index.scss'
import { useEffect, useState } from 'react';
import MaskedInput from './../MaskedInput/MaskedInput';
import{ ChildClass, City, School } from './../Filters/FiltersList';
import { correctorName, isCorrectDate } from '../../services/clientHome';


const ChildBlock = ({formData, setFormData, addChildIsActive, setAddChildIsActive, activeList, setActiveList, childAddFunc, isEdit, editChild, checkChildId, setIsEditChild, loading}) =>{
    const [error, setError] = useState('') 

    const [searchData, setSearchData] = useState({
        city: '',
        school: ''
    })

    const addChild = (e) =>{
        e.preventDefault()
        let fieldVal = {}
        if(!(formData.birthDate.length === 11 && formData.birthDate.indexOf('_') === -1 && isCorrectDate(formData.birthDate)))
            fieldVal.birthDate = true;
        if(correctorName(formData.name) !== '' && formData.city.id && formData.school.id && formData.classLetter && formData.classNumber && !Object.keys(fieldVal).length){
            if(isEdit){
                editChild(formData)
                setIsEditChild(false)
            }
            else
                childAddFunc()
        }
        else if(!correctorName(formData.name)){
            setError('Некорректное имя ребенка*')
        }
        else if(Object.keys(fieldVal).length){
            setError('Некорректная дата рождения*')
        }
        else setError('Необходимо заполнить все поля*')
    }

    useEffect(()=>{
        setError('')
    }, [addChildIsActive])



    return(
        <div className={`autorization__content-form-registartion-child ${addChildIsActive? 'active': ''} ${activeList? 'visible': ''}`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__button') && e.target.closest('.popup__drop-list-checked') === null){
                setActiveList('')
                setSearchData({ city: '', school: ''})
            }
        }}>
            <form className={`autorization__content-form registartion-child`} onSubmit={addChild}>
                <div className="autorization__content-form-top-for-adaptive align-center justify-between" onClick={()=>setAddChildIsActive(false)}>
                    <div className='autorization__content-form-top-for-adaptive-back-button'/>
                    <div className="autorization__content-form-top-for-adaptive-title lnh-auto">Добавление ребенка</div>
                    <div className='autorization__content-form-top-for-adaptive-back-button none'/>
                </div>
                
                <div className='autorization__content-form-top'>
                    <div className='autorization__content-close-button' onClick={()=>setAddChildIsActive(false)}/>
                    <div className="autorization__content-form-title fs-24 lnh-auto">Добавление ребенка</div>
                </div>
                <div className="autorization__content-form-label">ФИО</div>
                <div className="autorization__content-form-field h-54 d-flex align-center justify-between mb-16">
                    <input type={'text'} value={formData.name} className="autorization__content-form-field-input pr-16" onInput={(event)=>{
                        if(event.target.value.match(/^[a-zA-ZА-Яа-я- ]+$/) || !event.target.value) setFormData({...formData, name: event.target.value})
                    }}/>
                </div>

                <div className="autorization__content-form-label">Дата рождения</div>
                <div className="autorization__content-form-field h-54 d-flex align-center justify-between mb-16">
                    <MaskedInput
                        className={`autorization__content-form-field-input pr-16`}
                        mask="__.__.____г"
                        replacement={{_ : /[0-9]/}}
                        showMask={true}
                        // separate={true}
                        defaultValue={formData.birthDate}
                        name = 'endDate'
                        readOnly={false}
                        placeholder=''
                        onChange={(event)=>{
                            setFormData({...formData, birthDate: event.target.value})
                        }}
                    />
                </div>

                <City 
                    activeList={activeList} 
                    setActiveList={setActiveList} 
                    formData={formData} 
                    setFormData={setFormData} 
                    searchData={searchData} 
                    setSearchData={setSearchData}
                    setError={setError}
                    isNotAuthorize={true}
                    classes={'autorization__content-form-popup mb-16'}
                />

                <School 
                    activeList={activeList} 
                    setActiveList={setActiveList} 
                    formData={formData} 
                    setFormData={setFormData} 
                    searchData={searchData} 
                    setSearchData={setSearchData}
                    setError={setError}
                    isNotAuthorize={true}
                    classes={'autorization__content-form-popup mb-16'}
                />

                <ChildClass 
                    activeList={activeList} 
                    setActiveList={setActiveList} 
                    formData={formData} 
                    setFormData={setFormData} 
                    searchData={searchData} 
                    setSearchData={setSearchData}
                    setError={setError}
                    isNotAuthorize={true}
                    classes={'autorization__content-form-popup'}
                />

                <div className='popup__error'>{error}</div>
                <button type="submit" className={`autorization__content-form-btn ${(!isEdit && checkChildId) || loading? 'deactive-button': ''}`}>{checkChildId? 'Сохранить изменения': 'Добавить ребенка'}</button>
            </form>
        </div>
    )
}

export default ChildBlock;