import './index.scss'
import { useState, useEffect } from 'react';
import MaskedInput from './../../../MaskedInput/MaskedInput';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserProfile } from './../../../../redux/users/profile/selectors';
import { setUserProfile } from '../../../../redux/users/profile/profileSlice';
import { axiosProxyCatchPost } from '../../../../axiosProxy';
import { correctorName } from './../../../../services/clientHome';

import PasswordIcon from '../../../../img/autorization-password-none-icon.svg'
import PasswordActiveIcon from '../../../../img/autorization-password-check-icon.svg'

const EditProfile = ({popupBack, closePopup}) =>{
    const dispatch = useDispatch()
    const { profileData } = useSelector(selectUserProfile);

    const [errorText, setErrorText] = useState('')
    const [loading, setLoading] = useState(false)
    const [displayPass, setDisplayPass] = useState(false)
    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        phone: '',
        email: ''
    })

    useEffect(()=>{
        if(profileData.id !== 0) setFormData(profileData)
        else setFormData({
            id: 0,
            name: '',
            phone: '',
            email: '',
            password: ''
        })

        setErrorText('')
    }, [popupBack])

    const editProfileFunc = () =>{
        setErrorText('')
        const {name, phone} = formData;
        let editData = {
            name: correctorName(name),
            phone,
            // email,
            // password,
        };        

        // if(correctorName(formData.name) && (formData.phone && formData.phone.length === 17 && formData.phone.indexOf('_') === -1) && formData.password && formData.password.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/) && formData.password.length >= 6 && (/[a-zA-Z]/.test(formData.password))){
        if(correctorName(formData.name) && (formData.phone && formData.phone.length === 17 && formData.phone.indexOf('_') === -1)){
        //     setLoading(true)
        //     axiosProxyCatchPost({
        //         url: '/api/v1/parental/profile/update-refused-client',
        //         data: editData,
        //         resolved: () =>{
        //             dispatch(setUserProfile({id, name, phone}))
        //             setLoading(false)
        //             closePopup()
        //         },
        //         rejected: (error) =>{
        //             if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setErrorText(error.data.detail)
        //             else setErrorText('Что-то пошло не так*')
        //             setLoading(false)
        //         }
        //     })
            
            editProfilePostFunc(editData)
        }
        // else if(formData.password.length < 6 || !(/[a-zA-Z]/.test(formData.password))) setErrorText('Пароль не должен быть короче 6 символов и должен содержать хотя бы одну букву латинского алфавита*')
        // else if(!formData.password.match(/^[a-zA-Z0-9!"@#$:;*()_-]+$/)) setErrorText('Невалидный пароль*')
        // else if(!(formData.name || formData.email || formData.password ||formData.phone)) setErrorText('Необходимо заполнить все поля*')
        else{
            setErrorText('Необходимо заполнить все поля*')
        }
    }

    const editProfilePostFunc = (editData) =>{
        const {id, name, phone, email, password} = formData;

        if(profileData.status === -1){
            axiosProxyCatchPost({
                url: '/api/v1/parental/profile/update-refused-client',
                data: editData,
                resolved: () =>{
                    dispatch(setUserProfile({id, name, phone, email, password}))
                    setLoading(false)
                    closePopup()
                },
                rejected: (error) =>{
                    if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setErrorText(error.data.detail)
                    else setErrorText('Что-то пошло не так*')
                    setLoading(false)
                }
            })
        }
        else{
            axiosProxyCatchPost({
                url: '/api/v1/parental/profile/update-client-application',
                data: editData,
                resolved: () =>{
                    dispatch(setUserProfile({id: id, isUpdate: true, update: {id, name, phone, email, password, status: 0}}))
                    setLoading(false)
                    closePopup()
                },
                rejected: (error) =>{
                    if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setErrorText(error.data.detail)
                    else setErrorText('Что-то пошло не так*')
                    setLoading(false)
                }
            })
        }
    }

    const profileIsEdit = (formData, checkData) =>{
        const {name, phone, email} = formData;
        // const {name, phone, email, password} = formData;

        let editData = {};
        
        if(formData.name !== checkData.name) editData.name = name;
        if(formData.phone !== checkData.phone) editData.phone = phone;
        if(formData.email !== checkData.email) editData.email = email;
        // if(formData.password !== checkData.password) editData.password = password;

        return Object.keys(editData).length
    }

    return(
        <div className={`popup__container user-popup__container edit-profile`}>
            <label htmlFor="" className='popup__label mb-4'>ФИО</label>
            <input name='' type="text" placeholder='' value={formData.name} className="popup__input mb-16" onChange={(e) =>{
                if(e.target.value.match(/^[a-zA-ZА-Яа-я- ]+$/) || !e.target.value) setFormData({...formData, name: e.target.value})
            }}/>
            
            <label htmlFor="" className='popup__label mb-4'>Телефон</label>
            <MaskedInput
                className={`popup__input mb-16`}
                mask="+7(___) ___-__-__"
                replacement={ { _ : /[0-9]/ }}
                defaultValue={formData.phone}
                name = 'phone'
                readOnly={false}
                placeholder=''
                showMask={true}
                separate={true}
                onChange={(event)=>{
                    setFormData({...formData, phone: event.target.value})
                }}
            />

            {/* <label htmlFor="" className='popup__label mb-4'>Пароль</label>
            <div className="autorization__content-form-field d-flex align-center justify-between mb-16 h-54">
                <input autoComplete="new-password" type={(displayPass?'text':'password')} value={formData.password} className="autorization__content-form-field-input" onInput={(event)=>{
                    if(!/[а-яА-я]/.test(event.target.value) || event.target.value === '') setFormData({...formData, password: event.target.value.replaceAll(' ', '')})
                }}/>
                <img src={(displayPass? PasswordActiveIcon: PasswordIcon)} alt="" className="autorization__content-form-field-icon password" onClick={()=>setDisplayPass(!displayPass)}/>
                <span></span>
            </div> */}

            <label htmlFor="" className='popup__label mb-4'>E-mail</label>
            <input name='' readOnly={true} type="mail" placeholder='' value={formData.email} className="popup__input popup__disable-input" onChange={(e) =>{
                if((e.target.value.match(/^[a-zA-Z0-9.@_-]+$/) && e.target.value.indexOf('..') === -1) || !e.target.value) setFormData({...formData, email: e.target.value})}
            }/>
            <div className='popup__error'>{errorText}</div>
            <div className='d-flex'>
                <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={closePopup}>Отменить</button>
                <button className={`popup__button wd-50-mr-16 save ${loading || !profileIsEdit(formData, profileData)? 'deactive-button': ''}`} onClick={editProfileFunc}>Сохранить</button>
            </div>
        </div>
    )
}

export default EditProfile;