const Child = ({item, profileStatus, clickFunk}) =>{
    const {id, name, classObj, birthDay, city, school, status, comment, isUpdate, update} = item.isUpdate? {...item, ...item.update}: item;

    return (
        <div className={`user-page__about-container child w50-m24 ${(status > 1 && status < 5)? 'border-purple': ''}`} key={`user-page__about-container-child-elem-${id}`}>
            <div className='d-flex justify-between about-profile-underline'>
                <div className='d-flex align-center'>
                    <div className='user-page__about-profile-name child'>{name}</div>
                </div>
                <div className='d-flex align-center ml-8'>
                    <div className='user-page__about-profile-btn cursor-def'>{classObj.number}{classObj.letter} класс</div>
                    {!isUpdate? null: <div className='user-page__about-profile-status' data-tooltip={(update.status === 0? 'Ожидает подтверждения изменений.':update.status === -1? 'Изменения были отклонены.': 'Изменения были приняты.') + (comment? `\nПричина: ${comment}`: '')}/>}
                </div>
            </div>

            <div className='user-page__underline'/>

            <div className='d-flex justify-between align-center mb-16'>
                <div className='user-page__field w50-m16 d-flex justify-between align-center'>
                    <div className='user-page__field-text'>{birthDay}г</div>
                    <div className='user-page__field-icon calendar'/>
                </div>
                <div className='user-page__field w50-m16 d-flex justify-between align-center'>
                    <div className='user-page__field-text'>{city.name}</div>
                    <div className='user-page__field-icon location'/>
                </div>
            </div>
            <div className='user-page__field d-flex justify-between align-center'>
                <div className='user-page__field-text'>{school.name}</div>
                <div className='user-page__field-icon scool'/>
            </div>

            <div className={`user-page__about-child-block-button back-purple ${(status === 0 && !isUpdate)? 'deactive-button': ''}`} onClick={()=>clickFunk(item)}>
            {/* <div className={`user-page__about-child-block-button ${profileStatus !== -1? 'deactive-button': ''}`} onClick={()=>clickFunk(item)}> */}
            {/* <div className={`user-page__about-child-block-button ${profileStatus === 0? 'deactive-button': ''}`} onClick={()=>clickFunk(item)}> */}
                {!isUpdate? 'Редактировать': 'Посмотреть изменения'}
            </div>
        </div>
    )
}

export default Child;