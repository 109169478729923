import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosProxy, { axiosProxyCatchGet } from '../../../../axiosProxy';


export const fetchAdminUserVerificationAplication = createAsyncThunk(
  'AdminAplicationConfirm/fetchAdminUserVerificationAplication',
  async (dataObj={id: 0}) => {
    try {
      const response = await axiosProxy.get(`/api/v1/administrative/applications/get-application-verify?`+ new URLSearchParams({applicationId: dataObj.id}))
      if(response.status < 400){
        return {userVerificationData: response.data};                      
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);

export const fetchAdminUserEditedAplication = createAsyncThunk(
  'AdminAplicationConfirm/fetchAdminUserEditedAplication',
  async (dataObj={id: 0}) => {
    try {
      const response = await axiosProxy.get(`/api/v1/administrative/applications/get-client-update?`+ new URLSearchParams({id: dataObj.id}))
      if(response.status < 400){
        return {userData: response.data};                      
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);

export const fetchAdminEditChildConfirmAplication = createAsyncThunk(
  'AdminAplicationConfirm/fetchAdminEditChildConfirmAplication',
  async (dataObj={id: 0}) => {
    try {
      const response = await axiosProxy.get(`/api/v1/administrative/applications/get-student-update?`+ new URLSearchParams({id: dataObj.id}))
      if(response.status < 400){
        return {childData: response.data};                      
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);

export const fetchAdminConectedProgramAplication = createAsyncThunk(
  'AdminAplicationConfirm/fetchAdminConectedProgramAplication',
  async (dataObj={id: 0}) => {
    try {
      const response = await axiosProxy.get(`/fetchAdminConectedProgramAplication?`+ new URLSearchParams({aplicationId: dataObj.id}))
      if(response.status < 400){
        return {programData: response.data.data};                      
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);

export const fetchAdminConfirmDocumentAplication = createAsyncThunk(
  'AdminAplicationConfirm/fetchAdminConfirmDocumentAplication',
  async (dataObj={id: 0}) => {
    try {
      const response = await axiosProxy.get(`/api/v1/administrative/applications/get-document?`+ new URLSearchParams({appId: dataObj.id}))
      if(response.status < 400){
        return {documentData: response.data};                      
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


export const fetchAdminConfirmCertificateAplication = createAsyncThunk(
  'AdminAplicationConfirm/fetchAdminConfirmCertificateAplication',
  async (dataObj={id: 0}) => {
    try {
      const response = await axiosProxy.get(`/api/v1/administrative/applications/get-certificate?`+ new URLSearchParams({id: dataObj.id}))
      if(response.status < 400){
        return {certificateData: response.data};                      
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


