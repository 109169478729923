import Timer from '../../../Filters/Timer';
import './index.scss'

const ConfirmEmail = ({formData, setFormData, errorText, loading, closePopup, requestConfirmationCodeFunc, date, setDate, setConfirmationCodeIsSent}) =>{    
    return(
        <div className='d-flex flex-column align-center'> 
            <div className='popup__subtitle mb-16 text-color-grey'>Пожалуйста, введите новую почту, которую хотите привязать к аккаунту, в поле "Email".</div>
            <input name='' type="mail" placeholder='Email' value={formData.email} className="popup__input mb-16" onChange={(e) =>{
                if((e.target.value.match(/^[a-zA-Z0-9.@_-]+$/) && e.target.value.indexOf('..') === -1) || !e.target.value) setFormData({...formData, email: e.target.value})}
            }/>
            {
                (date)?
                    <div className='popup__confirmation-code-subtitle mb-16'>
                        Повторная отправка кода будет доступна через <span className='popup__confirmation-code-timer'><Timer date={date} setDate={setDate}/></span>
                    </div>
                :null
            }
            
            <div className={`popup__error height-auto ${errorText? 'active': ''}`}>{errorText}</div>
            
            <div className='popup__button-container d-flex justify-between wd-100'>
                <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={closePopup}>Закрыть</button>
                <button className={`popup__button wd-50-mr-16 save ${(loading)? 'deactive-button': ''}`} onClick={date? ()=>setConfirmationCodeIsSent(true) :()=>requestConfirmationCodeFunc()}>{date? 'Ввести код': 'Отправить код'}</button>
            </div>
        </div>
    )
}

export default ConfirmEmail;