import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminConectedProgramAplication, fetchAdminConfirmCertificateAplication, fetchAdminConfirmDocumentAplication, fetchAdminUserEditedAplication, fetchAdminUserVerificationAplication, fetchAdminEditChildConfirmAplication } from './asyncActions';
import { getResponseDateFormatFunc } from './../../../../services/clientHome';
import { editChildConfirmAplication, editClientdConfirmAplication } from './../../../../defaultData/admin/aplications/aplicatonsConfirm';

const initialState = {
  adminUserVerificationAplication:{
    id: 0,
    client: {
      id: 0,
      name: '',
      email: '',
      password: '',
      phone: ''
    },
    students: [
    ]
  },

  // adminUserVerificationAplication:{
  //   id: 1,
  //   client: {
  //       id: 1,
  //       name: 'Name Name Name',
  //       email: 'ggggg@ggg.gg',
  //       password: '12345yyy',
  //       phone: '+7(928)685-12-34'
  //   },
  //   students: [
  //     {
  //       id: 1,
  //       name: 'Name Name Name',
  //       city: {id: 1, name: 'Москва'},
  //       school: {id: 1, name: 'Моская гимназия №11'},
  //       classObj: {id: 1, number: 7, letter: 'A'},
  //       classNumber: 7,
  //       classLetter: 'A',
  //       birthDay: '12.12.2010',
  //       programs: [{id: 12, name: 'Математика', color: 'blue'}],
  //       startDate: "2024-11-02T00:00:00Z",
  //       startMonth: 11
  //     },
  //     {
  //       id: 2,
  //       name: 'Name Name Name2',
  //       city: {id: 1, name: 'Москва'},
  //       school: {id: 1, name: 'Моская гимназия №11'},
  //       classObj: {id: 1, number: 7, letter: 'A'},
  //       classNumber: 7,
  //       classLetter: 'A',
  //       birthDay: '12.12.2010',
  //       programs: [{id: 12, name: 'Математика', color: 'blue'}],
  //       startDate: "2024-11-02T00:00:00Z",
  //       startMonth: 11
  //     },
  //   ]
  // },

  // adminUserEditedAplication:{
  //   applicationId: 0,
  //   id: 0,
  //   name: '',
  //   email: '',
  //   password: '',
  //   phone: '',

  //   update:{
  //     id: 0,
  //     name: '',
  //     email: '',
  //     password: '',
  //     phone: ''
  //   }
  // },


  adminUserEditedAplication:{
    applicationId: 0,
    
    current: {
      id: 0,
      name: '',
      email: '',
      password: '',
      phone: '',
    },

    update:{
      id: 0,
      name: '',
      email: '',
      password: '',
      phone: ''
    }
  },
  
  adminConectedProgramAplication:{
    applicationId: 0,
    id: 0,
    name: '',
    city: '',
    school: '',
    classNumber: '',
    classLetter: '',
    program: {
        id: 0,
        name: '',
        color: ''
    }
  },

  adminConfirmDocumentAplication:{
    id: 0,
    clientName: '',
    type: 0,
    document: {
        id: 0,
        name: '',
        date: '',
        status: null,
        link: '',
        extension: ''
    },
  },
  
  adminConfirmCertificateAplication:{
    id: 0,
    clientName: '',
    type: 0,
    document: {
        id: 0,
        startDate: '',
        endDate: '',
        date: '',
        status: null,
        link: ''
    },
  },

  adminEditChildConfirmAplication:{
    applicationId: 0,
    
    current: {
      id: 0,
      name: '',
      city: {id: 0, name: ''},
      school: {id: 0, name: ''},
      classObj: {id: 0, number: '', letter: ''},
      birthDay: '',
    },

    update: {
      id: 0,
      name: '',
      city: {id: 0, name: ''},
      school: {id: 0, name: ''},
      classObj: {id: 0, number: '', letter: ''},
      birthDay: '',
    }
  },

  // adminEditChildConfirmAplication:{
  //  ...editChildConfirmAplication
  // },

  


};

const adminAplicationConfirm = createSlice({
  name: 'AdminAplicationConfirm',
  initialState,
  reducers: {
    setAdminUserVerificationAplication(state, action) {
      state.adminUserVerificationAplication = {...state.adminUserVerificationAplication, ...action.payload};
    },

    setAdminUserEditedAplication(state, action) {      
      state.adminUserEditedAplication = {...state.adminUserEditedAplication, ...action.payload};
    },

    setAdminConectedProgramAplication(state, action) {
      state.adminConectedProgramAplication = {...state.adminConectedProgramAplication, ...action.payload};
    },

    setAdminConfirmDocumentAplication(state, action) {
      state.adminConfirmDocumentAplication = {...state.adminConfirmDocumentAplication, ...action.payload};
    },

    setAdminConfirmCertificateAplication(state, action) {
      state.adminConfirmCertificateAplication = {...state.adminConfirmCertificateAplication, ...action.payload};
    },

    setAdminEditChildConfirmAplication(state, action) {
      state.adminEditChildConfirmAplication = {...state.adminEditChildConfirmAplication, ...action.payload};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminUserVerificationAplication.pending, (state, action) => {
      state.adminUserVerificationAplication = {
        ...state.adminUserVerificationAplication,
        client: {
            id: 0,
            name: '',
            email: '',
            password: '',
            phone: ''
        },
        students: [
        ]
      }
    });
    builder.addCase(fetchAdminUserVerificationAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.userVerificationData){
        let students = action.payload.userVerificationData.students;
        if(students.length){
          students = students.map(item=>{
            return {
              ...item,
              birthDay: new Date(item.birthDay).toLocaleDateString(),
              classNumber: item.classObj.number,
              classLetter: item.classObj.letter,
            }
          })
        }
        state.adminUserVerificationAplication = {...state.adminUserVerificationAplication, ...action.payload.userVerificationData, students: students};
      }
    });
    builder.addCase(fetchAdminUserVerificationAplication.rejected, (state, action) => {
    });


    builder.addCase(fetchAdminUserEditedAplication.pending, (state, action) => {
      state.adminUserEditedAplication = {
        ...state.adminUserEditedAplication,
        current: {
          id: 0,
          name: '',
          email: '',
          password: '',
          phone: '',
        },    
        update:{
          id: 0,
          name: '',
          email: '',
          password: '',
          phone: ''
        }
      }
    });
    builder.addCase(fetchAdminUserEditedAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.userData) state.adminUserEditedAplication = {...state.adminUserEditedAplication, ...action.payload.userData};
    });
    builder.addCase(fetchAdminUserEditedAplication.rejected, (state, action) => {
    });


    builder.addCase(fetchAdminEditChildConfirmAplication.pending, (state, action) => {
      state.adminEditChildConfirmAplication = {
        ...state.adminEditChildConfirmAplication,
        current: {
          id: 0,
          name: '',
          city: {id: 0, name: ''},
          school: {id: 0, name: ''},
          classObj: {id: 0, number: '', letter: ''},
          birthDay: '',
        },
    
        update: {
          id: 0,
          name: '',
          city: {id: 0, name: ''},
          school: {id: 0, name: ''},
          classObj: {id: 0, number: '', letter: ''},
          birthDay: '',
        }
      }
    });
    builder.addCase(fetchAdminEditChildConfirmAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.childData) state.adminEditChildConfirmAplication = {...state.adminEditChildConfirmAplication, ...action.payload.childData};
    });
    builder.addCase(fetchAdminEditChildConfirmAplication.rejected, (state, action) => {
    });


    builder.addCase(fetchAdminConectedProgramAplication.pending, (state, action) => {
      state.adminConectedProgramAplication = {
        ...state.adminConectedProgramAplication,
        id: 0,
        name: '',
        city: '',
        school: '',
        classNumber: '',
        classLetter: '',
        program: {
            id: 0,
            name: '',
            color: ''
        }
      }

    });
    builder.addCase(fetchAdminConectedProgramAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.programData) state.adminConectedProgramAplication = {...state.adminConectedProgramAplication, ...action.payload.programData};
    });
    builder.addCase(fetchAdminConectedProgramAplication.rejected, (state, action) => {
    });

    
    builder.addCase(fetchAdminConfirmDocumentAplication.pending, (state, action) => {
      state.adminConfirmDocumentAplication = {
        ...state.adminConfirmDocumentAplication,
        clientName: '',
        type: 0,
        document: {
            id: 0,
            name: '',
            date: '',
            status: null,
            link: ''
        },
      }
    });
    builder.addCase(fetchAdminConfirmDocumentAplication.fulfilled, (state, action) => {
      if(action.payload && action.payload.documentData) state.adminConfirmDocumentAplication = {...state.adminConfirmDocumentAplication, ...action.payload.documentData};
    });
    builder.addCase(fetchAdminConfirmDocumentAplication.rejected, (state, action) => {
    });


    builder.addCase(fetchAdminConfirmCertificateAplication.pending, (state, action) => {
      state.adminConfirmCertificateAplication = {
        ...state.adminConfirmCertificateAplication,
        clientName: '',
        type: {
            id: 0,
            name: ''
        },
        document: {
            id: 0,
            validity: {
                startDate: '',
                endDate: '',
            },
            date: '',
            status: null,
            link: ''
        },
      }
    });
    builder.addCase(fetchAdminConfirmCertificateAplication.fulfilled, (state, action) => {
      console.log(action.payload);
      if(action.payload && action.payload.certificateData) state.adminConfirmCertificateAplication = {...state.adminConfirmCertificateAplication, ...action.payload.certificateData};
    });
    builder.addCase(fetchAdminConfirmCertificateAplication.rejected, (state, action) => {
    });
  },

});

const { actions, reducer } = adminAplicationConfirm;


export default reducer;

export const { 
    setAdminUserVerificationAplication,
    setAdminUserEditedAplication,
    setAdminConfirmDocumentAplication,
    setAdminConfirmCertificateAplication,
    setAdminConectedProgramAplication,
    setAdminEditChildConfirmAplication
} = actions;

