const Child = ({item, profileStatus, clickFunc, loading, deletedChildApplication}) =>{
    const {id, name, classObj, birthDay, city, school, isFinish, application} = item.isUpdate? {...item, ...item.update}: item;    
    
    const getStatusBlock = ({status=0, type=-1, comment='nullable'}) =>{        
        switch(type){
            case 1:
                return status === -1? 
                    `Изменения были отклонены. ${(comment !== 'nullable'? `\nПричина: ${comment}`: '')}` 
                    :status === 0? 'Ожидает подтверждения изменений.' 
                    :status === 1? 'Изменения были приняты.' 
                    : ''

            case 6:
                return status === -1? 
                    `Заявка на повторное заключения договора была отклонена. ${(comment !== 'nullable'? `\nПричина: ${comment}`: '')}` 
                    :status === 0? 'Ожидает рассмотрения заявки на повторное заключения договора.' 
                    :status === 1? 'Заявка на повторное заключения договора была принята.' 
                    : ''

            case 5:
                return status === -1? 
                `Заявка на добавление нового ребенка была отклонена. ${(comment !== 'nullable'? `\nПричина: ${comment}`: '')}`
                :status === 0? 'Ожидает рассмотрения заявки на добавление нового ребенка.' 
                :status === 1? 'Заявка на добавление нового ребенка была принята.' 
                :''

            default:
                return ''        
        }
    }

    const getButtonData = () =>{
        if(application){
            let {status=0, type=-1,} = application;
            switch(type){
                case 1:
                    return  'Посмотреть изменения'   
                case 6:
                    return status === -1 || status === 1? 'Просмотрено' : 'Заключить новый договор'
                case 5:
                    return status === 0? 'Редактировать': 'Просмотрено'
                default:
                    return ''        
            } 
        }
        else if(isFinish)
            return 'Заключить новый договор'
        else 
            return 'Редактировать'
        
    }

    const clickButton = () =>{
        if(application){
            let {status=0, type=-1,} = application;
            switch(type){
                case 1:
                    clickFunc(item)
                    break;
                case 6:
                    if(status === -1 || status === 1)
                        deletedChildApplication(application.id, {id, application: null, isFinish: status===-1? true: false})
                    else
                        clickFunc(item, 'ChildNewAgreement')
                    break;
                case 5:
                    if(status === -1 || status === 1)
                        deletedChildApplication(application.id, {id, application: null, comment: application.comment}, status === -1? 'delete-child': 'edit')
                    else
                        clickFunc(item)
                    break;
                default:
                    break;        
            } 
        }
        else if(isFinish)
            clickFunc(item, 'ChildNewAgreement')
        else 
            clickFunc(item)

    }

    let statusText = application? getStatusBlock({...application}): '';

    return (
        <div className={`user-page__about-container child w50-m24 ${statusText? 'border-purple': ''}`} key={`user-page__about-container-child-elem-${id}`}>
            <div className='d-flex justify-between about-profile-underline'>
                <div className='d-flex align-center'>
                    <div className='user-page__about-profile-name child'>{name}</div>
                </div>
                <div className='d-flex align-center ml-8'>
                    <div className='user-page__about-child-block-learning-class'>{classObj.number}{classObj.letter} <span className='computer'>класс</span> <span className='mobile'>кл</span></div>
                    {
                        (statusText)?
                            <div 
                                className='user-page__about-profile-status' 
                                data-tooltip={statusText}
                            />
                        : null
                    }
                </div>
            </div>

            <div className='user-page__underline'/>

            <div className='d-flex justify-between align-center flex-wrap'>
                <div className='user-page__field user-page__about-child-block-field date d-flex justify-between align-center'>
                    <div className='user-page__field-text'>{birthDay}г</div>
                    <div className='user-page__field-icon calendar'/>
                </div>
                <div className='user-page__field user-page__about-child-block-field city d-flex justify-between align-center'>
                    <div className='user-page__field-text'>{city.name}</div>
                    <div className='user-page__field-icon location'/>
                </div>
            </div>
            <div className='user-page__field d-flex justify-between align-center'>
                <div className='user-page__field-text'>{school.name}</div>
                <div className='user-page__field-icon scool'/>
            </div>

            <div className={`user-page__about-child-block-button back-purple ${((isFinish || (application && application.type === 6)) && profileStatus > 0)? 'background-purple': ''} ${(loading || (application && application.status === 0 && application.type !== 1) || profileStatus === 0)? 'deactive-button': ''}`} onClick={clickButton}>
                {getButtonData()}
            </div>
        </div>
    )
}

export default Child;