import './index.scss'
import { useDispatch, useSelector } from 'react-redux';
import { selectUserChildren } from './../../../../redux/users/children/selectors';
import { useState } from 'react';
import ChildDataBlock from './ChildDataBlock/index';
import { setUserChildrenDataElemEdit } from '../../../../redux/users/children/childrenSlice';
import { axiosProxyCatchPost } from './../../../../axiosProxy';

const ChildCurrentChanges = ({popupBack, closePopup}) =>{
    const dispatch = useDispatch()
    const { checkChild } = useSelector(selectUserChildren);
    const [displayPass, setDisplayPass] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const viewedChangesFunc = () =>{
        setError('')
        axiosProxyCatchPost({
            url: '/api/v1/parental/change-update-student-visible',
            data: {id: checkChild.update.id},
            resolved: () =>{
                if(checkChild.update.status === 1){
                    dispatch(setUserChildrenDataElemEdit({...checkChild.update, id: checkChild.id, isUpdate: false, recalculate: checkChild.recalculate}))
                }
                else{
                    dispatch(setUserChildrenDataElemEdit({id: checkChild.id, isUpdate: false}))
                }
                setLoading(false)
                closePopup()
            },
            rejected: (error) =>{
                if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                else setError('Что-то пошло не так*')
                setLoading(false)
            }
        })
    }
    return(
        <div className={`popup__container user-popup__current-changes-data-container`}>
             <div className={`d-flex justify-between`}>
                <ChildDataBlock data={checkChild} label={'Данные до редактирования'}/>
                <div className='user-popup__current-changes-data-line'/>
                <ChildDataBlock data={checkChild.update} currentData={checkChild} label={'Данные после редактирования'} isUpdateData={true}/>
            </div>

            <div className={`popup__error ${(checkChild.update.status !== 0)? '': 'd-none'}`}>{error}</div>
            <div className={`d-flex ${(checkChild.update.status !== 0)? '': 'd-none'}`}>
                <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={closePopup}>Закрыть</button>
                <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={viewedChangesFunc}>Просмотрено</button>
            </div>
        </div>
    )
}

export default ChildCurrentChanges;