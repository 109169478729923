import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminFinanceChildsData, fetchAdminFinanceDocumentsChildsData } from './asyncActions';
import { financeUserChilds } from './../../../../defaultData/admin/finance/childs';

const initialState = {
  adminFinanceChildsData: [
    // ...financeUserChilds
  ],
  adminFinanceDocumentsChildsData: [
    // ...financeUserChilds
  ],

  adminFinanceCheckedChild:{
    id: 0,
    name: '',
    status: 0,
    city: {
        id: 0,
        name: ''
    },
    school:{
        id: 0,
        name: ''
    },
    classObj: {id: 0, number: '', letter: ''},
    programs: []
  },

  discount: '',
};

const adminFinanceChilds = createSlice({
  name: 'AdminFinanceChilds',
  initialState,
  reducers: {
    setAdminFinanceChildsData(state, action) {
      state.adminFinanceChildsData = action.payload;      
    },
    setAdminFinanceChildsDataEdit(state, action) {
      state.adminFinanceChildsData = state.adminFinanceChildsData.map(item=>(item.id === action.payload.id)? {...item, ...action.payload}: item);
    },
    setAdminFinanceChildsDataAdd(state, action) {
      state.adminFinanceChildsData = [...state.adminFinanceChildsData, action.payload];
    },
    setAdminFinanceChildsDataDelete(state, action) {
      state.adminFinanceChildsData = state.adminFinanceChildsData.filter(item=>item.id !== action.payload.id);
    },

    setAdminFinanceCheckedChild(state, action) {
      state.adminFinanceCheckedChild = {...state.adminFinanceCheckedChild, ...action.payload};    
    },
    setAdminFinanceCheckedChildRestore(state) {
      state.adminFinanceCheckedChild = {
          id: 0,
          name: '',
          status: 0,
          city: {
              id: 0,
              name: ''
          },
          school:{
              id: 0,
              name: ''
          },
          classObj: {id: 0, number: '', letter: ''},
          programs: []
      };    
    },

    setAdminFinanceDiscount(state, action){
      state.discount = action.payload;
    }

  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminFinanceChildsData.pending, (state, action) => {
      state.adminFinanceChildsData = [
      ];   
      state.discount='';
    });
    builder.addCase(fetchAdminFinanceChildsData.fulfilled, (state, action) => {
      if(action.payload && action.payload.childs && Array.isArray(action.payload.childs)){
        state.adminFinanceChildsData = action.payload.childs;
        if(action.payload.discount || action.payload.discount === 0) state.discount = action.payload.discount;
      }
    });
    builder.addCase(fetchAdminFinanceChildsData.rejected, (state, action) => {
    });



    builder.addCase(fetchAdminFinanceDocumentsChildsData.pending, (state, action) => {
      state.adminFinanceDocumentsChildsData = [];   
    });
    builder.addCase(fetchAdminFinanceDocumentsChildsData.fulfilled, (state, action) => {
      if(action.payload && action.payload.childs && Array.isArray(action.payload.childs)){
        state.adminFinanceDocumentsChildsData = action.payload.childs;
      }
    });
    builder.addCase(fetchAdminFinanceDocumentsChildsData.rejected, (state, action) => {
    });
  },

});

const { actions, reducer } = adminFinanceChilds;


export default reducer;

export const { 
  setAdminFinanceChildsData,
  setAdminFinanceChildsDataEdit,
  setAdminFinanceChildsDataAdd,
  setAdminFinanceChildsDataDelete,
  setAdminFinanceCheckedChild,
  setAdminFinanceDiscount,
  setAdminFinanceCheckedChildRestore
} = actions;

