import './index.scss'
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserProfile } from './../../../../redux/users/profile/selectors';
import { setUserProfile } from '../../../../redux/users/profile/profileSlice';
import { axiosProxyCatchPost } from '../../../../axiosProxy';
import RequestCode from './RequestCode';
import ConfirmEmail from './ConfirmEmail';
import SuccessfullyConfrim from './SuccessfullyConfrim';


const EditEmail = ({popupBack, closePopup}) =>{
    const dispatch = useDispatch()
    const { profileData } = useSelector(selectUserProfile);

    const [errorText, setErrorText] = useState('')
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        email: '',
    })
    const [code, setCode] = useState(['', '', '', '', '', ''])

    const [confirmationCodeIsSent, setConfirmationCodeIsSent] = useState(false)
    const [isSuccessfullyConfrim, setIsSuccessfullyConfrim] = useState(false)

    const [date, setDate] = useState()  
    


    useEffect(()=>{        
        if(popupBack){
            setFormData({
                email:profileData.newEmail? profileData.newEmail: '',
            })
            setCode(['', '', '', '', '', ''])
            setIsSuccessfullyConfrim(false)
            setErrorText('')
    
            let timerDate = localStorage.getItem('Edit_Mail_Request_Confirmation_Code_Time_Date')
            if(timerDate && new Date(timerDate) > new Date()) setDate(new Date(timerDate))
            else{
                localStorage.removeItem('Edit_Mail_Request_Confirmation_Code_Time_Date')
                setDate(null)
            }
        }
    }, [popupBack])

    const requestConfirmationCodeFunc = (isRepeated = false) =>{        
        setErrorText('')    
        if(formData.email && formData.email.indexOf('@') !== -1){
            axiosProxyCatchPost({
                url: '/api/v1/parental/profile/edit-email',
                data: {email: formData.email},
                resolved:() =>{
                    if(isRepeated){
                        let dateTimer = new Date()
                        dateTimer.setMinutes(dateTimer.getMinutes() + 5)            
                        localStorage.setItem('Edit_Mail_Request_Confirmation_Code_Time_Date', dateTimer.toISOString())
                        setDate(dateTimer)
                    }
                    else{
                        setConfirmationCodeIsSent(true)
                    }
                    dispatch(setUserProfile({id:profileData.id, newEmail: formData.email}))
                },
                rejected: (error) =>{
                    if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setErrorText(error.data.detail)
                    else setErrorText('Что-то пошло не так*')
                    setLoading(false)
                } 
            })
        }
        else setErrorText('Необходимо указать корректный email*')        
    }

    return(
        <div className={`popup__container user-popup__container edit-email`}>
            <div className='popup__title mb-16 fs-24' onClick={closePopup}>{isSuccessfullyConfrim? 'Почта успешно изменена': 'Сменить почту'}</div>  
            {
                (isSuccessfullyConfrim)?
                    <SuccessfullyConfrim 
                        closePopup={closePopup} 
                        email={formData.email}
                    />                
                :(confirmationCodeIsSent)?
                    <RequestCode 
                        email={formData.email} 
                        code={code} 
                        setCode={setCode} 
                        date={date} 
                        setDate={setDate} 
                        requestConfirmationCodeFunc={requestConfirmationCodeFunc}
                        setConfirmationCodeIsSent={setConfirmationCodeIsSent}
                        loading={loading}
                        setLoading={setLoading}
                        errorText={errorText}
                        setErrorText={setErrorText}
                        setIsSuccessfullyConfrim={setIsSuccessfullyConfrim}
                        profileData={profileData}
                    />
                :
                    <ConfirmEmail
                        formData={formData} 
                        setFormData={setFormData} 
                        requestConfirmationCodeFunc={requestConfirmationCodeFunc}
                        closePopup={closePopup}
                        loading={loading}
                        setLoading={setLoading}
                        errorText={errorText}
                        setErrorText={setErrorText}
                        date={date} 
                        setDate={setDate}
                        setConfirmationCodeIsSent={setConfirmationCodeIsSent}
                    />
            }
        </div>
    )
}

export default EditEmail;