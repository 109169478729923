import { getStatus } from '../../../../User/Documents/DocumentElem';

import '../DocumentConfirm/index.scss'
import './index.scss'
import Calend from './../../../../Calendar/Calendar';
import { useEffect, useState } from 'react';
import RefusalComment from '../../RefusalComment';
import { useDispatch, useSelector } from 'react-redux';
import { selectAdminAplicationConfirm } from '../../../../../redux/admin/aplications/aplicationConfirm/selectors';
import { fetchAdminConfirmCertificateAplication } from '../../../../../redux/admin/aplications/aplicationConfirm/asyncActions';
import { downloadFilePost, getDateFormatFunc, getDateFormatFuncServer, getDocumentType } from './../../../../../services/clientHome';
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import { setAdminAplicationsDataDeleteElem } from '../../../../../redux/admin/aplications/aplications/aplicationsSlice';


const CertificateConfirm = ({closePopup, popupBack}) =>{
    const dispatch = useDispatch();

    const { adminConfirmCertificateAplication:documentData } = useSelector(selectAdminAplicationConfirm);

    const [calendActive, setCalendActive] = useState(false)
    const [dateActive, setDateActive] = useState('')
    const [commentIsActive, setCommentIsActive] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [downloadLoading, setDownloadLoading] = useState(false)

    const [certificateDate, setCertificateDate] = useState({
        startDate: '',
        endDate: '',
    })

    useEffect(()=>{
        setCertificateDate({
            startDate: '',
            endDate: '',
        })
        setCalendActive(false)
        setCommentIsActive(false)
        setDateActive('')
        dispatch(fetchAdminConfirmCertificateAplication({id: documentData.id}))        
    }, [popupBack])

    useEffect(()=>{
        if(documentData.id) 
            setCertificateDate({
                startDate: documentData.document.startDate,
                endDate: documentData.document.endDate,
            })
    }, [documentData])

    const confirmAndRefuseFunc = (confirm, comment='') =>{
        setConfirmLoading(true)
        setCommentIsActive(false)
        const postData = {
            id: documentData.document.id,
            // documentId: documentData.document.id,
            status: confirm? 1: -1,
            documentStatus: documentData.document.status
        }
        if(!confirm && comment) postData.comment = comment;

        if(!confirm || certificateDate.startDate === documentData.document.startDate && certificateDate.endDate === documentData.document.endDate){
            confirmAndRefusePostFunc(postData)
        }
        else{
            let dateObj = {id: documentData.document.id}
            if(certificateDate.startDate) dateObj.startDate = getDateFormatFuncServer(certificateDate.startDate);
            if(certificateDate.endDate) dateObj.endDate = getDateFormatFuncServer(certificateDate.endDate);

            axiosProxyCatchPost({
                url: '/api/v1/administrative/finance/update-certificate',
                data: dateObj,
                resolved: (data)=>{
                    confirmAndRefusePostFunc(postData)
                },
                rejected: (error)=>{                    
                    setConfirmLoading(0)
                }
            })
        }
        
        
    }

    // const confirmAndRefusePostFunc = (postData) =>{
    //     axiosProxyCatchPost({
    //         url: '/api/v1/administrative/applications/update-document-status',
    //         data: postData,
    //         resolved: (data)=>{
    //             axiosProxyCatchPost({
    //                 url: '/api/v1/administrative/applications/update-application-status',
    //                 data: { ...postData, id: documentData.id},
    //                 resolved: (data)=>{
    //                     dispatch(setAdminAplicationsDataDeleteElem(documentData.id))
    //                     closePopup()
    //                     setConfirmLoading(false)
    //                 },
    //                 rejected: (error)=>{
    //                     setConfirmLoading(false)                        
    //                 }
    //             })
    //         },
    //         rejected: (error)=>{
    //             setConfirmLoading(false)                
    //         }
    //     })
    // }


    const confirmAndRefusePostFunc = (postData) =>{
        console.log(postData);
        axiosProxyCatchPost({
            url: '/api/v1/administrative/applications/update-document-status',
            data: postData,
            resolved: (data)=>{
                // dispatch(setAdminAplicationsDataDeleteElem(documentData.id))
                
                if(postData.status === 1){
                    axiosProxyCatchPost({
                        url: '/api/v1/administrative/applications/accept-certificate',
                        data: postData,
                        resolved: (data)=>{
                            axiosProxyCatchPost({
                                url: '/api/v1/administrative/applications/update-application-status',
                                data: { ...postData, id: documentData.id},
                                resolved: (data)=>{
                                    dispatch(setAdminAplicationsDataDeleteElem(documentData.id))
                                    closePopup()
                                    setConfirmLoading(false)
                                },
                                rejected: (error)=>{
                                    setConfirmLoading(false)                        
                                }
                            })
                        },
                        rejected: (error)=>{
                            // setConfirmLoading(false)
                        }
                    })
                }
                else{
                    axiosProxyCatchPost({
                        url: '/api/v1/administrative/applications/update-application-status',
                        data: { ...postData, id: documentData.id},
                        resolved: (data)=>{
                            dispatch(setAdminAplicationsDataDeleteElem(documentData.id))
                            closePopup()
                            setConfirmLoading(false)
                        },
                        rejected: (error)=>{
                            setConfirmLoading(false)                        
                        }
                    })
                }
            },
            rejected: (error)=>{
                // setConfirmLoading(false)
            }
        })

        if(postData.documentStatus === null){
            axiosProxyCatchPost({
                url: '/api/v1/administrative/applications/update-application-status',
                data: { ...postData, id: documentData.id},
                resolved: (data)=>{
                    dispatch(setAdminAplicationsDataDeleteElem(documentData.id))
                    closePopup()
                    setConfirmLoading(false)
                },
                rejected: (error)=>{
                    setConfirmLoading(false)                        
                }
            })
        }

        
    }



    return(
        <div className={`popup__container admin-popup__documnet-confirm admin-popup__setificate-confirm`} onClick={(e)=>{
            if(calendActive && !e.target.classList.contains("admin-popup__sertificate-confirm-line-item-validate-item") && e.target.closest(".admin-popup__sertificate-confirm-calendar") === null){
                setCalendActive(false)
                setDateActive('')
            }
            if(e.target.closest(".admin-popup__documnet-confirm-button-container") === null){
                setCommentIsActive(false)
            }
        }}>
            <div className="admin-popup__documnet-confirm-top">
                <div className='admin-popup__documnet-confirm-top-name'>{documentData.clientName}</div>
                <div className='admin-popup__documnet-confirm-top-type'>Справки</div>
            </div>

            <div className='admin-popup__documnet-confirm-line title mb-8 d-flex justify-between align-center'>
                <div className='admin-popup__sertificate-confirm-line-item validate'>Период</div>
                <div className='admin-popup__documnet-confirm-line-item date'>Дата загрузки</div>
                <div className='admin-popup__documnet-confirm-line-item status-title'>Статус</div>
                <div className='admin-popup__sertificate-confirm-line-item buttons'>Действие</div>
            </div>
            
            <div className='admin-popup__documnet-confirm-line mb-16 d-flex justify-between align-center'>
                <div className='admin-popup__sertificate-confirm-line-item validate d-flex align-center'>
                    <div className='admin-popup__documnet-confirm-line-item-document-icon'/>
                    <div className='admin-popup__sertificate-confirm-line-item-validate d-flex'>
                        <div className={`admin-popup__sertificate-confirm-line-item-validate-item mr-6 ${dateActive === 'start'? 'active': ''}`} onClick={()=>{
                            setDateActive(dateActive === 'start'? '': 'start')
                            setCalendActive(dateActive === 'start'? false: true)
                        }}>
                            {certificateDate.startDate}
                        </div>

                        <div>-</div> 

                        <div className={`admin-popup__sertificate-confirm-line-item-validate-item ml-6 ${dateActive === 'end'? 'active': ''}`} onClick={()=>{
                            setDateActive(dateActive === 'end'? '': 'end')
                            setCalendActive(dateActive === 'end'? false: true)
                        }}>
                            {certificateDate.endDate}
                        </div>
                        
                        {
                            (certificateDate.startDate && certificateDate.endDate)? <Calend classes={`admin-popup__sertificate-confirm-calendar ${calendActive? 'active': ''}`} checkDate={(dateActive === 'start')? getDateFormatFunc(certificateDate.startDate): (dateActive === 'end')? getDateFormatFunc(certificateDate.endDate): null} setCheckDate={(data)=>{
                                if(dateActive === 'start') setCertificateDate({...certificateDate, startDate: data.toLocaleDateString()}) 
                                if(dateActive === 'end') setCertificateDate({...certificateDate, endDate: data.toLocaleDateString()}) 
                                setCalendActive(false)
                                setDateActive('')
                            }}/>: null
                        }
                    </div>
                </div>
                <div className='admin-popup__documnet-confirm-line-item date'>{documentData.document.date}</div>
                <div className={`admin-popup__documnet-confirm-line-item status ${getStatus(documentData.document.status).color}`}>{getStatus(documentData.document.status).label}</div>
                <div className='admin-popup__sertificate-confirm-line-item buttons d-flex justify-end align-center'>
                    {/* <div className={`admin-popup__sertificate-confirm-line-item-button download ${downloadLoading? 'deactive-button': ''}`} onClick={() => downloadFilePost({url:'/api/v1/parental/document/get-layout?', name: documentData.name, type: '.pdf', postData: {id: documentData.id, studentId: 0}, setLoading: setDownloadLoading})}/> */}
                    {/* <a className='admin-popup__sertificate-confirm-line-item-button download' href={`http://localhost:3000`+documentData.document.link} download/> */}
                    <div className={`admin-popup__documnet-confirm-line-item-button download ${downloadLoading? 'deactive-button': ''}`} onClick={() => {
                        if(documentData.document.id){
                            downloadFilePost({url:'/api/v1/administrative/finance/get-file?', name: `${documentData.document.startDate} - ${documentData.document.endDate}`, type: (documentData.document.extesion && documentData.document.extesion.indexOf('.') === -1)? '.'+documentData.document.extesion: (documentData.document.extesion)? documentData.document.extesion: '', postData: {documentId: documentData.document.id}, setLoading: setDownloadLoading})
                        }
                    }}/>
                </div>
            </div>
            
            <div className='d-flex'>
                <div className='admin-popup__documnet-confirm-button-container wd-50-mr-16'>
                    <button className={`popup__button cancel admin-popup__documnet-confirm-button-cancel ${confirmLoading? 'deactive-button': ''}`} onClick={()=>setCommentIsActive(true)}>Отклонить</button>
                    <RefusalComment setCommentClose={()=>setCommentIsActive(false)} classes={`admin-popup__documnet-confirm-button-comment additionaly-popup-on-top ${commentIsActive? 'active': ''}`} clickFunc={(comment)=>confirmAndRefuseFunc(false, comment)}/>
                </div>
                <button className={`popup__button wd-50-mr-16 save ${confirmLoading? 'deactive-button': ''}`} onClick={()=>confirmAndRefuseFunc(true)}>Подтвердить</button>
            </div>
        </div>
    )
}

export default CertificateConfirm;