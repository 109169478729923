

const ChildDataBlock = ({data, label, currentData={}, isUpdateData=false}) =>{
    return(
        <div className={`user-popup__current-changes-data wd-50-m16`}>
            <div className='user-popup__current-changes-data-subtitle'>{label}</div>

            <label htmlFor="" className='popup__label mb-4'>ФИО</label>
            <input type="text" className={`popup__input mb-16 ${(isUpdateData && currentData.name !== data.name)? 'border-active': ''}`} readOnly value={data.name}/>

            <label htmlFor="" className='popup__label mb-4'>Дата рождения</label>
            <input type="text" className={`popup__input mb-16 ${(isUpdateData && currentData.birthDay !== data.birthDay)? 'border-active': ''}`} readOnly value={data.birthDay}/>

            <label htmlFor="" className='popup__label mb-4'>Город</label>
            <input type="text" className={`popup__input mb-16 ${(isUpdateData && currentData.city.id !== data.city.id)? 'border-active': ''}`} readOnly value={data.city.name}/>

            <label htmlFor="" className='popup__label mb-4'>Школа</label>
            <input type="text" className={`popup__input mb-16 ${(isUpdateData && currentData.school.id !== data.school.id)? 'border-active': ''}`} readOnly value={data.school.name}/>

            <label htmlFor="" className='popup__label mb-4'>Класс</label>
            <input type="text" className={`popup__input ${(isUpdateData && currentData.classObj.id !== data.classObj.id)? 'border-active': ''}`} readOnly value={`${data.classObj.number}${data.classObj.letter}`}/>
        </div>
    )
}

export default ChildDataBlock;


/// id, name, classObj, birthDay, city, school