import './index.scss'
import Timer from '../../../Filters/Timer';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { axiosProxyCatchPost } from '../../../../axiosProxy';
import { setTeacherProfileDataEdit } from '../../../../redux/teacher/about/aboutSlice';


const RequestCode = ({email, code, setCode, date, setDate, requestConfirmationCodeFunc, loading, setLoading, setConfirmationCodeIsSent, errorText, setErrorText, setIsSuccessfullyConfrim, profileData}) =>{
    const dispatch = useDispatch()
    const codeRefs = useRef([])

    const confirmEmailFunc = () =>{        
        if(code.indexOf('') === -1){
            setLoading(true)
            axiosProxyCatchPost({
                url: '/api/v1/teaching/profile/confirm-email',
                data: {email: email, code: code.join('')},
                resolved: () =>{
                    setConfirmationCodeIsSent(false)
                    setIsSuccessfullyConfrim(true)
                    dispatch(setTeacherProfileDataEdit({newEmail: '', email: email}))
                    localStorage.removeItem('Edit_Mail_Request_Confirmation_Code_Time_Date')
                    setCode(['', '', '', '', '', ''])
                    setLoading(false)
                },
                rejected: (error) =>{
                    if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setErrorText(error.data.detail)
                    else setErrorText('Что-то пошло не так*')
                    setLoading(false)
                }
            })
        }
        else setErrorText('Необходимо ввести код подтверждения*')        
    }

    const deleteCodeElem = (e, index) =>{               
        if(e.key === 'Backspace'){
            if (code[index].match(/^[0-9]$/)) {
                let resultCode = [...code]
                resultCode = resultCode.map((item, key)=>{
                    if(key >= index)
                        return ''
                    else 
                        return item
                })                
                setCode(resultCode)
            } else if(index) {
                codeRefs.current[index - 1].focus();
            }
        }
    }

    const editCodeFunc = (e, index) => {             
        let val = e.target.value;
        if(val.length > 1 && val.match(/^[0-9]+$/) && index === 0){            
            val = val.split('') 
            if(val[0] === code[0]) val = val.slice(1)
            if(val.length > 6) val = val.slice(0, 6)
                        
            val = code.map((elem, key)=>{
                if(val.length-1 >= key){
                    console.log(key, elem);
                    
                    return val[key]
                }
                else return '' 
            })
            
            setCode(val)
            codeRefs.current[val.length - 1].focus();                             
            codeRefs.current[val.length - 1].blur();                             
        }
        else if(val.match(/^[0-9]$/)){
            let resultCode = [...code]        
            if(val && index < resultCode.length - 1){
                codeRefs.current[index + 1].focus();
            }
            resultCode[index] = val 
            setCode(resultCode)
        }
    }

    return(
        <div className='d-flex flex-column align-center'>
            <div className='teacher__about-container-subtitle mb-24 text-center'>
                Мы отправили код по адресу {email}
                <hr/>Пожалуйста, введите его для подтверждения вашей почты.
            </div>
            <div className='d-flex align-center justify-center mb-24'>
                {
                    code.map((item, index) => 
                        <input type="text"
                            key={`teacher-edit-email-confirmation-code-input-${index}`}
                            value={item} 
                            onKeyDown={(e)=>deleteCodeElem(e, index)}
                            onChange={(e)=>editCodeFunc(e, index)}
                            ref={element => codeRefs.current[index] = element}
                            readOnly={!(index===0 || (index && code[index-1]))}
                            className={`teacher__about-edit-email-confirmation-code-input ${index===0 || (index && code[index-1])? '': 'popup__disable-input'}`}
                        />
                    )
                }
            </div>
            {
                (date)?
                <div className='teacher__about-container-subtitle'>
                    Повторная отправка кода будет доступна через <span className='teacher__about-edit-email-confirmation-code-timer'><Timer date={date} setDate={setDate}/></span>
                </div>
                :<div className='teacher__about-container-subtitle'>
                    Если не получили код, нажмите на кнопку "Отправить код еще раз"
                </div>
            }

            <div className='d-flex justify-between align-center mb-24'>
                <div className={`popup__confirmation-code-repeat-request-code ${date? 'deactive-button': ''}`} onClick={()=>requestConfirmationCodeFunc(true)}>Отправить код еще раз</div>
            </div>

            <div className={`teacher__about-container-error height-auto ${errorText? 'active': ''}`}>{errorText}</div>
            
            <div className={`d-flex wd-100`}>
                <button className={`teacher__about-button wd-50-mr-16 ${(loading )? 'deactive-button': ''}`} onClick={()=>setConfirmationCodeIsSent(false)}>
                    Вернуться назад
                </button>
                <button className={`teacher__about-button purple wd-50-mr-16 ${(loading)? 'deactive-button': ''}`} onClick={confirmEmailFunc}>
                    Подтвердить почту
                </button>
            </div>
        </div>
    )
}

export default RequestCode;