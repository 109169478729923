// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./width-744-adaptive.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-page__header-navbar {
  background: #F0F0F080;
  border: 1px solid #E6E6E6;
  border-radius: 16px;
  padding: 3px;
  position: relative; }
  .user-page__header-navbar-item {
    width: 152px;
    text-align: center;
    color: #1A1A1A;
    font-size: 16px;
    font-weight: 600;
    padding: 12px;
    border-radius: 12px;
    position: relative; }
    .user-page__header-navbar-item-active-elem {
      width: 152px;
      height: calc(100% - 6px);
      background: #fafafa;
      border-radius: 12px;
      position: absolute;
      left: 3px;
      z-index: 0;
      box-shadow: 0px 0px 8px 0px #6666660F;
      border: 1px solid #E6E6E6;
      transition: .3s all; }
      .user-page__header-navbar-item-active-elem.position-1 {
        left: 156px; }
      .user-page__header-navbar-item-active-elem.position-2 {
        left: 308px; }
      .user-page__header-navbar-item-active-elem.position-3 {
        left: 460px; }
`, "",{"version":3,"sources":["webpack://./src/components/User/Header/Navbar/index.scss"],"names":[],"mappings":"AAEA;EACI,qBAAqB;EACrB,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB,EAAA;EAElB;IACI,YAAY;IACZ,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,kBAAkB,EAAA;IAElB;MACI,YAAY;MACZ,wBAAwB;MACxB,mBAAmB;MACnB,mBAAmB;MACnB,kBAAkB;MAClB,SAAS;MACT,UAAU;MACV,qCAAqC;MACrC,yBAAyB;MACzB,mBAAmB,EAAA;MAVtB;QAaO,WAAW,EAAA;MAblB;QAiBO,WAAW,EAAA;MAjBlB;QAqBO,WAAW,EAAA","sourcesContent":["@import url(./width-744-adaptive.scss);\n\n.user-page__header-navbar{\n    background: #F0F0F080;\n    border: 1px solid #E6E6E6;\n    border-radius: 16px;\n    padding: 3px;\n    position: relative;\n\n    &-item{\n        width: 152px;\n        text-align: center;\n        color: #1A1A1A;\n        font-size: 16px;\n        font-weight: 600;\n        padding: 12px;\n        border-radius: 12px;\n        position: relative;\n\n        &-active-elem{\n            width: 152px;\n            height: calc(100% - 6px);\n            background: #fafafa;\n            border-radius: 12px;\n            position: absolute;\n            left: 3px;\n            z-index: 0;\n            box-shadow: 0px 0px 8px 0px #6666660F;\n            border: 1px solid #E6E6E6;\n            transition: .3s all;\n\n            &.position-1{\n                left: 156px;\n            }\n\n            &.position-2{\n                left: 308px;\n            }\n\n            &.position-3{\n                left: 460px;\n            }\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
