// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-popup__purple-subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #b158ff;
  padding: 6px 0;
  margin-left: 6px;
  cursor: pointer;
  border-bottom: 1px solid #b158ff00;
  display: inline-block;
  transition: color .3s; }
  .user-popup__purple-subtitle:hover {
    color: #6312aa; }
`, "",{"version":3,"sources":["webpack://./src/components/User/Popup/EditProfile/index.scss"],"names":[],"mappings":"AACI;EACI,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,kCAAkC;EAClC,qBAAqB;EACrB,qBAAqB,EAAA;EATxB;IAYO,cAAc,EAAA","sourcesContent":[".user-popup{\n    &__purple-subtitle{\n        font-size: 16px;\n        font-weight: 500;\n        color: #b158ff;\n        padding: 6px 0;\n        margin-left: 6px;\n        cursor: pointer;\n        border-bottom: 1px solid #b158ff00;\n        display: inline-block;\n        transition: color .3s;\n\n        &:hover{\n            color: #6312aa;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
