import { createSlice } from '@reduxjs/toolkit';
import { fetchUserProfileData } from './asyncActions';
import { profile } from './../../../defaultData/user/about/profile';

const initialState = {
    profileData: sessionStorage.getItem('profileData')? JSON.parse(sessionStorage.getItem('profileData')): {
      id: 0, 
      name: '',
      status: 0, 
      email: '', 
      phone: '',
      financeAccess: false,
      isUpdate: false,
    },
    // profileData:{
    //   ...profile
    // },

    profileCheckData: {
      id: 0, 
      name: '',
      email: '', 
      phone: '',
    },

};

const userProfile = createSlice({
  name: 'UserProfile',
  initialState,
  reducers: {
    setUserProfile(state, action) {
      sessionStorage.setItem('profileData', JSON.stringify({...state.profileData, ...action.payload}))
      state.profileData = {...state.profileData, ...action.payload};
    },

    setUserProfileCheckData(state, action) {
      state.profileData = {...state.profileCheckData, ...action.payload};      
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfileData.pending, (state, action) => {
    });

    builder.addCase(fetchUserProfileData.fulfilled, (state, action) => {      
      sessionStorage.setItem('profileData', JSON.stringify(action.payload? {...state.profileData, ...action.payload.data, isUpdate: action.payload.data.update? true: false}: state.profileData))
      state.profileData = action.payload? {...state.profileData, ...action.payload.data, isUpdate: action.payload.data.update? true: false}: state.profileData;
    });

    builder.addCase(fetchUserProfileData.rejected, (state, action) => {
    });
  },
});

const { actions, reducer } = userProfile;


export default reducer;

export const { 
  setUserProfile,
  setUserProfileCheckData
} = actions;

